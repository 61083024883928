/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*@author Sanobar  Golandaj
 * Campaign Specification
 @version 1.0
 */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { CardBody } from "mdbreact";
import { CampaignSpecificationLoader } from "../loaders/CampaignSpecificationLoader"; // 3428 - karan - loader
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3767-added default header
import { css } from "emotion"; //Nilesh-5212- Added css 
import { Table } from "antd"; //Nilesh-5212 -Added table

var abmFile = [];
var suppressionFile = [];
var execlusionFile = [];
var otherFile = [];
//var assetFile = [];
var countryArray = [];
var jobLevelArray = [];
var companyRevenueArray = [];
var jobFunctionArray = [];
var employeeSizeArray = [];
var customJobFunctionArray = [];
//var customJobLevelArray = [];
//var regionArray = [];
//var greeting = "";

//Nilesh-5212- Added css
const tableCSS = css({
	backgroundColor: "white",
	borderStyle: "bold",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3)!important",
		color: "white",
	},
	"& thead > tr >th": {
		border: "1px solid black",
		color: "white",
	},
});

class CampaignSpecificationAdvertiserDashboardDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      newsuccess: "",
      fields: {},
      dynamic: [],
      campCount: [],
      assetListArray: [],
      linkListArray: [],
      gdprPublisher: [],
      // gdprNoPublisher:[],
      otherDetails: [],
      abmDetails: [],
      pendingCampaignCount: "",
      suppresionDetails: [],
      exclusionDetails: [],
      region: "",
      sumOfAllocatedLeads: [],
      assetLinkArray: [],
      campID: " ",
      campIDTemp: " ",
      parentCampID: "",
      reallocationID: "",
      id: "",
      leadAllocation: 0,
      stateCopy: [
        {
          allocatedLead: "",
          cpl: "",
          campCPL: "",
        },
      ],
      newDynamicArray: [],
      newGDPRPubArray: [],
      gdprStatus: "false",
      campaignName: "",
      requiredLeadPerAsset: "",
      multiTouch: "",
      btnStatus: "",
      // allocatedLead: "",
      pendingLead: 0,
      campaignVisibleTime: "",
      campAcceptReject: "",
      campaignDetailAllocates: [],
      campaignDetailAssign: [],
      campaignDetailID: [],
      liveCampaignList: [],
      onClickAllocatedLead: [],
      onClickAllocatedLeadPubList: [],
      assignDeliveredLead: [],
      errors: {},
      alertDisplay: "none",
      alertBorder: "",
      alertWidth: "",
      alertAlign: "",
      spanSpaceDisplay: "none",
      success: "",
      puberror: "",
      allocerrors: "",
      reerrors: "",
      dateerrors: "",
      agencyID: "",
      show: false,
      campaignBriefDetails: [],
      campaignBriefDeliveryDetails: [],
      campaignBriefCustomQuestionDetails: [],
      campaignBriefSupportingDocumentAssetDetails: [],
      campaignBriefSupportingDocumentAbmDetails: [],
      campaignBriefSupportingDocumentSuppresionDetails: [],
      campaignBriefSupportingDocumentExclusionDetails: [],
      campaignBriefSupportingDocumentOtherDetails: [],
      publisherCampCPL: [],
      mergedCampaignDetailAssign: [],
      mergedGdprPublisher: [],
      isSuppLink: false,
      deliveryURL: "",
      customizedColumns: [],
      customQuestionURL: "",
      pacingCampaignDetails: [],
      pacingUnit: "None",
      countryWiseAllocationArray: [],
      requiredCountryWiseAllocation: "",
      totalApiCalls: 12, //3428-karan-loader
      apiCallsCompleted: 0, //3428-karan-loader
      customizeMappingFormat: "", //kiran-4884-declare variable for customize mapping format
      extraAddedFields: "", //rutuja-5097 added to store 21-30 Extra fields
      extraAddedFieldsYes: "", //rutuja-5097 added to store yes value only of 21-30 Extra fields
      columnsequencingarray:[],// Nilesh-5212-Initialized columnsequencingarray for storing columnsequence array
      columnSequenceCheck:"No" //Nilesh-5212-Initialized columnSequenceCheck no
    };
    this.handleCampaignBriefDetails =
      this.handleCampaignBriefDetails.bind(this);
    this.handleFileDownload = this.handleFileDownload.bind(this);
    this.loadingRef = React.createRef(); //3428-karan-loader handlers
    this.closeLoadingRef = React.createRef(); //3428-karan-loader handlers
  } //end of constructor

  componentDidMount() {
    // const {user} = this.props.auth;
    // const {isAuthenticated, user} = this.props.auth;
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      this.loadingRef.current.click(); //3428-karan-loader
      var campID = this.props.greeting;
      this.setState({ campID: campID });
      this.handleCampaignBriefDetails(campID);
      //called by raunak to get customized column values
      let data = {
        campID: campID,
      };
      fetch("/agency/agencyDeliveryFormatMapping", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((customizedColumns) => {
          //3428-karan-loader
          this.setState(
            (prev) => ({
              apiCallsCompleted: prev.apiCallsCompleted + 1,
            }),
            () => {
              this.dismissLoader();
            }
          );
          this.setState({
            customizedColumns: customizedColumns,
            campID: campID,
          });
        })
        .catch(function (err) {
          //3428-karan-loader
          this.setState(
            (prev) => ({
              apiCallsCompleted: prev.apiCallsCompleted + 1,
            }),
            () => {
              this.dismissLoader();
            }
          );
          console.log(err);
        });
    }
    fetch("campaign/pacingDetailsForEdit?campID=" + campID + "")
      .then((res) => res.json())
      .then((pacingCampaignDetails) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        // const pacingResult = Array.from(
        // 	new Set(pacingCampaignDetails.map((p) => p.campID))
        // ).map((campID) => {
        // 	return {
        // 		campID: campID,
        // 		pacingUnit: pacingCampaignDetails.find((p) => p.campID === campID)
        // 			.pacingUnit,
        // 	};
        // });//kiran-4747-removing console warning-no used
        this.setState({ pacingCampaignDetails: pacingCampaignDetails });
        if (pacingCampaignDetails.length > 0) {
          this.setState({ pacingUnit: pacingCampaignDetails[0].pacingUnit });
        }
      })
      // 3428-karan- added missing catch block to handle loader
      .catch((err) => {
        console.log(err);
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
      });

    // this api for getting countrywise allocation
    fetch("campaignNew/getCountryWiseLead?campID=" + campID)
      .then((res) => res.json())
      .then((countryWiseAllocationArray) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        this.setState({
          countryWiseAllocationArray: countryWiseAllocationArray,
        });
      })
      .catch(function (err) {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        console.log(err);
      });
  }

  /**
   * @author Somnath Keswad
   * @param  Description handle the Campaign Modal Details PDF
   * @return Description return All steps campaign Details PDF
   */
  handleFileDownload(e) {
    var campID = this.state.campID;
    //var parentCampID = this.state.parentCampID;
    /**
     * @author Narendra Phadke
     * @param  Description handle the Campaign related Files
     * @return Description return All The File Download in Zip
     */

    var formData = new FormData();
    formData.append("campID", campID);
    //const { isAuthenticated, user } = this.props.auth;
    // var userID = user.id;
    //This api is used for dynamic pdf genration
    //snehal-task-3766-specification VAPT api call
    fetch(
      "agency/downloadDynamicPDFCampaignDetailsforAgency?campID=" +
        campID +
        "&campName=" +
        this.state.campaignName
    )
      .then((r) => r.blob())
      .then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
      .then(this.handlePDFFileShowDownload);

    fetch(
      "agency/downloadCampaignDetailsforAgency?campID=" +
        campID +
        "&campName=" +
        this.state.campaignName
    )
      .then((r) => r.blob())
      .then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
      .then(this.handleFileShowDownload);
  } /** End of handle File Download */
  handleFileShowDownload(blob) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    let zipFileDownloadName =
      this.state.parentCampID +
      "-" +
      this.state.reallocationID +
      "-" +
      this.state.campaignName +
      ".zip";

    var newBlob = new Blob([this.state.downloadFiles], {
      type: "application/zip",
    });
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = zipFileDownloadName;
    link.click();

    // ));
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the Campaign Specification Dynamic PDF Details
   * @return Description return pdf file campaign details
   */
  handlePDFFileShowDownload(blob) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    let zipFileDownloadName =
      this.state.parentCampID +
      "-" +
      this.state.reallocationID +
      "-" +
      this.state.campaignName +
      "-CampaignSpecification.pdf";
    var newBlob = new Blob([this.state.downloadFiles], {
      type: "application/pdf",
    });
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = zipFileDownloadName;
    link.click();

    // ));
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the Campaign Modal Details
   * @return Description return All steps campaign Details
   */
  handleCampaignBriefDetails(campID1) {
    var campID = campID1;

    this.setState({ campID: campID });

    //This API is for campaign details from campaign table

    fetch("/agency/campaignBriefDetailsForAgency?campID=" + campID + "")
      .then((res) => res.json())
      .then((campaignBriefDetails) => {
        console.log(
          "campaignBriefDetails" + JSON.stringify(campaignBriefDetails)
        );
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        this.setState({
          campaignBriefDetails: campaignBriefDetails,
          requiredLeadPerAsset: campaignBriefDetails[0].requiredLeadPerAsset,
          multiTouch: campaignBriefDetails[0].multiTouch,
          requiredCountryWiseAllocation:
            campaignBriefDetails[0].requiredCountryWiseAllocation,
        });
        var parentCampID = "";
        var allocationID = "";
        if (
          this.state.campaignBriefDetails &&
          this.state.campaignBriefDetails.length
        ) {
          parentCampID = this.state.campaignBriefDetails[0].parentCampID;
          allocationID = this.state.campaignBriefDetails[0].reallocationID;
        }
        if (this.state.campaignDetail && this.state.campaignDetail.length) {
          var str = this.state.campaignDetail[0].customJobFunction;
          var str_array = str.split(",");
          for (var i = 0; i < str_array.length; i++) {
            if (str_array[i] !== "") {
              customJobFunctionArray.push({ jobFunction: str_array[i] });
            }
          }
        }

        if (
          this.state.campaignBriefDetails &&
          this.state.campaignBriefDetails.length
        ) {
          var str1 = this.state.campaignBriefDetails[0].country;
          var str_array1 = str1.split(",");
          for (var l = 0; l < str_array1.length; l++) {
            countryArray.push(str_array1[l], " | ");
          }
        }

        if (
          this.state.campaignBriefDetails &&
          this.state.campaignBriefDetails.length
        ) {
          var str2 = this.state.campaignBriefDetails[0].jobLevel;
          var str_array2 = str2.split(",");
          for (var j = 0; j < str_array2.length; j++) {
            jobLevelArray.push(str_array2[j], " | ");
          }
        }
        if (
          this.state.campaignBriefDetails &&
          this.state.campaignBriefDetails.length
        ) {
          var str3 = this.state.campaignBriefDetails[0].jobFunction;
          var str_array3 = str3.split(",");
          for (var k = 0; k < str_array3.length; k++) {
            jobFunctionArray.push(str_array3[k], " | ");
          }
        }
        if (
          this.state.campaignBriefDetails &&
          this.state.campaignBriefDetails.length
        ) {
          var str4 = this.state.campaignBriefDetails[0].employeeSize;
          var str_array4 = str4.split(",");
          for (var m = 0; m < str_array4.length; m++) {
            employeeSizeArray.push(str_array4[m], ",");
          }
        }

        // if (this.state.campaignBriefDetails && this.state.campaignBriefDetails.length) {
        //   var str = this.state.campaignBriefDetails[0].customJobFunction;
        //   var str_array = str.split(',');
        //   for (var i = 0; i < str_array.length; i++) {
        //   customJobFunction.push(str_array[i],',');
        //   }
        // }
        if (
          this.state.campaignBriefDetails &&
          this.state.campaignBriefDetails.length
        ) {
          var str5 = this.state.campaignBriefDetails[0].companyRevenue;
          var str_array5 = str5.split(",");
          for (var n = 0; n < str_array5.length; n++) {
            companyRevenueArray.push(str_array5[n], ",");
          }
        }
        if (
          this.state.campaignBriefDetails &&
          this.state.campaignBriefDetails.length
        ) {
          var campaignName = this.state.campaignBriefDetails[0].campaignName;
          this.setState({ campaignName: campaignName });
        }

        //This API is for campaign delivery details from delivery format jobLevelArray

        fetch("/publisher/campaignBriefDeliveryDetails?campID=" + campID)
          .then((res) => res.json())
          .then((campaignBriefDeliveryDetails) => {
            //Rutuja-5097-added to get the 21-30 extra fileds value 
        const extraFields = ["extra21", "extra22", "extra23", "extra24", "extra25", "extra26", "extra27", "extra28", "extra29", "extra30"];

        //Rutuja-5097-Iterate over each object in the array and filter null values
        const extractedDetailsArray = campaignBriefDeliveryDetails.map(details => {
          return Object.fromEntries(
            Object.entries(details)
              .filter(([key, value]) => extraFields.includes(key) && value !== null)
          );
        }).filter(details => Object.keys(details).length > 0); // Filter out empty objects

        //Rutuja-5097 -added Function to transform keys extra21 to Extra 21
        const transformKey = (key) => {
          return key.replace(/extra(\d+)/, 'Extra $1');
        };

        //Rutuja-5097 - Filter the object where the value is "Yes" and transform keys
        const yesDetailsArray = extractedDetailsArray.map(details => {
          return Object.fromEntries(
            Object.entries(details)
              .filter(([key, value]) => value === "Yes")
              .map(([key, value]) => [transformKey(key), value]) // Transform the key here
          );
        }).filter(details => Object.keys(details).length > 0); // Filter out empty objects

          this.setState({
            extraAddedFields: extractedDetailsArray,
            extraAddedFieldsYes: yesDetailsArray
          });
            //3428-karan-loader
            this.setState(
              (prev) => ({
                apiCallsCompleted: prev.apiCallsCompleted + 1,
              }),
              () => {
                this.dismissLoader();
              }
            );
            this.setState({
              campaignBriefDeliveryDetails: campaignBriefDeliveryDetails,
            });
            if (campaignBriefDeliveryDetails.length > 0) {
              let deliveryFormat = campaignBriefDeliveryDetails;
              let alternatePhoneNo = "";
              var jobTitle = "";
              var jobLevel = "";
              var jobFunction = "";
              var companyRevenue = "";
              var companyEmployeeSize = "";
              var industry = "";
              var assetName = "";
              var assetID = "";
              var assetNameTouch1 = "";
              var assetNameTouch2 = "";
              var assetNameTouch3 = "";
              var assetTimestampTouch1 = "";
              var assetTimestampTouch2 = "";
              var assetTimestampTouch3 = "";

              if (deliveryFormat[0].alternatePhoneNo === "Yes") {
                alternatePhoneNo = '&ALTPH=""';
              }
              if (deliveryFormat[0].jobTitleValidation === "Yes") {
                jobTitle = '&jobTitle=""';
              }
              if (deliveryFormat[0].jobLevel === "Yes") {
                jobLevel = '&jobLevel=""';
              }
              if (deliveryFormat[0].jobFunction === "Yes") {
                jobFunction = '&jobFunction=""';
              }
              if (deliveryFormat[0].companyRevenue === "Yes") {
                companyRevenue = '&revenue=""';
              }
              if (deliveryFormat[0].companyEmployeeSize === "Yes") {
                companyEmployeeSize = '&companySize=""';
              }
              if (deliveryFormat[0].industry === "Yes") {
                industry = '&industry=""';
              }
              if (deliveryFormat[0].assetName === "Yes") {
                if (
                  deliveryFormat[0].assetNameTouch1 === "Yes" ||
                  deliveryFormat[0].assetNameTouch2 === "Yes" ||
                  deliveryFormat[0].assetNameTouch3 === "Yes"
                ) {
                } else {
                  assetName = '&assetName=""';
                }
              }
              if (deliveryFormat[0].assetNameTouch1 === "Yes") {
                assetNameTouch1 = '&assetNameTouch1=""';
              }
              if (deliveryFormat[0].assetTimestampTouch1 === "Yes") {
                assetTimestampTouch1 = '&assetTimestampTouch1=""';
              }
              if (deliveryFormat[0].assetNameTouch2 === "Yes") {
                assetNameTouch2 = '&assetNameTouch2=""';
              }
              if (deliveryFormat[0].assetTimestampTouch2 === "Yes") {
                assetTimestampTouch2 = '&assetTimestampTouch2=""';
              }
              if (deliveryFormat[0].assetNameTouch3 === "Yes") {
                assetNameTouch3 = '&assetNameTouch3=""';
              }
              if (deliveryFormat[0].assetTimestampTouch3 === "Yes") {
                assetTimestampTouch3 = '&assetTimestampTouch3=""';
              }
              if (deliveryFormat[0].supportDocID === "Yes") {
                assetID = '&assetID=""';
              }
              var linkedIn = "";
              if (deliveryFormat[0].linkedIn === "Yes") {
                linkedIn = '&linkedIn=""';
              }
              var comments = "";
              if (deliveryFormat[0].comments === "Yes") {
                comments = '&comments=""';
              }
              var domain = "";
              if (deliveryFormat[0].domain === "Yes") {
                domain = '&domain=""';
              }
              var ip = "";
              if (deliveryFormat[0].ip === "Yes") {
                ip = '&ip=""';
              }
              var extra1 = "";
              if (deliveryFormat[0].extra1 === "Yes") {
                extra1 = '&extra1=""';
              }
              var extra2 = "";
              if (deliveryFormat[0].extra2 === "Yes") {
                extra2 = '&extra2=""';
              }
              var extra3 = "";
              if (deliveryFormat[0].extra3 === "Yes") {
                extra3 = '&extra3=""';
              }
              var extra4 = "";
              if (deliveryFormat[0].extra4 === "Yes") {
                extra4 = '&extra4=""';
              }
              var extra5 = "";
              if (deliveryFormat[0].extra5 === "Yes") {
                extra5 = '&extra5=""';
              }
              // Sandeep-task-3158-added code for extra field from 6to20
              var extra6 = "";
              if (deliveryFormat[0].extra6 === "Yes") {
                extra6 = '&extra6=""';
              }
              var extra7 = "";
              if (deliveryFormat[0].extra7 === "Yes") {
                extra7 = '&extra7=""';
              }
              var extra8 = "";
              if (deliveryFormat[0].extra8 === "Yes") {
                extra8 = '&extra8=""';
              }
              var extra9 = "";
              if (deliveryFormat[0].extra9 === "Yes") {
                extra9 = '&extra9=""';
              }
              var extra10 = "";
              if (deliveryFormat[0].extra10 === "Yes") {
                extra10 = '&extra10=""';
              }

              var extra11 = "";
              if (deliveryFormat[0].extra11 === "Yes") {
                extra11 = '&extra11=""';
              }
              var extra12 = "";
              if (deliveryFormat[0].extra12 === "Yes") {
                extra12 = '&extra12=""';
              }
              var extra13 = "";
              if (deliveryFormat[0].extra13 === "Yes") {
                extra13 = '&extra13=""';
              }
              var extra14 = "";
              if (deliveryFormat[0].extra14 === "Yes") {
                extra14 = '&extra14=""';
              }
              var extra15 = "";
              if (deliveryFormat[0].extra15 === "Yes") {
                extra15 = '&extra15=""';
              }

              var extra16 = "";
              if (deliveryFormat[0].extra16 === "Yes") {
                extra16 = '&extra16=""';
              }
              var extra17 = "";
              if (deliveryFormat[0].extra17 === "Yes") {
                extra17 = '&extra17=""';
              }
              var extra18 = "";
              if (deliveryFormat[0].extra18 === "Yes") {
                extra18 = '&extra18=""';
              }
              var extra19 = "";
              if (deliveryFormat[0].extra19 === "Yes") {
                extra19 = '&extra19=""';
              }
              var extra20 = "";
              if (deliveryFormat[0].extra20 === "Yes") {
                extra20 = '&extra20=""';
              }

              var allocationIDData = "";
              if (deliveryFormat[0].reAllocationID === "Yes") {
                allocationIDData = "&allocationID=" + allocationID;
              }
              var street = ""; //Somnath Task:3002, Add street if checked
              if (deliveryFormat[0].street === "Yes") {
                street = '&street=""';
              }
              let deliveryURL =
                "https://demandintegrate.azurewebsites.net/leadAPIURL?campID=" +
                parentCampID +
                '&pID=""&LIDT=MM/DD/YYYY&email=""&fname=""&lname=""&ADD=""&WP=""' +
                alternatePhoneNo +
                '&city=""&state=""&zipcode=""&country=""&companyName=""' +
                street +
                jobTitle +
                jobLevel +
                jobFunction +
                companyRevenue +
                companyEmployeeSize +
                industry +
                assetName +
                assetNameTouch1 +
                assetTimestampTouch1 +
                assetNameTouch2 +
                assetTimestampTouch2 +
                assetNameTouch3 +
                assetTimestampTouch3 +
                assetID +
                allocationIDData +
                linkedIn +
                comments +
                domain +
                ip +
                extra1 +
                extra2 +
                extra3 +
                extra4 +
                extra5 +
                extra6 +
                extra7 +
                extra8 +
                extra9 +
                extra10 +
                extra11 +
                extra12 +
                extra13 +
                extra14 +
                extra15 +
                extra16 +
                extra17 +
                extra18 +
                extra19 +
                extra20;
              let customizeMappingFormat =
                campaignBriefDeliveryDetails[0].customizeMappingFormat; // kiran-4884-taken value from array and setState
              this.setState({
                deliveryURL: deliveryURL,
                customizeMappingFormat,
              });
            }
          })
          .catch(function (err) {
            //3428-karan-loader
            this.setState(
              (prev) => ({
                apiCallsCompleted: prev.apiCallsCompleted + 1,
              }),
              () => {
                this.dismissLoader();
              }
            );
            console.log(err);
          });
      })
      // 3428-karan- added missing catch block to handle loader
      .catch((err) => {
        console.log(err);
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
      });

    //custom question come from new custom question
    fetch("/publisher/campaignBriefCustomQuestionDetails?campID=" + campID)
      .then((res) => res.json())
      .then((campaignBriefCustomQuestionDetails) => {
        //3428-karan-loader
        //alert("array=====>"+JSON.stringify(campaignBriefCustomQuestionDetails));
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );

        this.setState({
          campaignBriefCustomQuestionDetails:
            campaignBriefCustomQuestionDetails,
        });

        if (this.state.campaignBriefCustomQuestionDetails.length > 0) {
          var cqURL = "";
          for (
            var i = 0;
            i < this.state.campaignBriefCustomQuestionDetails.length;
            i++
          ) {
            var cq =
              "&CQ-" +
              this.state.campaignBriefCustomQuestionDetails[i]
                .customQuestionID +
              '=""';
            cqURL += cq;
          }
          this.setState({ customQuestionURL: cqURL });
        }
      })
      .catch(function (err) {
        //3428-karan-loader

        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        console.log(err);
      });
    const self = this;
    //This API is for campaign supporting document Asset details from delivery format
    fetch(
      "/publisher/campaignBriefSupportingDocumentAssetDetails?campID=" +
        campID +
        ""
    )
      .then((res) => res.json())
      .then((campaignBriefSupportingDocumentAssetDetails) => {
        //3428-karan-loader
        self.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader();
          }
        );
        self.setState({
          campaignBriefSupportingDocumentAssetDetails:
            campaignBriefSupportingDocumentAssetDetails,
        });
        if (
          self.state.campaignBriefSupportingDocumentAssetDetails !==
            undefined ||
          self.state.campaignBriefSupportingDocumentAssetDetails !== ""
        ) {
          var assetListArray = [];
          for (
            var i = 0;
            i < self.state.campaignBriefSupportingDocumentAssetDetails.length;
            i++
          ) {
            assetListArray.push({
              supportDocID:
                self.state.campaignBriefSupportingDocumentAssetDetails[i]
                  .supportDocID,
              leadPercentage:
                self.state.campaignBriefSupportingDocumentAssetDetails[i]
                  .leadPercentage,
              leadPerAsset:
                self.state.campaignBriefSupportingDocumentAssetDetails[i]
                  .leadPerAsset,
              suppDocName:
                self.state.campaignBriefSupportingDocumentAssetDetails[i]
                  .suppDocName,
              assetStatus:
                self.state.campaignBriefSupportingDocumentAssetDetails[i]
                  .assetStatus,
              multiTouch:
                self.state.campaignBriefSupportingDocumentAssetDetails[i]
                  .multiTouch,
            });
          }
          self.setState({ assetListArray });
        }
      })
      // 3428-karan- added missing catch block to handle loader
      .catch((err) => {
        console.log(err);
        //3428-karan-loader
        self.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader();
          }
        );
      });

    //This API is for campaign supporting document ABM details from delivery format
    fetch(
      "/publisher/campaignBriefSupportingDocumentAbmDetails?campID=" +
        campID +
        ""
    )
      .then((res) => res.json())
      .then((campaignBriefSupportingDocumentAbmDetails) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        this.setState({
          campaignBriefSupportingDocumentAbmDetails:
            campaignBriefSupportingDocumentAbmDetails,
        });
        if (
          this.state.campaignBriefSupportingDocumentAbmDetails !== undefined ||
          this.state.campaignBriefSupportingDocumentAbmDetails !== ""
        ) {
          for (
            var i = 0;
            i < this.state.campaignBriefSupportingDocumentAbmDetails.length;
            i++
          ) {
            abmFile.push(
              this.state.campaignBriefSupportingDocumentAbmDetails[i]
                .suppDocName + " | "
            );
          }
        }
      })
      // 3428-karan- added missing catch block to handle loader
      .catch((err) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        console.log(err);
      });

    //This API is for campaign supporting document Suppresion details from delivery format
    fetch(
      "/publisher/campaignBriefSupportingDocumentSuppresionDetails?campID=" +
        campID +
        ""
    )
      .then((res) => res.json())
      .then((campaignBriefSupportingDocumentSuppresionDetails) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        if (campaignBriefSupportingDocumentSuppresionDetails.length > 0) {
          var isSuppLink =
            campaignBriefSupportingDocumentSuppresionDetails[0].isSuppLink;
        }
        this.setState({
          isSuppLink: isSuppLink,
          campaignBriefSupportingDocumentSuppresionDetails:
            campaignBriefSupportingDocumentSuppresionDetails,
        });
        if (
          this.state.campaignBriefSupportingDocumentSuppresionDetails !==
            undefined ||
          this.state.campaignBriefSupportingDocumentSuppresionDetails !== ""
        ) {
          for (
            var i = 0;
            i <
            this.state.campaignBriefSupportingDocumentSuppresionDetails.length;
            i++
          ) {
            suppressionFile.push(
              this.state.campaignBriefSupportingDocumentSuppresionDetails[i]
                .suppDocName + " | "
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
      });

    //This API is for campaign supporting document Exclusion details from delivery format
    fetch(
      "/publisher/campaignBriefSupportingDocumentExclusionDetails?campID=" +
        campID +
        ""
    )
      .then((res) => res.json())
      .then((campaignBriefSupportingDocumentExclusionDetails) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        this.setState({
          campaignBriefSupportingDocumentExclusionDetails:
            campaignBriefSupportingDocumentExclusionDetails,
        });
        if (
          this.state.campaignBriefSupportingDocumentExclusionDetails !==
            undefined ||
          this.state.campaignBriefSupportingDocumentExclusionDetails !== ""
        ) {
          for (
            var i = 0;
            i <
            this.state.campaignBriefSupportingDocumentExclusionDetails.length;
            i++
          ) {
            execlusionFile.push(
              this.state.campaignBriefSupportingDocumentExclusionDetails[i]
                .suppDocName + " | "
            );
          }
        }
      })
      // 3428-karan- added missing catch block to handle loader
      .catch((err) => {
        console.log(err);
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
      });

    //This API is for campaign supporting document Other details from delivery format
    fetch(
      "/publisher/campaignBriefSupportingDocumentOtherDetails?campID=" +
        campID +
        ""
    )
      .then((res) => res.json())
      .then((campaignBriefSupportingDocumentOtherDetails) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        this.setState({
          campaignBriefSupportingDocumentOtherDetails:
            campaignBriefSupportingDocumentOtherDetails,
        });
        if (
          this.state.campaignBriefSupportingDocumentOtherDetails !==
            undefined ||
          this.state.campaignBriefSupportingDocumentOtherDetails !== ""
        ) {
          for (
            var i = 0;
            i < this.state.campaignBriefSupportingDocumentOtherDetails.length;
            i++
          ) {
            otherFile.push(
              this.state.campaignBriefSupportingDocumentOtherDetails[i]
                .suppDocName + " | "
            );
          }
        }
      })
      // 3428-karan- added missing catch block to handle loader
      .catch((err) => {
        console.log(err);
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
      });
    fetch("agency/getAssetLinkInSpec?campID=" + campID)
      .then((res) => res.json())
      .then((assetLinkArray) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        this.setState({ assetLinkArray: assetLinkArray });
        if (this.state.assetLinkArray.length > 0) {
          var linkListArray = [];
          for (var i = 0; i < this.state.assetLinkArray.length; i++) {
            linkListArray.push({
              supportDocID: this.state.assetLinkArray[i].supportDocID,
              leadPercentage: this.state.assetLinkArray[i].leadPercentage,
              leadPerAsset: this.state.assetLinkArray[i].leadPerAsset,
              suppDocName: this.state.assetLinkArray[i].suppDocName,
              assetLink: this.state.assetLinkArray[i].assetLink,
              assetStatus: this.state.assetLinkArray[i].assetStatus,
              multiTouch: this.state.assetLinkArray[i].multiTouch,
            });
          }
          this.setState({ linkListArray });
        }
      })
      .catch(function (err) {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        console.log(err);
      });

       //Nilesh-5212- fetch call for column sequencing details data
       fetch("agency/getColumnSequencing?campID=" + campID)
       .then((res) => res.json())
       .then((response) => {
         this.setState(
           (prev) => ({
             apiCallsCompleted: prev.apiCallsCompleted + 1,
           }),
           () => {
             this.dismissLoader(); 
           }
         );
         if (response.columnSequenceCheck === "Yes") {
           // If columnsSequence is present in the response
           this.setState({ columnsequencingarray: response.columnsSequence , columnSequenceCheck: response.columnSequenceCheck});
         } else {
           // If only columnSequenceCheck is present in the response
           this.setState({ columnSequenceCheck: response.columnSequenceCheck });
         } 
       })
       .catch(function (err) {
         this.setState(
           (prev) => ({
             apiCallsCompleted: prev.apiCallsCompleted + 1,
           }),
           () => {
             this.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
           }
         );
         console.log(err);
       });

    this.setState({ show: true });
  } // end of handleCampaignBriefDetails

  /**
   * @author Somnath Keswad
   * @param  Description  get multiple Link of Asset
   * @return Description return open Multiple Link when i click on button in campSpec
   */
  openAllLink() {
    var length = this.state.assetLinkArray.length;
    for (var i = 0; i < length; i++) {
      window.open(this.state.assetLinkArray[i].assetLink);
      let assetLink1 = this.state.assetLinkArray[i].assetLink;
      let assethttps = assetLink1.includes("https://");
      let assethttp = assetLink1.includes("http://");
      if (assethttps === true || assethttp === true) {
        window.open(this.state.assetLinkArray[i].assetLink);
      } else {
        window.open("https://" + this.state.assetLinkArray[i].assetLink);
      }
    }
  }

  openAssetLink(e) {
    var assetLink = e.target.getAttribute("assetLink");
    let assethttps = assetLink.includes("https://");
    let assethttp = assetLink.includes("http://");
    if (assethttps === true || assethttp === true) {
      window.open(assetLink);
    } else {
      window.open("https://" + assetLink);
    }
  }

  /**
   * @author Somnath Keswad
   * @param  Description  get multiple Link of Suppression
   * @return Description return open Multiple Link when i click on button in campSpec
   */
  openAllSuppLink() {
    var supp = this.state.campaignBriefSupportingDocumentSuppresionDetails;
    var suppData = supp.filter(function (s) {
      return !s.assetLink === "";
    });
    for (var i = 0; i < suppData.length; i++) {
      window.open(suppData[i].assetLink);
    }
  }

  /**
   *
   * @author : Karan Jagtap
   * @description : dismisses the loader
   */
  dismissLoader = () => {
    if (this.state.apiCallsCompleted === this.state.totalApiCalls) {
      this.closeLoadingRef.current.click();
    }
  };

  render() {
    //Nilesh-5212- Added columns for column sequencing table 
    const columns = [  
    {	
				width: "3%",
				render: (text, record ,index) => (
					<div >
						<span style={{ fontSize: "14px" }}>
							Column {index + 1}
						</span>
						<br />
					</div>
				),
			},
      {
				width: "6%",
				render: (text, record) => (
					<div >
						<span style={{ fontSize: "14px",fontWeight:"bold" }}>
							{record.value}
						</span>
						<br />
					</div>
				),
			},
    ];
    return (
      <div>
        {/* Hello Campaign Specification {this.props.greeting} */}
        {/* 3428-karan-di loader */}
        <CampaignSpecificationLoader
          loadingRef={this.loadingRef}
          closeLoadingRef={this.closeLoadingRef}
          message="wait a moment while we are loading your data."
        />
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          {/* <div style={{ border: "1px solid #cdcdcd" }}> */}
          <div style={{ border: "white" }}>
            {/* snehal-task-3561-Reff(3428)----spec and cust mapping format issue(added css for subtitles)  */}
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Basic Details :{" "}
            </div>
            {/* <div class="table-responsive">
                      <table id="myTable" className=" table table-bordered">
                        <thead>
                          <tr class="info">
                            <th class="table-header">Basic Details</th>
                          </tr>
                        </thead>
                      </table>
                      </div>
                   */}

            {/* /**
             * @author Narendra Phadke
             * @param  Description Display Campaign Details on Modal
             * @return Description return Campaign Details
             */}
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              <CardBody>
                {this.state.campaignBriefDetails.map((campaignBriefDetails) => (
                  <div class="row">
                    <div class="col-lg-12">
                      <table>
                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">&nbsp;Campaign Name</label>
                          </td>
                          <td>:&nbsp;{campaignBriefDetails.campaignName}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                ))}

                {this.state.campaignBriefDetails.map((campaignBriefDetails) => (
                  <div class="row">
                    <div class="col-lg-4">
                      <table>
                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">&nbsp;Client Name</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;{campaignBriefDetails.clientName}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">&nbsp;Lead Allocation</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;{campaignBriefDetails.leadAllocation}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          {/* User Story - 3428 - Karan Jagtap - top alignment */}
                          <td style={{ width: "170px", display: "flex" }}>
                            <label id="label">&nbsp;Timezone</label>
                          </td>
                          <td>
                            <span>:&nbsp;{campaignBriefDetails.timezone}</span>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div class="col-lg-4">
                      <table>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Agency Campaign ID</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;{campaignBriefDetails.clientCampID}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label"> Start Date</label>
                          </td>
                          <td>
                            <span>:&nbsp;{campaignBriefDetails.startDate}</span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">First Lead Delivery Date</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;
                              {campaignBriefDetails.firstLeadDeliveryDate}
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div class="col-lg-4">
                      <table>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Campaign Status</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;{campaignBriefDetails.campaignStatus}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">End Date</label>
                          </td>
                          <td>
                            <span>:&nbsp;{campaignBriefDetails.endDate}</span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">No Of Lead Per Domain</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;{campaignBriefDetails.noOfLeadPerDomain}
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                ))}
              </CardBody>
            </div>
            {/* /*row1 */}
            <br />
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Delivery Option :
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              {/* <table id="myTable" className=" table table-bordered">
                        <thead>
                          <tr class="info"> */}
              {/* <th class="table-header">Delivery Option</th>
                          </tr>
                        </thead>
                      </table> */}
              {/* /**
               * @author Narendra Phadke
               * @param  Description Display Campaign Details on Modal
               * @return Description return Campaign Details
               */}
              <CardBody className="deliveryOption">
                {this.state.campaignBriefDetails.map((campaignBriefDetails) => (
                  <div class="row">
                    <div class="col-lg-4">
                      <table>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Campaign Type</label>
                          </td>
                          <td>:&nbsp;{campaignBriefDetails.ABM}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">Campaign Reporting Day</label>
                          </td>
                          <td>
                            :&nbsp;{campaignBriefDetails.campaignReportingDay}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">Creative Approval</label>
                          </td>
                          {campaignBriefDetails.requiredLPApproval === "" ||
                          campaignBriefDetails.requiredLPApproval === null ||
                          campaignBriefDetails.requiredLPApproval ===
                            undefined ? (
                            <td>:&nbsp;No </td>
                          ) : (
                            <td style={{ wordBreak: "break-all" }}>
                              {" "}
                              :&nbsp;
                              {campaignBriefDetails.requiredLPApproval[0] ===
                              ","
                                ? campaignBriefDetails.requiredLPApproval.slice(
                                    1
                                  )
                                : campaignBriefDetails.requiredLPApproval}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">Call Audit</label>
                          </td>
                          <td>
                            :&nbsp;
                            {campaignBriefDetails.callAudit === "undefined" ||
                            campaignBriefDetails.callAudit === "null" ||
                            campaignBriefDetails.callAudit === null ||
                            campaignBriefDetails.callAudit === undefined
                              ? ""
                              : campaignBriefDetails.callAudit}
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div class="col-lg-4">
                      <table>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Marketing Channel</label>
                          </td>
                          <td style={{ wordBreak: "break-all" }}>
                            :&nbsp;{campaignBriefDetails.marketingChannel}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Lead Delivery Option</label>
                          </td>
                          <td>
                            :&nbsp;{campaignBriefDetails.leadDeliveryOption}
                          </td>
                        </tr>

                        {campaignBriefDetails.requiredLPApproval === "" ? (
                          ""
                        ) : (
                          <tr>
                            <td style={{ width: "160px" }}>
                              <label id="label">
                                Creative Approval Timeline (Hours)
                              </label>
                            </td>
                            <td>:&nbsp;{campaignBriefDetails.lpTimeline}</td>
                          </tr>
                        )}

                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Pacing Unit </label>
                          </td>
                          <td>
                            <span>:&nbsp;{this.state.pacingUnit}</span>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div class="col-lg-4">
                      <table>
                        {/* User Story : 3188 : Karan Jagtap : Sub-Contracting value shown */}
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Sub-Contracting</label>
                          </td>
                          {campaignBriefDetails.subContracting === undefined ||
                          campaignBriefDetails.subContracting === null ||
                          campaignBriefDetails.subContracting === "undefined" ||
                          campaignBriefDetails.subContracting === "null" ||
                          campaignBriefDetails.subContracting === "" ? (
                            <td>:</td>
                          ) : (
                            <td>
                              {" "}
                              :&nbsp;
                              {campaignBriefDetails.subContracting}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Lead Interaction Days</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;
                              {campaignBriefDetails.leadInteractionDays ===
                                "" ||
                              campaignBriefDetails.leadInteractionDays ===
                                undefined
                                ? 0
                                : campaignBriefDetails.leadInteractionDays}
                            </span>
                          </td>
                        </tr>
                        {campaignBriefDetails.requiredLPApproval === "" ||
                        campaignBriefDetails.requiredLPApproval === null ||
                        campaignBriefDetails.requiredLPApproval ===
                          undefined ? (
                          ""
                        ) : (
                          <tr>
                            <td style={{ width: "170px" }}>
                              <label id="label">
                                Creative Approval Required For
                              </label>
                            </td>
                            <td>
                              :&nbsp;
                              {campaignBriefDetails.creativeApprovalRequiredFor}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td style={{ width: "160px" }}>
                            {" "}
                            <label id="label">Pacing</label>
                          </td>
                          <td>:&nbsp;{campaignBriefDetails.pacing}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                ))}
                {this.state.pacingCampaignDetails.length > 0 ? (
                  <div>
                    <br />
                    <table class="table table-bordered table-responsive">
                      <thead>
                        <tr class="info">
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                            }}
                          >
                            Pacing Month
                          </th>
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                            }}
                          >
                            Pacing Percentage
                          </th>
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                            }}
                          >
                            Pacing Lead Count
                          </th>
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                            }}
                          >
                            Pacing End Date
                          </th>
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                            }}
                          >
                            Pacing carry forward date
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.state.pacingCampaignDetails.map(
                          (pacingCampaignDetails, i) => {
                            return (
                              <tr>
                                <td>
                                  <span>
                                    {pacingCampaignDetails.pacingMonth}
                                  </span>
                                </td>
                                <td>
                                  {/* snehal-task-3598-Akshay sir-Campaign specification changes */}
                                  <span>
                                    {pacingCampaignDetails.pacingPercentage}%
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {pacingCampaignDetails.pacingLeadCount}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {pacingCampaignDetails.pacingEndDate}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {pacingCampaignDetails.pacingCarryForward}
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  ""
                )}
              </CardBody>
            </div>

            {/* /*row 2 */}
            <br />
            {/* <table id="myTable" className=" table table-bordered">
                        <thead>
                          <tr class="info">
                            <th class="table-header">Campaign Specification</th>
                          </tr>
                        </thead>
                      </table> */}
            {/* /**
             * @author Narendra Phadke
             * @param  Description Display Campaign Details on Modal
             * @return Description return Campaign Details
             */}
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Campaign Specification :
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              <CardBody>
                {/* User Story - 3428 - Karan Jagtap - changed layout to fit whole label text in 1 line */}
                <div className="row">
                  <div className="col">
                    <table>
                      {this.state.campaignBriefDetails.map(
                        (campaignBriefDetails) => (
                          <>
                           {/* Nilesh-4910 Add Non-English Campaign field below */}
                            {/* Nilesh-5013 Add Multilingual Campaign field below */}
                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex",
                                }}
                              >
                                {/* <label id="label">&nbsp;Non-English Campaign</label> */}
                                <label id="label">&nbsp;Multilingual Campaign</label>
                              </td>
                              <td>
                                <span class="word-wrap">
                                  :&nbsp;
                                  {(campaignBriefDetails.nonEnglishCheck === "null" ||
                                  campaignBriefDetails.nonEnglishCheck === null ||
                                  campaignBriefDetails.nonEnglishCheck ===
                                    undefined ||
                                  campaignBriefDetails.nonEnglishCheck ===
                                    "undefined" || campaignBriefDetails.nonEnglishCheck ===
                                    "")
                                    ? "No"
                                    : campaignBriefDetails.nonEnglishCheck}
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Region</label>
                              </td>
                              <td>:&nbsp;{campaignBriefDetails.region}</td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Country</label>
                              </td>
                              <td>
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.country === "null" ||
                                  campaignBriefDetails.country === null ||
                                  campaignBriefDetails.country === undefined ||
                                  campaignBriefDetails.country === "undefined"
                                    ? ""
                                    : campaignBriefDetails.country}
                                </span>
                              </td>
                            </tr>
                            {/* //snehal-task-3793-geowise lead display none while rfp is geowise  */}
                            {campaignBriefDetails.biddingType === "Geowise" &&
                            campaignBriefDetails.requiredCountryWiseAllocation ===
                              "Yes" ? (
                              ""
                            ) : (
                              <tr>
                                <td
                                  style={{
                                    width: "170px",
                                    display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                  }}
                                >
                                  <label id="label">
                                    &nbsp;Lead Allocation by country
                                  </label>
                                </td>
                                <td>
                                  {/* Sandeep-task-2441-added code for countryWise lead allocation */}
                                  <span class="word-wrap">
                                    :&nbsp;
                                    {campaignBriefDetails.requiredCountryWiseAllocation ===
                                      "null" ||
                                    campaignBriefDetails.requiredCountryWiseAllocation ===
                                      null ||
                                    campaignBriefDetails.requiredCountryWiseAllocation ===
                                      undefined ||
                                    campaignBriefDetails.requiredCountryWiseAllocation ===
                                      "undefined"
                                      ? ""
                                      : campaignBriefDetails.requiredCountryWiseAllocation}
                                  </span>
                                </td>
                              </tr>
                            )}
                          </>
                        )
                      )}
                    </table>
                    <div className="row">
                      {this.state.campaignBriefDetails.map(
                        (campaignBriefDetails) => (
                          <>
                            {/* snehal-task-3598-Akshay sir-Campaign specification changes (for zip code state and city)*/}
                            {/* snehal-task-3670-STC---agency side---edit camp---zip code, state, city file issue*/}
                            {campaignBriefDetails.stateFileName !== "" &&
                            campaignBriefDetails.state === "Yes" ? (
                              <div className="col-lg-4">
                                {/* User Story - 3428 - Karan Jagtap - top alignment */}
                                <table>
                                  <tr>
                                    <td
                                      style={{
                                        width: "170px",
                                        display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                      }}
                                    >
                                      <label id="label">&nbsp;State</label>
                                    </td>
                                    <td>
                                      <span class="word-wrap">
                                        :&nbsp;
                                        {campaignBriefDetails.stateFileName}
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            ) : (
                              ""
                            )}
                            {campaignBriefDetails.cityFileName !== "" &&
                            campaignBriefDetails.city === "Yes" ? (
                              <div className="col-lg-4">
                                <table>
                                  <tr>
                                    <td
                                      style={{
                                        width: "170px",
                                        display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                      }}
                                    >
                                      <label id="label">&nbsp;City</label>
                                    </td>
                                    <td>
                                      <span class="word-wrap">
                                        :&nbsp;
                                        {campaignBriefDetails.cityFileName}
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            ) : (
                              ""
                            )}
                            {campaignBriefDetails.zipCodeFileName !== "" &&
                            campaignBriefDetails.zipCode === "Yes" ? (
                              <div className="col-lg-4">
                                <table>
                                  <tr>
                                    <td
                                      style={{
                                        width: "170px",
                                        display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                      }}
                                    >
                                      <label id="label">&nbsp;Zip Code</label>
                                    </td>
                                    <td>
                                      <span class="word-wrap">
                                        :&nbsp;
                                        {campaignBriefDetails.zipCodeFileName}
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )}
                    </div>
                    <table>
                      {this.state.campaignBriefDetails.map(
                        (campaignBriefDetails) => (
                          <>
                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Job Level</label>
                              </td>
                              <td>
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.jobLevel === "|"
                                    ? " "
                                    : campaignBriefDetails.jobLevel}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">
                                  &nbsp;Job &nbsp;Function
                                </label>
                              </td>
                              <td>
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.jobFunction === "|"
                                    ? ""
                                    : campaignBriefDetails.jobFunction}
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Job Title</label>
                              </td>
                              <td>
                                <span class="word-wrap">
                                  :&nbsp;{campaignBriefDetails.jobTitle}
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Industry</label>
                              </td>
                              {/**shivani-task 3081-added code to reflect industry and custome industry values in speciffication.**/}
                              <td>
                                :&nbsp;
                                {campaignBriefDetails.industry === "" &&
                                campaignBriefDetails.customIndustry === "" ? (
                                  <span class="word-wrap">Not Applicable</span>
                                ) : campaignBriefDetails.customIndustry ===
                                  "" ? (
                                  <span class="word-wrap">
                                    {campaignBriefDetails.industry}
                                  </span>
                                ) : campaignBriefDetails.industry === "" ? (
                                  <span class="word-wrap">
                                    {campaignBriefDetails.customIndustry}
                                  </span>
                                ) : (
                                  <span class="word-wrap">
                                    {
                                    // Chaitanya-4893- Commented condition for custom Industry size is "null"  
                                    // campaignBriefDetails.customIndustry ===
                                    //   "null" ||
                                    campaignBriefDetails.customIndustry ===
                                      null ||
                                    campaignBriefDetails.customIndustry ===
                                      undefined ||
                                    campaignBriefDetails.customIndustry ===
                                      "undefined" ||
                                    campaignBriefDetails.industry === "null" ||
                                    campaignBriefDetails.industry === null ||
                                    campaignBriefDetails.industry ===
                                      undefined ||
                                    campaignBriefDetails.industry ===
                                      "undefined"
                                      ? ""
                                      : campaignBriefDetails.industry +
                                        "|" +
                                        campaignBriefDetails.customIndustry}
                                  </span>
                                )}
                              </td>
                            </tr>
                            {/* snehal-task-3647-Industry Exclusion on DI - FE */}
                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">
                                  &nbsp;Industry &nbsp;Exclusion
                                </label>
                              </td>
                              <td>
                                :&nbsp;{" "}
                                {/* snehal-task-3667-ref(3647)--Industry file--check box issue (changes in comparison)*/}
                                {campaignBriefDetails.excludedIndustryFlag ===
                                "Yes" ? (
                                  <span className="word-wrap">
                                    {
                                      campaignBriefDetails.excludedIndustryFileName
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">
                                  &nbsp;Employee &nbsp;Size
                                </label>
                              </td>
                              <td>
                                :&nbsp;{" "}
                                {campaignBriefDetails.employeeSize === "" &&
                                campaignBriefDetails.customEmpSize === "" ? (
                                  <span class="word-wrap">Not Applicable</span>
                                ) : (
                                  <span class="word-wrap">
                                    {
                                      // Chaitanya-4890-Commented condition for custom employee size is "null"
                                      // campaignBriefDetails.customEmpSize ===
                                      // "null" ||
                                      campaignBriefDetails.customEmpSize ===
                                        null ||
                                      campaignBriefDetails.customEmpSize ===
                                        undefined ||
                                      campaignBriefDetails.customEmpSize ===
                                        "undefined" ||
                                      campaignBriefDetails.employeeSize ===
                                        "null" ||
                                      campaignBriefDetails.employeeSize ===
                                        null ||
                                      campaignBriefDetails.employeeSize ===
                                        undefined ||
                                      campaignBriefDetails.employeeSize ===
                                        "undefined"
                                        ? ""
                                        : campaignBriefDetails.employeeSize
                                            .length > 0 &&
                                          campaignBriefDetails.customEmpSize
                                        ? campaignBriefDetails.employeeSize +
                                          "|" +
                                          campaignBriefDetails.customEmpSize
                                        : campaignBriefDetails.employeeSize +
                                          "" +
                                          campaignBriefDetails.customEmpSize
                                    }
                                  </span>
                                  //  snehal-task-3343----edit campaign---employee size, company revenue
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">
                                  &nbsp;Company &nbsp;Revenue
                                </label>
                              </td>
                              <td>
                                {/* campaignBriefDetails.companyRevenue+','+campaignBriefDetails.customCompRevenue */}
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.companyRevenue +
                                    campaignBriefDetails.customCompRevenue ===
                                  "|"
                                    ? ""
                                    : campaignBriefDetails.companyRevenue
                                        .length === 0 &&
                                      campaignBriefDetails.customCompRevenue
                                        .length > 0
                                    ? campaignBriefDetails.companyRevenue +
                                      campaignBriefDetails.customCompRevenue
                                    : campaignBriefDetails.companyRevenue
                                        .length > 0 &&
                                      campaignBriefDetails.customCompRevenue
                                        .length > 0
                                    ? campaignBriefDetails.companyRevenue +
                                      "|" +
                                      campaignBriefDetails.customCompRevenue
                                    : campaignBriefDetails.companyRevenue +
                                      campaignBriefDetails.customCompRevenue}
                                </span>
                                {/* //  snehal-task-3343----edit campaign---employee size, company revenue */}
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Other Specs</label>
                              </td>
                              <td>
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.otherSpecs === "null" ||
                                  campaignBriefDetails.otherSpecs === null ||
                                  campaignBriefDetails.otherSpecs ===
                                    undefined ||
                                  campaignBriefDetails.otherSpecs ===
                                    "undefined"
                                    ? ""
                                    : campaignBriefDetails.otherSpecs}
                                </span>
                              </td>
                            </tr>
                          </>
                        )
                      )}
                    </table>
                  </div>
                </div>
              </CardBody>
            </div>

            <br />
            {/* <table id="myTable" className=" table table-bordered">
                           <thead>
                             <tr class="info">
                               <th class="table-header">Supporting Document</th>
                             </tr>
                           </thead>
                         </table>  */}
            {/* /**
             * @author Narendra Phadke
             * @param  Description Display Campaign Supporting Document Asset Details on Modal
             * @return Description return Campaign Details
             */}
            {/* <row 3> */}
            {/* <row4></row4> */}
            {/* Sandeep-task-2441-added code for countryWise lead allocation */}
            {/* //snehal-task-3793-geowise lead display none while rfp is geowise  */}
            {this.state.requiredCountryWiseAllocation === "Yes" &&
            this.state.campaignBriefDetails[0].biddingType !== "Geowise" ? (
              <div>
                <div
                  style={{
                    fontFamily: "roboto",
                    fontSize: "20px",
                    color: "#193d8f",
                    fontWeight: "600",
                  }}
                >
                  &nbsp;Lead Allocation by country :
                </div>
                <div
                  class="card"
                  style={{
                    backgroundColor: "#f8f8f8",
                    borderColor: "#707070",
                    borderWidth: "0.3px",
                  }}
                >
                  <CardBody>
                    <table class="col-md-5 col-lg-5 ">
                      <table class="table  table-bordered">
                        <thead>
                          <tr class="info">
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                              }}
                            >
                              Country Name
                            </th>
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                              }}
                            >
                              Lead Allocation
                            </th>
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                              }}
                            >
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.countryWiseAllocationArray.map((item) => (
                            <tr>
                              {item.status === "Removed" ? (
                                <td style={{ color: "red" }}>{item.country}</td>
                              ) : (
                                <td>{item.country}</td>
                              )}
                              {item.status === "Removed" ? (
                                <td style={{ color: "red" }}>{item.lead}</td>
                              ) : (
                                <td>{item.lead}</td>
                              )}
                              {item.status === "Removed" ? (
                                <td style={{ color: "red" }}>{item.status}</td>
                              ) : (
                                <td>{item.status}</td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </table>
                    <br />
                  </CardBody>
                </div>
              </div>
            ) : (
              ""
            )}
            <br />
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Supporting Document :
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              {/* kiran-4364-added display property in label for responsiveness */}
              <CardBody>
                <div class="row">
                  <div class="col-sm-6">
                    <table>
                      <tr>
                        <td>
                          {" "}
                          <label
                            id="label"
                            style={{ width: "80px", display: "inline-flex" }}
                          >
                            ABM
                          </label>
                        </td>
                        <td>
                          :&nbsp;
                          {this.state.campaignBriefSupportingDocumentAbmDetails.map(
                            (campaignBriefSupportingDocumentAbmDetails) => (
                              <span className="word-wrap">
                                {
                                  campaignBriefSupportingDocumentAbmDetails.suppDocName
                                }
                                |
                              </span>
                            )
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <label id="label" style={{ display: "inline-flex" }}>
                            Suppression
                          </label>
                        </td>
                        <td>
                          :&nbsp;
                          {this.state
                            .campaignBriefSupportingDocumentSuppresionDetails
                            .length > 0 ? (
                            <span>
                              {this.state.campaignBriefSupportingDocumentSuppresionDetails.map(
                                (suppressionList) => (
                                  <span className="word-wrap">
                                    {suppressionList.suppDocName}|
                                  </span>
                                )
                              )}
                            </span>
                          ) : (
                            ""
                          )}
                          {this.state.isSuppLink === true ? (
                            <div class="float-right">
                              <button
                                type="button"
                                class="btn btn-primary btn-xs"
                                onClick={this.openAllSuppLink.bind(this)}
                              >
                                Open All
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label
                            id="label"
                            style={{ width: "80px", display: "inline-flex" }}
                          >
                            &nbsp;Exclusion
                          </label>
                        </td>
                        <td>
                          :&nbsp;
                          {this.state.campaignBriefSupportingDocumentExclusionDetails.map(
                            (
                              campaignBriefSupportingDocumentExclusionDetails
                            ) => (
                              <span className="word-wrap">
                                {
                                  campaignBriefSupportingDocumentExclusionDetails.suppDocName
                                }
                                |
                              </span>
                            )
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label
                            id="label"
                            style={{ width: "80px", display: "inline-flex" }}
                          >
                            Other
                          </label>
                        </td>
                        <td>
                          :&nbsp;
                          {this.state.campaignBriefSupportingDocumentOtherDetails.map(
                            (campaignBriefSupportingDocumentOtherDetails) => (
                              <span className="word-wrap">
                                {
                                  campaignBriefSupportingDocumentOtherDetails.suppDocName
                                }
                                |
                              </span>
                            )
                          )}
                        </td>
                      </tr>
                      <tr>
                        {/* snehal-task-3620-Akshay Sir- Add exclude domains like .gov, .mil- Campaign to lead validation --> FE */}
                        {this.state.campaignBriefDetails.excludedDomain !==
                          "" ||
                        this.state.campaignBriefDetails.excludedDomain !==
                          null ||
                        this.state.campaignBriefDetails.excludedDomain !==
                          "null" ||
                        this.state.campaignBriefDetails.excludedDomain !==
                          undefined ||
                        this.state.campaignBriefDetails.excludedDomain !==
                          "undefined" ? (
                          <div>
                            <label
                              id="label"
                              style={{
                                display: "inline-flex",
                                overflow: "visible",
                              }}
                            >
                              Exclude Domain Extension :
                            </label>
                            {this.state.campaignBriefDetails.map(
                              (campaignBriefDetails) => (
                                <span>
                                  {/* className="word-wrap" */}
                                  {campaignBriefDetails.excludedDomain}
                                </span>
                              )
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </tr>
                    </table>
                  </div>
                </div>
              </CardBody>
            </div>

            {/*row 4 */}
            <br />
            {/* <table id="myTable" className=" table table-bordered">
                          <thead>
                            <tr class="info">
                              <th class="table-header">Campaign Asset</th>
                            </tr>
                          </thead>
                        </table> */}
            {/* /**
             * @author Narendra Phadke
             * @param  Description Display Campaign Supporting Document Asset Details on Modal
             * @return Description return Campaign Details
             */}
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Campaign Asset :
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              <CardBody>
                <div class="row">
                  {" "}
                  <div class="col-sm-12">
                    <label id="label">Assets File :</label>
                    <br />
                    <br />

                    {this.state.assetListArray.length > 0 ? (
                      <table class="table  table-bordered">
                        {" "}
                        {/* 4368-for table responsive  */}
                        <thead>
                          <tr class="info">
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                              }}
                            >
                              Asset ID
                            </th>
                            {this.state.requiredLeadPerAsset === "Yes" ? (
                              <th
                                style={{
                                  color: "#707070",
                                  backgroundColor: " rgba(126, 127, 129, 0.22)",
                                }}
                              >
                                Lead Percentage (%)
                              </th>
                            ) : (
                              ""
                            )}
                            {this.state.requiredLeadPerAsset === "Yes" ? (
                              <th
                                style={{
                                  color: "#707070",
                                  backgroundColor: " rgba(126, 127, 129, 0.22)",
                                }}
                              >
                                Lead Per Asset
                              </th>
                            ) : (
                              ""
                            )}
                            {this.state.multiTouch === "Yes" ? (
                              <th
                                style={{
                                  color: "#707070",
                                  backgroundColor: " rgba(126, 127, 129, 0.22)",
                                }}
                              >
                                Multi Touch
                              </th>
                            ) : (
                              ""
                            )}
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                              }}
                            >
                              Asset Name
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.assetListArray.map((assetListArray) =>
                            assetListArray.assetStatus === "Removed" ? (
                              <tr>
                                <td>
                                  <span
                                    style={{
                                      color: "red",
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    {assetListArray.supportDocID}
                                  </span>
                                </td>
                                {this.state.requiredLeadPerAsset === "Yes" ? (
                                  <td>
                                    <span
                                      style={{
                                        color: "red",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      {assetListArray.leadPercentage}%
                                    </span>
                                    {/* snehal-task-3598-Akshay sir-Campaign specification changes */}
                                  </td>
                                ) : (
                                  ""
                                )}
                                {this.state.requiredLeadPerAsset === "Yes" ? (
                                  <td>
                                    <span
                                      style={{
                                        color: "red",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      {assetListArray.leadPerAsset}{" "}
                                    </span>
                                  </td>
                                ) : (
                                  ""
                                )}
                                {this.state.multiTouch === "Yes" ? (
                                  <td>
                                    <span
                                      style={{
                                        color: "red",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      {assetListArray.multiTouch}
                                    </span>
                                  </td>
                                ) : (
                                  ""
                                )}
                                <td>
                                  <span
                                    style={{
                                      color: "red",
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    {assetListArray.suppDocName}
                                  </span>
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td>{assetListArray.supportDocID}</td>
                                {this.state.requiredLeadPerAsset === "Yes" ? (
                                  <td>
                                    {assetListArray.leadPercentage}%
                                  </td> /* snehal-task-3598-Akshay sir-Campaign specification changes */
                                ) : (
                                  ""
                                )}
                                {this.state.requiredLeadPerAsset === "Yes" ? (
                                  <td>{assetListArray.leadPerAsset}</td>
                                ) : (
                                  ""
                                )}
                                {this.state.multiTouch === "Yes" ? (
                                  <td>{assetListArray.multiTouch}</td>
                                ) : (
                                  ""
                                )}
                                <td>{assetListArray.suppDocName}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    ) : (
                      ""
                    )}
                  </div>
                  <br />
                  <div class="col-sm-12">
                    {this.state.assetLinkArray.length > 0 ? (
                      <p>
                        <label id="label">&nbsp;Assets link :</label>
                        <br />
                        <br />
                        <div>
                          <table class="col-md-12 col-lg-12 ">
                            <table class="table  table-bordered">
                              <thead>
                                <tr class="info">
                                  <th
                                    style={{
                                      color: "#707070",
                                      backgroundColor:
                                        " rgba(126, 127, 129, 0.22)",
                                    }}
                                  >
                                    Asset ID
                                  </th>

                                  {this.state.requiredLeadPerAsset !== null &&
                                  this.state.requiredLeadPerAsset === "Yes" ? (
                                    <th
                                      style={{
                                        color: "#707070",
                                        backgroundColor:
                                          " rgba(126, 127, 129, 0.22)",
                                      }}
                                    >
                                      Lead Percentage (%)
                                    </th>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.requiredLeadPerAsset !== null &&
                                  this.state.requiredLeadPerAsset === "Yes" ? (
                                    <th
                                      style={{
                                        color: "#707070",
                                        backgroundColor:
                                          " rgba(126, 127, 129, 0.22)",
                                      }}
                                    >
                                      Lead Per Asset
                                    </th>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.multiTouch !== null &&
                                  this.state.multiTouch === "Yes" ? (
                                    <th
                                      style={{
                                        color: "#707070",
                                        backgroundColor:
                                          " rgba(126, 127, 129, 0.22)",
                                      }}
                                    >
                                      Multi Touch
                                    </th>
                                  ) : (
                                    ""
                                  )}
                                  <th
                                    style={{
                                      color: "#707070",
                                      backgroundColor:
                                        " rgba(126, 127, 129, 0.22)",
                                    }}
                                  >
                                    Asset Name
                                  </th>
                                  <th
                                    style={{
                                      color: "#707070",
                                      backgroundColor:
                                        " rgba(126, 127, 129, 0.22)",
                                    }}
                                  >
                                    Asset Link
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.linkListArray.map((linkListArray) =>
                                  linkListArray.assetStatus === "Removed" ? (
                                    <tr>
                                      <td>
                                        <span
                                          style={{
                                            color: "red",
                                            textDecoration: "line-through",
                                          }}
                                        >
                                          {linkListArray.supportDocID}
                                        </span>
                                      </td>
                                      {this.state.requiredLeadPerAsset ===
                                      "Yes" ? (
                                        <td>
                                          <span
                                            style={{
                                              color: "red",
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {linkListArray.leadPercentage}%
                                          </span>
                                          {/* snehal-task-3598-Akshay sir-Campaign specification changes */}
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.requiredLeadPerAsset ===
                                      "Yes" ? (
                                        <td>
                                          <span
                                            style={{
                                              color: "red",
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {linkListArray.leadPerAsset}
                                          </span>
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                      {/* rutuja - task 4368 removing  this.state.campaignBriefDetails[0].multitouch from below line because page going crashed*/}
                                      {this.state.multiTouch === "Yes" ? (
                                        <td>
                                          <span
                                            style={{
                                              color: "red",
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {linkListArray.multiTouch}
                                          </span>
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                      <td>
                                        <span
                                          style={{
                                            color: "red",
                                            textDecoration: "line-through",
                                          }}
                                        >
                                          {linkListArray.suppDocName}
                                        </span>
                                      </td>
                                      <td style={{ wordBreak: "break-all" }}>
                                        {/* kiran-4747-removing console warning-added rel="noopener noreferrer" */}
                                        <a
                                          href={linkListArray.assetLink}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <span
                                            style={{
                                              color: "red",
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {linkListArray.assetLink}
                                          </span>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  ) : (
                                    <tr>
                                      <td>{linkListArray.supportDocID}</td>
                                      {this.state.requiredLeadPerAsset ===
                                      "Yes" ? (
                                        <td>
                                          {linkListArray.leadPercentage}%
                                        </td> /* snehal-task-3598-Akshay sir-Campaign specification changes */
                                      ) : (
                                        ""
                                      )}
                                      {this.state.requiredLeadPerAsset ===
                                      "Yes" ? (
                                        <td>{linkListArray.leadPerAsset}</td>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.multiTouch === "Yes" ? (
                                        <td>{linkListArray.multiTouch}</td>
                                      ) : (
                                        ""
                                      )}
                                      <td>{linkListArray.suppDocName}</td>
                                      <td style={{ wordBreak: "break-all" }}>
                                        <a
                                          href={linkListArray.assetLink}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          assetLink={linkListArray.assetLink}
                                          onClick={this.openAssetLink.bind(
                                            this
                                          )}
                                        >
                                          {linkListArray.assetLink}{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            <div class="float-right">
                              <button
                                type="button"
                                class="btn btn-primary btn-xs"
                                onClick={this.openAllLink.bind(this)}
                              >
                                Open All
                              </button>
                            </div>
                          </table>
                        </div>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </CardBody>
            </div>

            {/*row 4 */}
            <br />

            {/*row 5 */}
            <br />
            {/* <table id="myTable" className=" table table-bordered">
                          <thead>
                            <tr class="info">
                              <th class="table-header">Lead Delivery Through API</th>
                            </tr>
                          </thead>
                        </table> */}
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Lead Delivery Through API :
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              <CardBody>
                <div class="word-wrap">
                  <label>URL </label> :&nbsp;{this.state.deliveryURL}
                  {this.state.customQuestionURL}
                </div>
                <br />
              </CardBody>
            </div>

            {/* <table id="myTable" className=" table table-bordered">
                          <thead>
                            <tr class="info">
                              <th class="table-header">Delivery Format</th>
                            </tr>
                          </thead>
                        </table> */}
            {/* /**
             * @author Narendra Phadke
             * @param  Description Display Campaign Delivery Details on Modal
             * @return Description return Campaign Delivery Details
             */}
            <br></br>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Delivery Format :
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              <CardBody>
                {/* snehal-Task-3171-prod issue---specification customize mapping issue(on priority) */}

                {/* User Story - 3428 - Karan Jagtap - Reformating of Delivery format - Start */}
                {/* As this whole block of "Delivery Format" is re-arranged, but the data other than styling is not changed.
								Hence, I've still tried to maintain the comments given by other resources for their respective tasks inside this block  */}
                <div className="row">
                  {this.state.campaignBriefDeliveryDetails.map(
                    (campaignBriefDeliveryDetails) => (
                      <>
                        <div className="col-lg-3">
                          <table>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Publisher ID</label>
                              </td>
                              <td>:&nbsp;{campaignBriefDeliveryDetails.pID}</td>
                            </tr>

                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Lead interaction date
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {
                                  campaignBriefDeliveryDetails.leadInteractionDate
                                }
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label id="label">&nbsp;Work phone</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.workPhone}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Industry</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.industry}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;LinkedIn job title
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.linkedInJobTitle}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Country</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.country}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Address</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.address}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Asset name touch 1
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.assetNameTouch1 ===
                                "Yes"
                                  ? campaignBriefDeliveryDetails.assetNameTouch1
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;LinkedIn</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.linkedIn}
                              </td>
                            </tr>
                            {/** Somnath Task-3128,  campaignBriefDetails.length>0 condition added*/}
                            {this.state.campaignBriefDetails.length > 0 ? (
                              (this.state.campaignBriefDetails[0]
                                .marketingChannel === "Email/Telemarketing" ||
                                this.state.campaignBriefDetails[0]
                                  .marketingChannel === "TeleMarketing") &&
                              (campaignBriefDeliveryDetails.channel === "yes" ||
                                campaignBriefDeliveryDetails.channel ===
                                  "Yes") ? (
                                <tr>
                                  <td>
                                    <label id="label">&nbsp;Channel</label>
                                  </td>
                                  <td>:&nbsp;Yes</td>
                                </tr>
                              ) : (
                                <tr>
                                  <td>
                                    <label id="label">&nbsp;Channel</label>
                                  </td>
                                  <td>:&nbsp;No</td>
                                </tr>
                              )
                            ) : (
                              ""
                            )}
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 4</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra4}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 8</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra8}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 12</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra12}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 16</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra16}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 20</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra20}
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div className="col-lg-3">
                          <table>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Campaign ID</label>
                              </td>
                              <td>
                                :&nbsp;{campaignBriefDeliveryDetails.campaignID}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;First name</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.firstName}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Alternate phone no
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.alternatePhoneNo}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Company employee size
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {
                                  campaignBriefDeliveryDetails.companyEmployeeSize
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Job title validation
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {
                                  campaignBriefDeliveryDetails.jobTitleValidation
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;State</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.state}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Street</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.street}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Asset name touch 2
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.assetNameTouch2 ===
                                "Yes"
                                  ? campaignBriefDeliveryDetails.assetNameTouch2
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Domain</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.domain}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 1</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra1}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 5</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra5}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 9</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra9}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 13</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra13}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 17</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra17}
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div className="col-lg-3">
                          <table>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Campaign name</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.campaignName}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Last name</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.lastName}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label id="label">&nbsp;Company name</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.companyName}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Company revenue</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.companyRevenue}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Job level</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.jobLevel}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;City</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.city}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Asset name</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.assetNameTouch1 ===
                                "Yes"
                                  ? "No"
                                  : campaignBriefDeliveryDetails.assetName}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Asset name touch 3
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.assetNameTouch3 ===
                                "Yes"
                                  ? campaignBriefDeliveryDetails.assetNameTouch3
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;IP</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.ip}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 2</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra2}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 6</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra6}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 10</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra10}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 14</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra14}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 18</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra18}
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div className="col-lg-3">
                          <table>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Allocation ID</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.reAllocationID}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Email</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.email}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;LinkedIn company name
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {
                                  campaignBriefDeliveryDetails.linkedInCompanyName
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Job title</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.jobTitle}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Job function</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.jobFunction}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Zip code</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.zipCode}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Asset ID</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.supportDocID}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Customize mapping
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.customizeMapping ===
                                "Yes"
                                  ? campaignBriefDeliveryDetails.customizeMapping
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Comments</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.comments}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 3</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra3}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 7</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra7}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 11</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra11}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 15</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra15}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 19</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra19}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </>
                    )
                  )}
                </div>
                 {/* Rutuja-5097-added to display setected extra filed 21-30  in Delivery Format*/}
                 {this.state.extraAddedFieldsYes.length >= 1 ? (
                  <div style={{marginTop:"20px"}}>
                  <hr style={{borderColor:"#000"}}/>
                  <div
                        style={{
                        fontFamily: "roboto",
                        fontSize: "15px",
                        color: "#193d8f",
                        fontWeight: "600",
                      }}
                  >Additional Fields</div>
                    {this.state.extraAddedFieldsYes.map((details, index) => (
                      <div key={index} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: '20px' }}>
                        {Object.entries(details).map(([key, value]) => (
                          <div key={key} style={{ display: 'flex', marginRight: '80px' }}>
                            <label id="label">&nbsp;{key}</label>
                            <span>:&nbsp;{value}</span>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ) : ("")}
                {/* User Story - 3428 - Karan Jagtap - Reformating of Delivery format - End */}
              </CardBody>
            </div>

            <br />

            {
              this.state.customizedColumns.length === 0 ? (
                ""
              ) : (
                // <table id="myTable" className=" table table-bordered">
                //   <thead>
                //     <tr class="info">
                <div
                  style={{
                    fontFamily: "roboto",
                    fontSize: "20px",
                    color: "#193d8f",
                    fontWeight: "600",
                  }}
                >
                  Customized Column Mapping: &nbsp;
                  {this.state.customizeMappingFormat}
                  {/* kiran-4884-displaying format */}
                </div>
              )
              //     </tr>
              //   </thead>
              // </table>
            }
            {this.state.customizedColumns.length === 0 ? (
              ""
            ) : (
              <div
                class="card"
                style={{
                  backgroundColor: "#f8f8f8",
                  borderColor: "#707070",
                  borderWidth: "0.3px",
                }}
              >
                <CardBody>
                  <div
                    class="container-fluid"
                    style={{ paddingBottom: "11px" }}
                  >
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3"></div>
                      <table
                        class="table table-bordered"
                        id="myTable3"
                        style={{ width: "60%" }}
                      >
                        <thead>
                          <tr style={{ height: "35px" }}>
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                                width: "450px",
                              }}
                            >
                              System Field{" "}
                            </th>
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                                width: "900px",
                              }}
                            >
                              Mapping Alias Field{" "}
                            </th>
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                                width: "200px",
                              }}
                            >
                              Values
                            </th>
                          </tr>
                        </thead>

                        {this.state.campaignBriefDeliveryDetails.map(
                          (campaignBriefDeliveryDetails, i) => {
                            return (
                              <tbody>
                                {campaignBriefDeliveryDetails.pID === "yes" ||
                                campaignBriefDeliveryDetails.pID === "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Publisher ID </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Publisher ID</b>
                                        ) : (
                                          this.state.customizedColumns[0].pID
                                        )}
                                      </b>{" "}
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.campaignID ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.campaignID ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Campaign ID </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Campaign ID</b>
                                        ) : (
                                          this.state.customizedColumns[0].campID
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.reAllocationID ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.reAllocationID ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Allocation ID </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Allocation ID</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .reAllocationID
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.leadInteractionDate ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.leadInteractionDate ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Lead Interaction Date </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Lead Interaction Date</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .leadInteractionDate
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.campaignName ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.campaignName ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Campaign Name </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Campaign Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .campaignName
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.firstName ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.firstName ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;First Name</td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>First Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .firstName
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.lastName ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.lastName ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Last Name </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Last Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .lastName
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.companyName ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.companyName ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Company Name </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Company Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .companyName
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {/* Chaitanya-5054-Added linkedInCompanyName */}
                                {campaignBriefDeliveryDetails.linkedInCompanyName ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.linkedInCompanyName ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;LinkedIn Company Name </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>LinkedIn Company Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .linkedInCompanyName
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.email === "Yes" ||
                                campaignBriefDeliveryDetails.email === "yes" ? (
                                  <tr>
                                    <td>&nbsp;Email </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Email</b>
                                        ) : (
                                          this.state.customizedColumns[0].email
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.workPhone ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.workPhone ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Work Phone </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Work Phone</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .workPhone
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.workPhone ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.workPhone ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Work Phone Format</td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>91-XXXXXXXXXX</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .workPhoneFormat
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.jobTitle ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.jobTitle ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Job Title </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Job Title</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .jobTitle
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {/* Chaitanya-5054-Added linkedInJobTitle */}
                                {campaignBriefDeliveryDetails.linkedInJobTitle ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.linkedInJobTitle ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;LinkedIn Job Title </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>LinkedIn Job Title</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .linkedInJobTitle
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.address ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.address ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Address </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Address</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .address
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.country ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.country ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Country </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Country</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .country
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.city === "Yes" ||
                                campaignBriefDeliveryDetails.city === "yes" ? (
                                  <tr>
                                    <td>&nbsp;City </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>City</b>
                                        ) : (
                                          this.state.customizedColumns[0].city
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.state === "Yes" ||
                                campaignBriefDeliveryDetails.state === "yes" ? (
                                  <tr>
                                    <td>&nbsp;State </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>State</b>
                                        ) : (
                                          this.state.customizedColumns[0].state
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.zipCode ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.zipCode ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Zip Code </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Zip Code</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .zipCode
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.companyEmployeeSize ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.companyEmployeeSize ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Company Employee Size </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Company Employee Size</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .companyEmployeeSize
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.companyRevenue ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.companyRevenue ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Company Revenue </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Company Revenue</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .companyRevenue
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.industry ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.industry ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Industry </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Industry</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .industry
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.assetName ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetName ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Asset Name </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetName
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.assetNameTouch1 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetNameTouch1 ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Asset Name Touch 1 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Name Touch 1</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetNameTouch1
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.assetTimestampTouch1 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetTimestampTouch1 ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Asset Timestamp Touch 1 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Timestamp Touch 1</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetTimestampTouch1
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.assetNameTouch2 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetNameTouch2 ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Asset Name Touch 2 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Name Touch 2</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetNameTouch2
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.assetTimestampTouch2 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetTimestampTouch2 ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Asset Timestamp Touch 2 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Timestamp Touch 2</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetTimestampTouch2
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.assetNameTouch3 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetNameTouch3 ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Asset Name Touch 3 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Name Touch 3</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetNameTouch3
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.assetTimestampTouch3 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetTimestampTouch3 ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Asset Timestamp Touch 3 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Timestamp Touch 3</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetTimestampTouch3
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.street ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.street ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Street </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Street</b>
                                        ) : (
                                          this.state.customizedColumns[0].street
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.ip === "Yes" ||
                                campaignBriefDeliveryDetails.ip === "yes" ? (
                                  <tr>
                                    <td>&nbsp;IP </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>IP</b>
                                        ) : (
                                          this.state.customizedColumns[0].ip
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.supportDocID ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.supportDocID ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Asset ID </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset ID</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .supportDocID
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.jobLevel ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.jobLevel ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Job Level </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Job Level</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .jobLevel
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.jobFunction ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.jobFunction ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Job Function </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Job Function</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .JobFunction
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {this.state.campaignBriefDetails[0]
                                  .marketingChannel ===
                                "Email/Telemarketing" ? (
                                  campaignBriefDeliveryDetails.channel ===
                                    "Yes" ||
                                  campaignBriefDeliveryDetails.channel ===
                                    "yes" ? (
                                    <tr>
                                      <td>&nbsp;Channel </td>
                                      <td>
                                        &nbsp;Current:{" "}
                                        <b>
                                          &nbsp;
                                          {this.state.customizedColumns
                                            .length === 0 ? (
                                            <b>Channel</b>
                                          ) : (
                                            this.state.customizedColumns[0]
                                              .channel
                                          )}
                                        </b>
                                        <br />
                                      </td>
                                      <td></td>
                                    </tr>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra1 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra1 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 1 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 1</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra1
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra2 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra2 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 2 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 2</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra2
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra3 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra3 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 3</td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 3</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra3
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra4 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra4 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 4 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 4</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra4
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra5 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra5 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 5 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 5</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra5
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {/* // Sandeep-task-3158-added code for extra field from 6to20 */}
                                {campaignBriefDeliveryDetails.extra6 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra6 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 6 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 6</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra6
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra7 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra7 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 7 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 7</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra7
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra8 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra8 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 8 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 8</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra8
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra9 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra9 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 9 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 9</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra9
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra10 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra10 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 10 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 10</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra10
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra11 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra11 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 11 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 11</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra11
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra12 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra12 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 12 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 12</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra12
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra13 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra13 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 13 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 13</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra13
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra14 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra14 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 14 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 14</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra14
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra15 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra15 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 15 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 15</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra15
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra16 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra16 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 16 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 16</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra16
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra17 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra17 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 17 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 17</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra17
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra18 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra18 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 18 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 18</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra18
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra19 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra19 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 19 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 19</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra19
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra20 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra20 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 20 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 20</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra20
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.domain ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.domain ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Domain </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Domain</b>
                                        ) : (
                                          this.state.customizedColumns[0].domain
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.alternatePhoneNo ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.alternatePhoneNo ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Alternate Phone No </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Alternate Phone No</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .alternatePhoneNo
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.comments ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.comments ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Comments </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Comments</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .comments
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.linkedIn ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.linkedIn ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;LinkedIn </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>LinkedIn</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .linkedIn
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                 {campaignBriefDeliveryDetails.extra21 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra21 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 21 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 21</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra21
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra22 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra22 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 22 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 22</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra22
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra23 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra23 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 23 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 23</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra23
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra24 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra24 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 24 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 24</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra24
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra25 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra25 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 25 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 25</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra25
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra26 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra26 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 26 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 26</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra26
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra27 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra27 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 27 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 27</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra27
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra28 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra28 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 28 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 28</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra28
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra29 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra29 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 29 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 29</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra29
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra30 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra30 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 30 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 30</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra30
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                              </tbody>
                            );
                          }
                        )}
                      </table>
                    </div>
                  </div>
                </CardBody>
              </div>
            )}
            <br />
             {/* Nilesh-5212- if columnseqencing is yes then show below table */}
             {this.state.columnSequenceCheck === "Yes" ? (
               <div>
                      {" "}
                    <div
                    style={{
                      fontFamily: "roboto",
                      fontSize: "20px",
                      color: "#193d8f",
                      fontWeight: "600",
                    }}
                    >
                    Column Sequencing :
                    </div>
                    <div
                    class="card"
                    style={{
                      backgroundColor: "#f8f8f8",
                      borderColor: "#707070",
                      borderWidth: "0.3px",
                    }}
                    >
                    {/* kiran-4364-added display property in label for responsiveness */}
                    <CardBody  
                    style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    }}
                    >
                      <div className="table-container" style={{width: "50%"}}>
                        <Table
                        columns={columns} 
                        dataSource={this.state.columnsequencingarray}
                        pagination={false}
                        bordered
                        id="myTable"
                        showHeader={false}
                        size="small"
                        class="ant-table-thead"
                        className={tableCSS}
                        //scroll={{ x: 1250, y: 4000 }}
                        >
                        </Table>
                        </div>
                        </CardBody>
                        </div>
                        </div>
                        ):("")}
            <br/>
            {/*row 5 */}
            {/* /**
             * @author Narendra Phadke
             * @param  Description Display Campaign Custom Question Details on Modal
             * @return Description return Campaign Delivery Details
             */}
            {this.state.campaignBriefCustomQuestionDetails.length > 0 ? (
              <div>
                {" "}
                {/* snehal-task-3112--Custom Question Alias */}
                <div
                  style={{
                    fontFamily: "roboto",
                    fontSize: "20px",
                    color: "#193d8f",
                    fontWeight: "600",
                  }}
                >
                  Custom Questions
                </div>
                {/* Akash-Bug-4215 */}
                {this.state.campaignBriefDetails.map((campaignBriefDetails) => (
                  <div
                    className="card"
                    style={{
                      backgroundColor: "#f8f8f8",
                      borderColor: "#707070",
                      borderWidth: "0.3px",
                    }}
                  >
                    {campaignBriefDetails.customQuestionAliasName === "No" ? (
                      <CardBody>
                        <table>
                          <tr>
                            <td style={{ width: "160px" }}>
                              <label id="label">Add Question Alias</label>
                            </td>
                            {this.state.campaignBriefDetails.map(
                              (campaignBriefDetails) => (
                                <td>
                                  <span>
                                    :&nbsp;
                                    {
                                      campaignBriefDetails.customQuestionAliasName
                                    }
                                  </span>
                                </td>
                              )
                            )}
                          </tr>
                        </table>
                        {this.state.campaignBriefCustomQuestionDetails.map(
                          (campaignBriefCustomQuestionDetails, i) => (
                            <div class="row">
                              <div class="col-sm-12 col-lg-4">
                                {" "}
                                <label id="label">
                                  &nbsp;Question {i + 1}:
                                </label>
                                &nbsp;
                                <textarea
                                  id="customQuestion1"
                                  class="form-control"
                                  name="customQuestion1"
                                  disabled //bug-4450-disabled textarea
                                  defaultValue={unescape(
                                    campaignBriefCustomQuestionDetails.customQuestion
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                              <div class="col-sm-12 col-lg-4">
                                {" "}
                                <label id="label">Answer {i + 1}:</label>
                                &nbsp;
                                <textarea
                                  id="answer1"
                                  class="form-control"
                                  name="answer1"
                                  disabled //bug-4450-disabled textarea
                                  defaultValue={unescape(
                                    campaignBriefCustomQuestionDetails.answer
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                              <div class="col-sm-12 col-lg-4">
                                {" "}
                                <label id="label">
                                  Not Allowed Answer {i + 1}:
                                </label>
                                &nbsp;
                                <textarea
                                  id="nonallowedanswer"
                                  class="form-control"
                                  name="nonallowedanswer"
                                  disabled //bug-4450-disabled textarea
                                  defaultValue={unescape(
                                    campaignBriefCustomQuestionDetails.disAllowAnswer
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                            </div>
                          )
                        )}
                      </CardBody>
                    ) : (
                      <CardBody>
                        {/* snehal-task-3112--Custom Question Alias */}
                        <table>
                          <tr>
                            <td style={{ width: "160px" }}>
                              <label id="label">Add Question Alias</label>
                            </td>
                            {this.state.campaignBriefDetails.map(
                              (campaignBriefDetails) => (
                                <td>
                                  <span>
                                    :&nbsp;
                                    {
                                      campaignBriefDetails.customQuestionAliasName
                                    }
                                  </span>
                                </td>
                              )
                            )}
                          </tr>
                        </table>
                        {this.state.campaignBriefCustomQuestionDetails.map(
                          (campaignBriefCustomQuestionDetails, i) => (
                            <div class="row">
                              <div class="col-sm-12 col-lg-3">
                                {" "}
                                <label id="label">&nbsp;Alias {i + 1}:</label>
                                &nbsp;
                                <textarea
                                  id="aliasName1"
                                  class="form-control"
                                  name="aliasName1"
                                  disabled //bug-4450-disabled textarea
                                  defaultValue={unescape(
                                    campaignBriefCustomQuestionDetails.aliasName
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                              <div class="col-sm-12 col-lg-3">
                                {" "}
                                <label id="label">
                                  &nbsp;Question {i + 1}:
                                </label>
                                &nbsp;
                                <textarea
                                  id="customQuestion1"
                                  class="form-control"
                                  name="customQuestion1"
                                  disabled //bug-4450-disabled textarea
                                  defaultValue={unescape(
                                    campaignBriefCustomQuestionDetails.customQuestion
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                              <div class="col-sm-12 col-lg-3">
                                {" "}
                                <label id="label">Answer {i + 1}:</label>
                                &nbsp;
                                <textarea
                                  id="answer1"
                                  class="form-control"
                                  name="answer1"
                                  disabled //bug-4450-disabled textarea
                                  defaultValue={unescape(
                                    campaignBriefCustomQuestionDetails.answer
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                              <div class="col-sm-12 col-lg-3">
                                {" "}
                                <label id="label">
                                  Not Allowed Answer {i + 1}:
                                </label>
                                &nbsp;
                                <textarea
                                  id="nonallowedanswer"
                                  class="form-control"
                                  name="nonallowedanswer"
                                  disabled //bug-4450-disabled textarea
                                  defaultValue={unescape(
                                    campaignBriefCustomQuestionDetails.disAllowAnswer
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                            </div>
                          )
                        )}
                      </CardBody>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
            <br />
            <br />
            {/*row 6x */}
          </div>
          {/* // border */}
        </div>
        {/* // col-lg-12 */}
      </div>
    );
  }
} // end of class CampaignSpecificationDashboardDetails

/* @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
CampaignSpecificationAdvertiserDashboardDetails.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
  withRouter(CampaignSpecificationAdvertiserDashboardDetails)
);
