
/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
import React from "react";
import Footer from "../layouts/footer";
import PublisherNavigation from "../layouts/publisherNavPage";
import { MDBRow, MDBCol, } from "mdbreact";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
// import fs from "fs";  //Nilesh-4240-Removing console warnings
import { saveAs } from "file-saver";
import { Table, Checkbox } from "antd"; // Nilesh-4726 Add checkbox
import { css } from "emotion";
import "./loader.css";
//import { LeadReviewLoader } from "../loaders/LeadReviewLoader"; //Sandeep-task-3499-removed old loader
import "./publisherDeliverLead.css";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //sonali-task-3945-added default header

// const queryString = require("query-string");  //Nilesh-4240-Removing console warnings
const xlsx = require("xlsx");
const Swal = require("sweetalert2");
var dateTime = require("node-datetime"); //Somnath Task-3403, date time to get date time in specified format

const tableCSS = css({
  backgroundColor: "white",
  borderStyle: "bold",
  "& thead  > tr": {
    backgroundImage: "linear-gradient(to right,#28166f,#007cc3)!important",
    color: "white",
    fontSize: "14px",
    fontWeight: "500",
  },
  "& thead > tr >th": {
    border: "1px solid black",
    height: "20%",
    color: "white",
  },
});

var leadLength;
var leadFileExt;
class PublisherDeliverLead extends React.Component {
  constructor() {
    super();
    this.state = {
      campID: "",
      parentCampID: "",
      reallocationID: "",
      selectedFile: null,
      filesData: [],
      fields: {},
      leadDetails: [],
      leadDetailsData: [],
      getExt: [],
      leadDetailsData1: [],
      leadDetailsDataTest: [],
      errors: {},
      fileerrors: {},
      formaterrors: {},
      inputClass: "disabled",
      campaignLevelSwitch: "",
      DI_QA_Switch: "",
      msg: " ", //sandeep-task-3119-added code to store message
      columnSearch: [], //shivani-task-3204-added array for search functionality.
      key1: "lead", //shivani-task-3204-passed key in url .
      tableSearchText: "", //shivani-task-3204-declared variable for search .
      pageSize1: 100, //shivani-task-3204-declare variable for pagination records .
      selectedLead: " ", //shivani-task-3204-declared variable for filter the selected  status .
      leadResponseDetails: [], //shivani-task-3204-declared array for filter functionality .
      leadDetailsResponse: [], //shivani-task-3204-declared array for filter functionality .
      allDataArray: [], //shivani-task-3204-declared array for filter functionality
      rejectlead: 0, //shivani-task-3204-declared variable to display the reject lead count .
      loading1: false, //shivani-task-3204-declared variable to display loader .
      loaderLead: "", //Sandeep-task-3499-added code for loader
      headerErrors: {},  //Priyanka-4635-Added array to display error messages
      rejectedHeaders: "", //Priyanka-4635-Declared array to display rejected headers
      missingHeaders: "",  //Priyanka-4635-Declared array to display missing headers
      duplicateHeaders: "", //Chaitanya-4719-Declared array to display missing headers
      domainChecked: false, // Nilesh-4726-Store domainCheck value
      isDomainCheckedboxVisible: true,
      domainChecking: "",
      btnDisable: "btn add-button",
    };

    this.submitDeliverLead = this.submitDeliverLead.bind(this);
    this.handleChangeFileDeliverLead =
      this.handleChangeFileDeliverLead.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.downloadAuditReport = this.downloadAuditReport.bind(this);
    this.displayCampaignSpecification =
      this.displayCampaignSpecification.bind(this);
    //shivani-task 3204- bind below all functions for pagination, search funtionality and filter out the data as user will select the status .
    this.handleChangePageSize1 = this.handleChangePageSize1.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.selectLead = this.selectLead.bind(this);
    this.filterResponseLead = this.filterResponseLead.bind(this);
    this.viewVoiceLogs = this.viewVoiceLogs.bind(this); //sonali-3945-added this for VAPT
    this.updateLeadCount = this.updateLeadCount.bind(this); // Nilesh-4757-Bind the function

    // Sandeep-task-3499-removed old loader for loading issue
    //  this.loadingRef = React.createRef(); // shows the Loader
    //  this.closeLoadingRef = React.createRef(); // hides the Loader
  }
  //sonali-3945-added this for VAPT
  viewVoiceLogs(e) {
    e.preventDefault();
    this.props.history.push("/voiceLogsUpload", {
      campID: this.props.location.state.campID,
      parentCampID: this.props.location.state.parentCampID,
      reallocationID: this.props.location.state.reallocationID,
    }); //sonali-task-3945-replace query params
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the login authentication
   * @return Description return All details of authentication
   */
  componentDidMount() {
    //Sonali-3945-VAPT
    if (this.props.location.state !== undefined) {
      const { parentCampID, campID, reallocationID } =
        this.props.location.state;
      this.setState({ reallocationID, campID, parentCampID });
    }
    // var campID = this.props.location.state.campID;  //Nilesh-4240-Removing console warnings
    var parentCampID = this.props.location.state.parentCampID;
    var reallocationID = this.props.location.state.reallocationID;
    var key2 = this.state.key1;

    this.setState({ parentCampID, reallocationID, key2: key2 });

    // const { isAuthenticated, user } = this.props.auth;  //Nilesh-4240-Removing console warnings

    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    }
    this.setState({ campID: this.props.location.state.campID });
    fetch(
      "publisher/getDeliveryFileExtension?campID=" +
      this.props.location.state.campID
    )
      .then((res) => res.json())
      .then((getExt) => {
        this.setState({ getExt: getExt[0].leadDeliveryOption });
      })
      .catch(function (err) {
        console.log(err);
      });

    let data = {
      //pID:user.id,//sonali-3945-removing pID from the following call
      campID: this.props.location.state.campID,
    };

    fetch("/diQARole/getFlag", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((getFlag) => {
        this.setState({
          campaignLevelSwitch: getFlag[0].campaignLevelSwitch,
          DI_QA_Switch: getFlag[1].DI_QA_Switch,
        });
      })


      .catch(function (err) {
        console.log(err);
      });
  }

  /**
   * @author Sandeep Dhawale
   * @param  Description handle the campaignSpecificationPublisherDashboard
   * @return Description redirect to campaignSpecificationPublisherDashboard
   */

  displayCampaignSpecification(e) {
    //Sonali-3945-VAPT-get parameters from

    var campID = this.props.location.state.campID;
    var parentCampID = this.props.location.state.parentCampID;
    var reallocationID = this.props.location.state.reallocationID;

    // var campID = parsed.campID;//sandeep-task-3095-added URL parameter campID
    // var parentCampID = parsed.parentCampID;//sandeep-task-3095-added URL parameter parentCampID
    // var reallocationID = parsed.reallocationID;//sandeep-task-3095-added URL parameter reallocationID
    var key2 = this.state.key1; //shivani-task 3204-passes lead key to navigate back on the same page.
    //Sonali-3945-redirect to next page using history.push
    //Sonali-4032-adding into session storagr
    sessionStorage.setItem("campID", campID);
    sessionStorage.setItem("parentCampID", parentCampID);
    sessionStorage.setItem("reallocationID", reallocationID);
    sessionStorage.setItem("leadkey", key2);

    window.open("/campaignSpecificationPublisherDashboard");

    // ); //sunita-task-3099-added changes to open link in new tab
  }
  /**
   * @author Shivani pathak (3204-task)
   * @param  Description handled pagination
   * @return Description return All the records as per pagination selected .
   */
  handleChangePageSize1(e) {
    var pageSize1 = e.target.value;
    this.setState({ pageSize1: pageSize1 });
  }
  /**
   * @author Shivani pathak(3204-task)
   * @param  Description handle the Status of the leads
   * @return Description return All leads as we filter out from status
   */
  selectLead(e) {
    var selectedLead = e.target.value;
    this.setState({ selectedLead: selectedLead });
    if (selectedLead === "All") {
      this.setState({ leadDetailsData: this.state.allDataArray });
    } else {
      var leadDetailsResponse = [...this.state.leadDetailsResponse];
      let filterleadDetails = leadDetailsResponse.filter((a) => {
        let status = a.status;
        status = status.toString().trim();
        status = status.split("|").join("");
        status = status.replace(/\s*,\s*/g, ",");
        status = status.split(",");
        return status.includes(selectedLead);
      });
      this.setState({ leadDetailsData: filterleadDetails });
    }
  }
  /**
   * @author Shivani pathak(3204-task)
   * @param  Description handled filter data .
   * @return Description return as we filter out the status from dropdown .
   */
  filterResponseLead() {
    if (this.state.selectedLead !== "All") {
      var selectedLead = this.state.selectedLead;
      var leadDetailsResponse = this.state.leadDetailsResponse;
      let filterleadDetails = leadDetailsResponse.filter((a) => {
        return a.status.includes(selectedLead);
      });
      this.setState({ leadDetailsData: filterleadDetails });
    } else {
    }
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the file upload
   * @return Description return All details of file
   */

  handleChangeFileDeliverLead(e) {
    e.preventDefault();
    var fileName;
    var file = [];
    let headerErrors = {};//Priyanka-4635
    e = document.createElement("input"); //creating a input element but not binding it to body added by sandeep
    e.setAttribute("type", "file"); //setting a attribute for type file added by sandeep
    e.setAttribute("multiple", true); //setting a attribute for multiple added by sandeep
    e.click(); //to open file dialougeadded by sandeep
    e.addEventListener(
      "change",
      function () {
        //calling an eventListener at change event added by sandeep
        let filesData = e.files[0];
        let filesData1 = e.files;
        var output = document.getElementById("LeadList");
        leadLength = filesData1.length;
        var extFileName = filesData.name;
        leadFileExt = extFileName.split(".").pop();
        output.innerHTML = "<ul>";
        output.innerHTML += '<li id="fileUp">' + extFileName + "</li>";
        output.innerHTML += "</ul>";
        //sonali-3945-accessing parameters using this.props.location
        var campID = this.props.location.state.campID;
        var reAllocationID = this.props.location.state.reallocationID;
        var parentCampID = this.props.location.state.parentCampID;

        // const { isAuthenticated, user } = this.props.auth;  //Nilesh-4240-Removing console warnings

        if (this.validateForm(extFileName) === "false") {
          Swal.fire({
            text: "Please Upload only Excel(.xls, .xlsx) file",
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
          //kiran-4573-aaded swal fire for msg
          //Sandeep-task-3079-passsing filesData parameter in validateForm function
          return;
        } else {
          //Not insert file data into database only read file and get result into array
          // var wait = document.getElementById('pleaseWait');
          // alert("for wait=>"+wait)
          // wait.innerHTML="Please wait......";
          // this.setState({
          //   loading:" ",msg:"",//sandeep-task-3119-added code to set message
          // });
          // this.loadingRef.current.click();//Sandeep-task-3441-added code for loader
          this.setState({
            loading1: true,
            msg: "",
            headerErrors: headerErrors,
            loaderLead: "loader_uploadLead",
          });
          var clientDateTime = dateTime.create(); //Somnath Task-3403, Get current Date time as per specified dateTime
          clientDateTime = clientDateTime.format("Y-m-d H:M:S");
          var data = new FormData();
          data.append("campID", campID);
          data.append("parentCampID", parentCampID);
          //  data.append("userName",user.name); //kiran-bug 4253-removing username
          // data.append("pIDFile",user.id);//sonali-3945-removing pID from the following call
          data.append("reAllocationID", reAllocationID);
          data.append("file", filesData);
          data.append("clientDateTime", clientDateTime); //Somnath Task-3403, pass clientDateTime in body for leadInteractionDate validation

          if (leadFileExt === "xlsx") { //Nilesh-4240-Removing console warnings
            //Sandeep-task-3119-removed localhost code from API
            fetch("lead/uploadFileForLeadDeliver", {
              method: "POST",
              body: data,
            })

              .then((res) => res.json())
              .then((leadDetailsDataTest) => {
                
                //Aman-4567 Added this condtion to check if lead file length is <= 1900
                if (leadDetailsDataTest.success === "FileLengthIsGreater") {
                  
                  //Aman-4567 Added this to remove loading loader
                  this.setState({
                    loading1: false,
                    msg: "",
                    headerErrors: headerErrors,
                    loaderLead: "loader_uploadLead",
                  });

                  Swal.fire({
                    html: "<b>We are working to fix the current issue of large lead file uploads. Till that time, as a workaround, we request you to upload the lead files with leads upto 1900 leads maximum in one file.</b>",
                    type: "warning",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                    preConfirm: () => {
                      window.location.reload();
                    },
                  });
                  // fileInput.value = "";
                  return false;
                } 
                
                if (leadDetailsDataTest.length > 0) {
                  //this.closeLoadingRef.current.click();//Sandeep-task3441-added loader
                  let formaterrors = {};
                  // const { isAuthenticated, user } = this.props.auth;   //Nilesh-4240-Removing console warnings
                  let result = {
                    leadDetailsData: leadDetailsDataTest,
                    // user:user,//sonali-3945-removing user from the following call
                    campID: campID,
                    parentCampID: parentCampID,
                    reallocationID: reAllocationID,
                    campaignLevelSwitch: this.state.campaignLevelSwitch,
                    DI_QA_Switch: this.state.DI_QA_Switch,
                    extFileName: extFileName  //Aman-4660 -MBAdmin-(to send filename)eport - Lead File Tracking Report- DMA's Upload lead file count and file details
                  };

                  if (leadDetailsDataTest === undefined) {
                    formaterrors["format"] =
                      "Please upload correct format file";
                  } else {
                    fetch("/lead/updateLead", {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify(result),
                    })
                      .then((res) => res.json())
                      .then((leadDetailsData) => {
                        //this.closeLoadingRef.current.click();//Sandeep-task3441-added loader
                        this.setState({
                          allDataArray: leadDetailsData,
                          rejectlead: leadDetailsData[0].rejectedLead,
                        });
                        //shivani-3204(3287)-filtered the status of the leads.
                        //kiran-4511-added if condition for internal review as in status filter dropdown it not show properly 
                        //kiran-4514-declare count for showing proper status in dropdown
                        let leadfilter = [];
                        let count = 0;
                        for (let i = 0; i < leadDetailsData.length; i++) {
                          let status = leadDetailsData[i].status;
                          for (let j = 0; j < status.length; j++) {         
                            if (leadDetailsData[i].status == "InternalReview") { // datatype is object
                              count++;
                            }
                            else {
                              let label = status[j];
                              label = label.trim();
                              label = label.split("|").join("").trim();
                              leadfilter.push({ status: status[j], label });
                            }
                          }
                        }
                        if (count > 0) {
                          let status = "InternalReview";
                          let label = status;
                          leadfilter.push({ status: status, label });
                        }
                        const result = Array.from(
                          new Set(leadfilter.map((p) => p.label))
                        ).map((label) => {
                          return {
                            label: label,
                            status: leadfilter.find((p) => p.label === label)
                              .status,
                          };
                        });
                        //Nilesh-4726- For finding invalid domain status
                        let statusResults = [];
                        
                        for (let i = 0; i < leadDetailsData.length; i++) {
                          statusResults = leadDetailsData.filter((element) => {
                            return element.status.includes("REJECT_EMAIL_INVALID_DOMAIN") || element.status.includes(" | REJECT_EMAIL_INVALID_DOMAIN");//Nilesh-4760-Add or status condition

                          })
                        }
                        //this.closeLoadingRef.current.click();//Sandeep-task3441-added loader
                        this.setState({
                          leadDetailsData,
                          leadDetailsResponse: leadDetailsData,
                          loading1: false,
                          leadfilter: result,
                          loaderLead: "",
                          statusResults: statusResults
                        });

                        var status1;
                        //Nilesh-4240-Removing console warnings
                        if (
                          leadDetailsData[0].marketingChannel ===
                          "TeleMarketing" ||
                          leadDetailsData[0].marketingChannel ===
                          "Email/Telemarketing"
                        ) {
                          for (var i = 0; i < leadDetailsData.length; i++) {
                            if (
                              leadDetailsData[i].status == "voiceLogPending" // datatype is object
                            ) {
                              status1 = true;
                            }
                          }
                          //shivani-task 3183-passed parentCampID to the link for backbutton fun on voice Logs Upload page.
                          if (status1 === true) { //Nilesh-4240-Removing console warnings
                            document.getElementById(
                              "voiceLogLink"
                            ).style.display = "block";
                          }
                        }
                      });
                  }
                }
                //Sandeep-task-3119-added else block
                else {
                  //this.closeLoadingRef.current.click();//Sandeep-task3441-added loader
                  //setTimeout(function(){this.closeLoadingRef.current.click() ; }, 3000);//Sandeep-task-3441-added code for loader
                  //Priyanka - 4575 - Added condition to display invalid template error
                  //Priyanka-4635-Added different conditions to display error messages
                  if (leadDetailsDataTest.success === false) { //Nilesh-4240-Removing console warnings
                    let response = leadDetailsDataTest.response;
                    //alert("response ==>"+JSON.stringify(response));
                    let file = response[0].file;
                    //alert(rejectedHeaders+"---"+missingHeaders+"---"+file);
                    if (file === true) { //Nilesh-4240-Removing console warnings
                      let rejectHeader = response[0].rejectHeader;
                      let missingHeader = response[0].missingHeader;
                      let duplicateHeader = response[0].duplicateHeader;
                      //alert("duplicateHeader ==>"+JSON.stringify(response[0].duplicateHeader));
                      headerErrors["error"] = "INVALID DELIVERY TEMPLATE - Please check below columns in the uploaded file header";
                      if (rejectHeader.length > 0) {
                        headerErrors["rejectedHeaders"] = "Mismatch Columns - ";
                      }
                      if (missingHeader.length > 0) {
                        headerErrors["missingHeaders"] = "Expected Columns - ";
                      }
                      if (duplicateHeader.length > 0) {
                        headerErrors["duplicateHeaders"] = "Duplicate Columns - ";
                      } //Chaitanya-4719-Duplicate headers in delivery template due to CQ alias name
                      this.setState({
                        loading1: false,
                        loaderLead: "",
                        headerErrors: headerErrors,
                        rejectedHeaders: rejectHeader,
                        duplicateHeaders: duplicateHeader,
                        missingHeaders: missingHeader,
                        leadDetailsData: []
                      });
                    }
                    else {
                      headerErrors["error"] = "INVALID DELIVERY TEMPLATE - Please use correct DI Delivery Template to upload leads";
                      this.setState({
                        loading1: false,
                        loaderLead: "",
                        headerErrors: headerErrors,
                        leadDetailsData: []
                      });
                    }
                  }
                  else {
                    this.setState({
                      loading1: false,
                      msg: "Please upload DI template only",
                      loaderLead: "",
                    }); //Sandeep-task-3119-added code to stop loading and display error
                  }
                }
              })
              //Sandeep-task-3119-added catch block
              .catch(function (err) {
                console.log(err);
              });
          } /** End of else if Statement which check file extension */
          else if (
            leadFileExt == "csv"
          ) {
            fetch("/lead/uploadLeadDeliverCSV", {
              method: "POST",
              body: data,
            })
              .then((res) => res.json())
              .then((leadDetailsDataTest) => {
                if (leadDetailsDataTest.length > 0) {
                  let formaterrors = {};

                  // const { isAuthenticated, user } = this.props.auth;   //Nilesh-4240-Removing console warnings
                  let result = {
                    leadDetailsData: leadDetailsDataTest,
                    // user:user,//sonali-3945-removing pID from the following call
                    campID: campID,
                    parentCampID: parentCampID,
                    reallocationID: reAllocationID,
                    campaignLevelSwitch: this.state.campaignLevelSwitch,
                    DI_QA_Switch: this.state.DI_QA_Switch,
                  };
                  if (leadDetailsDataTest === undefined) {
                    formaterrors["format"] =
                      "Please upload correct format file";
                  } else {
                    this.updateLeadCount(result) // Nilesh-4757-Call the function
                  }
                }
              });
          }
          this.setState({
            filesData,
          });
        }
      }.bind(this)
    );
  }



  /**
   * @author Narendra Phadke
   * @param  Description submit the file
   * @return Description return All details of file
   */
  submitDeliverLead(e) {
    e.preventDefault();
    let formaterrors = {};
    //Sonali-3945-VAPT-get parameters from
    var campID = this.props.location.state.campID;
    // var parsed = queryString.parse(this.props.location.search);
    var parentCampID = this.props.location.state.parentCampID;
    var reallocationID = this.props.location.state.reallocationID;
    if (leadLength === undefined) {  //Nilesh-4240-Removing console warnings
      return;
    } else {
      if (this.validateForm() === "false") {
        return;
      } else {
        // const { isAuthenticated, user } = this.props.auth;  //Nilesh-4240-Removing console warnings
        let result = {
          leadDetailsData: this.state.leadDetailsDataTest,
          // user:user,//sonali-3945-removing user from the following call
          campID: campID,
          parentCampID: parentCampID,
          reallocationID: reallocationID,
          campaignLevelSwitch: this.state.campaignLevelSwitch,
          DI_QA_Switch: this.state.DI_QA_Switch,
        };
        if (this.state.leadDetailsDataTest === undefined) {
          formaterrors["format"] = "Please upload correct format file";
        } else {

          ///
          fetch("/lead/updateLead", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(result),
          })
            .then((res) => res.json())
            .then((leadDetailsData) => {
              this.setState({ leadDetailsData: leadDetailsData });
              var status1;
              // alert("leadDetailsData[0].marketingChannel---- >>" + JSON.stringify(leadDetailsData[0].marketingChannel))
              // alert("leadLength=== >>>>" + typeof(leadDetailsData[0].marketingChannel))
              if (
                leadDetailsData[0].marketingChannel == "TeleMarketing" ||
                leadDetailsData[0].marketingChannel == "Email/Telemarketing"
              ) {
                for (var i = 0; i < leadDetailsData.length; i++) {
                  if (leadDetailsData[i].status == "voiceLogPending") {
                    status1 = true;
                  }
                }
                //shivani-task 3183-passed parentCampID to the link for backbutton fun on voice Logs Upload page.
                if (status1 == true) {
                  document.getElementById("voiceLogLink").style.display =
                    "block";
                }
              }
            });

          ////
        }
      }
    }
  }

  validateForm(filesData1 = "") {
    //Sandeep-task-3079-accessing filesData parameter in validateForm function

    var pclick = document.getElementById("pleaseClick");
    pclick.innerHTML = "";
    let formIsValid = true;
    let errors = {};
    let fileerrors = {};

    var campFileExt;
    if (this.state.getExt === "Excel") { //Nilesh-4240-Removing console warnings
      campFileExt = "xlsx";
    } else if (this.state.getExt === "CSV") { //Nilesh-4240-Removing console warnings
      campFileExt = "csv";
    }

    
    if (leadLength === undefined) {  //Nilesh-4240-Removing console warnings
      errors["file"] = "Please upload minimum one excel file";
      formIsValid = "false";
    }

    if (leadLength !== undefined) {
      // var allowedFiles = [".xls", ".xlsx", ".csv"];
      var allowedFiles = [".xls", ".xlsx"];//kiran-4573-removed .csv file

      var deliveryLeadfiles = filesData1; //sandeep-task-3079-filesData1 stored into deliveryLeadfiles
      var regex = new RegExp(
        "([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
      );
      if (!regex.test(deliveryLeadfiles.toLowerCase())) {
        //Sandeep-task-3079-accessing deliveryLeadfiles with .name to access Leadfile name
        fileerrors["fileextensions"] =
          "Please upload files having extensions: " +
          allowedFiles.join(", ") +
          " only";
        formIsValid = "false";
      }
    }
    this.setState({
      errors: errors,
      fileerrors: fileerrors,
    });

    return formIsValid;
  }
  /**
   * @author Somnath Keswad
   * @param  Description Write the data in Excel File
   * @return Description Download the Excel File
   */

  downloadAuditReport(e) {
    e.preventDefault();
    var campID = e.target.id;
    // alert("campID ==>>" + JSON.stringify(campID))
    // var campID = this.props.location.state.campID; //Nilesh-4240-Removing console warnings
    // alert("campID ---->>" + JSON.stringify(campID))
    var parentCampID = this.props.location.state.parentCampID;
    var reAllocationID = this.props.location.state.reallocationID;
    // const { isAuthenticated, user } = this.props.auth;  //Nilesh-4240-Removing console warnings

    let data = {
      campID: campID,
      parentCampID: parentCampID,
      reallocationID: reAllocationID,
      listLeadInfoID: this.state.leadDetailsData,
      //  user: user//sonali-3945-removing user from the following call
    };

    fetch("publisher/downloadAuditReport", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((downloadFiles) => {
        this.setState({ downloadFiles: downloadFiles });

        if (downloadFiles.length > 0) {
          var ws = xlsx.utils.json_to_sheet(downloadFiles);
          var wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, "Lead");
          var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" });
          // var str = xlsx.write(wb, { bookType: "xlsx", type: "binary" });  //Nilesh-4240-Removing console warnings
          function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i != s.length; ++i)
              view[i] = s.charCodeAt(i) & 0xff;
            return buf;
          }
          var fileName =
            "Audit_Report" + parentCampID + "-" + reAllocationID + ".xlsx";
          saveAs(
            new Blob([s2ab(buf)], { type: "application/octet-stream" }),
            fileName
          );
        } else {
        }
      });
  }

  handleChangeSearch(e) {
    e.preventDefault();
    let currentList = [];
    let newList = [];
    let filterData = e.target.value;
    if (e.target.value === "") {
      newList = this.state.leadDetailsData;
    } else {
      currentList = this.state.leadDetailsData;
      newList = currentList.filter((item) => {
        const lc = item;
        const filter = e.target.value.toLowerCase();
        var data = Object.values(lc);
        var dataFinal = data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower = dataFinal.toLowerCase();
        return dataToLower.includes(filter);
      });
    }
    this.setState({
      columnSearch: [...newList],
      tableSearchText: filterData,
    });
  }

  //Nilesh-4726-For this function use for changing checkbox value
  onChangeDoaminCheckbox = (e) => {
    this.setState({
      domainChecked: e.target.checked,
    });
  };

  //Nilesh-4726-For this function use for invalid domain to valid
  offDomainValidation = (e) => {
    e.preventDefault();
    const result = this.state.statusResults;;
    // const { user } = this.props.auth;  //Nilesh-4240-Removing console warnings

    let data = {
      result,
      campaignDetail: [
        {
          domainChecking: this.state.domainChecked ? "OFF" : "ON",
        },
      ],

    };

    this.setState({
      loading1: true,
      msg: "",
      loaderLead: "loader_uploadLead",
    });
    fetch("/validateEmail/notValidateDomain", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((results) => {
        this.setState({
          loading1: false,
          loaderLead: "",
          results: results
        });

        let statusResults = this.state.statusResults
        let mainArray = this.state.leadDetailsData;

        for (let i = 0; i < this.state.results.length; i++) {

          for (let j = 0; j < mainArray.length; j++) {

            if (this.state.results[i].leadInfoID === mainArray[j].leadInfoID) {

              mainArray[j].status = this.state.results[i].status;

              statusResults = this.state.results.filter((element) => {

                return element.status.includes("REJECT_EMAIL_INVALID_DOMAIN") || element.status.includes(" | REJECT_EMAIL_INVALID_DOMAIN"); //Nilesh-4760-Add or status condition
              })
              break; // Exit the inner loop once the match is found
            }
          }

        }

        //Nilesh-4757- call for updateLeadCount function
        let result = {
          leadDetailsData: mainArray,
          // user:user,//sonali-3945-removing pID from the following call
          campID: this.state.campID,
          parentCampID: this.state.parentCampID,
          reallocationID: this.state.reAllocationID,
          campaignLevelSwitch: this.state.campaignLevelSwitch,
          DI_QA_Switch: this.state.DI_QA_Switch,
        };
        this.updateLeadCount(result);
       
        this.setState({
          loading1: false,
          loaderLead: "",
          //leadDetailsData: mainArray,
          statusResults: statusResults,
          // result: result
        });
      })
      .catch((error) => {
        // Handle any errors that occur during the fetch request
        console.error("Error:", error);
      });
  }


// Nilesh-4757- This function fetching updateLead route
  updateLeadCount(result) {
    fetch("/lead/updateLead", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(result),
    })
      .then((res) => res.json())
      .then((leadDetailsData) => {
        //this.closeLoadingRef.current.click();//Sandeep-task3441-added loader
        this.setState({
          allDataArray: leadDetailsData,
          rejectlead: leadDetailsData[0].rejectedLead,
        });
        //shivani-3204(3287)-filtered the status of the leads.
        //kiran-4511-added if condition for internal review as in status filter dropdown it not show properly 
        //kiran-4514-declare count for showing proper status in dropdown
        let leadfilter = [];
        let count = 0;
        for (let i = 0; i < leadDetailsData.length; i++) {
          let status = leadDetailsData[i].status;
          for (let j = 0; j < status.length; j++) {
            if (leadDetailsData[i].status == "InternalReview") {
              count++;
            }
            else {
              let label = status[j];
              label = label.trim();
              label = label.split("|").join("").trim();
              leadfilter.push({ status: status[j], label });
            }
          }
        }
        if (count > 0) {
          let status = "InternalReview";
          let label = status;
          leadfilter.push({ status: status, label });
        }
        const result = Array.from(
          new Set(leadfilter.map((p) => p.label))
        ).map((label) => {
          return {
            label: label,
            status: leadfilter.find((p) => p.label === label)
              .status,
          };
        });
        //Nilesh-4726- For finding invalid domain status
        let statusResults = [];
        for (let i = 0; i < leadDetailsData.length; i++) {

          statusResults = leadDetailsData.filter((element) => {
            return element.status.includes("REJECT_EMAIL_INVALID_DOMAIN");

          })
        }

        //this.closeLoadingRef.current.click();//Sandeep-task3441-added loader
        this.setState({
          leadDetailsData,
          leadDetailsResponse: leadDetailsData,
          loading1: false,
          leadfilter: result,
          loaderLead: "",
          statusResults: statusResults
        });

        var status1;
        // alert("----+>" + JSON.stringify(leadDetailsData[0].marketingChannel))
        // alert("====+>" + typeof(leadDetailsData[0].marketingChannel))
        if (
          leadDetailsData[0].marketingChannel ==
          "TeleMarketing" ||
          leadDetailsData[0].marketingChannel ==
          "Email/Telemarketing"
        ) {
          for (var i = 0; i < leadDetailsData.length; i++) {
            if (
              leadDetailsData[i].status == "voiceLogPending"
            ) {
              status1 = true;
            }
          }
          //shivani-task 3183-passed parentCampID to the link for backbutton fun on voice Logs Upload page.
          if (status1 == true) {
            document.getElementById(
              "voiceLogLink"
            ).style.display = "block";
          }
        }
      });
  }

  render() {
    // const { isAuthenticated, user } = this.props.auth;
    const { user } = this.props.auth;  //Nilesh-4240-Removing console warnings

    const column1 = [
      {
        title: "Lead ID",
        dataIndex: "leadInfoID",
        key: "leadInfoID",
        width: "5%",
        align: "left", //Sandeep-task-3441-algin table data to left

        render: (text, record) =>
          record.status == "InternalReview" ? (
            <div style={{ color: "green" }}>{record.leadInfoID}</div>
          ) : (
            <div style={{ color: "red" }}>{record.leadInfoID}</div>
          ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: "15%",
        align: "left", //Sandeep-task-3441-algin table data to left
        render: (text, record) =>
          record.status == "InternalReview" ? (
            <div style={{ color: "green" }}>{record.email}</div>
          ) : (
            <div style={{ color: "red" }}>{record.email}</div>
          ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "30%",
        align: "left", //Sandeep-task-3441-algin table data to left
        render: (text, record) =>
          record.status == "InternalReview" ? (
            <div style={{ color: "green" }}>{record.status}</div>
          ) : (
            <div style={{ color: "red" }}>{record.status}</div>
          ),
      },
    ];
    // let locale = {  emptyText: " ", }; //Sandeep-task-3341  //Nilesh-4240-Removing console warnings
    
    const result = Array.from(
      new Set(this.state.leadDetailsData.map((p) => p.totalLead))
    ).map((totalLead) => {
      return {
        totalLead: totalLead,
        acceptedLead: this.state.leadDetailsData.find(
          (p) => p.totalLead === totalLead
        ).acceptedLead,
        rejectedLead: this.state.leadDetailsData.find(
          (p) => p.totalLead === totalLead
        ).rejectedLead,
        voiceLogLead: this.state.leadDetailsData.find(
          (p) => p.totalLead === totalLead
        ).voiceLogLead,
      };
    });

    return (
      <div>
        {/* Sandeep-task-3499-removed old loader */}
        {/* <LeadReviewLoader
					loadingRef={this.loadingRef}
					closeLoadingRef={this.closeLoadingRef}
					message="Please wait...Leads are validating.."
				/> */}
        {/* Sandeep-task-3341-added code for loader */}
        <PublisherNavigation />
        <br />
        <div
          class="container-fluid"
          style={{
            paddingBottom: "150px",
            paddingTop: "70px",
            overflowX: "hidden",
          }}
        >
          {/*sunita-task-3067-added code to hide the scrollbar. */}
          <div class="row">
            <div class="col-sm-12 col-md-1 col-lg-2">
              <a
                href="#/" //Nilesh-4240-Removing console warnings
                style={{ 
                  // color: "#fff", //Nilesh-4240-Removing console warnings 
                   color: "#056eb8" 
                }}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/publisherCampaignList", {
                    pID: user.id,
                  });
                }}
              >
                <FaArrowAltCircleLeft
                  size={32}
                  title="Back to publisherCampaignList"
                />
              </a>
            </div>
            <div class=" col-xs-12 col-sm-12 col-md-8 col-lg-7" align="center">
              {/* //shivani-3308-passed ID for DI label consistency */}
              <label id="labelDI">
                Leads Delivery
                <span id="labelDI">
                  (Campaign ID:{this.props.location.state.parentCampID})
                </span>
              </label>
            </div>

            <div
              class="col-sm-12 col-md-3 col-lg-3"
              align="right"
              style={{
                color: "#000080",
                fontSize: "18px",
                paddingLeft: "12px",
              }}
            >
              <button
                class="btn add-button"
                onClick={this.displayCampaignSpecification}
              >
                {" "}
                Campaign Specification
              </button>
              &nbsp;
            </div>
          </div>

          <div></div>

          <hr />

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="card-body">
              <MDBRow>
                <MDBCol lg="4">
                  <div class="row" style={{ marginLeft: "10px" }}>
                    <div>
                      <button
                        id="file"
                        class="btn11 info11"
                        onClick={this.handleChangeFileDeliverLead}
                        style={{ fontWeight: "500" }}
                      >
                        Upload Lead{" "}
                      </button>

                      {/* sandeep-task-3079-removing input element added onclick event with button to upload LeadFile */}
                      {/* <input type="file"  id="deliveryLeadfiles" name="deliveryLeadfiles" class="form-control"  onChange={this.handleChangeFileDeliverLead}
                                                /> */}
                    </div>

                    <span id="filediv" style={{ marginTop: "-8px" }}>
                      <div id="LeadList"></div>

                      <div style={{ color: "red", paddingTop: "23px" }}>
                        {this.state.errors.fileExt}
                      </div>
                      <div style={{ color: "red" }}>
                        {this.state.fileerrors.fileextensions}
                      </div>

                      <div style={{ color: "red" }}>
                        {this.state.formaterrors.format}
                      </div>

                      {/* Sandeep-task-3499-added code to  display error */}
                      {/* <div className="msg" style={{ color: 'red',marginLeft:"-111px" }} >{this.state.msg}</div> sunita-task-3507 - commented the code from this place and handled as per condition in below */}

                      {/* sunita-task-3507-added code for handling error msgs alignment as per condition */}
                      {this.state.fileerrors.fileextensions ? (
                        ""
                      ) : (
                        <div
                          className="msg"
                          style={{ color: "red", marginLeft: "-111px" }}
                        >
                          {this.state.msg}{" "}
                        </div>
                      )}


                      <div
                        id="pleaseClick"
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "13px",
                          top: "-500px",
                        }}
                      ></div>
                    </span>
                  </div>
                  <div
                    id="voiceLogLink"
                    style={{
                      color: "#056FB9",
                      fontWeight: "bold",
                      fontSize: "20px",
                      display: "none",
                    }}
                  >
                    <a href="#/"  //Nilesh-4240-Removing console warnings 
                    onClick={this.viewVoiceLogs}>
                      Click here to upload voice logs
                    </a>
                  </div>
                </MDBCol>
                <MDBCol lg="3">
                  <div style={{ color: "red" }}>{this.state.errors.file}</div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                {/*Priyanka-4611-4635-Displaying error messages of delivery template validations*/}
                <MDBCol lg="12">
                  {this.state.headerErrors.error ? (
                    <span>
                      <div style={{ color: "red", paddingTop: "1%", marginLeft: "1%" }}>
                        {this.state.headerErrors.error}
                      </div>
                      {this.state.headerErrors.rejectedHeaders ? (
                        <div style={{ color: "red", paddingTop: "0.5%", marginLeft: "1%" }}>
                          <b>{this.state.headerErrors.rejectedHeaders}</b>
                          {this.state.rejectedHeaders.join(", ")}
                        </div>
                      ) : ("")}
                      {this.state.headerErrors.missingHeaders ? (
                        <div style={{ color: "red", paddingTop: "0.5%", marginLeft: "1%" }}>
                          <b>{this.state.headerErrors.missingHeaders}</b>
                          {this.state.missingHeaders.join(", ")}
                        </div>
                      ) : ("")}
                      {/* Chaitanya-4719-Duplicate headers in delivery template due to CQ alias name */}
                      {this.state.headerErrors.duplicateHeaders ? (
                        <div style={{ color: "red", paddingTop: "0.5%", marginLeft: "1%" }}>
                          <b>{this.state.headerErrors.duplicateHeaders}</b>
                          {this.state.duplicateHeaders.join(", ")}
                        </div>
                      ) : ("")}
                    </span>
                  ) : ("")}
                </MDBCol>
              </MDBRow>

              <br />

              <div style={{ display: this.state.tableDisplay }}>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-8 col-lg-9">
                    {result.map((result) => {
                      if (result.totalLead != undefined) {
                        return (
                          <label
                            class="font-weight-bold"
                            style={{
                              color: "green",
                              fontSize: "13px",
                              marginLeft: "10px",
                            }}
                          >
                            Leads are uploaded successfully!&nbsp;
                          </label>
                        );
                      }
                    })}

                    {result.map((result) => {
                      if (result.totalLead != undefined) {
                        return (
                          <label
                            class="font-weight-bold"
                            style={{
                              color: "black",
                              fontSize: "13px",
                              marginLeft: "10px",
                            }}
                          >
                            ( Total:&nbsp;
                            <span
                              style={{ color: "black", fontWeight: "bold" }}
                            >
                              {result.totalLead}
                            </span>
                            <span style={{ color: "black" }}>
                              &nbsp;|&nbsp;
                            </span>
                          </label>
                        );
                      }
                    })}

                    {result.map((result) => {
                      if (result.acceptedLead != undefined) {
                        return (
                          <label
                            class="font-weight-bold"
                            style={{ fontSize: "13px", color: "green" }}
                          >
                            &nbsp; Internal Review:&nbsp;
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              {result.acceptedLead}

                            </span>
                            <span
                              style={{ color: "black", fontWeight: "bold" }}
                            >
                              <span style={{ color: "black" }}>
                                &nbsp;&nbsp;|&nbsp;
                              </span>
                            </span>
                          </label>
                        );
                      }
                    })}

                    {/*Akash-Bug-4238*/}

                    {this.state.rejectlead > 0 ? (
                      <label
                        class="font-weight-bold"
                        style={{ fontSize: "13px", color: "red" }}
                      >
                        &nbsp; Rejected:&nbsp;
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          {this.state.rejectlead}


                        </span>
                        <span style={{ color: "black", fontWeight: "bold" }}>
                          &nbsp;&nbsp;|&nbsp;
                        </span>
                      </label>
                    ) : (
                      ""
                    )}
                    {result.map((result) => {
                      if (result.voiceLogLead != undefined) {
                        return (
                          <label
                            class="font-weight-bold"
                            style={{
                              color: "rgb(5, 110, 184",
                              fontSize: "13px",
                            }}
                          >
                            &nbsp; VoiceLogPending:&nbsp;
                            <span
                              style={{
                                color: "rgb(5, 110, 184",
                                fontWeight: "bold",
                              }}
                            >
                              {result.voiceLogLead}
                            </span>
                            <span
                              style={{ color: "black", fontWeight: "bold" }}
                            ></span>
                            <span style={{ color: "black" }}>&nbsp;)</span>
                          </label>
                        );
                      }
                    })}
                  </div>
                  {/*Akash-Bug-4238*/}
                  {result.map((result) => {
                    if (result.totalLead != undefined) {
                      return (
                        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                          <span>
                            <a href="#/"  //Nilesh-4240-Removing console warnings
                            >
                              <img
                                alt="" //Nilesh-4240-Removing console warnings
                                src="download1.png"
                                onClick={this.downloadAuditReport}
                                id={this.state.campID}
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  color: "#17278E",
                                  float: "right",
                                  marginRight: "10px",
                                }}
                              ></img>
                            </a>
                            <span
                              id=""
                              style={{
                                color: "#40a9ff",
                                fontWeight: "500",
                                float: "right",
                                marginRight: "15px",
                                fontSize: "14px",
                              }}
                            >
                              Download Audit Report
                            </span>
                          </span>
                          &nbsp;&nbsp;
                        </div>
                      );
                    }
                  })}
                </div>

                <MDBRow>
                  <MDBCol lg="12" className="mb-12">
                    <div style={{ paddingTop: "10px" }}>
                      <div style={{ width: "100%" }}>
                        {result.map((result) => {
                          if (result.totalLead != undefined) {
                            return (
                              <div
                                class="row"
                                id="topdiv"
                                style={{
                                  width: "99.5%",
                                  marginLeft: "3px",
                                  backgroundColor: "white",
                                }}
                              >


                                {/* Nilesh-4726- showing continue button and error massege */}
                                {this.state.statusResults.length > 0 ?
                                  (<div class="col-xs-11 col-sm-11 col-md-6 col-lg-9 to1">
                                    <Checkbox
                                      style={{
                                        fontSize: 15,
                                        textAlign: "left",
                                        marginBottom: "-60px",
                                        marginTop: "5px"
                                      }}
                                      checked={this.state.domainChecked}
                                      onChange={this.onChangeDoaminCheckbox}
                                    >
                                      <span style={{ color: "red" }}>
                                        Click on 'Continue' button to disable Email Domain Validation for those leads showing error 'REJECT_EMAIL_INVALID DOMAIN'.
                                      </span>
                                    </Checkbox>
                                    <div class="col-xs-11 col-sm-11 col-md-6 col-lg-2">
                                      <button
                                        type="submit"
                                        // data-dismiss="modal"
                                        className={this.state.btnDisable}
                                        disabled={
                                          this.state.isDomainCheckedboxVisible
                                            ? this.state.domainChecked
                                              ? false
                                              : true
                                            : false
                                        }
                                        style={{
                                          backgroundColor: "#0000b3",
                                          color: "white",
                                          borderRadius: "3px"
                                        }}
                                        onClick={this.offDomainValidation}>
                                        <b>Continue...</b>
                                      </button>
                                    </div>
                                  </div>)
                                  :
                                  ""
                                }


                                <div class="col-xs-11 col-sm-11 col-md-6  offset-lg-2 col-lg-7 to1">
                                  <span
                                    id="statusDiv"
                                    style={{
                                      color: "black",
                                      float: "right",
                                      marginTop: "15px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Status:&nbsp;&nbsp;&nbsp;&nbsp;
                                    <select
                                      id="selfil"
                                      style={{
                                        height: "29px",
                                        width: "280px",
                                        backgroundColor: "#F5F5F5",
                                        border: "1px solid #DCDCDC",
                                      }}
                                      value={this.state.selectedLead}
                                      required
                                      onChange={this.selectLead}
                                    >
                                      <option
                                        value="All"
                                        selected
                                        style={{ fontWeight: "bold" }}
                                      >
                                        All
                                      </option>
                                      {this.state.leadfilter.map(
                                        (leadfilter) => {
                                          return (
                                            <option
                                              value={leadfilter.label}
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {leadfilter.label}
                                            </option>
                                          );

                                        }
                                      )}
                                    </select>
                                  </span>
                                </div>
                                <div
                                  class="col-xs-11 col-sm-11 offset-md-6 col-md-6 offset-lg-9 col-lg-3 "
                                  id="searchAdj"
                                  style={{
                                    height: "50px",
                                    marginTop: "-30px",
                                    marginRight: "20px",
                                  }}
                                >
                                  <span style={{ marginTop: "15px" }}>
                                    <input
                                      className="search"
                                      id="searchAdj1"
                                      type="text"
                                      name="search"
                                      placeholder="Search"
                                      style={{
                                        backgroundImage: "url(searchIcon.png)",
                                        backgroundPosition: "15px 6px",
                                        backgroundSize: "15px 15px",
                                        backgroundRepeat: "no-repeat",
                                        width: "215px",
                                        height: "30px",
                                        fontSize: "10px",
                                        border: "1px solid lightGray",
                                        padding: "12px 20px 12px 34px",
                                      }}
                                      onChange={this.handleChangeSearch}
                                      onPressEnter={this.handleChangeSearch}
                                    ></input>
                                  </span>
                                </div>
                              </div>
                            );
                          }
                        })}

                        <div align="center" id={this.state.loaderLead}></div>

                        <Table
                          {...this.state.tableState}
                          //id="myTable"
                          bordered
                          dataSource={
                            this.state.tableSearchText === ""  //Nilesh-4240-Removing console warnings
                              ? this.state.leadDetailsData
                              : this.state.columnSearch
                          }
                          columns={column1}
                          className={tableCSS}
                          //locale={this.state.loading1?locale:"No Data"}//Sandeep task-3341
                          size="small"
                          scroll={{ y: 2000, x: 500 }}
                          pagination={{ pageSize: this.state.pageSize1 }}
                        ></Table>
                        {/* kiran-4514-4446-adjusted scroll size for responsiveness */}
                        {this.state.loading1 === false ? (
                          <div
                            class="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6"
                            style={{ marginTop: "-53px" }}
                          >
                            Records per page:&nbsp;
                            <select
                              defaultValue={this.state.pageSize1}
                              onChange={this.handleChangePageSize1}
                              id="pacing11"
                              class="input-small"
                              className="form-control"
                              name="pacing"
                              style={{
                                width: "80px",
                                height: "30px",
                                display: "initial",
                              }}
                            >
                              <option value="100" selected>
                                100
                              </option>
                              <option value="500">500</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* Sandeep-task-3119-added code to  display error */}
                    </div>
                  </MDBCol>
                </MDBRow>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
PublisherDeliverLead.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
  withRouter(PublisherDeliverLead)
);
