/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @author : Karan Jagtap
 * @description : Step 2 : Supporting Document and Delivery Format
 */
import React, { Component } from "react";
import { Card, Checkbox, Steps, Icon, Tooltip, Switch, Input } from "antd";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import * as $ from "jquery";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";

import Navigation from "../layouts/navPage";
import "./supportingDocumentCreate.css";
// import Dragger from "antd/lib/upload/Dragger"; // commented when dragger removed
import AgencyCustomizeMappingModal from "./AgencyCustomizeMappingModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //snehal-task-3766-specification VAPT api call
const Swal = require("sweetalert2");
//use for fetch url value
var result = [];
var abmresult = [];
// var errorFile=false;
var allassets = true;
var allabms = true;
var allassetLength = false;
var allabmLength = false;
var allLinkLength = false;
var linkflag = false;
var assetflag = false;
var linkflagBack = false;
var assetflagBack = false;
var assetLength;
var assetLinkLength;
var leadperassetCount = 0;
var leadperassetlinkCount = 0;
//var names=[];
var abmnames = [];
var abmCounts = [];
var newabmfilenames = [];
var newabmFileCount = [];
var suppressionnames = [];
var newsuppressionfilenames = [];
var execlusionnames = [];
var newexclusionfilenames = [];
var othernames = [];
var newotherfilenames = [];
var assetnames = [];
var newassetfilenames = [];
var inputType = [];
var a = [];
var key = 0;
var key1 = 0;
let chkABMExist = [],
  duplicateABMFile = []; // this is used for check duplicate file name
let chkExclusionExist = [],
  duplicateExclusionFile = [];
let chkSuppressionExist = [],
  duplicateSuppressionFile = [];
let chkOtherExist = [],
  duplicateOtherFile = [];
var count = 0;
var linkSuppCount = 0;
var oldABMFileArray = [];
var assetValidationArray = []; // store the rejected asset name in this array which come from Backend for validte true or false
var exclusionValidationArray = []; // store the rejected Exclusion name in this array which come from Backend for validte true or false
var otherValidationArray = [];
var successOtherCount = [],
  rejectOtherCount = [];
var suppValidationArray = [];
var successAssetCount = [];
var rejectAssetCount = [];
var successExclusionCount = [];
var rejectExclusionCount = [];
var successAbmCount = [];
var rejectAbmCount = [];
var successSuppressionCount = [];
var rejectSuppressionCount = [];
var rfpChange = 0;
var abmCountArray = [];
var backabmCount = [];
var newbackabmfileCount = [];
var asset1 = false;
var asset2 = false;

// start - added by karan
const StepTabs = [
  {
    title: "Supporting Documents",
    icon: (
      <img className="step-icon" src="supporting_documents_step2.png"></img>
    ),
  },
  {
    title: "DI QA & Delivery Format",
    icon: <img className="step-icon" src="di_qa_delivery_format.png"></img>,
  },
];
// end - added by karan

class supportingDocumentCreate extends Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      count: "",
      expand: false,
      campID: "",
      leadPerAssetChk: "No",
      multiTouchChk: "No",
      multiTouchChkBack: "No",
      checkedValue: false,
      errors: {},
      linknameerror: {},
      abmerror: {},
      backerror: {},
      backerrorasset: {},
      backabmerror: {},
      exclusionerror: {},
      fileerrors: {},
      suppressionerror: {},
      leaderrors: {},
      leadAsseterrors: {},
      linkleadserrors: {},
      asseterrors: {},
      abmerrors: {},
      sdEditDetail: [],
      abmBackArray: [],
      sdEditDetail1: [],
      sdEditDetailABM: [],
      sdEditDetailSuppression: [],
      sdEditDetailExclusion: [],
      sdEditDetailAsset: [],
      sdEditDetailOther: [],
      // dynamicAssetName:[],
      deleteAssetLink: [],
      // sdBudget:[],
      inputType: [],
      inputType1: [],
      result: [],
      assetsFile: [],
      assetFileNameArray: [],
      assetLeadArray: [],
      assetTotalLead: 0,
      assetLinkArray: [{ linkAssetFileName: "", assetLink: "" }],
      assetLinkLeadArray: [],
      dynamicAssetName: [],
      dynamicAssetLead: [],
      result: [],
      dynamicSuppDocName: [],
      suppressionLinkArray: [],
      suppressionLink: [],
      selectedFile: "",
      files: {},
      alertDisplay: "none",
      spanSpaceDisplay: "none",
      requiredLPApproval: "No",
      currency: "USD",
      lpTimeline: "12",
      displayTable: "none",
      displayTableabm: "none",
      displayTableexclusion: "none",
      displayTablesuppression: "none",
      displayTableother: "none",
      suppstatus: "false",
      backDynamicInput: [],
      dynamicSuppressionInput: [
        { count: 0, suppressionLink: "", suppressionFileName: "" },
      ],
      dynamicInput: [],
      backDynamicSuppressionInput: [],
      editDeliveryFormat: [],
      companyName: "Yes",
      linkedInCompanyName: "Yes",
      campaignID: "Yes",
      campaignDetail: [],
      assetName: "Yes",
      assetName_touch1: "No",
      assetTimestamp_touch1: "1970-01-01 00:00:01",
      assetName_touch2: "No",
      assetTimestamp_touch2: "1970-01-01 00:00:01",
      assetName_touch3: "No",
      assetTimestamp_touch3: "1970-01-01 00:00:01",
      pID: "Yes",
      assetId: "No",
      leadInteractionDate: "Yes",
      firstName: "Yes",
      lastName: "Yes",
      jobTitleDelivery: "Yes",
      linkedInJobTitle: "Yes",
      jobTitleValidation: "",
      jobLevelDelivery: "",
      jobFunctionDelivery: "",
      assetName: "Yes",
      companyRevenue: "",
      address: "Yes",
      email: "Yes",
      workPhone: "Yes",
      street: "No",
      city: "Yes",
      state: "Yes",
      countryDelivery: "Yes",
      zipCode: "Yes",
      companyEmployeeSize: "",
      companyRevenue: "No",
      industry: "",
      ip: "No",
      optInDate: "Yes",
      optInTime: "Yes",
      optInTimeZone: "Yes",
      linkedIn: "No",
      extra1: "No",
      extra2: "No",
      extra3: "No",
      extra4: "No",
      extra5: "No",
      extra6: "No",
      extra7: "No",
      extra8: "No",
      extra9: "No",
      extra10: "No",
      extra11: "No",
      extra12: "No",
      extra13: "No",
      extra14: "No",
      extra15: "No",
      extra16: "No",
      extra17: "No",
      extra18: "No",
      extra19: "No",
      extra20: "No", //Sandeep-task-3158-added code for extra 15 fields

      extra21: "No", //Aman 5085 -init the other extra filed 21 to 30
      extra22: "No",
      extra23: "No",
      extra24: "No",
      extra25: "No",
      extra26: "No",
      extra27: "No",
      extra28: "No",
      extra29: "No",
      extra30: "No",
      reAllocationID: "No",
      campaignName: "No",
      domain: "No",
      comments: "No",
      alternatePhoneNo: "No",
      totalLeadAllocation: "",
      requiredLeadPerAsset: "",
      campaignType: "",
      assetWiseLead: "",
      channel: "",
      agencyCustomizeMapping: [],
      customizeMapping: "",
      customizeMappingFormat: "Format 1",
      newCustomizeMappingFormat:"", //Nilesh-5002 -Create a veriable
      rfpCampaignID: "",
      abmFileCount: [],
      switchValue: "ON",
      assetJson: [], // store all file which get from response
      abmJson: [],
      suppressionJson: [],
      exclusionJson: [],
      otherJson: [],
      isAbmFileValid: false,
      mappingFormat: [],
      excludeText: "", //snehal-task3620-Add exclude domains
      excludeError: {}, //snehal-task3620-Add exclude domains

      // start - added by karan
      // this is to check if the user comes from incomplete tab and that campaign has already saved its supporting document details but not saved the delivery format step.
      isIncompleteSuppDocStepAlreadyComplete: false,

      // to handle the step navigation
      currentStep: 0,
      displayStep1: "flex",
      displayStep2: "none",
      canProceedToStep2: false,

      // this is for maintaining the UI for Asset files table
      assetFilesUploadedSuccessCount: 0,
      assetFilesUploadedFailedCount: 0,
      assetFilesSelectedDocuments: [],
      // assetFileListUI: [], // commented when dragger removed

      // this is for maintaining the UI for ABM files
      abmFilesSelectedDocuments: [],
      backABMArrayLengthForUI: 0,
      abmErrorValidHeader: false,

      // this is for maintaining the UI for Suppression files
      suppressionFilesSelectedDocuments: [],
      backSuppressionArrayLengthForUI: 0,

      // this is for maintaining the UI for Exclusion files
      exclusionFilesSelectedDocuments: [],
      backExclusionArrayLengthForUI: 0,

      // this is for maintaining the UI for Other files
      otherFilesSelectedDocuments: [],
      backOtherArrayLengthForUI: 0,

      // 3126 - karan - to fire componentWillMount() to update changed asset file names
      step1SuppDocSaved: false,

      campaignStatus: "Active", // added by somnath sir
      agencyCustomizeMappingModal: [], //karan-task-3059-customize mapping alias state variable
      // end - added by karan
      // isChecked:true,
      cityStateValidationCheck: "OFF", //Aman-task-4765 added (By default it is OFF)City-state validation checkbox
    };
    this.dynamicABMFile = this.dynamicABMFile.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    this.handleChangeMultipleFile = this.handleChangeMultipleFile.bind(this);
    //   this.handleselectedFile = this.handleselectedFile.bind(this);
    this.handleChangeMultipleFileOfOther =
      this.handleChangeMultipleFileOfOther.bind(this);
    this.handleChangeMultipleFileOfABM =
      this.handleChangeMultipleFileOfABM.bind(this);
    this.handleChangeMultipleFileOfExclusion =
      this.handleChangeMultipleFileOfExclusion.bind(this);
    this.handleChangeMultipleFileOfSuppression =
      this.handleChangeMultipleFileOfSuppression.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.leadPerAssethandleChange = this.leadPerAssethandleChange.bind(this);
    this.leadPerAssethandleChangeBack =
      this.leadPerAssethandleChangeBack.bind(this);
    this.multiTouchHandleChange = this.multiTouchHandleChange.bind(this);
    this.multiTouchHandleChangeBack =
      this.multiTouchHandleChangeBack.bind(this);
    this.switchHandlechange = this.switchHandlechange.bind(this);
    this.handleFormatChange = this.handleFormatChange.bind(this);
    this.customizeMappingFormatHandleChange =
      this.customizeMappingFormatHandleChange.bind(this);
    this.customizeMappingCancleHandleChange =
      this.customizeMappingCancleHandleChange.bind(this);
    this.setcustomizeMapping = this.setcustomizeMapping.bind(this); // raunak-2951-binding this function to access setstate for customize mapping
    this.excludeTextChange = this.excludeTextChange.bind(this); //snehal-task3620-Add exclude domains
    // this.getFields=this.getFields.bind(this);
    //this.topScroll=this.topScroll.bind(this);
    this.handleCityStateValidationChecked =
      this.handleCityStateValidationChecked.bind(this); //task-4765 added City-state validation checkbox
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the Back Button and login
   * @return Description return All details of authentication
   */
  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      let self = this;
      var parsed = this.props.location.state;
      if (parsed !== undefined) {
        const { isAuthenticated, user } = this.props.auth;
        var userID = user.id;
        var campID,
          rfpCampaignID,
          status,
          step2 = "na";
        if (parsed.hasOwnProperty("campID")) {
          campID = parsed.campID;
        }
        if (parsed.hasOwnProperty("rfpCampaignID")) {
          rfpCampaignID = parsed.rfpCampaignID;
        }
        if (parsed.hasOwnProperty("status")) {
          status = parsed.status;
        }
        if (parsed.hasOwnProperty("step2")) {
          step2 = "step2";
        }
        if (parsed.hasOwnProperty("rfpCampaign")) {
          step2 = "rfpCampaign";
        }
        //self.setState({rfpCampaignID:rfpCampaignID})
        this.setState({ rfpCampaignID: rfpCampaignID, campID }, function () {});

        //Sonali-4072-remove agency id from call

        fetch("/diQARole/getSwitchValue")
          .then((res) => res.json())
          .then((switchDetails) => {
            if (switchDetails.length > 0) {
              if (switchDetails[0].DI_QA_Switch == "") {
                switchDetails[0].DI_QA_Switch = "OF";
              }
              this.setState({ switchValue: switchDetails[0].DI_QA_Switch });
            }
          });

        if (step2 === "rfpCampaign") {
          step2 = "step2";
        }
        if (
          step2 === undefined ||
          step2 === null ||
          step2 === "" ||
          step2 === "undefined" ||
          (step2 !== "step2" && status !== "SupportingDocument")
        ) {
          fetch("campaign/getMappingFormat?campID=" + campID + "")
            .then((res) => res.json())
            .then((mappingFormat) => {
              this.setState({ mappingFormat: mappingFormat });
            });

          fetch("campaign/getAgencyCustomizeMapping?campID=" + campID + "")
            .then((res) => res.json())
            .then((agencyCustomizeMapping) => {
              this.setState({
                agencyCustomizeMapping: agencyCustomizeMapping,
                customizeMapping: "No",
              });
            });
        } else {
          let self = this;
          // this is for coming from incomplete tab - karan
          let statusCheck = `status=${status}`;
          // Scenario 1 : /step2supportingdocument?campID=###&rfpCampaign&rfpCampaignID=###&status=Step1&parentcampID=###&reallocationID=###
          // Scenario 2 : /step2supportingdocument?campID=###&parentcampID=###&reallocationID=###&status=Step1

          fetch("/campaign/supportingDocumentForBack?campID=" + campID + "")
            .then((res) => res.json())
            .then((sdEditDetail) => {
              let campaignStatus;
              if (sdEditDetail.length > 0) {
                campaignStatus = sdEditDetail[0].campaignStatus;
                if (statusCheck === "status=Step1") {
                  this.setState({
                    isIncompleteSuppDocStepAlreadyComplete: true,
                  });
                }
              }
              this.setState({
                sdEditDetail: sdEditDetail,
                campaignStatus,
              });

              if (
                this.state.sdEditDetail != undefined ||
                this.state.sdEditDetail != "" ||
                this.state.sdEditDetail != null
              ) {
                this.setState({ canProceedToStep2: true });
                let assetFilesArrayUI = [];
                let abmFilesArrayUI = [];
                let suppressionFilesArrayUI = [];
                let exclusionFilesArrayUI = [];
                let otherFilesArrayUI = [];
                let inputType = [...this.state.inputType];
                let successAssetCount = 0;
                for (let i = 0; i < this.state.sdEditDetail.length; i++) {
                  if (this.state.sdEditDetail[i].typeOfSuppDoc == "Asset") {
                    successAssetCount++;
                    key++;
                    inputType = [
                      ...inputType.filter(
                        (item) =>
                          item.suppDocName !==
                          this.state.sdEditDetail[i].suppDocName
                      ),
                      {
                        suppDocName: this.state.sdEditDetail[i].suppDocName,
                        leadPerAsset: this.state.sdEditDetail[i].leadPerAsset,
                        key: key,
                        leadPercentage:
                          this.state.sdEditDetail[i].leadPercentage,
                        multiTouch: this.state.sdEditDetail[i].multiTouch,
                      },
                    ];
                    // commented by karan - 3125 - when clicked back double records were seen
                    // this.state.inputType.push({
                    // 	suppDocName: this.state.sdEditDetail[i].suppDocName,
                    // 	leadPerAsset: this.state.sdEditDetail[i].leadPerAsset,
                    // 	key: key,
                    // 	leadPercentage: this.state.sdEditDetail[i].leadPercentage,
                    // 	multiTouch: this.state.sdEditDetail[i].multiTouch,
                    // });
                    assetFilesArrayUI = [
                      ...assetFilesArrayUI.filter(
                        (item) =>
                          item.suppDocName !==
                          this.state.sdEditDetail[i].suppDocName
                      ),
                      {
                        key: key,
                        success: true,
                        suppDocName: this.state.sdEditDetail[i].suppDocName,
                        leadPerAsset: this.state.sdEditDetail[i].leadPerAsset,
                        leadPercentage:
                          this.state.sdEditDetail[i].leadPercentage,
                        multiTouch: this.state.sdEditDetail[i].multiTouch,
                        supportDocID: this.state.sdEditDetail[i].supportDocID,
                        typeOfSuppDoc: this.state.sdEditDetail[i].typeOfSuppDoc,
                      },
                    ];
                  } else if (
                    this.state.sdEditDetail[i].typeOfSuppDoc == "ABM"
                  ) {
                    abmFilesArrayUI = [
                      ...abmFilesArrayUI,
                      {
                        success: true,
                        suppDocName: this.state.sdEditDetail[i].suppDocName,
                        dataFileID: this.state.sdEditDetail[i].dataFileID,
                        count: this.state.sdEditDetail[i].fileRecordCount,
                        typeOfSuppDoc: this.state.sdEditDetail[i].typeOfSuppDoc,
                        supportDocID: this.state.sdEditDetail[i].supportDocID,
                      },
                    ];
                  } else if (
                    this.state.sdEditDetail[i].typeOfSuppDoc == "Exclusion"
                  ) {
                    exclusionFilesArrayUI = [
                      ...exclusionFilesArrayUI,
                      {
                        success: true,
                        suppDocName: this.state.sdEditDetail[i].suppDocName,
                        typeOfSuppDoc: this.state.sdEditDetail[i].typeOfSuppDoc,
                        supportDocID: this.state.sdEditDetail[i].supportDocID,
                      },
                    ];
                  } else if (
                    this.state.sdEditDetail[i].typeOfSuppDoc == "Suppression"
                  ) {
                    suppressionFilesArrayUI = [
                      ...suppressionFilesArrayUI,
                      {
                        success: true,
                        suppDocName: this.state.sdEditDetail[i].suppDocName,
                        typeOfSuppDoc: this.state.sdEditDetail[i].typeOfSuppDoc,
                        supportDocID: this.state.sdEditDetail[i].supportDocID,
                      },
                    ];
                  } else if (
                    this.state.sdEditDetail[i].typeOfSuppDoc == "Other"
                  ) {
                    otherFilesArrayUI = [
                      ...otherFilesArrayUI,
                      {
                        success: true,
                        suppDocName: this.state.sdEditDetail[i].suppDocName,
                        typeOfSuppDoc: this.state.sdEditDetail[i].typeOfSuppDoc,
                        supportDocID: this.state.sdEditDetail[i].supportDocID,
                      },
                    ];
                  }
                }
                this.setState({
                  inputType,
                  assetFilesSelectedDocuments: assetFilesArrayUI,
                  abmFilesSelectedDocuments: abmFilesArrayUI,
                  suppressionFilesSelectedDocuments: suppressionFilesArrayUI,
                  exclusionFilesSelectedDocuments: exclusionFilesArrayUI,
                  otherFilesSelectedDocuments: otherFilesArrayUI,
                  assetFilesUploadedSuccessCount: successAssetCount,
                  assetFilesUploadedFailedCount: 0,
                });
                if (rfpCampaignID) {
                } else {
                  //Sandeep-task-3617-STC---added code as per snehal's requirement for excludedDoamain
                  // this.setState({excludeText: sdEditDetail[0].excludedDomain})
                  if (sdEditDetail.length > 0) {
                    this.setState({
                      excludeText: sdEditDetail[0].excludedDomain,
                    });
                  } else {
                  }
                }
                //snehal-task3620-Add exclude domains(for excludeDomain)(if condition for rfp campaign)
                if (this.state.inputType.length > 0) {
                  this.setState({ displayTable: "block" });
                }
              }
            });

          fetch("campaign/getAssetLink?campID=" + campID)
            .then((res) => res.json())
            .then((backDynamicInput) => {
              self.setState({ backDynamicInput: backDynamicInput });
              if (backDynamicInput.length > 0) {
                self.setState({ canProceedToStep2: true });
                if (
                  statusCheck === "status=Step1" ||
                  status === "SupportingDocument"
                ) {
                  self.setState({
                    isIncompleteSuppDocStepAlreadyComplete: true,
                    dynamicInput: backDynamicInput,
                  });
                }
              }
            })
            .catch(function (err) {
              console.log(err);
            });

          fetch("campaign/getSuppressionLink?campID=" + campID)
            .then((res) => res.json())
            .then((suppressionLink) => {
              self.setState({
                suppressionLink: suppressionLink,
                displayTablesuppression: "block",
              });
            })
            .catch(function (err) {
              console.log(err);
            });

          fetch("campaign/supportingDocumentForEditABM?campID=" + campID + "")
            .then((res) => res.json())
            .then((sdEditDetailABM) => {
              self.setState({
                sdEditDetailABM: sdEditDetailABM,
                displayTableabm: "block",
              });
              for (let i = 0; i < sdEditDetailABM.length; i++) {
                successAbmCount.push(sdEditDetailABM[i].suppDocName);
                chkABMExist.push(sdEditDetailABM[i].suppDocName);
              }
            });

          fetch("campaign/supportingDocumentForEditAsset?campID=" + campID + "")
            .then((res) => res.json())
            .then((sdEditDetailAsset) => {
              self.setState({ sdEditDetailAsset: sdEditDetailAsset });
              for (let i = 0; i < sdEditDetailAsset.length; i++) {
                successAssetCount.push(
                  unescape(sdEditDetailAsset[i].suppDocName)
                );
                this.state.assetFileNameArray.push(
                  unescape(sdEditDetailAsset[i].suppDocName)
                );
              }
            });
          fetch("campaign/supportingDocumentForEditOther?campID=" + campID + "")
            .then((res) => res.json())
            .then((sdEditDetailOther) => {
              self.setState({
                sdEditDetailOther: sdEditDetailOther,
                displayTableother: "block",
              });
              for (let i = 0; i < sdEditDetailOther.length; i++) {
                successOtherCount.push(sdEditDetailOther[i].suppDocName);
                chkOtherExist.push(sdEditDetailOther[i].suppDocName);
              }
            });

          fetch(
            "campaign/supportingDocumentForEditSuppression?campID=" +
              campID +
              ""
          )
            .then((res) => res.json())
            .then((sdEditDetailSuppression) => {
              self.setState({
                sdEditDetailSuppression: sdEditDetailSuppression,
                displayTablesuppression: "block",
              });
              for (let i = 0; i < sdEditDetailSuppression.length; i++) {
                successSuppressionCount.push(
                  sdEditDetailSuppression[i].suppDocName
                );
                chkSuppressionExist.push(
                  sdEditDetailSuppression[i].suppDocName
                );
              }
            });

          fetch(
            "campaign/supportingDocumentForEditExclusion?campID=" + campID + ""
          )
            .then((res) => res.json())
            .then((sdEditDetailExclusion) => {
              self.setState({
                sdEditDetailExclusion: sdEditDetailExclusion,
                displayTableexclusion: "block",
              });
              for (var i = 0; i < sdEditDetailExclusion.length; i++) {
                successExclusionCount.push(
                  sdEditDetailExclusion[i].suppDocName
                );
                chkExclusionExist.push(sdEditDetailExclusion[i].suppDocName);
              }
            });

          fetch("campaign/getAgencyCustomizeMapping?campID=" + campID + "")
            .then((res) => res.json())
            .then((agencyCustomizeMapping) => {
              this.setState({
                agencyCustomizeMapping: agencyCustomizeMapping,
                customizeMapping: "No",
              });
            });
          //karan-task-3059-not required anymore because this is handled in customizeMappingFormatHandleChange()
          // if (this.state.agencyCustomizeMapping.length > 0) {
          // 	let customMapping = this.state.agencyCustomizeMapping.filter(
          // 		(a) => a.customizeMappingFormat == this.state.customizeMappingFormat
          // 	);
          // 	this.setState({ agencyCustomizeMapping: customMapping });
          // }
          fetch("campaign/deliveryFormatEdit?campID=" + campID + "")
            .then((res) => res.json())
            .then((editDeliveryFormat) => {
              me.setState({
                editDeliveryFormat: editDeliveryFormat, //[{'city':true}]
              });
            });
        }

        this.setState({ campID: campID });
        let me = this;
        if (!me.props.auth.isAuthenticated) {
          me.props.history.push("/userLogin");
        }
      }
    }

    fetch("campaign/CampaignDetails?campID=" + campID + "")
      .then((res) => res.json())
      .then((detailsOfCampaign) => {
        let multiTouchVal = detailsOfCampaign[0].multiTouch;
        if (
          multiTouchVal === null ||
          multiTouchVal === undefined ||
          multiTouchVal === "null" ||
          multiTouchVal === "undefined" ||
          multiTouchVal === ""
        ) {
          multiTouchVal = "No";
        }
        this.setState({
          detailsOfCampaign: detailsOfCampaign,
          totalLeadAllocation: detailsOfCampaign[0].leadAllocation,
          requiredLeadPerAsset: detailsOfCampaign[0].requiredLeadPerAsset,
          campaignType: detailsOfCampaign[0].ABM,
          multiTouchChkBack: multiTouchVal,
          multiTouchChk: multiTouchVal,
        });

        var leadPerAsset = document.getElementById("leadPerAssetBack");

        var defaultChecked = false;

        var leadPerAssetChk = "";
        if (
          this.state.requiredLeadPerAsset === "Yes" ||
          detailsOfCampaign[0].requiredLeadPerAsset === "Yes" // added by karan - this is because before setting the state above this if is fired
        ) {
          defaultChecked = true;
          leadPerAssetChk = "Yes";
        } else {
          defaultChecked = false;
          leadPerAssetChk = "No";
        }

        this.setState({
          leadPerAssetChk: leadPerAssetChk,
          checkedValue: defaultChecked,
        });
        // added by karan
        // from campaignNew.js, it may return 5 types of values,
        // anything from below values could be returned to indicate that the db has no value present in it:
        // 1. ""
        // 2. null
        // 3. "null"
        // 4. undefined
        // 5. "undefined"
        // below there are 2 variables to check with hence, the conditional combination goes as follows:
        // point 1 with point 2,3,4,5 and point 2 with point 1,3,4,5 and so on.. = 4*2variables*4=32 conditions
        // this cannot be done in reverse order because value of jobLevel can be 1 of 6 values and
        // value of customJobLevel is custom hence it cannot be predicted.
        // Therefore needs to use this approach
        // This same logic goes for the following checkboxes:
        // 1. jobLevel
        // 2. jobFunction
        // 3. companyRevenue
        // 4. employeeSize
        // 5. industry
        if (
          (detailsOfCampaign[0].jobLevel === "" &&
            detailsOfCampaign[0].customJobLevel === "") ||
          (detailsOfCampaign[0].jobLevel === null &&
            detailsOfCampaign[0].customJobLevel === null) ||
          (detailsOfCampaign[0].jobLevel === undefined &&
            detailsOfCampaign[0].customJobLevel === undefined) ||
          (detailsOfCampaign[0].jobLevel === "null" &&
            detailsOfCampaign[0].customJobLevel === null) ||
          (detailsOfCampaign[0].jobLevel === "undefined" &&
            detailsOfCampaign[0].customJobLevel === undefined) ||
          (detailsOfCampaign[0].jobLevel === null &&
            detailsOfCampaign[0].customJobLevel === "null") ||
          (detailsOfCampaign[0].jobLevel === undefined &&
            detailsOfCampaign[0].customJobLevel === "undefined") ||
          (detailsOfCampaign[0].jobLevel === "null" &&
            detailsOfCampaign[0].customJobLevel === "null") ||
          (detailsOfCampaign[0].jobLevel === "undefined" &&
            detailsOfCampaign[0].customJobLevel === "undefined") ||
          (detailsOfCampaign[0].jobLevel === "" &&
            detailsOfCampaign[0].customJobLevel === null) ||
          (detailsOfCampaign[0].jobLevel === "" &&
            detailsOfCampaign[0].customJobLevel === undefined) ||
          (detailsOfCampaign[0].jobLevel === "" &&
            detailsOfCampaign[0].customJobLevel === "null") ||
          (detailsOfCampaign[0].jobLevel === "" &&
            detailsOfCampaign[0].customJobLevel === "undefined") ||
          (detailsOfCampaign[0].customJobLevel === "" &&
            detailsOfCampaign[0].jobLevel === null) ||
          (detailsOfCampaign[0].customJobLevel === "" &&
            detailsOfCampaign[0].jobLevel === undefined) ||
          (detailsOfCampaign[0].customJobLevel === "" &&
            detailsOfCampaign[0].jobLevel === "null") ||
          (detailsOfCampaign[0].customJobLevel === "" &&
            detailsOfCampaign[0].jobLevel === "undefined")
        ) {
          this.setState({
            detailsOfCampaign: detailsOfCampaign,
            jobLevelDelivery: "No",
          });
        } else {
          this.setState({
            jobLevelDelivery: "Yes",
          });
        }

        if (detailsOfCampaign[0].marketingChannel === "Email/Telemarketing") {
          this.setState({
            detailsOfCampaign: detailsOfCampaign,
            channel: "Yes",
          });
        } else {
          this.setState({
            channel: "No",
          });
        }

        if (detailsOfCampaign[0].jobTitleValidation === "Yes") {
          this.setState({
            jobTitleValidation: "Yes",
          });
        } else {
          this.setState({
            jobTitleValidation: "No",
          });
        }

        if (
          (detailsOfCampaign[0].jobFunction === "" &&
            detailsOfCampaign[0].customJobFunction === "") ||
          (detailsOfCampaign[0].jobFunction === null &&
            detailsOfCampaign[0].customJobFunction === null) ||
          (detailsOfCampaign[0].jobFunction === undefined &&
            detailsOfCampaign[0].customJobFunction === undefined) ||
          (detailsOfCampaign[0].jobFunction === "null" &&
            detailsOfCampaign[0].customJobFunction === null) ||
          (detailsOfCampaign[0].jobFunction === "undefined" &&
            detailsOfCampaign[0].customJobFunction === undefined) ||
          (detailsOfCampaign[0].jobFunction === null &&
            detailsOfCampaign[0].customJobFunction === "null") ||
          (detailsOfCampaign[0].jobFunction === undefined &&
            detailsOfCampaign[0].customJobFunction === "undefined") ||
          (detailsOfCampaign[0].jobFunction === "null" &&
            detailsOfCampaign[0].customJobFunction === "null") ||
          (detailsOfCampaign[0].jobFunction === "undefined" &&
            detailsOfCampaign[0].customJobFunction === "undefined") ||
          (detailsOfCampaign[0].jobFunction === "" &&
            detailsOfCampaign[0].customJobFunction === null) ||
          (detailsOfCampaign[0].jobFunction === "" &&
            detailsOfCampaign[0].customJobFunction === undefined) ||
          (detailsOfCampaign[0].jobFunction === "" &&
            detailsOfCampaign[0].customJobFunction === "null") ||
          (detailsOfCampaign[0].jobFunction === "" &&
            detailsOfCampaign[0].customJobFunction === "undefined") ||
          (detailsOfCampaign[0].customJobFunction === "" &&
            detailsOfCampaign[0].jobFunction === null) ||
          (detailsOfCampaign[0].customJobFunction === "" &&
            detailsOfCampaign[0].jobFunction === undefined) ||
          (detailsOfCampaign[0].customJobFunction === "" &&
            detailsOfCampaign[0].jobFunction === "null") ||
          (detailsOfCampaign[0].customJobFunction === "" &&
            detailsOfCampaign[0].jobFunction === "undefined")
        ) {
          this.setState({
            jobFunctionDelivery: "No",
          });
        } else {
          this.setState({
            jobFunctionDelivery: "Yes",
          });
        }

        if (
          (detailsOfCampaign[0].companyRevenue === "" &&
            detailsOfCampaign[0].customCompRevenue === "") ||
          (detailsOfCampaign[0].companyRevenue === null &&
            detailsOfCampaign[0].customCompRevenue === null) ||
          (detailsOfCampaign[0].companyRevenue === undefined &&
            detailsOfCampaign[0].customCompRevenue === undefined) ||
          (detailsOfCampaign[0].companyRevenue === "null" &&
            detailsOfCampaign[0].customCompRevenue === null) ||
          (detailsOfCampaign[0].companyRevenue === "undefined" &&
            detailsOfCampaign[0].customCompRevenue === undefined) ||
          (detailsOfCampaign[0].companyRevenue === null &&
            detailsOfCampaign[0].customCompRevenue === "null") ||
          (detailsOfCampaign[0].companyRevenue === undefined &&
            detailsOfCampaign[0].customCompRevenue === "undefined") ||
          (detailsOfCampaign[0].companyRevenue === "null" &&
            detailsOfCampaign[0].customCompRevenue === "null") ||
          (detailsOfCampaign[0].companyRevenue === "undefined" &&
            detailsOfCampaign[0].customCompRevenue === "undefined") ||
          (detailsOfCampaign[0].companyRevenue === "" &&
            detailsOfCampaign[0].customCompRevenue === null) ||
          (detailsOfCampaign[0].companyRevenue === "" &&
            detailsOfCampaign[0].customCompRevenue === undefined) ||
          (detailsOfCampaign[0].companyRevenue === "" &&
            detailsOfCampaign[0].customCompRevenue === "null") ||
          (detailsOfCampaign[0].companyRevenue === "" &&
            detailsOfCampaign[0].customCompRevenue === "undefined") ||
          (detailsOfCampaign[0].customCompRevenue === "" &&
            detailsOfCampaign[0].companyRevenue === null) ||
          (detailsOfCampaign[0].customCompRevenue === "" &&
            detailsOfCampaign[0].companyRevenue === undefined) ||
          (detailsOfCampaign[0].customCompRevenue === "" &&
            detailsOfCampaign[0].companyRevenue === "null") ||
          (detailsOfCampaign[0].customCompRevenue === "" &&
            detailsOfCampaign[0].companyRevenue === "undefined")
        ) {
          this.setState({ companyRevenue: "No" });
        } else {
          this.setState({ companyRevenue: "Yes" });
        }

        if (
          (detailsOfCampaign[0].employeeSize === "" &&
            detailsOfCampaign[0].customEmpSize === "") ||
          (detailsOfCampaign[0].employeeSize === null &&
            detailsOfCampaign[0].customEmpSize === null) ||
          (detailsOfCampaign[0].employeeSize === undefined &&
            detailsOfCampaign[0].customEmpSize === undefined) ||
          (detailsOfCampaign[0].employeeSize === "null" &&
            detailsOfCampaign[0].customEmpSize === null) ||
          (detailsOfCampaign[0].employeeSize === "undefined" &&
            detailsOfCampaign[0].customEmpSize === undefined) ||
          (detailsOfCampaign[0].employeeSize === null &&
            detailsOfCampaign[0].customEmpSize === "null") ||
          (detailsOfCampaign[0].employeeSize === undefined &&
            detailsOfCampaign[0].customEmpSize === "undefined") ||
          (detailsOfCampaign[0].employeeSize === "null" &&
            detailsOfCampaign[0].customEmpSize === "null") ||
          (detailsOfCampaign[0].employeeSize === "undefined" &&
            detailsOfCampaign[0].customEmpSize === "undefined") ||
          (detailsOfCampaign[0].employeeSize === "" &&
            detailsOfCampaign[0].customEmpSize === null) ||
          (detailsOfCampaign[0].employeeSize === "" &&
            detailsOfCampaign[0].customEmpSize === undefined) ||
          // Chaitanya-4878- Commented condition for custom employee size is "null"
          //  (detailsOfCampaign[0].employeeSize === "" &&
          // 	 detailsOfCampaign[0].customEmpSize === "null") ||
          (detailsOfCampaign[0].employeeSize === "" &&
            detailsOfCampaign[0].customEmpSize === "undefined") ||
          (detailsOfCampaign[0].customEmpSize === "" &&
            detailsOfCampaign[0].employeeSize === null) ||
          (detailsOfCampaign[0].customEmpSize === "" &&
            detailsOfCampaign[0].employeeSize === undefined) ||
          (detailsOfCampaign[0].customEmpSize === "" &&
            detailsOfCampaign[0].employeeSize === "null") ||
          (detailsOfCampaign[0].customEmpSize === "" &&
            detailsOfCampaign[0].employeeSize === "undefined")
        ) {
          this.setState({
            detailsOfCampaign: detailsOfCampaign,
            companyEmployeeSize: "No",
          });
        } else {
          this.setState({
            companyEmployeeSize: "Yes",
          });
        }

        if (
          (detailsOfCampaign[0].industry === "" &&
            detailsOfCampaign[0].customIndustry === "") ||
          (detailsOfCampaign[0].industry === null &&
            detailsOfCampaign[0].customIndustry === null) ||
          (detailsOfCampaign[0].industry === undefined &&
            detailsOfCampaign[0].customIndustry === undefined) ||
          (detailsOfCampaign[0].industry === "null" &&
            detailsOfCampaign[0].customIndustry === null) ||
          (detailsOfCampaign[0].industry === "undefined" &&
            detailsOfCampaign[0].customIndustry === undefined) ||
          (detailsOfCampaign[0].industry === null &&
            detailsOfCampaign[0].customIndustry === "null") ||
          (detailsOfCampaign[0].industry === undefined &&
            detailsOfCampaign[0].customIndustry === "undefined") ||
          (detailsOfCampaign[0].industry === "null" &&
            detailsOfCampaign[0].customIndustry === "null") ||
          (detailsOfCampaign[0].industry === "undefined" &&
            detailsOfCampaign[0].customIndustry === "undefined") ||
          (detailsOfCampaign[0].industry === "" &&
            detailsOfCampaign[0].customIndustry === null) ||
          (detailsOfCampaign[0].industry === "" &&
            detailsOfCampaign[0].customIndustry === undefined) ||
          // Chaitanya-4878- Commented condition for custom Industry is "null"
          //  (detailsOfCampaign[0].industry === "" &&
          // 	 detailsOfCampaign[0].customIndustry === "null") ||
          (detailsOfCampaign[0].industry === "" &&
            detailsOfCampaign[0].customIndustry === "undefined") ||
          (detailsOfCampaign[0].customIndustry === "" &&
            detailsOfCampaign[0].industry === null) ||
          (detailsOfCampaign[0].customIndustry === "" &&
            detailsOfCampaign[0].industry === undefined) ||
          (detailsOfCampaign[0].customIndustry === "" &&
            detailsOfCampaign[0].industry === "null") ||
          (detailsOfCampaign[0].customIndustry === "" &&
            detailsOfCampaign[0].industry === "undefined")
        ) {
          this.setState({
            industry: "No",
          });
        } else {
          this.setState({
            industry: "Yes",
          });
        }
      });

    if (
      status === "SupportingDocument" &&
      this.state.isIncompleteSuppDocStepAlreadyComplete === false
    ) {
      this.setState(
        {
          canProceedToStep2: true,
          isIncompleteSuppDocStepAlreadyComplete: true,
        },
        () => this.onHandleStepChange(1)
      );
    }
  }

  onHandleStepChange = (currentStep) => {
    if (this.state.alertDisplay === "none" && this.state.canProceedToStep2) {
      this.setState({ currentStep });
      if (currentStep === 0) {
        this.setState({
          displayStep1: "flex",
          displayStep2: "none",
        });
      } else {
        this.setState({
          displayStep1: "none",
          displayStep2: "flex",
        });
      }
    }
  };

  /**
   * @author Raunak Thakkar
   * @param  Description Handle change of switch*/
  switchHandlechange(value) {
    value == true
      ? this.setState({ switchValue: "ON" })
      : this.setState({ switchValue: "OFF" });
  }

  // /**
  //  * @author Sanobar Golandaj
  //  * @param  Description handle add  and remove the dynamic text fields*/
  // addSuppressionLink() {
  // 	linkSuppCount = linkSuppCount + 1;
  // 	this.setState({
  // 		dynamicSuppressionInput: [
  // 			...this.state.dynamicSuppressionInput,
  // 			{ count: linkSuppCount, suppressionLink: "", suppressionFileName: "" },
  // 		],
  // 	});
  // }
  // /**
  //  * @author Somnath Keswad
  //  * @param  Description handle Remove the dynamic text fields of Suppression
  //  * @return Description return the remaining text field in Suppression Link
  //  */
  // removeSuppressionLink(i) {
  // 	this.state.dynamicSuppressionInput.splice(i, 1); // Iterate .map
  // 	this.state.suppressionLinkArray.splice(i, 1); // for Backend insert
  // 	this.setState({
  // 		dynamicSuppressionInput: this.state.dynamicSuppressionInput,
  // 	});
  // }

  // /**
  //  * @author Somnath Keswad
  //  * @param  Description handle backButton Add the dynamic text fields of Suppression in Back Button
  //  * @return Description return the 1 more text field in Suppression Link
  //  */
  // addBackSuppressionLink() {
  // 	count = this.state.suppressionLink.length;
  // 	count = count + 1;
  // 	var tempSuppArray = [
  // 		{ suppressionFileName: "", suppressionLink: "", count: count },
  // 	];
  // 	let finalData = this.state.suppressionLink.concat(tempSuppArray);
  // 	this.setState({ suppressionLink: finalData });
  // }
  // /**
  //  * @author Somnath Keswad
  //  * @param  Description handle backButton remove the dynamic text fields of Suppression
  //  * @return Description return remaining text field
  //  */
  // removeBackSuppressionLink(e, i) {
  // 	var supportDocID = e.target.getAttribute("name");
  // 	this.state.suppressionLink.splice(i, 1);
  // 	this.setState({ suppressionLink: this.state.suppressionLink });
  // 	if (supportDocID !== undefined || supportDocID != "") {
  // 		fetch("campaign/deleteSuppressionLink?supportDocID=" + supportDocID)
  // 			.then((res) => res.json())
  // 			.then((deleteAssetLink) => {
  // 				this.setState({ deleteAssetLink: deleteAssetLink });
  // 			})
  // 			.catch(function (err) {
  // 				console.log(err);
  // 			});
  // 	}
  // }

  // /**
  //  * @author Sanobar Golandaj
  //  * @param  Description handle the Dynamic TextBox for AssetLink
  //  */
  // handleSuppressionLink(i, e) {
  // 	const { name, value } = e.target;
  // 	var parsed = queryString.parse(this.props.location.search);
  // 	var step2 = window.location.href.split("&")[
  // 		window.location.href.split("?").length - 1
  // 	];
  // 	if (
  // 		step2 === "undefined" ||
  // 		step2 === null ||
  // 		step2 === "" ||
  // 		step2 === undefined ||
  // 		(step2 !== "step2" && parsed.status !== "SupportingDocument")
  // 	) {
  // 		let suppressionLinkArray = [...this.state.suppressionLinkArray];
  // 		suppressionLinkArray[i] = { ...suppressionLinkArray[i], [name]: value };
  // 		this.setState({ suppressionLinkArray: suppressionLinkArray });
  // 	} else {
  // 		let suppressionLink = [...this.state.suppressionLink];
  // 		suppressionLink[i] = { ...suppressionLink[i], [name]: value };
  // 		this.setState({ suppressionLink: suppressionLink });
  // 	}
  // }

  /**
   * @author Somnath Keswad
   * @param  Description handle add the dynamic text fields
   * @return Description return all text field
   */
  addLink(e) {
    count = count + 1;
    var assetLinkArray = [];
    // count=assetLinkArray.length;
    if (this.state.multiTouchChk === "Yes") {
      assetLinkArray = [
        {
          linkAssetFileName: "",
          assetLink: "",
          multiTouch: "1st Touch",
          count: count,
        },
      ];
      let finalData = this.state.assetLinkArray.concat(assetLinkArray);
      this.setState({
        dynamicInput: [
          ...this.state.dynamicInput,
          {
            count: count,
            assetLink: "",
            linkAssetFileName: "",
            multiTouch: "1st Touch",
          },
        ],
        assetLinkArray: finalData,
      });
    } else {
      assetLinkArray = [{ linkAssetFileName: "", assetLink: "", count: count }];
      let finalData = this.state.assetLinkArray.concat(assetLinkArray);
      this.setState({
        dynamicInput: [
          ...this.state.dynamicInput,
          { count: count, assetLink: "", linkAssetFileName: "" },
        ],
        assetLinkArray: finalData,
      });
    }
  }

  /**
   * @author Somnath Keswad
   * @param  Description handle add Back Button the dynamic text fields
   * @return Description return all text field
   */
  backAddLink(e) {
    var count = this.state.backDynamicInput.length;
    count = count + 1;
    var assetLinkArray;
    // added by karan - preferable code
    // if (this.state.isIncompleteSuppDocStepAlreadyComplete) {
    // 	if (
    // 		this.state.leadPerAssetChk === "Yes" &&
    // 		this.state.multiTouchChkBack === "Yes"
    // 	) {
    // 		assetLinkArray = [
    // 			{
    // 				linkAssetFileName: "",
    // 				assetLink: "",
    // 				leadPerAsset: "",
    // 				leadPercentage: "",
    // 				multiTouch: "1st Touch",
    // 				count: count,
    // 			},
    // 		];
    // 	} else if (
    // 		this.state.leadPerAssetChk === "Yes" &&
    // 		this.state.multiTouchChkBack === "No"
    // 	) {
    // 		assetLinkArray = [
    // 			{
    // 				linkAssetFileName: "",
    // 				assetLink: "",
    // 				leadPerAsset: "",
    // 				leadPercentage: "",
    // 				count: count,
    // 			},
    // 		];
    // 	} else if (
    // 		this.state.leadPerAssetChk === "No" &&
    // 		this.state.multiTouchChkBack === "Yes"
    // 	) {
    // 		assetLinkArray = [
    // 			{
    // 				linkAssetFileName: "",
    // 				assetLink: "",
    // 				multiTouch: "1st Touch",
    // 				count: count,
    // 			},
    // 		];
    // 	} else {
    // 		assetLinkArray = [
    // 			{ linkAssetFileName: "", assetLink: "", count: count },
    // 		];
    // 	}
    // 	let finalData = this.state.backDynamicInput.concat(assetLinkArray);
    // 	this.setState({ backDynamicInput: finalData });
    // 	return;
    // }
    if (this.state.leadPerAssetChk === "Yes") {
      assetLinkArray = [
        {
          linkAssetFileName: "",
          assetLink: "",
          leadPerAsset: "",
          leadPercentage: "",
          count: count,
        },
      ];
    }
    //Sanjana â€“ Task -2979- Adding bydefault 1st Touch multiTouch if user first clicks on check box and then try to add new link
    if (this.state.multiTouchChkBack === "Yes") {
      assetLinkArray = [
        {
          linkAssetFileName: "",
          assetLink: "",
          multiTouch: "1st Touch",
          count: count,
        },
      ];
    } else {
      // possible code ammbiguity here - needs consultation to change - karan
      assetLinkArray = [{ linkAssetFileName: "", assetLink: "", count: count }];
    }

    let finalData = this.state.backDynamicInput.concat(assetLinkArray);
    this.setState({ backDynamicInput: finalData });
  }
  // end of backAddLink

  /**
   * @author Somnath Keswad
   * @param  Description handle remove the dynamic text fields
   * @return Description return remaining text field
   */

  removeLink(i) {
    this.setState({
      dynamicInput: this.state.dynamicInput.filter((_, index) => index !== i),
      assetLinkArray: this.state.assetLinkArray.filter(
        (_, index) => index !== i
      ),
    });
    //this.state.dynamicInput.splice(i, 1);
    //const d = this.state.assetLinkArray.splice(i, 1);
    this.setState({ backDynamicInput: this.state.assetLinkArray });
  }

  /**
   * @author Somnath Keswad
   * @param  Description handle backButton remove the dynamic text fields
   * @return Description return remaining text field
   */
  backRemoveLink(e, i) {
    var supportDocID = e.target.getAttribute("name");
    this.state.backDynamicInput.splice(i, 1);
    this.setState({ backDynamicInput: this.state.backDynamicInput });
    if (supportDocID !== undefined || supportDocID != "") {
      const { user } = this.props.auth;
      let suppDocName = e.target.getAttribute("suppDocName");
      let deleteData = {
        supportDocID,
        campID: this.state.campID,
        fileName: suppDocName,
        campaignStatus: this.state.campaignStatus,
      }; // karan-task-3719-vapt header and query params

      fetch("campaign/deleteAssetLink", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(deleteData),
      })
        .then((res) => res.json())
        .then((deleteAssetLink) => {
          this.setState({ deleteAssetLink });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  backTo() {
    var parsed = this.props.location.state;
    if (parsed !== undefined) {
      if (parsed.hasOwnProperty("campID")) {
        var campID = parsed.campID;
        this.setState({ campID: campID });
      }
    }
  }

  /**
   * @author Narendra Phadke
   * @param  A supportDoID sending to BE for delete file
   * @return List of uploded file along with remove link
   */

  dynamicABMFile(e) {
    e.preventDefault();
    let self = this;
    var suppDocName = e.currentTarget.getAttribute("name");
    let supportDocID;
    let supportDocType;

    var resValue = new Array();
    resValue = e.currentTarget.id.split(" ");
    for (i = 0; i < resValue.length; i++) {
      supportDocType = resValue[0];
      supportDocID = resValue[1];
    }
    if (
      this.state.isIncompleteSuppDocStepAlreadyComplete &&
      (supportDocID === null ||
        suppDocName === null ||
        supportDocID === undefined ||
        suppDocName === undefined)
    ) {
      supportDocID = e.currentTarget.getAttribute("supportdocid");
      suppDocName = e.currentTarget.getAttribute("data-fileid");
      supportDocType = this.state.sdEditDetail.filter(
        (item) => item.supportDocID == supportDocID
      )[0].typeOfSuppDoc;
    }

    if (supportDocType == "Exclusion") {
      var deleteExclusionCounter = 0;
      var nooutputexeclusion = document.getElementById("noexclusionList");
      var valuenooutputexeclusion =
        document.getElementById("noexclusionList").innerHTML;
      var resexeclusion = new Array();
      resexeclusion = valuenooutputexeclusion.split(" ");
      var backecelusionarraylength;
      for (var i = 0; i < resexeclusion.length; i++) {
        backecelusionarraylength = resexeclusion[0];
      }
      deleteExclusionCounter++;
      // commented by karan
      //$(this).parent().remove();
      if (deleteExclusionCounter > 0) {
        backecelusionarraylength =
          backecelusionarraylength - deleteExclusionCounter;
        nooutputexeclusion.innerHTML =
          backecelusionarraylength + " Exclusion  Files Selected";
      } else {
        nooutputexeclusion.innerHTML = "0 Exclusion File Selected";
      }
      for (var k = 0; k < successExclusionCount.length; k++) {
        if (successExclusionCount[k] === suppDocName) {
          successExclusionCount.splice(k, 1);
        }
      }
      var rejectedCount =
        newexclusionfilenames.length - successExclusionCount.length;
      if (rejectedCount < 0) {
        rejectedCount = 0;
      }
      var msg1 = "";
      // "Successfully Uploads Exclusion Files (Uploaded Files :"+successExclusionCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
      var message1 = document.getElementById("exclusionMessage");
      if ($("#exclusionMessage").text() === "") {
        message1.innerHTML += msg1;
      } else {
        message1.innerHTML = msg1;
      }
      for (let k = 0; k < chkExclusionExist.length; k++) {
        if (chkExclusionExist[k] == suppDocName) {
          chkExclusionExist.splice(k, 1);
        }
      }

      // added by karan
      newexclusionfilenames.splice(
        newexclusionfilenames.indexOf(suppDocName),
        1
      );
      this.setState({
        exclusionFilesSelectedDocuments: [
          ...this.state.exclusionFilesSelectedDocuments.filter((item) => {
            if (item.suppDocName === suppDocName) {
              return false;
            }
            if (item.dataFileID === suppDocName) {
              return false;
            }
            if (item.supportDocID === supportDocID) {
              return false;
            }
            return true;
          }),
        ],
        // assetFileListUI: [], // commented when dragger removed
      });
    } else if (supportDocType == "ABM") {
      var deleteABMCounter = 0;
      var nooutput = document.getElementById("noabmList");
      var valuenooutput = document.getElementById("noabmList").innerHTML;
      var res = new Array();
      res = valuenooutput.split(" ");
      var backarraylength;
      for (i = 0; i < res.length; i++) {
        backarraylength = res[0];
      }
      deleteABMCounter++;
      // commented by karan
      //$(this).parent().remove();

      if (deleteABMCounter > 0) {
        backarraylength = parseInt(backarraylength);
        backarraylength = backarraylength - deleteABMCounter;
        nooutput.innerHTML = backarraylength;
      } else {
        nooutput.innerHTML = "0 ABM File Selected";
      }
      for (var k = 0; k < successAbmCount.length; k++) {
        if (successAbmCount[k] == suppDocName) {
          successAbmCount.splice(k, 1);
        }
      }
      var rejectedCount = newabmfilenames.length - successAbmCount.length;
      if (rejectedCount < 0) {
        rejectedCount = 0;
      }
      var msg1 = "";
      // "Successfully Uploads ABM Files (Uploaded Files :"+successAbmCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
      var message1 = document.getElementById("abmMessage");
      if ($("#abmMessage").text() === "") {
        message1.innerHTML += msg1;
      } else {
        message1.innerHTML = msg1;
      }
      for (let k = 0; k < chkABMExist.length; k++) {
        if (chkABMExist[k] == suppDocName) {
          chkABMExist.splice(k, 1);
        }
      }

      // added by karan
      newabmfilenames.splice(newabmfilenames.indexOf(suppDocName), 1);
      this.setState({
        abmFilesSelectedDocuments: [
          ...this.state.abmFilesSelectedDocuments.filter((item) => {
            if (item.suppDocName === suppDocName) {
              return false;
            }
            if (item.dataFileID === suppDocName) {
              return false;
            }
            if (item.supportDocID === supportDocID) {
              return false;
            }
            return true;
          }),
        ],
        // assetFileListUI: [], // commented when dragger removed
      });
    } else if (supportDocType == "Suppression") {
      var deleteSuppressionCounter = 0;
      var nosuppressionoutput = document.getElementById("nosuppressionList");
      var valuenosuppressionoutput =
        document.getElementById("nosuppressionList").innerHTML;
      var suppressionres = new Array();
      suppressionres = valuenosuppressionoutput.split(" ");

      var backsuppressionarraylength;
      for (i = 0; i < suppressionres.length; i++) {
        backsuppressionarraylength = suppressionres[0];
      }
      deleteSuppressionCounter++;
      // commented by karan
      //$(this).parent().remove();

      if (deleteSuppressionCounter > 0) {
        backsuppressionarraylength =
          backsuppressionarraylength - deleteSuppressionCounter;
        nosuppressionoutput.innerHTML = backsuppressionarraylength;
      } else {
        nosuppressionoutput.innerHTML = "";
      }
      for (var k = 0; k < successSuppressionCount.length; k++) {
        if (successSuppressionCount[k] === suppDocName) {
          successSuppressionCount.splice(k, 1);
        }
      }
      let rejectedCount =
        newsuppressionfilenames.length - successSuppressionCount.length;
      if (rejectedCount < 0) {
        rejectedCount = 0;
      }
      let msg1 = "";
      // "Successfully Uploads Suppression Files (Uploaded Files :"+successSuppressionCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
      let message1 = document.getElementById("suppresionMessage");
      if ($("#suppresionMessage").text() === "") {
        message1.innerHTML += msg1;
      } else {
        message1.innerHTML = msg1;
      }

      for (let k = 0; k < chkSuppressionExist.length; k++) {
        if (chkSuppressionExist[k] == suppDocName) {
          chkSuppressionExist.splice(k, 1);
        }
      }

      // added by karan
      newsuppressionfilenames.splice(
        newsuppressionfilenames.indexOf(suppDocName),
        1
      );
      this.setState({
        suppressionFilesSelectedDocuments: [
          ...this.state.suppressionFilesSelectedDocuments.filter((item) => {
            if (item.suppDocName === suppDocName) {
              return false;
            }
            if (item.dataFileID === suppDocName) {
              return false;
            }
            if (item.supportDocID === supportDocID) {
              return false;
            }
            return true;
          }),
        ],
        // assetFileListUI: [], // commented when dragger removed
      });
    } else if (supportDocType == "Other") {
      var deleteOtherCounter = 0;
      var nootheroutput = document.getElementById("nootherList");
      var valuenootheroutput = document.getElementById("nootherList").innerHTML;
      var otherres = new Array();
      otherres = valuenootheroutput.split(" ");

      var backotherarraylength;
      for (i = 0; i < otherres.length; i++) {
        backotherarraylength = otherres[0];
      }
      deleteOtherCounter++;
      // commented by karan
      //$(this).parent().remove();

      if (deleteOtherCounter > 0) {
        backotherarraylength = backotherarraylength - deleteOtherCounter;
        nootheroutput.innerHTML = backotherarraylength;
      } else {
        nosuppressionoutput.innerHTML = "0 Other File Selected";
      }
      for (var k = 0; k < successOtherCount.length; k++) {
        if (successOtherCount[k] === suppDocName) {
          successOtherCount.splice(k, 1);
        }
      }
      let rejectedCount = newotherfilenames.length - successOtherCount.length;
      if (rejectedCount < 0) {
        rejectedCount = 0;
      }
      let msg1 = "";
      // "Successfully Uploads Other Files (Uploaded Files :"+successOtherCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
      let message1 = document.getElementById("otherMessage");
      if ($("#otherMessage").text() === "") {
        message1.innerHTML += msg1;
      } else {
        message1.innerHTML = msg1;
      }
      for (let k = 0; k < chkOtherExist.length; k++) {
        if (chkOtherExist[k] == suppDocName) {
          chkOtherExist.splice(k, 1);
        }
      }

      // added by karan
      newotherfilenames.splice(newotherfilenames.indexOf(suppDocName), 1);
      this.setState({
        otherFilesSelectedDocuments: [
          ...this.state.otherFilesSelectedDocuments.filter((item) => {
            if (item.suppDocName === suppDocName) {
              return false;
            }
            if (item.dataFileID === suppDocName) {
              return false;
            }
            if (item.supportDocID === supportDocID) {
              return false;
            }
            return true;
          }),
        ],
        // assetFileListUI: [], // commented when dragger removed
      });
    } else if (supportDocType == "Asset") {
      var deleteAssetCounter = 0;
      var noassetoutput = document.getElementById("noassetList");
      var valuenoassetoutput = document.getElementById("noassetList").innerHTML;
      var assetres = new Array();
      assetres = valuenoassetoutput.split(" ");
      var backassetarraylength;
      for (i = 0; i < assetres.length; i++) {
        backassetarraylength = assetres[0];
      }
      deleteAssetCounter++;
      //commented by karan
      //$(this).parent().remove();
      // to overcome this element removal, the item is removed from inputType state instead

      if (deleteAssetCounter > 0) {
        backassetarraylength = backassetarraylength - deleteAssetCounter;
        noassetoutput.innerHTML = backassetarraylength;
      } else {
        noassetoutput.innerHTML = "0 Other File Selected";
      }
      for (var k = 0; k < successAssetCount.length; k++) {
        if (successAssetCount[k] === suppDocName) {
          successAssetCount.splice(k, 1);
        }
      }
      successAssetCount = [...new Set(successAssetCount)]; //Sandeep-task-3617-STC---Agency side---create camp---upload asset file back flow issue
      var rejectedCount = newassetfilenames.length - successAssetCount.length;
      if (rejectedCount < 0) {
        rejectedCount = 0;
      }
      var succcount = successAssetCount.length;
      var rejeccount = rejectAssetCount.length;
      var totalcount = succcount + rejeccount;
      newassetfilenames.splice(newassetfilenames.indexOf(suppDocName), 1);
      this.setState({
        assetFilesUploadedSuccessCount: succcount,
        assetFilesUploadedFailedCount: rejectedCount,
        assetFilesSelectedDocuments: [
          ...this.state.assetFilesSelectedDocuments.filter((item) => {
            if (item.suppDocName === suppDocName) {
              return false;
            }
            if (item.dataFileID === suppDocName) {
              return false;
            }
            if (item.supportDocID === supportDocID) {
              return false;
            }
            return true;
          }),
        ],
        // assetFileListUI: [], // commented when dragger removed
      });
    }
    let campaignDetail = this.state.sdEditDetail;

    campaignDetail = campaignDetail.filter(
      (el) => el.supportDocID != supportDocID
    );
    var index = campaignDetail.findIndex(
      (v) => v.supportDocID === supportDocID
    );

    self.setState({ sdEditDetail: campaignDetail });

    var result = [];
    result = Array.from(
      new Set(self.state.dynamicAssetName.map((p) => p.key))
    ).map((key) => {
      return {
        key: key,
        suppDocName: self.state.dynamicAssetName.find((p) => p.key === key)
          .suppDocName,
        leadPerAsset: this.state.dynamicAssetName.find((p) => p.key === key)
          .leadPerAsset,
        leadPercentage: this.state.dynamicAssetName.find((p) => p.key === key)
          .leadPercentage,
      };
    });

    /**
     * @author Narendra Phadke
     * @param  Delete File From Database
     */

    for (var i = 0; i < self.state.inputType.length; i++) {
      if (suppDocName === self.state.inputType[i].suppDocName) {
        self.state.inputType.splice(i, 1);
        result.splice(i, 1);
      }
    }
    self.setState({
      inputType: self.state.inputType,
      dynamicAssetName: result,
    });

    if (self.state.inputType.length == 0) {
      self.setState({ displayTable: "none" });
    }

    const { user } = self.props.auth;
    const userID = user.id;
    let data = {
      supportDocID: supportDocID,
      supportDocType: supportDocType,
      campID: self.state.campID,
      names: suppDocName,
      typeOfSuppDoc: supportDocType,
      campaignStatus: this.state.campaignStatus,
    }; // karan-task-3719-vapt header and query params
    //campaign / deleteSupportingDocFile
    fetch("campaign/deleteSupportingDocFile", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    // window.location.reload();
  }

  /**
   * @author Supriya Gore
   * @param  Description handle the leads per asset
   * @return Description return successfully leads per asset
   */
  leadPerAssethandleChange(e) {
    let leadPerAssetChk = e.target.value;

    if (e.target.checked === true) {
      leadPerAssetChk = "Yes";
    } else {
      leadPerAssetChk = "No";
    }

    this.setState({ leadPerAssetChk: leadPerAssetChk });
  }

  /**
   * @author Supriya Gore
   * @param  Description handle the leads per asset
   * @return Description return successfully leads per asset
   */
  leadPerAssethandleChangeBack(e) {
    let leadPerAssetChk = e.target.value;
    var defaultChecked = false;
    if (e.target.checked === true) {
      leadPerAssetChk = "Yes";
      defaultChecked = true;
    } else {
      leadPerAssetChk = "No";
      defaultChecked = false;
    }
    this.setState({
      leadPerAssetChk: leadPerAssetChk,
      checkedValue: defaultChecked,
    });
  }
  // end of leadPerAssethandleChangeBack
  /**
   * @author Raunak Thakkar
   * @param  Description handle the multiple Touch for assets
   * @return shows a check box if more than 1 asset is present
   */

  multiTouchHandleChange(e) {
    let multiTouchChk = e.target.value;
    var assetName_touch1 = this.state.assetName_touch1;
    var assetTimestamp_touch1 = this.state.assetTimestamp_touch1;
    let tempInputType = []; // created by Raunak
    let tempDynamicInput = []; //created by Raunak
    var assetLinkFile = [];
    var multiTouchUnCheck = "No";
    if (e.target.checked === true) {
      multiTouchUnCheck = "Yes";
      multiTouchChk = "Yes";
      assetName_touch1 = "Yes";
      assetTimestamp_touch1 = "1970-01-01 00:00:01";
      for (var i = 0; i < this.state.inputType.length; i++) {
        if (this.state.inputType[i] === undefined) {
        } else {
          if (this.state.inputType[i].hasOwnProperty("multiTouch")) {
            if (this.state.inputType[i].multiTouch === "") {
              tempInputType.push({
                ...this.state.inputType[i],
                multiTouch: "1st Touch",
              });
            } else {
              tempInputType.push({ ...this.state.inputType[i] });
            }
          } else {
            tempInputType.push({
              ...this.state.inputType[i],
              multiTouch: "1st Touch",
            });
          }
        }
      }

      for (var i = 0; i < this.state.dynamicInput.length; i++) {
        if (this.state.dynamicInput[i] === undefined) {
        } else {
          if (this.state.dynamicInput[i].hasOwnProperty("multiTouch")) {
            if (this.state.dynamicInput[i].multiTouch === "") {
              tempDynamicInput.push({
                ...this.state.dynamicInput[i],
                multiTouch: "1st Touch",
              });
            } else {
              tempDynamicInput.push(this.state.dynamicInput[i]);
            }
          } else {
            tempDynamicInput.push({
              ...this.state.dynamicInput[i],
              multiTouch: "1st Touch",
            });
          }
        }
      }
    } else {
      multiTouchChk = "No";
    }
    if (multiTouchUnCheck === "Yes") {
      this.setState({
        multiTouchChk: multiTouchChk,
        assetName_touch1: assetName_touch1,
        assetTimestamp_touch1: assetTimestamp_touch1,
        inputType: tempInputType,
        dynamicInput: tempDynamicInput,
      });
    } else {
      this.setState({
        multiTouchChk: multiTouchChk,
        assetName_touch1: assetName_touch1,
        assetTimestamp_touch1: assetTimestamp_touch1,
      });
    }
  }

  /**
   * @author Sanjana Godbole
   * @param  Description Task 2979- handle the multiple Touch for assets for back flow
   * @return Check box check uncheck functionality
   */
  multiTouchHandleChangeBack(e) {
    let multiTouchChkBack = e.target.value;
    var assetName_touch1 = this.state.assetName_touch1;
    var assetTimestamp_touch1 = this.state.assetTimestamp_touch1;
    var assetLink = [];
    var assetLinkFile = [];
    var multiTouchUnCheck = "No";
    if (e.target.checked === true) {
      multiTouchChkBack = "Yes";
      assetName_touch1 = "Yes";
      assetTimestamp_touch1 = "1970-01-01 00:00:01";
      multiTouchUnCheck = "Yes";

      //For Asset File
      for (var i = 0; i < this.state.inputType.length; i++) {
        if (this.state.inputType[i] === undefined) {
        } else {
          if (this.state.inputType[i].hasOwnProperty("multiTouch")) {
            if (this.state.inputType[i].multiTouch === "") {
              assetLinkFile.push({
                ...this.state.inputType[i],
                multiTouch: "1st Touch",
              });
            } else {
              assetLinkFile.push({ ...this.state.inputType[i] });
            }
          } else {
            assetLinkFile.push({
              ...this.state.inputType[i],
              multiTouch: "1st Touch",
            });
          }
        }
      }

      //For Asset Link
      for (var i = 0; i < this.state.backDynamicInput.length; i++) {
        if (this.state.backDynamicInput[i] === undefined) {
        } else {
          if (this.state.backDynamicInput[i].hasOwnProperty("multiTouch")) {
            if (this.state.backDynamicInput[i].multiTouch === "") {
              assetLink.push({
                ...this.state.backDynamicInput[i],
                multiTouch: "1st Touch",
              });
            } else {
              assetLink.push({ ...this.state.backDynamicInput[i] });
            }
          } else {
            assetLink.push({
              ...this.state.backDynamicInput[i],
              multiTouch: "1st Touch",
            });
          }
        }
      }
    } else {
      multiTouchChkBack = "No";
    }
    if (multiTouchUnCheck === "Yes") {
      this.setState({
        multiTouchChkBack: multiTouchChkBack,
        assetName_touch1: assetName_touch1,
        assetTimestamp_touch1: assetTimestamp_touch1,
        backDynamicInput: assetLink,
        inputType: assetLinkFile,
      });
    } else {
      this.setState({
        multiTouchChkBack: multiTouchChkBack,
        assetName_touch1: assetName_touch1,
        assetTimestamp_touch1: assetTimestamp_touch1,
      });
    }
  }
  // end of multiTouchHandleChangeBack

  /**
   * @author Narendra Phadke
   * @param  Description handle the multiple asset uploads
   * @return Description return successfully files upload message
   */

  handleChangeMultipleFile(e) {
    e.preventDefault();
    successAssetCount = [...new Set(successAssetCount)]; //Sandeep-task-3617-STC---Agency side---create camp---upload asset file back flow issue
    var MultipleFileCounter = 0;
    let self = this;
    var parsed = this.props.location.state;
    if (parsed !== undefined) {
      var campID,
        status,
        step2 = "na";
      if (parsed.hasOwnProperty("campID")) {
        campID = parsed.campID;
      }
      if (parsed.hasOwnProperty("status")) {
        status = parsed.status;
      }
      if (parsed.hasOwnProperty("step2")) {
        step2 = "step2";
      }
      if (parsed.hasOwnProperty("rfpCampaign")) {
        step2 = "rfpCampaign";
      }
      assetnames = [];
      self.setState({ displayTable: "block" });
      let assetfilesData = e.target.files; // commented by karan
      // let assetfilesData = e.fileList.map((f) => f.originFileObj);
      if (step2 === "rfpCampaign") {
        step2 = "step2";
      }
      if (
        step2 === "undefined" ||
        step2 === null ||
        step2 === "" ||
        step2 === undefined ||
        (step2 !== "step2" && status !== "SupportingDocument")
      ) {
        if (self.state.inputType === 0) {
          var num = Math.floor(Math.random() * 163);
          var size = self.state.inputType.length + num;
        } else {
          var len = self.state.inputType.length - 1;

          if (len > 0) {
            var num = Math.floor(Math.random() * 137);
            var size1 = self.state.inputType[len].key;
            var size = size1 + self.state.inputType.length + num; // hardcoded because incremental kay
          } else {
            var num = Math.floor(Math.random() * 100);
            var size = self.state.inputType.length + num;
          }
        }
      } else {
        if (self.state.dynamicAssetName === 0) {
          var size = self.state.inputType.length + 13;
          size = size + 13;
        } else {
          var len = self.state.dynamicAssetName.length - 1;
          if (len > 0) {
            var num = Math.floor(Math.random() * 137);
            var size1 = self.state.dynamicAssetName[len].key;
            var size = size1 + self.state.inputType.length + num; // hardcoded because incremental kay
          } else {
            var num = Math.floor(Math.random() * 100);
            var size = self.state.inputType.length + num;
          }
        }
      }

      key = size;
      var assetNameDuplicateFileCheck = [];
      for (var j = 0; j < self.state.inputType.length; j++) {
        assetNameDuplicateFileCheck.push(self.state.inputType[j].suppDocName);
      }
      let fileerrors = {};

      if ($.isEmptyObject(assetnames)) {
        for (var i = 0, l = assetfilesData.length; i < l; i++) {
          let assetNameTemp = assetfilesData[i].name;
          let assetCheckDuplicateLength = 0;
          let assetCheckDuplicate = assetNameDuplicateFileCheck.filter(
            function (word) {
              return assetNameTemp.includes(word);
            }
          );
          assetCheckDuplicateLength = assetCheckDuplicate.length;

          if (assetCheckDuplicateLength > 0) {
            fileerrors["FileError"] =
              "Please select file with another name   " +
              assetNameTemp +
              "  already exists";
            this.setState({ fileerrors: fileerrors });
          } else {
            this.setState({ fileerrors: "" });
            assetnames.push(assetfilesData[i].name);
            var assetFileCheck = assetnames.MultipleFileCounter++;
            key = key + 3;

            self.state.inputType.push({
              suppDocName: assetfilesData[i].name,
              key: key,
            });
          }
        }
        self.setState(
          { inputType: self.state.inputType, assetsFile: assetnames },
          function () {
            //Sanjana â€“ Task -2979- by default leadPerAsset and multi-touch fields added in array while uploading asset files
            let inputTypeTemp = [...this.state.inputType];
            if (document.getElementById("leadPerAssetChk").checked == true) {
              for (let i = 0; i < this.state.inputType.length; i++) {
                if (!inputTypeTemp[i].hasOwnProperty("leadPerAsset")) {
                  inputTypeTemp[i] = { ...inputTypeTemp[i], leadPerAsset: "" };
                }
                if (!inputTypeTemp[i].hasOwnProperty("leadPercentage")) {
                  inputTypeTemp[i] = {
                    ...inputTypeTemp[i],
                    leadPercentage: "",
                  };
                }
              }
            }
            if (
              document.getElementById("multitouchChk") == null
                ? document.getElementById("multitouchChkBack").checked
                : document.getElementById("multitouchChk").checked
            ) {
              let inputTypeTemp = [...this.state.inputType];
              for (let i = 0; i < this.state.inputType.length; i++) {
                if (!inputTypeTemp[i].hasOwnProperty("multiTouch")) {
                  inputTypeTemp[i] = {
                    ...inputTypeTemp[i],
                    multiTouch: "1st Touch",
                    assetTimestamp_touch: "Yes",
                  };
                }
              }
              this.setState({ inputType: inputTypeTemp }, function () {});
            }
          }
        );
      } else {
        for (var j = 0, m = assetfilesData.length; j < m; j++) {
          key = key + 3;

          self.state.inputType.push({
            suppDocName: assetfilesData[j].name,
            key: key,
          });

          assetnames.push(assetfilesData[j].name);
          MultipleFileCounter++;
        }
        self.setState({
          inputType: self.state.inputType,
          assetsFile: assetnames,
        });
      }

      assetLength = assetnames.length;

      if (assetLength > 0) {
        //   this.setState({
        //     asseterrors: ''
        // })
      }

      /**
       * @author Sanjana Godbole
       * @param  A FileList object that lists every selected file
       * @return List of uploded file along with remove link
       */

      var link;
      var filename = "";
      var arrayLength = assetnames.length;
      var nooutput = document.getElementById("noassetList");

      var assetarrayLength = assetnames.length;
      var noassetoutput = document.getElementById("noassetList");
      var valuenoassetoutput = document.getElementById("noassetList").innerHTML;

      var assetres = new Array();
      assetres = valuenoassetoutput.split(" ");

      var backassetarraylength;
      for (i = 0; i < assetres.length; i++) {
        backassetarraylength = assetres[0];
      }

      assetarrayLength = parseInt(assetarrayLength);
      backassetarraylength = parseInt(backassetarraylength);

      MultipleFileCounter = parseInt(MultipleFileCounter);
      var assetarrayLength1;
      assetarrayLength1 =
        MultipleFileCounter + backassetarraylength + assetLength;

      if (assetarrayLength1 > 0) {
        noassetoutput.innerHTML = assetarrayLength1 + "Asset  Files Selected";
      } else {
        noassetoutput.innerHTML = "0 Asset File Selected";
      }

      for (var j = 0; j < assetarrayLength; j++) {
        newassetfilenames.push(assetnames[j]);
      }

      //var HTML = "<table style='width:100%'>";
      let HTML;
      var imgsuccess;
      let fileName;
      let removeLink;
      let assetFilesArrayUI = [...this.state.assetFilesSelectedDocuments];

      for (var j = 0; j < newassetfilenames.length; j++) {
        var isExist = assetValidationArray.includes(newassetfilenames[j]);

        let loading = true;
        let index = assetFilesArrayUI.findIndex(
          (item) =>
            item.dataFileID === newassetfilenames[j] && item.loading === false
        );
        if (index > -1) {
          loading = false;
        }

        let toBeState;
        if (isExist === true) {
          toBeState = {
            success: false,
            dataFileID: newassetfilenames[j],
            typeOfSuppDoc: "Asset",
            loading,
          };
        } else {
          toBeState = {
            success: true,
            dataFileID: newassetfilenames[j],
            typeOfSuppDoc: "Asset",
            loading,
          };
        }
        //Sandeep-task-3617-STC---Agency side---create camp---upload asset file back flow issue
        assetFilesArrayUI = [
          ...assetFilesArrayUI.filter(
            (item) =>
              item.dataFileID !== toBeState.dataFileID &&
              item.suppDocName !== toBeState.dataFileID
          ),
          toBeState,
        ];
      }
      self.setState({ assetFilesSelectedDocuments: assetFilesArrayUI });
      //HTML += "</table>";
      //commented by karan
      //document.getElementById("assetList").innerHTML = HTML;

      $(document).on("click", ".removeAssetFile", function (e) {
        e.preventDefault();
        var clickedFile = $(this).closest("a").data("fileid");
        var supportDocID = $(this).attr("supportDocID");
        var j = 0;
        // let assetFilesArrayUI = self.state.assetFilesSelectedDocuments;
        // let assetFileListArrayUI = self.state.assetFileListUI;
        for (j; j < newassetfilenames.length; j++) {
          var temp = j;
          if (newassetfilenames[temp] === clickedFile) {
            // errorFile=false;
            var isExist = assetValidationArray.includes(newassetfilenames[j]);
            if (isExist === true) {
              var index = assetValidationArray.indexOf(newassetfilenames[j]);
              assetValidationArray.splice(index, 1);
            }

            filename = newassetfilenames[temp];
            newassetfilenames.splice(j, 1);
            // filesData.splice(j,1);
            // assetFilesArrayUI = assetFilesArrayUI.filter(
            // 	(item) => item.dataFileID !== clickedFile
            // );
            // assetFileListArrayUI = assetFileListArrayUI.filter(
            // 	(item) => item.name !== clickedFile
            // ),
            //item.dataFileID !== clickedFile ||
            //Sandeep task-3617-STC---Agency side---create camp---upload asset file back flow issue
            self.setState({
              assetFilesSelectedDocuments:
                self.state.assetFilesSelectedDocuments.filter(
                  (item) =>
                    item.suppDocName !== clickedFile &&
                    item.dataFileID !== clickedFile
                ),

              // assetFileListUI: self.state.assetFileListUI.filter(
              // 	(item) => item.name !== clickedFile
              // ), // commented when dragger removed
            });
            // commented by karan
            //$(this).parent().remove();
            assetarrayLength = newassetfilenames.length;
            if (newassetfilenames.length == 0) {
              assetValidationArray = [];
              rejectAssetCount = [];
              // successAssetCount=[];
            }
            for (var k = 0; k < rejectAssetCount.length; k++) {
              if (rejectAssetCount[k] == clickedFile) {
                rejectAssetCount.splice(k, 1);
                var rejectedCount =
                  newassetfilenames.length - successAssetCount.length;
              }
            }
            for (var k = 0; k < successAssetCount.length; k++) {
              if (successAssetCount[k] == clickedFile) {
                successAssetCount.splice(k, 1);
              }
            }
            var rejectedCount =
              newassetfilenames.length - successAssetCount.length;
            if (rejectedCount < 0) {
              rejectedCount = 0;
            }
            var succcount = successAssetCount.length;
            var rejeccount = rejectAssetCount.length;
            var totalcount = succcount + rejeccount;
            self.setState({
              assetFilesUploadedSuccessCount: succcount,
              assetFilesUploadedFailedCount: rejectedCount,
            });
            // commented by karan
            // var msg1 =
            // 	"<span style='color:#777;font-family:roboto;'>Total Uploaded Asset Files :" +
            // 	totalcount +
            // 	"</span>&nbsp;(Successfully Uploaded :" +
            // 	successAssetCount.length +
            // 	", <font color='red'> Upload Failed :" +
            // 	rejectedCount +
            // 	"</font>)";
            // var message1 = document.getElementById("assetMessage");
            // if ($("#assetMessage").text() === "") {
            // 	message1.innerHTML += msg1;
            // } else {
            // 	message1.innerHTML = msg1;
            // }

            /**
             * @author Narendra Phadke
             * @param  Delete File From Database
             */
            if (step2 === "rfpCampaign") {
              step2 = "step2";
            }
            for (var i = 0; i < self.state.inputType.length; i++) {
              if (filename === self.state.inputType[i].suppDocName) {
                self.state.inputType.splice(i, 1);
                self.state.assetFileNameArray.splice(i, 1);
                if (
                  step2 === "undefined" ||
                  step2 === null ||
                  step2 === "" ||
                  step2 === undefined ||
                  (step2 !== "step2" && status !== "SupportingDocument")
                ) {
                } else {
                  self.state.dynamicAssetName.splice(i, 1);
                  self.setState({
                    dynamicAssetName: self.state.dynamicAssetName,
                  });
                }
              }
            }

            self.setState({
              inputType: self.state.inputType,
              assetFileNameArray: self.state.assetFileNameArray,
            });

            if (self.state.inputType.length == 0) {
              self.setState({ displayTable: "none" });
            }

            var typeOfSuppDoc = "Asset";
            let data = {
              campID: campID,
              names: filename,
              typeOfSuppDoc: typeOfSuppDoc,
              supportDocID,
              step: "Create",
            };
            fetch("campaign/supportingDocumentDeleteFile", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              //mode:'no-cors',
              //body: data
              body: JSON.stringify(data),
            });
            assetarrayLength = parseInt(assetarrayLength);
            backassetarraylength = parseInt(backassetarraylength);
            assetarrayLength1 = assetarrayLength + backassetarraylength;

            if (assetarrayLength1 > 0) {
              noassetoutput.innerHTML = assetarrayLength1;
            } else {
              noassetoutput.innerHTML = "0 Asset File Selected";
              $("#asset").val("");
              self.setState({
                assetFilesUploadedSuccessCount: 0,
                assetFilesUploadedFailedCount: 0,
              });
              // commented by karan
              // var message = document.getElementById("assetMessage");
              // message.innerHTML = "";
            }
          } // end of  if(names[temp] === clickedFile)
        } // end of for
        let assetJson1 = [...self.state.assetJson];
        let assetJson = assetJson1.filter(
          (a) => a.supportDocID != supportDocID
        );
        self.setState({ assetJson });
      }); //$(document).on

      var data = new FormData();
      data.append("campID", campID);
      //data.append("names",names);
      var assetNameFlag;
      for (var i = 0, l = assetfilesData.length; i < l; i++) {
        var assetNameTemp = assetfilesData[i].name;
        var assetCheckDuplicateLength = 0;
        var assetCheckDuplicate = assetNameDuplicateFileCheck.filter(function (
          word
        ) {
          return assetNameTemp.includes(word);
        });
        assetCheckDuplicateLength = assetCheckDuplicate.length;
        if (assetCheckDuplicateLength > 0) {
        } else {
          data.append("file", assetfilesData[i]);
          this.setState({ fileerrors: "" });
          assetNameFlag = true;
          this.state.assetFileNameArray.push({
            assetFileName: assetfilesData[i].name,
          });
        }
      }
      //campaign/supportingDocument
      if (assetNameFlag === true) {
        fetch("campaign/supportingDocumentAsset", {
          method: "POST",
          // headers: {'Content-Type': 'application/json'},
          //mode:'no-cors',
          // body: data
          body: data,
        })
          .then((res) => res.json())
          .then((assetList) => {
            let assetJson1 = [...self.state.assetJson];
            let assetJson = [...assetJson1, ...assetList];
            self.setState({ assetJson });
            if (assetList[0].success === true) {
              var suppDocName = [];
              for (let i = 0; i < assetList.length; i++) {
                suppDocName.push(assetList[i].fileName);
                successAssetCount.push(assetList[i].fileName);
              }
              for (let i = 0; i < suppDocName.length; i++) {
                var count = 0;
                for (var j = 0; j < newassetfilenames.length; j++) {
                  if (suppDocName[i] == newassetfilenames[j]) {
                    count = count + 1;
                  }
                }
              }
              if (count > 1) {
                newassetfilenames.pop();
                assetValidationArray.pop();
              }
              var rejectCount = 0;
              //var HTML2 = "<table style='width:100%'>";
              var HTML2;
              // TODO : check this again for create flow, spread used for back flow checking purpose
              let assetFilesArrayUI = [
                ...this.state.assetFilesSelectedDocuments,
              ];
              for (let j = 0; j < newassetfilenames.length; j++) {
                let toBeState;
                let singleAsset = assetJson.filter(
                  (a) => a.fileName == newassetfilenames[j]
                );
                let supportDocID = singleAsset[0].supportDocID;
                var isExist = assetValidationArray.includes(
                  newassetfilenames[j]
                );
                if (isExist === true) {
                  toBeState = {
                    success: false,
                    supportDocID,
                    dataFileID: newassetfilenames[j],
                    typeOfSuppDoc: "Asset",
                    loading: false,
                  };
                  /**** If uploaded file is wrong then it displays as in Red color */
                  rejectCount = rejectCount + 1;
                } else {
                  toBeState = {
                    success: true,
                    supportDocID,
                    dataFileID: newassetfilenames[j],
                    typeOfSuppDoc: "Asset",
                    loading: false,
                  };
                }
                //Sandeep-task-3617-STC---Agency side---create camp---upload asset file back flow issue
                assetFilesArrayUI = [
                  ...assetFilesArrayUI.filter(
                    (item) =>
                      item.dataFileID !== toBeState.dataFileID &&
                      item.suppDocName !== toBeState.dataFileID
                  ),
                  toBeState,
                ];
              }
              self.setState({
                assetFilesSelectedDocuments: assetFilesArrayUI,
              });
              // commented by karan
              // HTML2 += "</table>";
              // document.getElementById("assetList").innerHTML = HTML2;
              var succcount = successAssetCount.length;
              var rejeccount = rejectAssetCount.length;
              var totalcount = succcount + rejeccount;
              this.setState({
                assetFilesUploadedSuccessCount: succcount,
                assetFilesUploadedFailedCount: rejectCount,
              });
              // commented by karan
              // var msg =
              // 	"<span style='color:#777;font-family:roboto;'>Total Uploaded Asset Files :" +
              // 	totalcount +
              // 	"</span>&nbsp;(Successfully Uploaded :" +
              // 	successAssetCount.length +
              // 	", <font color='red'> Upload Failed :" +
              // 	rejectCount +
              // 	"</font>)";
              // var message = document.getElementById("assetMessage");
              // if ($("#assetMessage").text() === "") {
              // 	message.innerHTML += msg;
              // } else {
              // 	message.innerHTML = msg;
              // }
            } else {
              for (let i = 0; i < assetList.length; i++) {
                assetValidationArray.push(assetList[i].fileName);
              }
              var HTML = "<table style='width:100%'>";
              // TODO : changed for back flow, check this for create flow with spread op
              let assetFilesArrayUI = [
                ...this.state.assetFilesSelectedDocuments,
              ];
              for (let j = 0; j < newassetfilenames.length; j++) {
                let toBeState;
                let singleAsset = assetJson.filter(
                  (a) => a.fileName == newassetfilenames[j]
                );
                let supportDocID = singleAsset[0].supportDocID;
                let isValid = assetValidationArray.includes(
                  newassetfilenames[j]
                );
                if (isValid === true) {
                  toBeState = {
                    success: false,
                    supportDocID,
                    dataFileID: newassetfilenames[j],
                    typeOfSuppDoc: "Asset",
                    loading: false,
                  };
                  imgsuccess =
                    '<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>&nbsp;';

                  link =
                    '<a class="removeAssetFile" href="#"supportDocID=' +
                    '"' +
                    supportDocID +
                    '"' +
                    " data-fileid=" +
                    '"' +
                    newassetfilenames[j] +
                    '"' +
                    '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                  HTML +=
                    "<tr style='background-color:#fbd5db'><td style='color:red;border: 1px solid #ffffff;padding-bottom:0px;'>" +
                    imgsuccess +
                    "&nbsp;" +
                    "<span class=asset1 style='text-align:left;font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 215px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;float:left'>" +
                    newassetfilenames[j] +
                    "</span>" +
                    link +
                    "<br><br></td></tr>";
                } else {
                  toBeState = {
                    success: true,
                    supportDocID,
                    dataFileID: newassetfilenames[j],
                    typeOfSuppDoc: "Asset",
                    loading: false,
                  };
                  let isTrue = successAssetCount.includes(newassetfilenames[j]);
                  if (isTrue === false) {
                    successAssetCount.push(newassetfilenames[j]);
                  }
                  imgsuccess =
                    '<img src="success.png" alt="sucess" height="18" width="18" style="float:left"/>&nbsp;';
                  link =
                    '<a class="removeAssetFile" href="#"supportDocID=' +
                    '"' +
                    supportDocID +
                    '"' +
                    " data-fileid=" +
                    '"' +
                    newassetfilenames[j] +
                    '"' +
                    '><span style="font-family:roboto;font-size:13px;font-weight:500;float:right">Remove</span></a>';
                  HTML +=
                    "<tr style='background-color:#daecda'><td style='color:green;width:576px;border: 1px solid #ffffff;padding-bottom:0px;'>" +
                    imgsuccess +
                    "&nbsp;" +
                    "<span class=asset1 style='text-align:left;font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 215px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;float:left'>" +
                    newassetfilenames[j] +
                    "</span>" +
                    link +
                    "<br><br></td></tr>";
                }
                // this.setState((prev) => ({
                // 	assetFilesSelectedDocuments: [
                // 		...prev.assetFilesSelectedDocuments.filter(
                // 			(item) => item.dataFileID !== toBeState.dataFileID
                // 		),
                // 		toBeState,
                // 	],
                // }));
                //Sandeep-task-3617-STC---Agency side---create camp---upload asset file back flow issue
                assetFilesArrayUI = [
                  ...assetFilesArrayUI.filter(
                    (item) =>
                      item.dataFileID !== toBeState.dataFileID &&
                      item.suppDocName !== toBeState.dataFileID
                  ),
                  toBeState,
                ];
              }
              self.setState({
                assetFilesSelectedDocuments: assetFilesArrayUI,
              });
              HTML += "</table>";
              document.getElementById("assetList").innerHTML = HTML;
              var rejectedCount =
                newassetfilenames.length - successAssetCount.length;
              var succcount = successAssetCount.length;
              var rejeccount = rejectAssetCount.length;
              var totalcount = succcount + rejeccount;
              this.setState({
                assetFilesUploadedSuccessCount: succcount,
                assetFilesUploadedFailedCount: rejectedCount,
              });
              // commented by karan
              // var msg1 =
              // 	"<span style='color:#777;font-family:roboto;'>Total Uploaded Asset Files :" +
              // 	totalcount +
              // 	"</span>&nbsp;(Successfully Uploaded :" +
              // 	successAssetCount.length +
              // 	", <font color='red'> Upload Failed :" +
              // 	rejectedCount +
              // 	"</font>)";
              // var message1 = document.getElementById("assetMessage");
              // if ($("#assetMessage").text() === "") {
              // 	message1.innerHTML += msg1;
              // } else {
              // 	message1.innerHTML = msg1;
              // }
            }
          })
          .catch(function (err) {
            console.log(err);
          });
        this.setState({ files: data });
      }
      e.target.value = null; //Sandeep-task-3617-STC---Agency side---create camp---upload asset file back flow issue
    }
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the multiple other files uploads
   * @return Description return successfully files upload message
   */
  handleChangeMultipleFileOfOther(e) {
    let othernames = [];
    let self = this;
    var parsed = this.props.location.state;
    if (parsed !== undefined) {
      var campID;
      if (parsed.hasOwnProperty("campID")) {
        campID = parsed.campID;
      }
      e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
      e.setAttribute("type", "file"); //setting a attribute for type file added by Raunak
      e.setAttribute("multiple", true); //setting a attribute for multiple added by Raunak
      e.click(); //to open file dialougeadded by Raunak
      e.addEventListener(
        "change",
        function () {
          //calling an eventListener at change event added by Raunak
          self.setState({ displayTableother: "block" });

          let otherfilesData = e.files;

          for (var i = 0, l = otherfilesData.length; i < l; i++) {
            let fileName = otherfilesData[i].name;
            if (chkOtherExist.includes(fileName)) {
              duplicateOtherFile.push(fileName);
            } else {
              othernames.push(otherfilesData[i].name);
            }
          }
          if (chkOtherExist != othernames) {
            rfpChange++;
          }

          /**
           * @author Sanjana Godbole
           * @param  A FileList object that lists every selected file
           * @return List of uploded file along with remove link
           */

          var link;
          var filename = "";
          var arrayLength = othernames.length;
          //var nooutput = document.getElementById('nootherList');
          var otherarrayLength = othernames.length;
          var nootheroutput = document.getElementById("nootherList");
          var valuenootheroutput =
            document.getElementById("nootherList").innerHTML;

          var otherres = new Array();
          otherres = valuenootheroutput.split(" ");

          var backotherarraylength;
          for (let i = 0; i < otherres.length; i++) {
            backotherarraylength = otherres[0];
          }
          self.setState({
            backOtherArrayLengthForUI: backotherarraylength,
          });

          otherarrayLength = parseInt(otherarrayLength);
          backotherarraylength = parseInt(backotherarraylength);

          var otherarrayLength1;
          otherarrayLength1 = otherarrayLength + backotherarraylength;

          if (otherarrayLength1 > 0) {
            nootheroutput.innerHTML = otherarrayLength;
          } else {
            nootheroutput.innerHTML = "0 Other File Selected";
          }
          for (var j = 0; j < otherarrayLength; j++) {
            newotherfilenames.push(othernames[j]);
            chkOtherExist.push(othernames[j]);
          }

          var HTML = "<table>";
          var imgsuccess;
          let otherFilesArrayUI = self.state.otherFilesSelectedDocuments;
          for (var j = 0; j < newotherfilenames.length; j++) {
            var isExist = otherValidationArray.includes(newotherfilenames[j]);
            let loading = true;
            let index = otherFilesArrayUI.findIndex(
              (item) =>
                item.dataFileID === newotherfilenames[j] &&
                item.loading === false
            );
            if (index > -1) {
              loading = false;
            }
            let toBeState;
            if (isExist === true) {
              toBeState = {
                success: false,
                dataFileID: newotherfilenames[j],
                suppDocName: newotherfilenames[j],
                typeOfSuppDoc: "Other",
                loading,
              };
            } else {
              toBeState = {
                success: true,
                dataFileID: newotherfilenames[j],
                suppDocName: newotherfilenames[j],
                typeOfSuppDoc: "Other",
                loading,
              };
            }
            otherFilesArrayUI = [
              ...otherFilesArrayUI.filter(
                (item) => item.dataFileID !== toBeState.dataFileID
              ),
              toBeState,
            ];
          }
          self.setState({ otherFilesSelectedDocuments: otherFilesArrayUI });
          HTML += "</table>";

          // commented by karan
          //document.getElementById("otherList").innerHTML = HTML;
          // $(document).on("click", ".removeOtherFile", function (e) {
          // 	e.preventDefault();
          // 	var clickedFile = $(this).parent().children("a").data("fileid");
          // 	let supportDocID = $(this).attr("supportDocID");
          // 	var j = 0;
          // 	for (j; j < newotherfilenames.length; j++) {
          // 		var temp = j;

          // 		if (newotherfilenames[temp] === clickedFile) {
          // 			var isExist = otherValidationArray.includes(newotherfilenames[j]);

          // 			if (isExist === true) {
          // 				var index = otherValidationArray.indexOf(newotherfilenames[j]);
          // 				otherValidationArray.splice(index, 1);
          // 			}
          // 			filename = newotherfilenames[temp];

          // 			newotherfilenames.splice(j, 1);
          // 			chkOtherExist.splice(j, 1);
          // 			$(this).parent().remove();
          // 			otherarrayLength = newotherfilenames.length;
          // 			if (newotherfilenames.length == 0) {
          // 				otherValidationArray = [];
          // 				rejectOtherCount = [];
          // 				var message1 = document.getElementById("otherMessage");
          // 				message1.innerHTML = "";
          // 			}
          // 			for (var k = 0; k < rejectOtherCount.length; k++) {
          // 				if (rejectOtherCount[k] == clickedFile) {
          // 					rejectOtherCount.splice(k, 1);
          // 				}
          // 			}
          // 			for (var k = 0; k < successOtherCount.length; k++) {
          // 				if (successOtherCount[k] == clickedFile) {
          // 					successOtherCount.splice(k, 1);
          // 				}
          // 			}
          // 			var rejectedCount =
          // 				newotherfilenames.length - successOtherCount.length;
          // 			if (rejectedCount < 0) {
          // 				rejectedCount = 0;
          // 			}
          // 			var msg1 = "";
          // 			// "Successfully Uploads Other Files (Uploaded Files :"+successOtherCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
          // 			var message1 = document.getElementById("otherMessage");

          // 			if (otherValidationArray.length > 0) {
          // 			} else {
          // 				message1.innerHTML = msg1;
          // 			}

          // 			if (newotherfilenames.length == 0) {
          // 				var message1 = document.getElementById("otherMessage");
          // 				message1.innerHTML = "";
          // 			}
          // 			/**
          // 			 * @author Narendra Phadke
          // 			 * @param  Delete File From Database
          // 			 */
          // 			if (newotherfilenames.length == 0) {
          // 				self.setState({ displayTableother: "none" });
          // 			}
          // 			var typeOfSuppDoc = "Other";
          // 			let data = {
          // 				campID: campID,
          // 				names: filename,
          // 				typeOfSuppDoc: typeOfSuppDoc,
          // 				supportDocID,
          // 				step: "Create",
          // 			};

          // 			fetch("campaign/supportingDocumentDeleteFile", {
          // 				method: "POST",
          // 				headers: { "Content-Type": "application/json" },
          // 				//mode:'no-cors',
          // 				//body: data
          // 				body: JSON.stringify(data),
          // 			});

          // 			otherarrayLength = parseInt(otherarrayLength);
          // 			backotherarraylength = parseInt(backotherarraylength);
          // 			otherarrayLength1 = otherarrayLength + backotherarraylength;

          // 			if (otherarrayLength1 > 0) {
          // 				nootheroutput.innerHTML = otherarrayLength1;
          // 			} else {
          // 				nootheroutput.innerHTML = "0 Other File Selected";

          // 				var message = document.getElementById("otherMessage");
          // 				message = "";
          // 				//  var number=document.getElementById("otherMessage").innerHTML;
          // 				//  if(number=="")
          // 				//  {
          // 				//    message.innerHTML += msg;
          // 				//  }
          // 			}
          // 		} // end of  if(names[temp] === clickedFile)
          // 	} // end of for
          // 	let otherJson1 = [...self.state.otherJson];
          // 	let otherJson = otherJson1.filter(
          // 		(a) => a.supportDocID != supportDocID
          // 	);
          // 	self.setState({ otherJson });
          // }); //$(document).on

          var data = new FormData();
          data.append("campID", campID);

          for (var i = 0, l = otherfilesData.length; i < l; i++) {
            let fileName = otherfilesData[i].name;
            if (duplicateOtherFile.includes(fileName)) {
            } else {
              data.append("file", otherfilesData[i]);
            }
          }
          //campaign/supportingDocument

          fetch("campaign/supportingDocumentOther", {
            method: "POST",
            //mode:'no-cors',
            body: data,
          })
            .then((res) => res.json())
            .then((otherList) => {
              let otherJson1 = [...self.state.otherJson];
              let otherJson = [...otherJson1, ...otherList];
              self.setState({ otherJson });
              if (otherList[0].success === true) {
                var suppDocName = [];
                for (let i = 0; i < otherList.length; i++) {
                  suppDocName.push(otherList[i].fileName);
                  successOtherCount.push(otherList[i].fileName); //only for count
                }
                for (let i = 0; i < suppDocName.length; i++) {
                  var count = 0;
                  for (let j = 0; j < newotherfilenames.length; j++) {
                    if (suppDocName[i] == newotherfilenames[j]) {
                      count = count + 1;
                    }
                  }
                }

                if (count > 1) {
                  newotherfilenames.pop();
                  otherValidationArray.pop();
                }
                var RejectedCount = 0;
                var imgsuccess;
                var HTML2 = "<table>";
                let otherFilesArrayUI = self.state.otherFilesSelectedDocuments;
                for (let j = 0; j < newotherfilenames.length; j++) {
                  let singleFile = otherJson.filter(
                    (a) => a.fileName == newotherfilenames[j]
                  );
                  let supportDocID = singleFile[0].supportDocID;
                  let isExist = otherValidationArray.includes(
                    newotherfilenames[j]
                  );
                  let toBeState;
                  if (isExist === true) {
                    toBeState = {
                      success: false,
                      supportDocID,
                      dataFileID: newotherfilenames[j],
                      suppDocName: newotherfilenames[j],
                      typeOfSuppDoc: "Other",
                      loading: false,
                    };
                    /**** If uploaded file is wrong then it displays as in Red color */
                    RejectedCount = RejectedCount + 1;
                  } else {
                    toBeState = {
                      success: true,
                      supportDocID,
                      dataFileID: newotherfilenames[j],
                      suppDocName: newotherfilenames[j],
                      typeOfSuppDoc: "Other",
                      loading: false,
                    };
                  }
                  otherFilesArrayUI = [
                    ...otherFilesArrayUI.filter(
                      (item) => item.dataFileID !== toBeState.dataFileID
                    ),
                    toBeState,
                  ];
                }
                self.setState({
                  otherFilesSelectedDocuments: otherFilesArrayUI,
                });
                HTML2 += "</table>";
                // commented by karan
                //document.getElementById("otherList").innerHTML = HTML2;
                var msg = "";
                // "Successfully Uploads Other Files (Uploaded Files :"+(successOtherCount.length)+", <font color='red'> Failed Files :"+RejectedCount+"</font>)";
                var message = document.getElementById("otherMessage");
                if ($("#otherMessage").text() === "") {
                  message.innerHTML += msg;
                } else {
                  message.innerHTML = msg;
                }
              } else {
                for (let i = 0; i < otherList.length; i++) {
                  otherValidationArray.push(otherList[i].fileName);
                }

                var HTML = "<table>";
                var imgsuccess;
                let otherFilesArrayUI = self.state.otherFilesSelectedDocuments;
                for (let j = 0; j < newotherfilenames.length; j++) {
                  let singleFile = otherJson.filter(
                    (a) => a.fileName == newotherfilenames[j]
                  );
                  let supportDocID = singleFile[0].supportDocID;
                  let isValid = otherValidationArray.includes(
                    newotherfilenames[j]
                  );
                  let toBeState;
                  if (isValid === true) {
                    toBeState = {
                      success: false,
                      supportDocID,
                      dataFileID: newotherfilenames[j],
                      suppDocName: newotherfilenames[j],
                      typeOfSuppDoc: "Other",
                      loading: false,
                    };
                  } else {
                    toBeState = {
                      success: true,
                      supportDocID,
                      dataFileID: newotherfilenames[j],
                      suppDocName: newotherfilenames[j],
                      typeOfSuppDoc: "Other",
                      loading: false,
                    };
                    let isTrue = successOtherCount.includes(
                      newotherfilenames[j]
                    );
                    if (isTrue === false) {
                      successOtherCount.push(newotherfilenames[j]);
                    }
                  }
                  otherFilesArrayUI = [
                    ...otherFilesArrayUI.filter(
                      (item) => item.dataFileID !== toBeState.dataFileID
                    ),
                    toBeState,
                  ];
                }
                self.setState({
                  otherFilesSelectedDocuments: otherFilesArrayUI,
                });
                HTML += "</table>";
                // commented by karan
                //document.getElementById("otherList").innerHTML = HTML;
                var rejectedCount =
                  newotherfilenames.length - successOtherCount.length;
                var msg1 = "";
                //  "Successfully Uploads Other Files (Uploaded Files :"+(successOtherCount.length)+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";

                var message1 = document.getElementById("otherMessage");
                if (otherValidationArray.length > 0) {
                } else {
                  message1.innerHTML = msg1;
                }

                //  if($("#otherMessage").text()==="")
                //   {
                //     message1.innerHTML += msg1;
                //   }
                //   else
                //   {
                //     message1.innerHTML= msg1;
                //   }
              }
            })
            .catch(function (err) {
              console.log(err);
            });
          this.setState({
            files: data,
          });
        }.bind(this)
      );
    }
  }

  /**
   * @author : Karan Jagtap
   * @description : removes the file element from UI part when file removed from ABM uploaded file section
   */
  removeOtherFileSelectedDocuments = (clickedFile, supportDocID) => {
    let parsed = this.props.location.state;
    if (parsed !== undefined) {
      let campID;
      if (parsed.hasOwnProperty("campID")) {
        campID = parsed.campID;
      }
      var j = 0;
      for (j; j < newotherfilenames.length; j++) {
        var temp = j;

        if (newotherfilenames[temp] === clickedFile) {
          var isExist = otherValidationArray.includes(newotherfilenames[j]);

          if (isExist === true) {
            var index = otherValidationArray.indexOf(newotherfilenames[j]);
            otherValidationArray.splice(index, 1);
          }
          let filename = newotherfilenames[temp];

          newotherfilenames.splice(j, 1);
          chkOtherExist.splice(j, 1);
          $(this).parent().remove();
          let otherarrayLength = newotherfilenames.length;
          if (newotherfilenames.length == 0) {
            otherValidationArray = [];
            rejectOtherCount = [];
            var message1 = document.getElementById("otherMessage");
            message1.innerHTML = "";
          }
          for (var k = 0; k < rejectOtherCount.length; k++) {
            if (rejectOtherCount[k] == clickedFile) {
              rejectOtherCount.splice(k, 1);
            }
          }
          for (var k = 0; k < successOtherCount.length; k++) {
            if (successOtherCount[k] == clickedFile) {
              successOtherCount.splice(k, 1);
            }
          }
          var rejectedCount =
            newotherfilenames.length - successOtherCount.length;
          if (rejectedCount < 0) {
            rejectedCount = 0;
          }
          var msg1 = "";
          // "Successfully Uploads Other Files (Uploaded Files :"+successOtherCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
          var message1 = document.getElementById("otherMessage");

          if (otherValidationArray.length > 0) {
          } else {
            message1.innerHTML = msg1;
          }

          if (newotherfilenames.length == 0) {
            var message1 = document.getElementById("otherMessage");
            message1.innerHTML = "";
          }
          /**
           * @author Narendra Phadke
           * @param  Delete File From Database
           */
          if (newotherfilenames.length == 0) {
            this.setState({ displayTableother: "none" });
          }
          var typeOfSuppDoc = "Other";
          let data = {
            campID: campID,
            names: filename,
            typeOfSuppDoc: typeOfSuppDoc,
            supportDocID,
            step: "Create",
          };

          fetch("campaign/supportingDocumentDeleteFile", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            //mode:'no-cors',
            //body: data
            body: JSON.stringify(data),
          });

          otherarrayLength = parseInt(otherarrayLength);
          let backotherarraylength = parseInt(
            this.state.backOtherArrayLengthForUI
          );
          let otherarrayLength1 = otherarrayLength + backotherarraylength;

          if (otherarrayLength1 > 0) {
            //commented by karan
            //nootheroutput.innerHTML = otherarrayLength1;
          } else {
            //commented by karan
            //nootheroutput.innerHTML = "0 Other File Selected";

            var message = document.getElementById("otherMessage");
            message = "";
            //  var number=document.getElementById("otherMessage").innerHTML;
            //  if(number=="")
            //  {
            //    message.innerHTML += msg;
            //  }
          }
        } // end of  if(names[temp] === clickedFile)
      } // end of for
      let otherJson1 = [...this.state.otherJson];
      let otherJson = otherJson1.filter((a) => a.supportDocID != supportDocID);
      this.setState({
        otherJson,
        otherFilesSelectedDocuments:
          this.state.otherFilesSelectedDocuments.filter(
            (item) => item.dataFileID !== clickedFile
          ),
      });
    }
  };

  /**
   * @author Somnath Keswad
   * @param  Description handle the Dynamic TextBox for assetUpload filename
   * @return Description return Number of text field
   * @revised : Karan Jagtap
   * @description : completely configured with new UI
   */
  handleChangeAssetFileName = (e, i, suppDocName) => {
    const { name, value } = e.target;
    var parsed = this.props.location.state;
    if (parsed !== undefined) {
      let status;
      var step2 = "na";
      if (parsed.hasOwnProperty("status")) {
        status = parsed.status;
      }
      if (parsed.hasOwnProperty("step2")) {
        step2 = "step2";
      }
      if (parsed.hasOwnProperty("rfpCampaign")) {
        step2 = "rfpCampaign";
      }
      if (step2 === "rfpCampaign") {
        step2 = "step2";
      }
      if (
        step2 === "undefined" ||
        step2 === null ||
        step2 === "" ||
        step2 === undefined ||
        (step2 !== "step2" && status !== "SupportingDocument")
      ) {
        if (name === "leadPercentage") {
          assetflag = true;
          var leadAllocation = this.state.totalLeadAllocation;
          var assetLead1 = (value * leadAllocation) / 100;
          assetLead1 = Math.round(assetLead1);
          var assetWiseLead = 0;
          var name1 = "leadPerAsset";
          var assetLength = newassetfilenames.length;
          let assetFileNameArray = [...this.state.assetFileNameArray];
          assetFileNameArray[i] = {
            ...assetFileNameArray[i],
            [name1]: assetLead1,
          };
          this.state.assetFileNameArray = [...assetFileNameArray];
          let inputType = [...this.state.inputType];
          inputType[i] = { ...inputType[i], [name1]: assetLead1 };
          inputType[i] = { ...inputType[i], [name]: value };
          this.state.inputType = [...inputType];

          /**
           * @author : Karan Jagtap
           * @description : to change the UI part when changes are done
           */
          let temp = this.state.assetFilesSelectedDocuments;
          temp[i] = {
            ...this.state.assetFilesSelectedDocuments[i],
            [name1]: assetLead1,
            [name]: value,
          };
          this.setState({
            assetFilesSelectedDocuments: temp,
          });
        }

        let inputType = [...this.state.inputType];
        inputType[i] = { ...inputType[i], [name]: value };
        //this.setState({ inputType });

        this.setState({ inputType });
        /**
         * @author : Karan Jagtap
         * @description : to change the UI part when changes are done
         */
        if (name === "assetFileName") {
          this.setState({
            assetFilesSelectedDocuments:
              this.state.assetFilesSelectedDocuments.map((item) => {
                if (
                  item.suppDocName === suppDocName ||
                  item.dataFileID === suppDocName
                ) {
                  // 3126-karan-removed unnecessary changes for asset file name
                  return {
                    ...item,
                    [name]: value,
                  };
                }
                return {
                  ...item,
                };
              }),
          });
        }

        if (this.state.multiTouchChk === "Yes") {
          assetflag = true;
          var assetName_touch1 = "";
          var assetName_touch2 = "";
          var assetName_touch3 = "";
          var assetTimestamp_touch1 = "";
          var assetTimestamp_touch2 = "";
          var assetTimestamp_touch3 = "";
          //inputType= Asset File
          //dynamicInput-Asset Link

          for (var i = 0; i < inputType.length; i++) {
            // added by karan
            let singleObjectAssetFilesSelectedDocuments;
            if (inputType[i] == null) {
              assetName_touch1 = "1st Touch";
              assetTimestamp_touch1 = "Yes";
              inputType[i] = {
                ...inputType[i],
                ["assetName_touch"]: assetName_touch1,
                ["assetTimestamp_touch"]: assetTimestamp_touch1,
              };
              singleObjectAssetFilesSelectedDocuments = {
                ...this.state.assetFilesSelectedDocuments[i],
                ["assetName_touch"]: assetName_touch1,
                ["assetTimestamp_touch"]: assetTimestamp_touch1,
              };
            } else {
              if (inputType[i].multiTouch == "1st Touch") {
                assetName_touch1 = "1st Touch";
                assetTimestamp_touch1 = "Yes";

                inputType[i] = {
                  ...inputType[i],
                  ["assetName_touch"]: assetName_touch1,
                  ["assetTimestamp_touch"]: assetTimestamp_touch1,
                };
                singleObjectAssetFilesSelectedDocuments = {
                  ...this.state.assetFilesSelectedDocuments[i],
                  ["assetName_touch"]: assetName_touch1,
                  ["assetTimestamp_touch"]: assetTimestamp_touch1,
                };
              } else {
                if (inputType[i].multiTouch == "1st Touch") {
                  assetName_touch1 = "1st Touch";
                  assetTimestamp_touch1 = "Yes";

                  inputType[i] = {
                    ...inputType[i],
                    ["assetName_touch"]: assetName_touch1,
                    ["assetTimestamp_touch"]: assetTimestamp_touch1,
                  };
                  singleObjectAssetFilesSelectedDocuments = {
                    ...this.state.assetFilesSelectedDocuments[i],
                    ["assetName_touch"]: assetName_touch1,
                    ["assetTimestamp_touch"]: assetTimestamp_touch1,
                  };
                }
                if (inputType[i].multiTouch == "2nd Touch") {
                  assetName_touch2 = "2nd Touch";
                  assetTimestamp_touch2 = "Yes";

                  inputType[i] = {
                    ...inputType[i],
                    ["assetName_touch"]: assetName_touch2,
                    ["assetTimestamp_touch"]: assetTimestamp_touch2,
                  };

                  singleObjectAssetFilesSelectedDocuments = {
                    ...this.state.assetFilesSelectedDocuments[i],
                    ["assetName_touch"]: assetName_touch2,
                    ["assetTimestamp_touch"]: assetTimestamp_touch2,
                  };
                }
                if (inputType[i].multiTouch == "3rd Touch") {
                  assetName_touch3 = "3rd Touch";
                  assetTimestamp_touch3 = "Yes";

                  inputType[i] = {
                    ...inputType[i],
                    ["assetName_touch"]: assetName_touch3,
                    ["assetTimestamp_touch"]: assetTimestamp_touch3,
                  };
                  singleObjectAssetFilesSelectedDocuments = {
                    ...this.state.assetFilesSelectedDocuments[i],
                    ["assetName_touch"]: assetName_touch3,
                    ["assetTimestamp_touch"]: assetTimestamp_touch3,
                  };
                }
              }
            }
            /**
             * @author : Karan Jagtap
             * @description : to change the UI part when changes are done
             */
            let temp = this.state.assetFilesSelectedDocuments;
            temp[i] = singleObjectAssetFilesSelectedDocuments;
            this.setState({
              assetFilesSelectedDocuments: temp,
            });
          }

          this.setState({ inputType });
        }
      } else {
        // For Back Button

        i = this.state.inputType.findIndex(
          (item) => item.suppDocName === suppDocName
        );
        // setting this here so that i can access these values outside this if()
        if (name === "leadPercentage") {
          assetflagBack = true;
          var leadAllocation = this.state.totalLeadAllocation;
          var assetLead1 = (value * leadAllocation) / 100;
          assetLead1 = Math.round(assetLead1);
          var assetWiseLead = 0;
          var name1 = "leadPerAsset";
          let dynamicAssetName = [...this.state.dynamicAssetName];
          dynamicAssetName[i] = { ...dynamicAssetName[i], [name]: value };
          dynamicAssetName[i] = { ...dynamicAssetName[i], [name1]: assetLead1 };
          this.setState({ dynamicAssetName });
          // this.state.dynamicAssetName=[...dynamicAssetName];
          let inputType = [...this.state.inputType];
          inputType[i] = { ...inputType[i], [name1]: assetLead1 };
          inputType[i] = { ...inputType[i], [name]: value };
          this.state.inputType = [...inputType];

          this.setState({
            assetFilesSelectedDocuments: [
              ...this.state.assetFilesSelectedDocuments.map((item) => {
                if (
                  item.suppDocName === suppDocName ||
                  item.dataFileID === suppDocName
                ) {
                  return {
                    ...item,
                    [name]: value,
                    [name1]: assetLead1,
                  };
                }
                return {
                  ...item,
                };
              }),
            ],
          });
        } else {
          let dynamicAssetName = [...this.state.dynamicAssetName];
          dynamicAssetName[i] = { ...dynamicAssetName[i], [name]: value };
          var resultBack = [];
          resultBack = Array.from(
            new Set(dynamicAssetName.map((p) => p.key))
          ).map((key) => {
            return {
              key: key,
              suppDocName: dynamicAssetName.find((p) => p.key === key)
                .suppDocName,
              leadPerAsset: dynamicAssetName.find((p) => p.key === key)
                .leadPerAsset,
              leadPercentage: dynamicAssetName.find((p) => p.key === key)
                .leadPercentage,
              multiTouch: dynamicAssetName.find((p) => p.key === key)
                .multiTouch,
              assetFileName: dynamicAssetName.find((p) => p.key === key)
                .assetFileName, //3061-Raunak extracting the assetFilename key
            };
          });

          var assetName_touch1 = "";
          var assetName_touch2 = "";
          var assetName_touch3 = "";
          var assetTimestamp_touch1 = "";
          var assetTimestamp_touch2 = "";
          var assetTimestamp_touch3 = "";

          //Asset File
          let temp = [...this.state.assetFilesSelectedDocuments]; // added by karan
          for (let i = 0; i < resultBack.length; i++) {
            if (resultBack[i].multiTouch == "1st Touch") {
              assetName_touch1 = "1st Touch";
              assetTimestamp_touch1 = "Yes";
              resultBack[i] = {
                ...resultBack[i],
                ["assetName_touch"]: assetName_touch1,
                ["assetTimestamp_touch"]: assetTimestamp_touch1,
              };
              temp = [
                ...temp.map((item) => {
                  if (
                    item.suppDocName === resultBack[i].suppDocName ||
                    item.dataFileID === resultBack[i].suppDocName
                  ) {
                    return {
                      ...item,
                      ["assetName_touch"]: assetName_touch1,
                      ["assetTimestamp_touch"]: assetTimestamp_touch1,
                    };
                  }
                  return {
                    ...item,
                  };
                }),
              ];
            }
            if (resultBack[i].multiTouch == "2nd Touch") {
              assetName_touch2 = "2nd Touch";
              assetTimestamp_touch2 = "Yes";
              resultBack[i] = {
                ...resultBack[i],
                ["assetName_touch"]: assetName_touch2,
                ["assetTimestamp_touch"]: assetTimestamp_touch2,
              };
              temp = [
                ...temp.map((item) => {
                  if (
                    item.suppDocName === resultBack[i].suppDocName ||
                    item.dataFileID === resultBack[i].suppDocName
                  ) {
                    return {
                      ...item,
                      ["assetName_touch"]: assetName_touch2,
                      ["assetTimestamp_touch"]: assetTimestamp_touch2,
                    };
                  }
                  return {
                    ...item,
                  };
                }),
              ];
            }
            if (resultBack[i].multiTouch == "3rd Touch") {
              assetName_touch3 = "3rd Touch";
              assetTimestamp_touch3 = "Yes";
              resultBack[i] = {
                ...resultBack[i],
                ["assetName_touch"]: assetName_touch3,
                ["assetTimestamp_touch"]: assetTimestamp_touch3,
              };
              temp = [
                ...temp.map((item) => {
                  if (
                    item.suppDocName === resultBack[i].suppDocName ||
                    item.dataFileID === resultBack[i].suppDocName
                  ) {
                    return {
                      ...item,
                      ["assetName_touch"]: assetName_touch3,
                      ["assetTimestamp_touch"]: assetTimestamp_touch3,
                    };
                  }
                  return {
                    ...item,
                  };
                }),
              ];
            }
          }

          //Sanjana â€“ Task -2979- resultBack set to inputType to update touch after handleChange
          this.setState(
            {
              dynamicAssetName: resultBack,
              inputType: resultBack,
              assetFilesSelectedDocuments: temp,
            },
            () => {
              /**
               * @author : Karan Jagtap
               * @description : to change the UI part when changes are done,
               * Setting this state here because multitouch state is being saved above and
               * to not loose any changed value, after completion of previous state, here current state is saved
               */
              this.setState({
                assetFilesSelectedDocuments: [
                  ...this.state.assetFilesSelectedDocuments.map((item) => {
                    if (
                      item.suppDocName === suppDocName ||
                      item.dataFileID === suppDocName
                    ) {
                      return {
                        ...item,
                        [name]: value,
                      };
                    }
                    return {
                      ...item,
                    };
                  }),
                ],
              });
            }
          );
        }
      }
    }
  };
  // end of handleChangeAssetFileName

  /**
   * @author Somnath Keswad
   * @param  Description handle the Dynamic TextBox for AssetLink
   * @return Description return Number of text field
   */
  handleAssetLink(i, e) {
    var parsed = this.props.location.state;
    if (parsed !== undefined) {
      let status;
      var step2 = "na";
      if (parsed.hasOwnProperty("status")) {
        status = parsed.status;
      }
      if (parsed.hasOwnProperty("step2")) {
        step2 = "step2";
      }
      if (parsed.hasOwnProperty("rfpCampaign")) {
        step2 = "rfpCampaign";
      }
      const { name, value } = e.target;
      if (step2 === "rfpCampaign") {
        step2 = "step2";
      }

      if (
        step2 === "undefined" ||
        step2 === null ||
        step2 === "" ||
        step2 === undefined ||
        (step2 !== "step2" && status !== "SupportingDocument")
      ) {
        if (name === "leadPercentage") {
          linkflag = true;
          var leadAllocation = this.state.totalLeadAllocation;
          var assetLead1 = (value * leadAllocation) / 100;
          assetLead1 = Math.round(assetLead1);
          var assetWiseLead = 0;
          var name1 = "leadPerAsset";
          var assetLength = newassetfilenames.length;

          let assetLinkArray = [...this.state.assetLinkArray];
          assetLinkArray[i] = { ...assetLinkArray[i], [name1]: assetLead1 };
          assetLinkArray[i] = { ...assetLinkArray[i], [name]: value };
          this.setState({ assetLinkArray: assetLinkArray }); // for Calculating Percentage wise lead
          assetLinkLength = assetLinkArray.length;

          let dynamicInput = [...this.state.dynamicInput]; // for showing Column for leadPerAsset Name & Value
          dynamicInput[i] = { ...dynamicInput[i], [name1]: assetLead1 };
          dynamicInput[i] = { ...dynamicInput[i], [name]: value }; //Sanjana-Task 2881- value on handle change added in dynamicInput array
          this.state.dynamicInput = [...dynamicInput];
        } else {
          let assetLinkArray = [...this.state.assetLinkArray]; // For Sending database
          assetLinkArray[i] = { ...assetLinkArray[i], [name]: value };
          this.state.assetLinkArray = [...assetLinkArray];
          assetLinkLength = assetLinkArray.length;

          if (this.state.multiTouchChk === "Yes") {
            assetflag = true;
            var assetName_touch1 = "";
            var assetName_touch2 = "";
            var assetName_touch3 = "";
            var assetTimestamp_touch1 = "";
            var assetTimestamp_touch2 = "";
            var assetTimestamp_touch3 = "";
            for (var j = 0; j < assetLinkArray.length; j++) {
              if (assetLinkArray[j].hasOwnProperty("multiTouch")) {
                if (assetLinkArray[j].multiTouch == "1st Touch") {
                  assetName_touch1 = "1st Touch";
                  assetTimestamp_touch1 = "Yes";
                  //if(assetFileNameArray[i].hasOwnProperty('assetFileName')){
                  assetLinkArray[j] = {
                    ...assetLinkArray[j],
                    ["assetName_touch"]: assetName_touch1,
                    ["assetTimestamp_touch"]: assetTimestamp_touch1,
                  };
                  // }
                }
              } else {
                assetLinkArray[j] = {
                  ...assetLinkArray[j],
                  multiTouch: "1st Touch",
                  ["assetName_touch"]: assetName_touch1,
                  ["assetTimestamp_touch"]: assetTimestamp_touch1,
                };
              }
              if (assetLinkArray[j].multiTouch == "2nd Touch") {
                assetName_touch2 = "2nd Touch";
                assetTimestamp_touch2 = "Yes";
                assetLinkArray[j] = {
                  ...assetLinkArray[j],
                  ["assetName_touch"]: assetName_touch2,
                  ["assetTimestamp_touch"]: assetTimestamp_touch2,
                };
              }
              if (assetLinkArray[j].multiTouch == "3rd Touch") {
                assetName_touch3 = "3rd Touch";
                assetTimestamp_touch3 = "Yes";
                assetLinkArray[j] = {
                  ...assetLinkArray[j],
                  ["assetName_touch"]: assetName_touch3,
                  ["assetTimestamp_touch"]: assetTimestamp_touch3,
                };
              }
            }
            this.setState({ assetLinkArray: assetLinkArray });
          }

          let dynamicInput = [...this.state.dynamicInput]; // for showing Column for leadPerAsset Name & Value
          dynamicInput[i] = { ...dynamicInput[i], [name]: value };
          this.state.dynamicInput = [...dynamicInput];
        }
      } else {
        // For back flow
        const { name, value } = e.target;
        if (name === "leadPercentage") {
          linkflagBack = true;
          var leadAllocation = this.state.totalLeadAllocation;
          var assetLead1 = (value * leadAllocation) / 100;
          assetLead1 = Math.round(assetLead1);
          var assetWiseLead = 0;
          var name1 = "leadPerAsset";
          var assetLength = newassetfilenames.length;
          let backDynamicInput = [...this.state.backDynamicInput];
          backDynamicInput[i] = { ...backDynamicInput[i], [name1]: assetLead1 }; // for lead per asset
          backDynamicInput[i] = { ...backDynamicInput[i], [name]: value }; // for leadPercentage
          this.setState({ backDynamicInput });
          if (this.state.isIncompleteSuppDocStepAlreadyComplete) {
            this.setState({ dynamicInput: backDynamicInput });
          }
          // this.state.backDynamicInput=[...backDynamicInput];
          assetLinkLength = backDynamicInput.length;
        } else {
          let backDynamicInput = [...this.state.backDynamicInput];
          backDynamicInput[i] = { ...backDynamicInput[i], [name]: value };

          var assetName_touch1 = "";
          var assetName_touch2 = "";
          var assetName_touch3 = "";
          var assetTimestamp_touch1 = "";
          var assetTimestamp_touch2 = "";
          var assetTimestamp_touch3 = "";
          for (var i = 0; i < backDynamicInput.length; i++) {
            if (backDynamicInput[i].multiTouch == "1st Touch") {
              assetName_touch1 = "1st Touch";
              assetTimestamp_touch1 = "Yes";
              backDynamicInput[i] = {
                ...backDynamicInput[i],
                ["assetName_touch"]: assetName_touch1,
                ["assetTimestamp_touch"]: assetTimestamp_touch1,
              };
            }
            if (backDynamicInput[i].multiTouch == "2nd Touch") {
              assetName_touch2 = "2nd Touch";
              assetTimestamp_touch2 = "Yes";
              backDynamicInput[i] = {
                ...backDynamicInput[i],
                ["assetName_touch"]: assetName_touch2,
                ["assetTimestamp_touch"]: assetTimestamp_touch2,
              };
            }
            if (backDynamicInput[i].multiTouch == "3rd Touch") {
              assetName_touch3 = "3rd Touch";
              assetTimestamp_touch3 = "Yes";
              backDynamicInput[i] = {
                ...backDynamicInput[i],
                ["assetName_touch"]: assetName_touch3,
                ["assetTimestamp_touch"]: assetTimestamp_touch3,
              };
            }
          }

          this.setState({ backDynamicInput });
          if (this.state.isIncompleteSuppDocStepAlreadyComplete) {
            this.setState({ dynamicInput: backDynamicInput });
          }

          assetLinkLength = backDynamicInput.length;
        }
      }
    }
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the multiple ABM files uploads
   * @return Description return successfully files upload message
   */
  handleChangeMultipleFileOfABM(e) {
    let self = this;
    abmnames = [];
    let abmerror = {};

    var parsed = this.props.location.state;
    if (parsed !== undefined) {
      var campID;
      if (parsed.hasOwnProperty("campID")) {
        campID = parsed.campID;
      }
      e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
      e.setAttribute("type", "file"); //setting a attribute for type file added by Raunak
      e.setAttribute("multiple", true); //setting a attribute for multiple added by Raunak
      e.click(); //to open file dialougeadded by Raunak
      e.addEventListener(
        "change",
        function () {
          //calling an eventListener at change event added by Raunak

          self.setState({ displayTableabm: "block" });
          let abmfilesData = e.files;
          for (let i = 0, l = abmfilesData.length; i < l; i++) {
            let fileName = abmfilesData[i].name;
            if (chkABMExist.includes(fileName)) {
              duplicateABMFile.push(fileName);
            } else {
              abmnames.push(abmfilesData[i].name);
            }
          }
          if (chkABMExist != abmnames) {
            rfpChange++;
          }

          /**
           * @author Sanjana Godbole
           * @param  A FileList object that lists every selected file
           * @return List of uploded file along with remove link
           */

          var link;
          var filename = "";
          var abmarrayLength = abmnames.length;

          var nooutput = document.getElementById("noabmList");
          var valuenooutput = document.getElementById("noabmList").innerHTML;

          var res = new Array();
          res = valuenooutput.split(" ");

          var backarraylength;
          for (let i = 0; i < res.length; i++) {
            backarraylength = res[0];
          }
          // added by karan
          self.setState({
            backABMArrayLengthForUI: parseInt(backarraylength) || 0,
          });

          abmarrayLength = parseInt(abmarrayLength);
          backarraylength = parseInt(backarraylength);

          var arrayabmLength1;
          arrayabmLength1 = abmarrayLength + backarraylength;

          if (arrayabmLength1 > 0) {
            var allowedFiles = [".xls", ".xlsx", ".csv"];
            var regex = new RegExp(
              "([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
            );
            for (
              let i = 0;
              i < abmnames.length;
              i++ //iterating through abmnames array added by Raunak
            ) {
              if (!regex.test(abmnames[i].toLowerCase())) {
                //check each value and return if even one is wrong added by Raunak
                abmerror["abmerr1"] =
                  "Please upload files having extensions: " +
                  allowedFiles.join(", ") +
                  " only";

                this.setState({
                  abmerror: abmerror,
                });
                return;
              }
            }
            for (var j = 0; j < abmarrayLength; j++) {
              newabmfilenames.push(abmnames[j]);

              chkABMExist.push(abmnames[j]);
            }
            var HTML = "<table>";
            var FileExt;
            var imgsuccess;
            var countABM = 0;
            let abmFilesArrayUI = [...self.state.abmFilesSelectedDocuments];
            for (var j = 0; j < newabmfilenames.length; j++) {
              if (document.getElementById("abmError") !== null) {
                document.getElementById("abmError").innerHTML = "";
              }
              FileExt = newabmfilenames[j].split(".").pop();

              var allowedFiles = [".xls", ".xlsx", ".csv"];
              if (FileExt == "csv" || FileExt == "xlsx" || FileExt == "xls") {
              } else {
                countABM = countABM + 1;
              }
              var isExist = oldABMFileArray.includes(newabmfilenames[j]);

              let loading = true;
              let abmCount = "";
              let index = abmFilesArrayUI.findIndex(
                (item) =>
                  item.dataFileID === newabmfilenames[j] &&
                  item.loading === false
              );
              if (index > -1) {
                loading = false;
                abmCount = abmFilesArrayUI[index].count;
              }

              let toBeState;
              if (isExist === true) {
                toBeState = {
                  success: false,
                  dataFileID: newabmfilenames[j],
                  suppDocName: newabmfilenames[j],
                  typeOfSuppDoc: "ABM",
                  loading,
                  count: abmCount,
                };
              } else {
                toBeState = {
                  success: true,
                  suppDocName: newabmfilenames[j],
                  dataFileID: newabmfilenames[j],
                  typeOfSuppDoc: "ABM",
                  loading,
                  count: abmCount,
                };
              }
              abmFilesArrayUI = [
                ...abmFilesArrayUI.filter(
                  (item) => item.dataFileID !== toBeState.dataFileID
                ),
                toBeState,
              ];
            }
            self.setState({ abmFilesSelectedDocuments: abmFilesArrayUI });
            HTML += "</table>";

            // commented by karan
            //document.getElementById("abmList").innerHTML = HTML;

            if (countABM > 0) {
              var msg2 =
                "Please upload files having extensions: " +
                allowedFiles.join(", ") +
                " only";
              var abmErrorFile = document.getElementById("abmError");

              abmErrorFile.innerHTML += msg2;
            }

            // commented by karan - is not used anymore. replacement function removeABMFileSelectedDocuments
            $(document).on("click", ".removeFile", function (e) {
              var clickedFile = $(this).parent().children("a").data("fileid");
              e.preventDefault();
              var supportDocID = $(this).attr("supportDocID");
              var j = 0;
              for (j; j < newabmfilenames.length; j++) {
                var temp = j;

                if (newabmfilenames[temp] === clickedFile) {
                  var isExist = oldABMFileArray.includes(newabmfilenames[j]);

                  if (isExist === true) {
                    var index = oldABMFileArray.indexOf(newabmfilenames[j]);

                    oldABMFileArray.splice(index, 1);
                  }

                  filename = newabmfilenames[temp];

                  newabmfilenames.splice(j, 1);

                  chkABMExist.splice(j, 1);

                  abmCountArray.splice(j, 1);

                  // commented by karan
                  self.setState({
                    abmFilesSelectedDocuments:
                      self.state.abmFilesSelectedDocuments.filter(
                        (item) => item.dataFileID !== clickedFile
                      ),
                  });
                  $(this).parent().remove();
                  abmarrayLength = newabmfilenames.length;

                  if (newabmfilenames.length == 0) {
                    oldABMFileArray = [];
                    rejectAbmCount = [];
                  }
                  for (var k = 0; k < rejectAbmCount.length; k++) {
                    if (rejectAbmCount[k] == clickedFile) {
                      rejectAbmCount.splice(k, 1);
                    }
                  }
                  for (var k = 0; k < successAbmCount.length; k++) {
                    if (successAbmCount[k] == clickedFile) {
                      successAbmCount.splice(k, 1);
                    }
                  }
                  var rejectedCount =
                    newabmfilenames.length - successAbmCount.length;
                  if (rejectedCount < 0) {
                    rejectedCount = 0;
                  }
                  var msg1 = "";
                  // "Successfully Uploads ABM Files (Uploaded Files :"+successAbmCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
                  var message1 = document.getElementById("abmMessage");

                  if (oldABMFileArray.length > 0) {
                  } else {
                    message1.innerHTML = msg1;
                  }

                  //  if($("#abmMessage").text()==="")
                  //   {message1.innerHTML += msg1;
                  //   }else{message1.innerHTML= msg1;}

                  /**
                   * @author Narendra Phadke
                   * @param  Delete File From Database
                   */
                  if (newabmfilenames.length == 0) {
                    self.setState({ displayTableabm: "none" });
                  }
                  var typeOfSuppDoc = "ABM";
                  let data = {
                    campID: campID,
                    names: filename,
                    typeOfSuppDoc: typeOfSuppDoc,
                    supportDocID,
                    step: "Create",
                  };

                  fetch("campaign/supportingDocumentDeleteFile", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    //mode:'no-cors',
                    //body: data
                    body: JSON.stringify(data),
                  });

                  abmarrayLength = parseInt(abmarrayLength);

                  backarraylength = parseInt(backarraylength);
                  arrayabmLength1 = abmarrayLength + backarraylength;
                  if (arrayabmLength1 > 0) {
                    nooutput.innerHTML =
                      arrayabmLength1 + "ABM  Files Selected";
                  } else {
                    nooutput.innerHTML = "0 ABM File Selected";
                    // $("#abm1").val('');
                    var message = document.getElementById("abmMessage");
                    message.innerHTML = "";
                  }
                } // end of  if(names[temp] === clickedFile)
              } // end of for
              let abmJson1 = [...self.state.abmJson];

              let abmJson = abmJson1.filter((a) => a.fileName != clickedFile);

              self.setState({ abmJson });
            }); //$(document).on

            var data = new FormData();
            data.append("campID", campID);
            //data.append("names",names);
            for (var i = 0, l = abmfilesData.length; i < l; i++) {
              let fileName = abmfilesData[i].name;
              if (duplicateABMFile.includes(fileName)) {
              } else {
                data.append("file", abmfilesData[i]);
              }
            }
            //campaign/supportingDocument
            fetch("campaign/supportingDocumentABM", {
              method: "POST",
              body: data,
            })
              .then(function (response) {
                if (response.status >= 400) {
                  throw new Error("Bad response from server");
                }
                return response.json();
              })
              .then(function (response) {
                let abmJson1 = [...self.state.abmJson];

                let abmJson = [...abmJson1, ...response];

                self.setState({ abmJson });
                if (response[0].success == true) {
                  var suppDocName = [];
                  var abmFileCount = [];
                  // var successAbmCount=[];
                  //  var suppDocName=response.seccessFileList;
                  for (let i = 0; i < response.length; i++) {
                    let { fileName, success } = response[i];
                    if (success == true) {
                      suppDocName.push(fileName);
                      successAbmCount.push(fileName);
                    }

                    abmCountArray.push(response[i]);

                    abmFileCount.push(response[i].abmCount);
                  }
                  for (let i = 0; i < suppDocName.length; i++) {
                    var count = 0;
                    for (let j = 0; j < newabmfilenames.length; j++) {
                      if (suppDocName[i] == newabmfilenames[j]) {
                        count = count + 1;
                      }
                    }
                  }
                  if (count > 1) {
                    newabmfilenames.pop();
                    oldABMFileArray.pop();
                  }
                  var RejectedCount = 0;
                  var HTML2 = "<table>";
                  var imgsuccess;
                  let abmFilesArrayUI = self.state.abmFilesSelectedDocuments;
                  for (let j = 0; j < newabmfilenames.length; j++) {
                    let singleAbm = abmJson.filter(
                      (a) => a.fileName == newabmfilenames[j]
                    );
                    let supportDocID = singleAbm[0].supportDocID;

                    var isExist = oldABMFileArray.includes(newabmfilenames[j]);
                    var abmCount = abmCountArray.filter(
                      (a) => a.fileName == newabmfilenames[j]
                    );

                    var cnt = abmCount[0].abmCount;
                    let toBeState;
                    if (isExist === true) {
                      /**** If uploaded file is wrong then it displays as in Red color */
                      toBeState = {
                        success: false,
                        supportDocID,
                        dataFileID: newabmfilenames[j],
                        count: cnt,
                        typeOfSuppDoc: "ABM",
                        loading: false,
                      };
                      RejectedCount = RejectedCount + 1;
                    } else {
                      toBeState = {
                        success: true,
                        supportDocID,
                        dataFileID: newabmfilenames[j],
                        count: cnt,
                        typeOfSuppDoc: "ABM",
                        loading: false,
                      };
                    }
                    abmFilesArrayUI = [
                      ...abmFilesArrayUI.filter(
                        (item) => item.dataFileID !== toBeState.dataFileID
                      ),
                      toBeState,
                    ];
                  }
                  self.setState({
                    abmFilesSelectedDocuments: abmFilesArrayUI,
                  });
                  HTML2 += "</table>";
                  // commented by karan
                  //document.getElementById("abmList").innerHTML = HTML2;
                  var msg = "";
                  //  "Successfully Uploads ABM Files ( Uploaded File :"+successAbmCount.length+", <font color='red'> Failed Files :"+RejectedCount+"</font>)";

                  var message = document.getElementById("abmMessage");

                  // document.getElementById("abmMessage").style.color="green";
                  var number = document.getElementById("abmMessage").innerHTML;

                  message.innerHTML = "";
                  message.innerHTML += msg;
                  nooutput.innerHTML = arrayabmLength1;
                } else {
                  var isError = response[0].Error;
                  for (let i = 0; i < response.length; i++) {
                    let { success, fileName } = response[i];
                    if (success == false) {
                      oldABMFileArray.push(fileName);
                    }
                    abmCountArray.push(response[i]);
                  }
                  var HTML1 = "<table>";
                  var imgsuccess;
                  let abmFilesArrayUI = self.state.abmFilesSelectedDocuments;
                  for (let j = 0; j < newabmfilenames.length; j++) {
                    let singleAbm = abmJson.filter(
                      (a) => a.fileName == newabmfilenames[j]
                    );

                    let supportDocID = singleAbm[0].supportDocID;

                    let isExist = oldABMFileArray.includes(newabmfilenames[j]);
                    var abmCount = abmCountArray.filter(
                      (a) => a.fileName == newabmfilenames[j]
                    );

                    var cnt = abmCount[0].abmCount;

                    let toBeState;
                    if (isExist === true) {
                      /**** If uploaded file is wrong then it displays as in Red color */
                      toBeState = {
                        success: false,
                        supportDocID,
                        dataFileID: newabmfilenames[j],
                        count: cnt,
                        typeOfSuppDoc: "ABM",
                        loading: false,
                      };
                    } else {
                      let isTrue = successAbmCount.includes(newabmfilenames[j]);
                      if (isTrue === false) {
                        successAbmCount.push(newabmfilenames[j]);
                      }
                      toBeState = {
                        success: true,
                        supportDocID,
                        dataFileID: newabmfilenames[j],
                        count: cnt,
                        typeOfSuppDoc: "ABM",
                        loading: false,
                      };
                    }
                    abmFilesArrayUI = [
                      ...abmFilesArrayUI.filter(
                        (item) => item.dataFileID !== toBeState.dataFileID
                      ),
                      toBeState,
                    ];
                  }
                  self.setState({
                    abmFilesSelectedDocuments: abmFilesArrayUI,
                  });
                  HTML1 += "</table>";
                  // commented by karan
                  //document.getElementById("abmList").innerHTML = HTML1;
                  if (isError === "Yes") {
                    var rejectedCount =
                      newabmfilenames.length - successAbmCount.length;
                    var msg1 = "";

                    // "<font color='green'>Successfully Uploads Asset Files </font>(Uploaded Files :"+(successAbmCount.length)+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
                  } else {
                    var msg1 =
                      "<font color='red'>Please Upload Valid Header File </font>";
                  }
                  var message1 =
                    document.getElementById("abmMessage").innerHTML;

                  if (message1 == "") {
                    document.getElementById("abmMessage").innerHTML = msg1;
                  } else {
                    document.getElementById("abmMessage").innerHTML = msg1;
                  }
                }
              })
              .catch(function (err) {
                console.log(err);
              });

            this.setState({ files: data, abmerror: "" });
            nooutput.innerHTML = arrayabmLength1;
          } else {
            nooutput.innerHTML = "Please select file in excel format";
          }
        }.bind(this)
      ); //binding this function to use setstate added by raunak
    }
  }

  /**
   * @author : Karan Jagtap
   * @description : removes the file element from UI part when file removed from ABM uploaded file section
   */
  removeABMFileSelectedDocuments = (clickedFile, supportDocID) => {
    let nooutput = document.getElementById("noabmList");
    let parsed = this.props.location.state;
    if (parsed !== undefined) {
      let campID;
      if (parsed.hasOwnProperty("campID")) {
        campID = parsed.campID;
      }
      var j = 0;
      for (j; j < newabmfilenames.length; j++) {
        var temp = j;
        if (newabmfilenames[temp] === clickedFile) {
          var isExist = oldABMFileArray.includes(newabmfilenames[j]);
          if (isExist === true) {
            var index = oldABMFileArray.indexOf(newabmfilenames[j]);
            oldABMFileArray.splice(index, 1);
          }
          let filename = newabmfilenames[temp];
          newabmfilenames.splice(j, 1);
          chkABMExist.splice(j, 1);
          abmCountArray.splice(j, 1);
          // commented by karan
          //$(this).parent().remove();
          let abmarrayLength = newabmfilenames.length;

          if (newabmfilenames.length == 0) {
            oldABMFileArray = [];
            rejectAbmCount = [];
          }
          for (var k = 0; k < rejectAbmCount.length; k++) {
            if (rejectAbmCount[k] == clickedFile) {
              rejectAbmCount.splice(k, 1);
            }
          }
          for (var k = 0; k < successAbmCount.length; k++) {
            if (successAbmCount[k] == clickedFile) {
              successAbmCount.splice(k, 1);
            }
          }
          var rejectedCount = newabmfilenames.length - successAbmCount.length;
          if (rejectedCount < 0) {
            rejectedCount = 0;
          }
          var msg1 = "";
          // "Successfully Uploads ABM Files (Uploaded Files :"+successAbmCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
          var message1 = document.getElementById("abmMessage");
          if (oldABMFileArray.length > 0) {
          } else {
            message1.innerHTML = msg1;
          }

          //  if($("#abmMessage").text()==="")
          //   {message1.innerHTML += msg1;
          //   }else{message1.innerHTML= msg1;}

          /**
           * @author Narendra Phadke
           * @param  Delete File From Database
           */
          if (newabmfilenames.length == 0) {
            this.setState({ displayTableabm: "none" });
          }
          var typeOfSuppDoc = "ABM";
          let data = {
            campID: campID,
            names: filename,
            typeOfSuppDoc: typeOfSuppDoc,
            supportDocID,
            step: "Create",
          };

          fetch("campaign/supportingDocumentDeleteFile", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            //mode:'no-cors',
            //body: data
            body: JSON.stringify(data),
          });

          abmarrayLength = parseInt(abmarrayLength);

          let backarraylength =
            parseInt(this.state.backABMarraylengthForUI) || 0;
          let arrayabmLength1 = abmarrayLength + backarraylength;
          if (arrayabmLength1 > 0) {
            //commented by karan
            nooutput.innerHTML = arrayabmLength1 + "ABM  Files Selected";
          } else {
            //commented by karan
            nooutput.innerHTML = "0 ABM File Selected";
            // $("#abm1").val('');
            var message = document.getElementById("abmMessage");
            message.innerHTML = "";
          }
        } // end of  if(names[temp] === clickedFile)
      } // end of for
      let abmJson1 = [...this.state.abmJson];
      let abmJson = abmJson1.filter((a) => a.fileName != clickedFile);
      this.setState({
        abmJson,
        abmFilesSelectedDocuments: this.state.abmFilesSelectedDocuments.filter(
          (item) => item.dataFileID !== clickedFile
        ),
      });
    }
  };

  /**
   * @author Narendra Phadke
   * @param  Description handle the multiple exclusion files uploads
   * @return Description return successfully files upload message
   */
  handleChangeMultipleFileOfExclusion(e) {
    execlusionnames = [];
    let exclusionerror = {};
    let self = this;

    e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
    e.setAttribute("type", "file"); //setting a attribute for type file added by Raunak
    e.setAttribute("multiple", true); //setting a attribute for multiple added by Raunak
    e.click(); //to open file dialougeadded by Raunak
    e.addEventListener(
      "change",
      function () {
        //calling an eventListener at change event added by Raunak

        var parsed = this.props.location.state;
        if (parsed !== undefined) {
          var campID;
          if (parsed.hasOwnProperty("campID")) {
            campID = parsed.campID;
          }
          self.setState({ displayTableexclusion: "block" });
          let execlusionfilesData = e.files;
          for (let i = 0, l = execlusionfilesData.length; i < l; i++) {
            let fileName = execlusionfilesData[i].name;

            if (chkExclusionExist.includes(fileName)) {
              duplicateExclusionFile.push(fileName);
            } else {
              execlusionnames.push(fileName);
            }
          }

          if (chkExclusionExist != execlusionnames) {
            rfpChange++;
          }

          /**
           * @author Sanjana Godbole
           * @param  A FileList object that lists every selected file
           * @return List of uploded file along with remove link
           */
          var link;
          var filename = "";
          var execlusionarrayLength = execlusionnames.length;
          var nooutputexeclusion = document.getElementById("noexclusionList");
          var valuenooutputexeclusion =
            document.getElementById("noexclusionList").innerHTML;

          var resexeclusion = new Array();
          resexeclusion = valuenooutputexeclusion.split(" ");

          var backecelusionarraylength;
          for (let i = 0; i < resexeclusion.length; i++) {
            backecelusionarraylength = resexeclusion[0];
          }
          self.setState({
            backExclusionArrayLengthForUI: backecelusionarraylength,
          });

          execlusionarrayLength = parseInt(execlusionarrayLength);
          backecelusionarraylength = parseInt(backecelusionarraylength);

          var arrayexeclusionLength1 =
            execlusionarrayLength + backecelusionarraylength;
          if (arrayexeclusionLength1 > 0) {
            var allowedFiles = [".xls", ".xlsx", ".csv"];
            var regex = new RegExp(
              "([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
            );
            for (let i = 0; i < execlusionnames.length; i++) {
              if (!regex.test(execlusionnames[i].toLowerCase())) {
                exclusionerror["exclusionerr"] =
                  "Please upload files having extensions: " +
                  allowedFiles.join(", ") +
                  " only";
                this.setState({
                  exclusionerror: exclusionerror,
                });
                return;
              }
            }

            for (var j = 0; j < execlusionarrayLength; j++) {
              newexclusionfilenames.push(execlusionnames[j]);
              chkExclusionExist.push(execlusionnames[j]);
            }

            var HTML = "<table>";
            var FileExt;
            var countExclusion = 0;
            var imgsuccess;
            let exclusionFilesArrayUI =
              self.state.exclusionFilesSelectedDocuments;
            for (var j = 0; j < newexclusionfilenames.length; j++) {
              if (document.getElementById("exclusionError") !== null) {
                document.getElementById("exclusionError").innerHTML = "";
              }

              FileExt = newexclusionfilenames[j].split(".").pop();

              var allowedFiles = [".xls", ".xlsx", ".csv"];
              if (FileExt == "csv" || FileExt == "xlsx" || FileExt == "xls") {
              } else {
                countExclusion = countExclusion + 1;
              }

              var isExist = exclusionValidationArray.includes(
                newexclusionfilenames[j]
              );

              let loading = true;
              let index = exclusionFilesArrayUI.findIndex(
                (item) =>
                  item.dataFileID === newexclusionfilenames[j] &&
                  item.loading === false
              );
              if (index > -1) {
                loading = false;
              }

              let toBeState;
              if (isExist === true) {
                toBeState = {
                  success: false,
                  dataFileID: newexclusionfilenames[j],
                  suppDocName: newexclusionfilenames[j],
                  typeOfSuppDoc: "Exclusion",
                  loading,
                };
              } else {
                toBeState = {
                  success: true,
                  dataFileID: newexclusionfilenames[j],
                  suppDocName: newexclusionfilenames[j],
                  typeOfSuppDoc: "Exclusion",
                  loading,
                };
              }
              exclusionFilesArrayUI = [
                ...exclusionFilesArrayUI.filter(
                  (item) => item.dataFileID !== toBeState.dataFileID
                ),
                toBeState,
              ];
            }
            self.setState({
              exclusionFilesSelectedDocuments: exclusionFilesArrayUI,
            });
            HTML += "</table>";

            // commented by karan
            // document.getElementById("exclusionList").innerHTML = HTML;
            if (countExclusion > 0) {
              var msg2 =
                "Please upload files having extensions: " +
                allowedFiles.join(", ") +
                " only";
              var exclusionErrorFile =
                document.getElementById("exclusionError");

              exclusionErrorFile.innerHTML += msg2;
            }

            var data = new FormData();
            data.append("campID", campID);
            //data.append("names",names);

            for (var i = 0, l = execlusionfilesData.length; i < l; i++) {
              let fileName = execlusionfilesData[i].name;
              if (duplicateExclusionFile.includes(fileName)) {
              } else {
                data.append("file", execlusionfilesData[i]);
              }
            }

            //campaign/supportingDocument

            fetch("campaign/supportingDocumentExclusion", {
              method: "POST",
              body: data,
            })
              .then((res) => res.json())
              .then((exclusionList) => {
                let exclusionJson1 = [...self.state.exclusionJson];
                let exclusionJson = [...exclusionJson1, ...exclusionList];
                self.setState({ exclusionJson });
                if (exclusionList[0].success == true) {
                  var suppDocName = [];
                  for (let i = 0; i < exclusionList.length; i++) {
                    let { success, fileName } = exclusionList[i];
                    if (success === true) {
                      suppDocName.push(fileName);
                      successExclusionCount.push(fileName);
                    }
                  }
                  for (let i = 0; i < suppDocName.length; i++) {
                    var count = 0;
                    for (let j = 0; j < newexclusionfilenames.length; j++) {
                      if (suppDocName[i] == newexclusionfilenames[j]) {
                        count = count + 1;
                      }
                    }
                  }

                  if (count > 1) {
                    newexclusionfilenames.pop();
                    exclusionValidationArray.pop();
                  }
                  var RejectCount = 0;
                  var HTML2 = "<table>";
                  var imgsuccess;
                  let exclusionFilesArrayUI =
                    self.state.exclusionFilesSelectedDocuments;
                  for (let j = 0; j < newexclusionfilenames.length; j++) {
                    let singleFile = exclusionJson.filter(
                      (a) => a.fileName == newexclusionfilenames[j]
                    );
                    let supportDocID = singleFile[0].supportDocID;
                    var isExist = exclusionValidationArray.includes(
                      newexclusionfilenames[j]
                    );
                    let toBeState;
                    if (isExist === true) {
                      toBeState = {
                        success: false,
                        supportDocID,
                        dataFileID: newexclusionfilenames[j],
                        suppDocName: newexclusionfilenames[j],
                        typeOfSuppDoc: "Exclusion",
                        loading: false,
                      };
                      /**** If uploaded file is wrong then it displays as in Red color */
                      RejectCount = RejectCount + 1;
                    } else {
                      toBeState = {
                        success: true,
                        supportDocID,
                        dataFileID: newexclusionfilenames[j],
                        suppDocName: newexclusionfilenames[j],
                        typeOfSuppDoc: "Exclusion",
                        loading: false,
                      };
                    }
                    exclusionFilesArrayUI = [
                      ...exclusionFilesArrayUI.filter(
                        (item) => item.dataFileID !== toBeState.dataFileID
                      ),
                      toBeState,
                    ];
                  }
                  self.setState({
                    exclusionFilesSelectedDocuments: exclusionFilesArrayUI,
                  });
                  HTML2 += "</table>";
                  // commented by karan
                  //document.getElementById("exclusionList").innerHTML = HTML2;
                  var msg = "";
                  //  "Successfully Uploads Exclusion Files (Uploaded Files :"+(successExclusionCount.length)+", <font color='red'> Failed Files :"+RejectCount+"</font>)";

                  var message = document.getElementById("exclusionMessage");
                  //  document.getElementById("exclusionMessage").style.color="green";
                  var number =
                    document.getElementById("exclusionMessage").innerHTML;

                  message.innerHTML = "";
                  message.innerHTML += msg;
                  nooutputexeclusion.innerHTML = arrayexeclusionLength1;
                } else {
                  var isError = exclusionList[0].Error;
                  for (let i = 0; i < exclusionList.length; i++) {
                    let { success, fileName } = exclusionList[i];
                    if (success === false) {
                      exclusionValidationArray.push(fileName);
                    }
                  }
                  var HTML = "<table>";
                  var imgsuccess;
                  let exclusionFilesArrayUI =
                    self.state.exclusionFilesSelectedDocuments;
                  for (let j = 0; j < newexclusionfilenames.length; j++) {
                    let singleFile = exclusionJson.filter(
                      (a) => a.fileName == newexclusionfilenames[j]
                    );
                    let supportDocID = singleFile[0].supportDocID;
                    var isValid = exclusionValidationArray.includes(
                      newexclusionfilenames[j]
                    );
                    let toBeState;
                    if (isValid === true) {
                      toBeState = {
                        success: false,
                        supportDocID,
                        dataFileID: newexclusionfilenames[j],
                        suppDocName: newexclusionfilenames[j],
                        typeOfSuppDoc: "Exclusion",
                        loading: false,
                      };
                    } else {
                      toBeState = {
                        success: true,
                        supportDocID,
                        dataFileID: newexclusionfilenames[j],
                        suppDocName: newexclusionfilenames[j],
                        typeOfSuppDoc: "Exclusion",
                        loading: false,
                      };
                      let isTrue = successExclusionCount.includes(
                        newexclusionfilenames[j]
                      );
                      if (isTrue === false) {
                        successExclusionCount.push(newexclusionfilenames[j]);
                      }
                    }
                    exclusionFilesArrayUI = [
                      ...exclusionFilesArrayUI.filter(
                        (item) => item.dataFileID !== toBeState.dataFileID
                      ),
                      toBeState,
                    ];
                  }
                  self.setState({
                    exclusionFilesSelectedDocuments: exclusionFilesArrayUI,
                  });
                  HTML += "</table>";
                  var countReject =
                    newexclusionfilenames.length - successExclusionCount.length;

                  // commented by karan
                  //document.getElementById("exclusionList").innerHTML = HTML;
                  if (isError == "Yes") {
                    var msg1 = "";
                  } else {
                    var msg1 =
                      "<font color='Red'>Please Upload Valid Header File </font>";
                  }
                  var message1 = document.getElementById("exclusionMessage");
                  if (message1 == "") {
                    document.getElementById("exclusionMessage").innerHTML =
                      msg1;
                  } else {
                    document.getElementById("exclusionMessage").innerHTML =
                      msg1;
                  }
                }
              })
              .catch(function (err) {
                console.log(err);
              });
            this.setState({
              files: data,
              exclusionerror: "",
            });
            nooutputexeclusion.innerHTML = arrayexeclusionLength1;
          } else {
            nooutputexeclusion.innerHTML = " No Exclusion File Selected";
          }
        }
      }.bind(this)
    );
  }

  /**
   * @author : Karan Jagtap
   * @description : removes the file element from UI part when file removed from Exclusion uploaded file section
   */
  removeExclusionFileSelectedDocuments = (clickedFile, supportDocID) => {
    let parsed = this.props.location.state;
    if (parsed !== undefined) {
      let campID;
      if (parsed.hasOwnProperty("campID")) {
        campID = parsed.campID;
      }
      var j = 0;
      for (j = 0; j < newexclusionfilenames.length; j++) {
        var temp = j;

        if (newexclusionfilenames[temp] === clickedFile) {
          var isExist = exclusionValidationArray.includes(
            newexclusionfilenames[j]
          );

          if (isExist === true) {
            var index = exclusionValidationArray.indexOf(
              newexclusionfilenames[j]
            );
            exclusionValidationArray.splice(index, 1);
          }

          let filename = newexclusionfilenames[temp];

          newexclusionfilenames.splice(j, 1);
          chkExclusionExist.splice(j, 1);
          // commented by karan
          //$(this).parent().remove();
          let execlusionarrayLength = newexclusionfilenames.length;
          if (newexclusionfilenames.length == 0) {
            exclusionValidationArray = [];
            rejectExclusionCount = [];
            // successExclusionCount=[];
          }
          for (var k = 0; k < rejectExclusionCount.length; k++) {
            if (rejectExclusionCount[k] == clickedFile) {
              rejectExclusionCount.splice(k, 1);
            }
          }
          for (var k = 0; k < successExclusionCount.length; k++) {
            if (successExclusionCount[k] == clickedFile) {
              successExclusionCount.splice(k, 1);
            }
          }
          var rejectedCount =
            newexclusionfilenames.length - successExclusionCount.length;
          if (rejectedCount < 0) {
            rejectedCount = 0;
          }

          var msg1 = "";
          // "Successfully Uploads Exclusion Files (Uploaded Files :"+successExclusionCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
          var message1 = document.getElementById("exclusionMessage");

          if (exclusionValidationArray.length > 0) {
          } else {
            message1.innerHTML = msg1;
          }

          /**
           * @author Narendra Phadke
           * @param  Delete File From Database
           */
          if (newexclusionfilenames.length == 0) {
            this.setState({ displayTableexclusion: "none" });
          }
          var typeOfSuppDoc = "Exclusion";
          let data = {
            campID: campID,
            names: filename,
            typeOfSuppDoc: typeOfSuppDoc,
            supportDocID,
            step: "Create",
          };

          fetch("campaign/supportingDocumentDeleteFile", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            //mode:'no-cors',
            //body: data
            body: JSON.stringify(data),
          });
          execlusionarrayLength = parseInt(execlusionarrayLength);
          let backecelusionarraylength = parseInt(
            this.state.backExclusionArrayLengthForUI
          );

          let arrayexeclusionLength1 =
            execlusionarrayLength + backecelusionarraylength;

          if (arrayexeclusionLength1 > 0) {
            //commented by karan
            //nooutputexeclusion.innerHTML = arrayexeclusionLength1;
          } else {
            //commented by karan
            //nooutputexeclusion.innerHTML = "0 Exclusion File Selected";

            var message = document.getElementById("exclusionMessage");
            message.innerHTML = "";
          }
        } // end of  if(names[temp] === clickedFile)
      } // end of for
      let exclusionJson1 = [...this.state.exclusionJson];
      let exclusionJson = exclusionJson1.filter(
        (a) => a.supportDocID != supportDocID
      );
      this.setState({
        exclusionJson,
        exclusionFilesSelectedDocuments:
          this.state.exclusionFilesSelectedDocuments.filter(
            (item) => item.dataFileID !== clickedFile
          ),
      });
    }
  };

  /**
   * @author Snehal More
   * @param  Description handle change of exclude domain (//snehal-task3620-Add exclude domains)
   * @return Description to set tha values of exclude domain
   */
  excludeTextChange(e) {
    const { name, value } = e.target;
    this.setState({
      excludeText: value,
    });
  }
  /**
   * @author Narendra Phadke
   * @param  Description handle the multiple supperssion files uploads
   * @return Description return successfully files upload message
   */
  handleChangeMultipleFileOfSuppression(e) {
    let suppressionerror = {};
    let self = this;
    var parsed = this.props.location.state;
    if (parsed !== undefined) {
      var campID;
      if (parsed.hasOwnProperty("campID")) {
        campID = parsed.campID;
      }
      e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
      e.setAttribute("type", "file"); //setting a attribute for type file added by Raunak
      e.setAttribute("multiple", true); //setting a attribute for multiple added by Raunak
      e.click(); //to open file dialougeadded by Raunak
      e.addEventListener(
        "change",
        function () {
          //calling an eventListener at change event added by Raunak
          suppressionnames = [];

          self.setState({ displayTablesuppression: "block" });

          let suppressionfilesData = e.files;
          for (let i = 0, l = suppressionfilesData.length; i < l; i++) {
            let fileName = suppressionfilesData[i].name;
            if (chkSuppressionExist.includes(fileName)) {
              duplicateSuppressionFile.push(fileName);
            } else {
              suppressionnames.push(fileName);
            }
          }

          if (chkSuppressionExist != suppressionnames) {
            rfpChange++;
          }

          /**
           * @author Sanjana Godbole
           * @param  A FileList object that lists every selected file
           * @return List of uploded file along with remove link
           */

          var link;
          var filename = "";
          var suppressionarrayLength = suppressionnames.length;

          var nosuppressionoutput =
            document.getElementById("nosuppressionList");
          var valuenosuppressionoutput =
            document.getElementById("nosuppressionList").innerHTML;

          var suppressionres = new Array();
          suppressionres = valuenosuppressionoutput.split(" ");

          var backsuppressionarraylength;
          for (let i = 0; i < suppressionres.length; i++) {
            if (suppressionres[0] === " " || suppressionres[0] == "") {
              backsuppressionarraylength = suppressionres[1];
            } else {
              backsuppressionarraylength = suppressionres[0];
            }
          }
          self.setState({
            backSuppressionArrayLengthForUI: backsuppressionarraylength,
          });

          suppressionarrayLength = parseInt(suppressionarrayLength);
          backsuppressionarraylength = parseInt(backsuppressionarraylength);

          var suppressionarrayLength1;

          backsuppressionarraylength = backsuppressionarraylength || 0;
          suppressionarrayLength1 =
            suppressionarrayLength + backsuppressionarraylength;
          if (suppressionarrayLength1 >= 1) {
            var allowedFiles = [".xls", ".xlsx", ".csv"];
            var regex = new RegExp(
              "([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
            );
            for (let i = 0; i < suppressionnames.length; i++) {
              if (!regex.test(suppressionnames[i].toLowerCase())) {
                suppressionerror["suppressionerr"] =
                  "Please upload files having extensions: " +
                  allowedFiles.join(", ") +
                  " only";
                this.setState({
                  suppressionerror: suppressionerror,
                });
                return;
              }
            }

            for (var j = 0; j < suppressionarrayLength; j++) {
              newsuppressionfilenames.push(suppressionnames[j]);
              chkSuppressionExist.push(suppressionnames[j]);
            }

            var FileExt;
            var HTML = "<table>";
            var imgsuccess;
            var countSuppressison = 0;
            let suppressionFilesArrayUI =
              self.state.suppressionFilesSelectedDocuments;
            for (var j = 0; j < newsuppressionfilenames.length; j++) {
              if (document.getElementById("suppressionError") !== null) {
                document.getElementById("suppressionError").innerHTML = "";
              }
              FileExt = newsuppressionfilenames[j].split(".").pop();

              var allowedFiles = [".xls", ".xlsx", ".csv"];
              if (FileExt == "csv" || FileExt == "xlsx" || FileExt == "xls") {
              } else {
                countSuppressison = countSuppressison + 1;
              }
              var isExist = suppValidationArray.includes(
                newsuppressionfilenames[j]
              );

              let loading = true;
              let index = suppressionFilesArrayUI.findIndex(
                (item) =>
                  item.dataFileID === newsuppressionfilenames[j] &&
                  item.loading === false
              );
              if (index > -1) {
                loading = false;
              }

              let toBeState;
              if (isExist === true) {
                toBeState = {
                  success: false,
                  dataFileID: newsuppressionfilenames[j],
                  suppDocName: newsuppressionfilenames[j],
                  typeOfSuppDoc: "Suppression",
                  loading,
                };
              } else {
                toBeState = {
                  success: true,
                  dataFileID: newsuppressionfilenames[j],
                  suppDocName: newsuppressionfilenames[j],
                  typeOfSuppDoc: "Suppression",
                  loading,
                };
              }
              suppressionFilesArrayUI = [
                ...suppressionFilesArrayUI.filter(
                  (item) => item.dataFileID !== toBeState.dataFileID
                ),
                toBeState,
              ];
            }
            self.setState({
              suppressionFilesSelectedDocuments: suppressionFilesArrayUI,
            });
            HTML += "</table>";

            // commented by karan
            //document.getElementById("suppressionList").innerHTML = HTML;
            if (countSuppressison > 0) {
              var msg3 =
                "Please upload files having extensions: " +
                allowedFiles.join(", ") +
                " only";
              var suppressionErrorFile =
                document.getElementById("suppressionError");

              suppressionErrorFile.innerHTML += msg3;
            }

            var data = new FormData();
            data.append("campID", campID);
            //data.append("names",names);

            for (let i = 0, l = suppressionfilesData.length; i < l; i++) {
              let fileName = suppressionfilesData[i].name;
              if (duplicateSuppressionFile.includes(fileName)) {
              } else {
                data.append("file", suppressionfilesData[i]);
              }
            }

            //campaign/supportingDocument
            fetch("campaign/supportingDocumentSuppression", {
              method: "POST",
              //mode:'no-cors',
              body: data,
            })
              .then((res) => res.json())
              .then((suppList) => {
                let suppressionJson1 = [...self.state.suppressionJson];
                let suppressionJson = [...suppressionJson1, ...suppList];
                self.setState({ suppressionJson });
                if (suppList[0].success === true) {
                  var suppDocName = [];
                  for (let i = 0; i < suppList.length; i++) {
                    let { success, fileName } = suppList[i];
                    if (success === true) {
                      suppDocName.push(fileName);
                      successSuppressionCount.push(fileName);
                    }
                  }
                  for (let i = 0; i < suppDocName.length; i++) {
                    var count = 0;
                    for (var j = 0; j < newsuppressionfilenames.length; j++) {
                      if (suppDocName[i] == newsuppressionfilenames[j]) {
                        count = count + 1;
                      }
                    }
                  }

                  if (count > 1) {
                    newsuppressionfilenames.pop();
                    suppValidationArray.pop();
                  }
                  var RejectedCount = 0;
                  var HTML2 = "<table>";
                  var imgsuccess;
                  let suppressionFilesArrayUI =
                    self.state.suppressionFilesSelectedDocuments;
                  for (let j = 0; j < newsuppressionfilenames.length; j++) {
                    let singleFile = suppressionJson.filter(
                      (a) => a.fileName == newsuppressionfilenames[j]
                    );
                    let supportDocID = singleFile[0].supportDocID;
                    var isExist = suppValidationArray.includes(
                      newsuppressionfilenames[j]
                    );
                    let toBeState;
                    if (isExist === true) {
                      toBeState = {
                        success: false,
                        supportDocID,
                        dataFileID: newsuppressionfilenames[j],
                        suppDocName: newsuppressionfilenames[j],
                        typeOfSuppDoc: "Suppression",
                        loading: false,
                      };
                      /**** If uploaded file is wrong then it displays as in Red color */
                      RejectedCount = RejectedCount + 1;
                    } else {
                      toBeState = {
                        success: true,
                        supportDocID,
                        dataFileID: newsuppressionfilenames[j],
                        suppDocName: newsuppressionfilenames[j],
                        typeOfSuppDoc: "Suppression",
                        loading: false,
                      };
                    }
                    suppressionFilesArrayUI = [
                      ...suppressionFilesArrayUI.filter(
                        (item) => item.dataFileID !== toBeState.dataFileID
                      ),
                      toBeState,
                    ];
                  }
                  self.setState({
                    suppressionFilesSelectedDocuments: suppressionFilesArrayUI,
                  });
                  HTML2 += "</table>";
                  // commented by karan
                  //document.getElementById("suppressionList").innerHTML = HTML2;
                  var msg = "";
                  // "Successfully Uploads Suppression Files (Uploaded Files :"+(successSuppressionCount.length)+",<font color='red'>  Failed Files :"+RejectedCount+"</font>)";
                  var message = document.getElementById("suppresionMessage");
                  document.getElementById("suppresionMessage").style.color =
                    "green";
                  var number =
                    document.getElementById("suppresionMessage").innerHTML;
                  message.innerHTML = "";
                  message.innerHTML += msg;
                  nosuppressionoutput.innerHTML = suppressionarrayLength1;
                } else {
                  var isError = suppList[0].Error;
                  for (let i = 0; i < suppList.length; i++) {
                    let { success, fileName } = suppList[i];
                    if (success === false) {
                      suppValidationArray.push(fileName);
                    }
                  }

                  var HTML = "<table>";
                  var imgsuccess;
                  let suppressionFilesArrayUI =
                    self.state.suppressionFilesSelectedDocuments;
                  for (var j = 0; j < newsuppressionfilenames.length; j++) {
                    let singleFile = suppressionJson.filter(
                      (a) => a.fileName == newsuppressionfilenames[j]
                    );
                    let supportDocID = singleFile[0].supportDocID;
                    var isValid = suppValidationArray.includes(
                      newsuppressionfilenames[j]
                    );
                    let toBeState;
                    if (isValid === true) {
                      toBeState = {
                        success: false,
                        supportDocID,
                        dataFileID: newsuppressionfilenames[j],
                        suppDocName: newsuppressionfilenames[j],
                        typeOfSuppDoc: "Suppression",
                        loading: false,
                      };
                    } else {
                      toBeState = {
                        success: true,
                        supportDocID,
                        dataFileID: newsuppressionfilenames[j],
                        suppDocName: newsuppressionfilenames[j],
                        typeOfSuppDoc: "Suppression",
                        loading: false,
                      };
                      let isTrue = successSuppressionCount.includes(
                        newsuppressionfilenames[j]
                      );
                      if (isTrue === false) {
                        successSuppressionCount.push(
                          newsuppressionfilenames[j]
                        );
                      }
                    }
                    suppressionFilesArrayUI = [
                      ...suppressionFilesArrayUI.filter(
                        (item) => item.dataFileID !== toBeState.dataFileID
                      ),
                      toBeState,
                    ];
                  }
                  self.setState({
                    suppressionFilesSelectedDocuments: suppressionFilesArrayUI,
                  });
                  HTML += "</table>";
                  let rejectedCount =
                    newsuppressionfilenames.length -
                    successSuppressionCount.length;
                  // commented by karan
                  //document.getElementById("suppressionList").innerHTML = HTML;
                  if (isError === "Yes") {
                    var msg1 = "";
                    // "Successfully Uploads Suppression Files (Uploaded Files :"+(successSuppressionCount.length)+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
                  } else {
                    var msg1 =
                      "<font color='Red'>Please Upload Valid Header File</font>";
                  }

                  var message1 = document.getElementById("suppresionMessage");
                  if (message1 === "") {
                    message1.innerHTML += msg1;
                  } else {
                    message1.innerHTML = msg1;
                    // document.getElementById("suppresionMessage").style.color="red";
                    // document.getElementById("suppresionMessage").innerHTML= msg1;
                  }
                }
              })
              .catch(function (err) {
                console.log(err);
              });

            this.setState({
              files: data,
              suppressionerror: "",
            });

            //nosuppressionoutput.innerHTML = suppressionarrayLength1+" Suppression Files";
          } else {
            nosuppressionoutput.innerHTML = "";
          }
        }.bind(this)
      );
    }
  }

  /**
   * @author : Karan Jagtap
   * @description : removes the file element from UI part when file removed from ABM uploaded file section
   */
  removeSuppressionFileSelectedDocuments = (clickedFile, supportDocID) => {
    let parsed = this.props.location.state;
    if (parsed !== undefined) {
      var campID;
      if (parsed.hasOwnProperty("campID")) {
        campID = parsed.campID;
      }

      let j = 0;
      for (j; j < newsuppressionfilenames.length; j++) {
        var temp = j;

        if (newsuppressionfilenames[temp] === clickedFile) {
          var isExist = suppValidationArray.includes(
            newsuppressionfilenames[j]
          );
          if (isExist === true) {
            var index = suppValidationArray.indexOf(newsuppressionfilenames[j]);
            suppValidationArray.splice(index, 1);
          }

          let filename = newsuppressionfilenames[temp];

          newsuppressionfilenames.splice(j, 1);
          chkSuppressionExist.splice(j, 1);
          $(this).parent().remove();
          let suppressionarrayLength = newsuppressionfilenames.length;

          if (newsuppressionfilenames.length === 0) {
            suppValidationArray = [];
            rejectSuppressionCount = [];
            // successSuppressionCount=[];
          }
          for (var k = 0; k < rejectSuppressionCount.length; k++) {
            if (rejectSuppressionCount[k] == clickedFile) {
              rejectSuppressionCount.splice(k, 1);
            }
          }
          for (var k = 0; k < successSuppressionCount.length; k++) {
            if (successSuppressionCount[k] === clickedFile) {
              successSuppressionCount.splice(k, 1);
            }
          }
          var rejectedCount =
            newsuppressionfilenames.length - successSuppressionCount.length;
          if (rejectedCount < 0) {
            rejectedCount = 0;
          }
          var msg1 = "";
          // "Successfully Uploads Suppression Files (Uploaded Files :"+successSuppressionCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
          var message1 = document.getElementById("suppresionMessage");

          if (suppValidationArray.length > 0) {
          } else {
            message1.innerHTML = msg1;
          }

          /**
           * @author Narendra Phadke
           * @param  Delete File From Database
           */
          if (newsuppressionfilenames.length == 0) {
            this.setState({ displayTablesuppression: "none" });
          }

          var typeOfSuppDoc = "Suppression";
          let data = {
            campID: campID,
            names: filename,
            typeOfSuppDoc: typeOfSuppDoc,
            supportDocID,
            step: "Create",
          };

          fetch("campaign/supportingDocumentDeleteFile", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            //mode:'no-cors',
            //body: data
            body: JSON.stringify(data),
          });
          suppressionarrayLength = parseInt(suppressionarrayLength);
          let backsuppressionarraylength = parseInt(
            this.state.backSuppressionArrayLengthForUI
          );
          let suppressionarrayLength1 =
            suppressionarrayLength + backsuppressionarraylength;

          if (suppressionarrayLength1 > 0) {
            //commented by karan
            //nosuppressionoutput.innerHTML =	suppressionarrayLength1 + " Suppression  Files Selected";
          } else {
            //commented by karan
            //nosuppressionoutput.innerHTML = "";
            //  $("#suppression").val('');
            var message = document.getElementById("suppresionMessage");
            message.innerHTML = "";
          }
        } // end of  if(names[temp] === clickedFile)
      } // end of for
      let suppressionJson1 = [...this.state.suppressionJson];
      let suppressionJson = suppressionJson1.filter(
        (a) => a.supportDocID != supportDocID
      );
      this.setState({
        suppressionJson,
        suppressionFilesSelectedDocuments:
          this.state.suppressionFilesSelectedDocuments.filter(
            (item) => item.dataFileID !== clickedFile
          ),
      });
    }
  };

  /**
   * @auhthor Narendra Phadke
   * @param  Description handle the for checkbox selected dynamic
   * @return Description return All details of delivery format
   */
  handleChecked(e) {
    //New code
    let fields = this.state;
    const { name, value } = e.target;
    fields[e.target.name] = e.target.value;
    if (e.target.checked === true) {
      fields[e.target.name] = "Yes";
    } else {
      fields[e.target.name] = "No";
    }
    // this.setState({ isChecked: !this.state.isChecked }); raunak-2951-Prod Issue-Internal-Supporting Document Page-Custom mapping Format Dropdown issue- disabling because this particular key-value is disabled in state
  }

  /**
   * @auhthor Aman Shaikh - 4765
   * @param  Description handle the for checkbox selected dynamic
   * @return Description added City-state validation checkbox Div
   */
  handleCityStateValidationChecked() {
    const { cityStateValidationCheck } = this.state;
    const newValue = cityStateValidationCheck === "ON" ? "OFF" : "ON";
    this.setState({ cityStateValidationCheck: newValue });
  }

  /**
   * @auhthor Raunak Thakkar
   * @param  Description handle the checkbox for customize mapping
   * @return Description returns the updated value of the checkbox
   */
  setcustomizeMapping(e) {
    this.setState(
      {
        customizeMapping: e.target.checked === true ? "Yes" : "No",
      },
      () => {
        //karan-task-3059-changes the checkbox labels when customize mapping checbox is checked/unchecked
        this.customizeMappingFormatHandleChange();
      }
    );
  }
  handleChangeCheck(e) {
    let fields = this.state;
    const { name, value } = e.target;
    fields[e.target.name] = e.target.value;
    if (e.target.checked === true) {
      fields[e.target.name] = "Yes";
    } else {
      fields[e.target.name] = "No";
    }
    // this.setState({ isChecked: !this.state.isChecked }); raunak-2951-Prod Issue-Internal-Supporting Document Page-Custom mapping Format Dropdown issue- disabling because this particular key-value is disabled in state
  }
  /**
   * @author Snehal More
   * @param  Description handle the for format of customize mapping
   * @return Description format option for customize mapping
   */
  handleFormatChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    // alert("value -->" + value)
    //Nilesh - 5002- Add if else condition for non English
    let value1;
    if(value.includes('- Non English')){
      value1 = value.replace(" - Non English","");
    }
    else{
      value1 = value;
    }
    
    this.setState(
      {
        customizeMappingFormat: value1,
        sdEditDetail: this.state.sdEditDetail,
        newCustomizeMappingFormat:value
      },
      () => {
        //karan-task-3059-changes the checkbox labels when customize mapping dropdown is changed
        this.customizeMappingFormatHandleChange();
      }
    );
  }
  /**
   * @author Snehal More
   * @param  Description onclick for format of customize mapping
   * @return Description onclick function for poup of customize mapping
   */
  customizeMappingFormatHandleChange(e) {
    if (this.state.agencyCustomizeMapping.length > 0) {
      let customMapping = this.state.agencyCustomizeMapping.filter(
        (a) => a.format == this.state.customizeMappingFormat
      );
      //karan-task-3059-using agencyCustomizeMappingModal variable to handle the checkbox label changes, this is only for UI part
      // do not use agencyCustomizeMapping variable here, this might disrupt the original funcitonality
      //this.state.agencyCustomizeMapping.length = 0;
      this.setState({ agencyCustomizeMappingModal: customMapping });
    }
  }
  /**
   * @author Snehal More
   * @param  Description onclick for format of customize mapping
   * @return Description onclick function for cancel poup of customize mapping
   */
  customizeMappingCancleHandleChange(e) {
    let self = this;
    var parsed = this.props.location.state;
    const { isAuthenticated, user } = this.props.auth;
    var userID = user.id;
    if (parsed !== undefined) {
      var campID, rfpCampaignID;
      if (parsed.hasOwnProperty("campID")) {
        campID = parsed.campID;
      }
      if (parsed.hasOwnProperty("rfpCampaignID")) {
        rfpCampaignID = parsed.rfpCampaignID;
      }
      fetch("campaign/getAgencyCustomizeMapping?campID=" + campID + "")
        .then((res) => res.json())
        .then((agencyCustomizeMapping) => {
          this.setState({
            agencyCustomizeMapping: agencyCustomizeMapping,
            customizeMapping: "No",
          });
        });
    }
  }
  /**
   * @author Narendra Phadke
   * @param  Description handle the for Back Button Call
   * @return Description return Back Button
   */
  handleBackButton(e) {
    if (this.state.currentStep === 0) {
      var campID = this.state.campID;
      var rfpCampaignID = this.state.rfpCampaignID;
      if (rfpCampaignID) {
        this.props.history.push("/welcomePage", {
          campID,
          rfpCampaignID,
          fromBack: true,
          rfpCampaign: "",
          step1: "",
        }); // karan-task-3933-vapt header and query params
      } else {
        this.props.history.push("/welcomePage", {
          campID,
          fromBack: true,
          step1: "",
        }); // karan-task-3933-vapt header and query params
      }
    } else {
      if (this.state.isIncompleteSuppDocStepAlreadyComplete) {
        this.componentWillMount();
      }
      // 3126 - karan - added this because the changed file name was not changed in "Selected Files" column
      if (this.state.step1SuppDocSaved) {
        fetch(
          "/campaign/supportingDocumentForBack?campID=" + this.state.campID + ""
        )
          .then((res) => res.json())
          .then((sdEditDetail) => {
            let campaignStatus;
            if (sdEditDetail.length > 0) {
              campaignStatus = sdEditDetail[0].campaignStatus;
            }
            this.setState({
              sdEditDetail: sdEditDetail,
              campaignStatus,
            });

            if (
              this.state.sdEditDetail != undefined ||
              this.state.sdEditDetail != "" ||
              this.state.sdEditDetail != null
            ) {
              let assetFilesArrayUI = [];
              let inputType = [...this.state.inputType];
              let successAssetCount = 0;
              for (let i = 0; i < this.state.sdEditDetail.length; i++) {
                if (this.state.sdEditDetail[i].typeOfSuppDoc == "Asset") {
                  successAssetCount++;
                  key++;
                  inputType = [
                    ...inputType.filter(
                      (item) =>
                        item.suppDocName !==
                          this.state.sdEditDetail[i].suppDocName &&
                        item.assetFileName !==
                          this.state.sdEditDetail[i].suppDocName
                    ),
                    {
                      suppDocName: this.state.sdEditDetail[i].suppDocName,
                      leadPerAsset: this.state.sdEditDetail[i].leadPerAsset,
                      key: key,
                      leadPercentage: this.state.sdEditDetail[i].leadPercentage,
                      multiTouch: this.state.sdEditDetail[i].multiTouch,
                    },
                  ];
                  assetFilesArrayUI = [
                    ...assetFilesArrayUI.filter(
                      (item) =>
                        item.suppDocName !==
                        this.state.sdEditDetail[i].suppDocName
                    ),

                    {
                      key: key,
                      success: true,
                      suppDocName: this.state.sdEditDetail[i].suppDocName,
                      leadPerAsset: this.state.sdEditDetail[i].leadPerAsset,
                      leadPercentage: this.state.sdEditDetail[i].leadPercentage,
                      multiTouch: this.state.sdEditDetail[i].multiTouch,
                      supportDocID: this.state.sdEditDetail[i].supportDocID,
                      typeOfSuppDoc: this.state.sdEditDetail[i].typeOfSuppDoc,
                    },
                  ];
                }
              }
              this.setState({
                inputType,
                assetFilesSelectedDocuments: assetFilesArrayUI,
                assetFilesUploadedSuccessCount: successAssetCount,
                assetFilesUploadedFailedCount: 0,
              });
              if (this.state.inputType.length > 0) {
                this.setState({ displayTable: "block" });
              }
            }
          });
      }
      this.onHandleStepChange(0);
    }
  }

  /**
   * @author : Karan Jagtap
   * @description : Step 1 save and next function
   */
  submitStep1 = () => {
    var parsed = this.props.location.state;
    if (parsed !== undefined) {
      let status, campID;
      var step2 = "na";
      if (parsed.hasOwnProperty("status")) {
        status = parsed.status;
      }
      if (parsed.hasOwnProperty("campID")) {
        campID = parsed.campID;
      }
      if (parsed.hasOwnProperty("step2")) {
        step2 = "step2";
      }
      if (parsed.hasOwnProperty("rfpCampaign")) {
        step2 = "rfpCampaign";
      }
      if (step2 === "rfpCampaign") {
        step2 = "step2";
      }
      if (
        step2 == undefined ||
        step2 == null ||
        step2 == "" ||
        (step2 !== "step2" && status !== "SupportingDocument")
      ) {
      } else {
        // var result=[];
        result = Array.from(
          new Set(this.state.dynamicAssetName.map((p) => p.key))
        ).map((key) => {
          return {
            key: key,
            suppDocName: this.state.dynamicAssetName.find((p) => p.key === key)
              .suppDocName,
            leadPerAsset: this.state.dynamicAssetName.find((p) => p.key === key)
              .leadPerAsset,
            leadPercentage: this.state.dynamicAssetName.find(
              (p) => p.key === key
            ).leadPercentage,
            multiTouch: this.state.dynamicAssetName.find((p) => p.key === key)
              .multiTouch,
          };
        });
        this.setState({ result: result }, function () {});
      }

      if (this.validateForm() == false) {
        return;
      } else {
        /*@author Narendra phadke
         *Desc Fetch campID from Url
         * dependencies query-string
         */

        this.setState({
          alertDisplay: "none",
        });
        if (step2 === "rfpCampaign") {
          step2 = "step2";
        }
        if (
          step2 == undefined ||
          step2 == null ||
          step2 == "" ||
          (step2 !== "step2" && status !== "SupportingDocument")
        ) {
          //Raunak-2881 following setState done to send asset file and asset link data to backend
          this.state.assetLinkArray = [...this.state.dynamicInput];
          this.state.assetFileNameArray = [...this.state.inputType];
          let data = {
            campID: campID,
            //  budget:this.state.campaignBudget,
            //  currency:this.state.currency,
            suppressionLinkArray: this.state.suppressionLinkArray,
            assetLinkArray: this.state.assetLinkArray,
            // assetLinkLeadArray:this.state.assetLinkLeadArray,
            assetFileName: this.state.assetFileNameArray,
            //assetLeadArray:this.state.assetLeadArray,
            assetsFile: this.state.inputType,
            requiredLeadPerAsset: this.state.leadPerAssetChk,
            multiTouch: this.state.multiTouchChk,
            assetJson: this.state.assetJson,
            abmJson: this.state.abmJson,
            exclusionJson: this.state.exclusionJson,
            suppressionJson: this.state.suppressionJson,
            otherJson: this.state.otherJson,
            excludedDomain: this.state.excludeText,
          };
          //snehal-task3620-Add exclude domains(added for exclude domain)
          //     let formData = new FormData();
          // var fileList=this.state.files;

          //      formData.append('campID', campID);
          //      formData.append('file', fileList);

          // alert("data send"+JSON.stringify(data));

          fetch("campaign/supportingDocument", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            //mode:'no-cors',
            body: JSON.stringify(data),
          })
            .then(function (response) {
              if (response.status >= 400) {
                throw new Error("Bad response from server");
              }
              return response.json();
            })
            .then((response) => {
              //fetch insertId because we AutoIncrement ID so first fetch insertId is a our autocreated ID
              var campID = response.campID;
              this.setState(
                { canProceedToStep2: true, step1SuppDocSaved: true },
                () => this.onHandleStepChange(1)
              );
              // window.location.href = '/finishCampaign?campID='+campID+'';
            })
            .catch(function (err) {
              console.log(err);
            });
        } else {
          //  In else Block for edit
          var inputType = [...this.state.inputType];
          var backDynamicInput = [...this.state.backDynamicInput];

          var assetTotalLead = 0;
          for (var i = 0; i < inputType.length; i++) {
            assetTotalLead =
              assetTotalLead + parseInt(inputType[i].leadPerAsset);
          }
          for (var i = 0; i < backDynamicInput.length; i++) {
            assetTotalLead =
              assetTotalLead + parseInt(backDynamicInput[i].leadPerAsset);
          }

          //Sanjana-2979 following setState done to send asset file and asset link data to backend
          this.state.dynamicAssetName = [...this.state.inputType];
          let data = {
            assetFileName: this.state.dynamicAssetName,
            dynamicInput: this.state.backDynamicInput,
            assetsFile: this.state.inputType,
            requiredLeadPerAsset: this.state.leadPerAssetChk,
            campID: campID,
            suppressionLink: this.state.suppressionLink,
            multiTouchEdit: this.state.multiTouchChkBack,
            assetJson: this.state.assetJson,
            abmJson: this.state.abmJson,
            exclusionJson: this.state.exclusionJson,
            suppressionJson: this.state.suppressionJson,
            otherJson: this.state.otherJson,
            excludedDomain: this.state.excludeText,
          };
          //snehal-task3620-Add exclude domains(added for exclude text)

          fetch("campaign/editSupportingDocument", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            //mode:'no-cors',
            body: JSON.stringify(data),
          })
            .then(function (response) {
              if (response.status >= 400) {
                throw new Error("Bad response from server");
              }
              return response.json();
            })
            .then((response) => {
              var campID = response.campID;
              this.setState(
                { canProceedToStep2: true, step1SuppDocSaved: true },
                () => this.onHandleStepChange(1)
              );
            })
            .catch(function (err) {
              console.log(err);
            });
        } /// end of else
      } // ens of validateForm true
    }
  };

  /**
   * @author : Karan Jagtap
   * @description : Step 2 save and finish function
   */
  submitStep2 = () => {
    var parsed = this.props.location.state;
    if (parsed !== undefined) {
      let status, campID;
      var step2 = "na";
      if (parsed.hasOwnProperty("status")) {
        status = parsed.status;
      }
      if (parsed.hasOwnProperty("campID")) {
        campID = parsed.campID;
      }
      if (parsed.hasOwnProperty("step2")) {
        step2 = "step2";
      }
      if (parsed.hasOwnProperty("rfpCampaign")) {
        step2 = "rfpCampaign";
      }

      if (step2 === "rfpCampaign") {
        step2 = "step2";
      }
      if (
        step2 == undefined ||
        step2 == null ||
        step2 == "" ||
        (step2 !== "step2" && status !== "SupportingDocument")
      ) {
      } else {
        // var result=[];
        result = Array.from(
          new Set(this.state.dynamicAssetName.map((p) => p.key))
        ).map((key) => {
          return {
            key: key,
            suppDocName: this.state.dynamicAssetName.find((p) => p.key === key)
              .suppDocName,
            leadPerAsset: this.state.dynamicAssetName.find((p) => p.key === key)
              .leadPerAsset,
            leadPercentage: this.state.dynamicAssetName.find(
              (p) => p.key === key
            ).leadPercentage,
            multiTouch: this.state.dynamicAssetName.find((p) => p.key === key)
              .multiTouch,
          };
        });
        this.setState({ result: result }, function () {});
      }

      if (this.validateForm() == false) {
        return;
      } else {
        /*@author Narendra phadke
         *Desc Fetch campID from Url
         * dependencies query-string
         */

        this.setState({
          alertDisplay: "none",
        });
        if (step2 === "rfpCampaign") {
          step2 = "step2";
        }
        if (
          step2 == undefined ||
          step2 == null ||
          step2 == "" ||
          (step2 !== "step2" && status !== "SupportingDocument")
        ) {
          let data11 = {
            campID: campID,
            switchValue: this.state.switchValue,
          };

          fetch("campaign/updateSwitchValue", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            //mode:'no-cors',
            body: JSON.stringify(data11),
          }).catch(function (err) {
            console.log(err);
          });
          //Raunak-2881 following setState done to send asset file and asset link data to backend
          this.state.assetLinkArray = [...this.state.dynamicInput];
          this.state.assetFileNameArray = [...this.state.inputType];

          //Narendra add this code here because some time it gives error and go into incomplete campaign
          let deliveryData = {
            campID: campID,
            companyName: this.state.companyName,
            linkedInCompanyName: this.state.linkedInCompanyName,
            pID: this.state.pID,
            assetId: this.state.assetId,
            campaignID: this.state.campaignID,
            leadInteractionDate: this.state.leadInteractionDate,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            jobTitleDelivery: this.state.jobTitleDelivery,
            linkedInJobTitle: this.state.linkedInJobTitle,
            jobTitleValidation: this.state.jobTitleValidation,
            jobFunctionDelivery: this.state.jobFunctionDelivery,
            address: this.state.address,
            jobLevelDelivery: this.state.jobLevelDelivery,
            assetName: this.state.assetName,

            email: this.state.email,
            workPhone: this.state.workPhone,
            street: this.state.street,
            city: this.state.city,
            state: this.state.state,
            countryDelivery: this.state.countryDelivery,
            zipCode: this.state.zipCode,
            companyEmployeeSize: this.state.companyEmployeeSize,
            companyRevenue: this.state.companyRevenue,
            industry: this.state.industry,
            ip: this.state.ip,
            extra1: this.state.extra1,
            extra2: this.state.extra2,
            extra3: this.state.extra3,
            extra4: this.state.extra4,
            extra5: this.state.extra5,

            extra6: this.state.extra6, //Sandeep-task-3158-added code for extra 15 fields from 6-20
            extra7: this.state.extra7,
            extra8: this.state.extra8,
            extra9: this.state.extra9,
            extra10: this.state.extra10,

            extra11: this.state.extra11,
            extra12: this.state.extra12,
            extra13: this.state.extra13,
            extra14: this.state.extra14,
            extra15: this.state.extra15,

            extra16: this.state.extra16,
            extra17: this.state.extra17,
            extra18: this.state.extra18,
            extra19: this.state.extra19,
            extra20: this.state.extra20,

            extra21: this.state.extra21, //Aman 5085 -set the other extra filed 21 to 30 value
            extra22: this.state.extra22,
            extra23: this.state.extra23,
            extra24: this.state.extra24,
            extra25: this.state.extra25,
            extra26: this.state.extra26,
            extra27: this.state.extra27,
            extra28: this.state.extra28,
            extra29: this.state.extra29,
            extra30: this.state.extra30,

            reAllocationID: this.state.reAllocationID,
            campaignName: this.state.campaignName,
            domain: this.state.domain,
            alternatePhoneNo: this.state.alternatePhoneNo,
            comments: this.state.comments,
            linkedIn: this.state.linkedIn,
            channel: this.state.channel,
            assetFileNameArray: this.state.assetFileNameArray,
            assetLinkArray: this.state.assetLinkArray,
            customizeMapping: this.state.customizeMapping,
            customizeMappingFormat: this.state.customizeMappingFormat,
            newCustomizeMappingFormat:this.state.newCustomizeMappingFormat, //Nilesh-5002- add newCustomizeMappingFormat
            multiTouch: this.state.multiTouchChk,
            rfpCamp: this.state.rfpCampaignID,
            format: this.state.format,
            countryAbbreviation: this.state.countryAbbreviation, //rutja task-4687 pass country abbreviation
            cityStateValidationCheck: this.state.cityStateValidationCheck, //task-4765 added City-state validation checkbox
            // customQuestion1:this.state.customQuestion1,
            // customQuestion2:this.state.customQuestion2,
            // customQuestion3:this.state.customQuestion3,
            // customQuestion4:this.state.customQuestion4,
            // customQuestion5:this.state.customQuestion5,
            // customQuestion6:this.state.customQuestion6,
            // additionalComments:this.state.additionalComments,
            // answer1:this.state.answer1,
            // answer2:this.state.answer2,
            // answer3:this.state.answer3,
            // answer4:this.state.answer4,
            // answer5:this.state.answer5,
            // answer6:this.state.answer6,
          }; // karan-task-3719-vapt header and query params
          fetch("/campaign/deliveryFormat", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            //mode:'no-cors',
            body: JSON.stringify(deliveryData),
          })
            .then((res) => res.json())
            .then((campaignDetail) => {
              //var campID=response.body.campID;
              this.setState({
                campaignDetail: campaignDetail,
              });
              var campID = campaignDetail[0].campID;
              var parentCampID = campaignDetail[0].parentCampID;

              //send campID to new page

              if (this.state.rfpCampaignID) {
                // window.location.href =
                // 	"/successPage?campID=" +
                // 	campID +
                // 	"&parentCampID=" +
                // 	parentCampID +
                // 	"&rfpCampaignID=" +
                // 	this.state.rfpCampaignID;
                this.props.history.push("/successPage", {
                  campID,
                  parentCampID,
                  rfpCampaignID: this.state.rfpCampaignID,
                }); // karan-task-3719-vapt header and query params
              } else {
                // window.location.href =
                // 	"/successPage?campID=" +
                // 	campID +
                // 	"&parentCampID=" +
                // 	parentCampID +
                // 	"";
                this.props.history.push("/successPage", {
                  campID,
                  parentCampID,
                }); // karan-task-3719-vapt header and query params
              }
              if (deliveryData === "success") {
                this.refs.msg.show("Some text or component", {
                  time: 2000,
                  type: "success",
                  icon: <img src="path/to/some/img/32x32.png" />,
                });
              }
            })
            .catch(function (err) {
              console.log(err);
            });
        } else {
          //  In else Block for edit

          let data11 = {
            campID: campID,
            switchValue: this.state.switchValue,
          };

          fetch("campaign/updateSwitchValue", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            //mode:'no-cors',
            body: JSON.stringify(data11),
          }).catch(function (err) {
            console.log(err);
          }); // route for switchvalue at back flow
          var inputType = [...this.state.inputType];

          var backDynamicInput = [...this.state.backDynamicInput];

          var assetTotalLead = 0;
          for (var i = 0; i < inputType.length; i++) {
            assetTotalLead =
              assetTotalLead + parseInt(inputType[i].leadPerAsset);
          }
          for (var i = 0; i < backDynamicInput.length; i++) {
            assetTotalLead =
              assetTotalLead + parseInt(backDynamicInput[i].leadPerAsset);
          }

          //Sanjana-2979 following setState done to send asset file and asset link data to backend
          this.state.dynamicAssetName = [...this.state.inputType];
          let data = {
            assetFileName: this.state.dynamicAssetName,
            dynamicInput: this.state.backDynamicInput,
            assetsFile: this.state.inputType,
            requiredLeadPerAsset: this.state.leadPerAssetChk,
            campID: campID,
            suppressionLink: this.state.suppressionLink,
            multiTouchEdit: this.state.multiTouchChkBack,
            assetJson: this.state.assetJson,
            abmJson: this.state.abmJson,
            exclusionJson: this.state.exclusionJson,
            suppressionJson: this.state.suppressionJson,
            otherJson: this.state.otherJson,
          };

          //alert("editSupportingDocument"+JSON.stringify(data));
          // commented by karan - because this is called in submitStep1()
          // fetch("campaign/editSupportingDocument", {
          // 	method: "POST",
          // 	headers: { "Content-Type": "application/json" },
          // 	//mode:'no-cors',
          // 	body: JSON.stringify(data),
          // })
          // 	.then(function (response) {
          // 		if (response.status >= 400) {
          // 			throw new Error("Bad response from server");
          // 		}
          // 		return response.json();
          // 	})
          // 	.then(function (response) {
          //
          // 		var campID = response.campID;
          //
          // 		//window.location.href = '/finishCampaign?campID=' + campID + '&step3';
          // 	})
          // 	.catch(function (err) {
          // 		console.log(err);
          // 	});

          //delivery backend
          const { user } = this.props.auth;
          let deliveryData = {
            campID: campID,
            companyName: this.state.companyName,
            linkedInCompanyName: this.state.linkedInCompanyName,
            pID: this.state.pID,
            assetId: this.state.assetId,
            campaignID: this.state.campaignID,
            leadInteractionDate: this.state.leadInteractionDate,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            jobTitleDelivery: this.state.jobTitleDelivery,
            linkedInJobTitle: this.state.linkedInJobTitle,
            jobTitleValidation: this.state.jobTitleValidation,
            jobLevelDelivery: this.state.jobLevelDelivery,
            jobFunctionDelivery: this.state.jobFunctionDelivery,
            address: this.state.address,

            assetName: this.state.assetName,

            email: this.state.email,
            workPhone: this.state.workPhone,
            street: this.state.street,
            city: this.state.city,
            state: this.state.state,
            countryDelivery: this.state.countryDelivery,
            zipCode: this.state.zipCode,
            companyEmployeeSize: this.state.companyEmployeeSize,
            companyRevenue: this.state.companyRevenue,
            industry: this.state.industry,
            ip: this.state.ip,
            extra1: this.state.extra1,
            extra2: this.state.extra2,
            extra3: this.state.extra3,
            extra4: this.state.extra4,
            extra5: this.state.extra5,

            extra6: this.state.extra6, //Sandeep-task-3158-added code for extra 15 fields from 6to20
            extra7: this.state.extra7,
            extra8: this.state.extra8,
            extra9: this.state.extra9,
            extra10: this.state.extra10,

            extra11: this.state.extra11,
            extra12: this.state.extra12,
            extra13: this.state.extra13,
            extra14: this.state.extra14,
            extra15: this.state.extra15,

            extra16: this.state.extra16,
            extra17: this.state.extra17,
            extra18: this.state.extra18,
            extra19: this.state.extra19,
            extra20: this.state.extra20,

            extra21: this.state.extra21, //Aman 5085 -set the other extra filed 21 to 30 value
            extra22: this.state.extra22,
            extra23: this.state.extra23,
            extra24: this.state.extra24,
            extra25: this.state.extra25,
            extra26: this.state.extra26,
            extra27: this.state.extra27,
            extra28: this.state.extra28,
            extra29: this.state.extra29,
            extra30: this.state.extra30,

            reAllocationID: this.state.reAllocationID,
            campaignName: this.state.campaignName,
            domain: this.state.domain,
            alternatePhoneNo: this.state.alternatePhoneNo,
            comments: this.state.comments,
            linkedIn: this.state.linkedIn,
            channel: this.state.channel,
            dynamicAssetName: this.state.dynamicAssetName,
            backDynamicInput: this.state.backDynamicInput,
            customizeMapping: this.state.customizeMapping,
            customizeMappingFormat:
              this.state
                .customizeMappingFormat /*Raunak 2915-Customize mapping missing issue- customizeMappingFormat's value is passed */,
            rfpCamp: this.state.rfpCampaignID,
            newCustomizeMappingFormat: this.state.newCustomizeMappingFormat, //Nilesh - 5002
            format: this.state.format,
            countryAbbreviation: this.state.countryAbbreviation, //rutja task-4694 pass country abbreviation
            cityStateValidationCheck: this.state.cityStateValidationCheck, //task-4765 added City-state validation checkbox
          }; // karan-task-3719-vapt header and query params
          var rfpCampaignID = this.state.rfpCampaignID;
          if (rfpCampaignID) {
            if (rfpChange > 0) {
              //Changes in rfp specifications
              Swal.fire({
                text: "Please note that updated specification not matching with RFP details, Are you sure you want to change the specification?",
                type: "question",
                title: "Are you sure you want to change the specification?",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#193D8F",
                cancelButtonColor: "#193D8F",
                confirmButtonText: "Yes, go ahead !",
                cancelButtonText: "Cancel",
                allowOutsideClick: false,
                preConfirm: () => {
                  fetch("/campaign/deliveryFormat", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },

                    body: JSON.stringify(deliveryData),
                  })
                    .then((res) => res.json())
                    .then((campaignDetail) => {
                      //var campID=response.body.campID;
                      this.setState({
                        campaignDetail: campaignDetail,
                      });
                      var campID = campaignDetail[0].campID;
                      var parentCampID = campaignDetail[0].parentCampID;

                      //send campID to new page
                      if (this.state.rfpCampaignID) {
                        // window.location.href =
                        // 	"/successPage?campID=" +
                        // 	campID +
                        // 	"&parentCampID=" +
                        // 	parentCampID +
                        // 	"&rfpCampaignID=" +
                        // 	this.state.rfpCampaignID;
                        this.props.history.push("/successPage", {
                          campID,
                          parentCampID,
                          rfpCampaignID: this.state.rfpCampaignID,
                        }); // karan-task-3719-vapt header and query params
                      } else {
                        // window.location.href =
                        // 	"/successPage?campID=" +
                        // 	campID +
                        // 	"&parentCampID=" +
                        // 	parentCampID +
                        // 	"";
                        this.props.history.push("/successPage", {
                          campID,
                          parentCampID,
                        }); // karan-task-3719-vapt header and query params
                      }
                      if (deliveryData === "success") {
                        this.refs.msg.show("Some text or component", {
                          time: 2000,
                          type: "success",
                          icon: <img src="path/to/some/img/32x32.png" />,
                        });
                      }
                    })
                    .catch(function (err) {
                      console.log(err);
                    });
                }, // end of preConfirm
              });
            } else if (rfpChange == 0) {
              //no changes in rfp specifications
              //delivery backend
              //alert("rfpChange == 0"+JSON.stringify(deliveryData))

              fetch("/campaign/deliveryFormat", {
                method: "POST",
                headers: { "Content-Type": "application/json" },

                body: JSON.stringify(deliveryData),
              })
                .then((res) => res.json())
                .then((campaignDetail) => {
                  //var campID=response.body.campID;
                  this.setState({
                    campaignDetail: campaignDetail,
                  });
                  var campID = campaignDetail[0].campID;
                  var parentCampID = campaignDetail[0].parentCampID;

                  //send campID to new page
                  if (this.state.rfpCampaignID) {
                    // window.location.href =
                    // 	"/successPage?campID=" +
                    // 	campID +
                    // 	"&parentCampID=" +
                    // 	parentCampID +
                    // 	"&rfpCampaignID=" +
                    // 	this.state.rfpCampaignID;
                    this.props.history.push("/successPage", {
                      campID,
                      parentCampID,
                      rfpCampaignID: this.state.rfpCampaignID,
                    }); // karan-task-3719-vapt header and query params
                  } else {
                    // window.location.href =
                    // 	"/successPage?campID=" +
                    // 	campID +
                    // 	"&parentCampID=" +
                    // 	parentCampID +
                    // 	"";
                    this.props.history.push("/successPage", {
                      campID,
                      parentCampID,
                    }); // karan-task-3719-vapt header and query params
                  }
                  if (deliveryData === "success") {
                    this.refs.msg.show("Some text or component", {
                      time: 2000,
                      type: "success",
                      icon: <img src="path/to/some/img/32x32.png" />,
                    });
                  }
                })
                .catch(function (err) {
                  console.log(err);
                });
            } // end of else of no changes in rfp specifications
          } //  end of   if(rfpCampaignID)
          else {
            fetch("/campaign/deliveryFormat", {
              method: "POST",
              headers: { "Content-Type": "application/json" },

              body: JSON.stringify(deliveryData),
            })
              .then((res) => res.json())
              .then((campaignDetail) => {
                //var campID=response.body.campID;
                this.setState({
                  campaignDetail: campaignDetail,
                });

                var campID = campaignDetail[0].campID;
                var parentCampID = campaignDetail[0].parentCampID;
                //send campID to new page
                if (this.state.rfpCampaignID) {
                  // window.location.href =
                  // 	"/successPage?campID=" +
                  // 	campID +
                  // 	"&parentCampID=" +
                  // 	parentCampID +
                  // 	"&rfpCampaignID=" +
                  // 	this.state.rfpCampaignID;
                  this.props.history.push("/successPage", {
                    campID,
                    parentCampID,
                    rfpCampaignID: this.state.rfpCampaignID,
                  }); // karan-task-3719-vapt header and query params
                } else {
                  // window.location.href =
                  // 	"/successPage?campID=" +
                  // 	campID +
                  // 	"&parentCampID=" +
                  // 	parentCampID +
                  // 	"";
                  this.props.history.push("/successPage", {
                    campID,
                    parentCampID,
                  }); // karan-task-3719-vapt header and query params
                }
                if (deliveryData === "success") {
                  this.refs.msg.show("Some text or component", {
                    time: 2000,
                    type: "success",
                    icon: <img src="path/to/some/img/32x32.png" />,
                  });
                }
              })
              .catch(function (err) {
                console.log(err);
              });
          }
        } /// end of else
      } // ens of validateForm true
    }
  };

  validateForm() {
    var parsed = this.props.location.state;
    if (parsed !== undefined) {
      let status;
      var step2 = "na";
      if (parsed.hasOwnProperty("status")) {
        status = parsed.status;
      }
      if (parsed.hasOwnProperty("step2")) {
        step2 = "step2";
      }
      if (parsed.hasOwnProperty("rfpCampaign")) {
        step2 = "rfpCampaign";
      }
      if (step2 === "rfpCampaign") {
        step2 = "step2";
      }
      //if (step2 === null || step2 === undefined) {
      if (
        step2 === "undefined" ||
        step2 === null ||
        step2 === "" ||
        step2 === undefined ||
        (step2 !== "step2" && status !== "SupportingDocument")
      ) {
        let fields = this.state.fields;
        let errors = {};
        let leaderrors = {};
        let asseterrors = {};
        let abmerrors = {};
        let spanDisplay = "none";
        let excludeError = {}; //snehal-task3620-Add exclude domains
        let formIsValid = true; // main flag
        let assest1 = true; // used for asset files and asset link
        let assestinput = true; //used for asset file name and asset link name
        let assetvalidate = true; //used for lead per asset
        let multiTouchChkFlag = true; // used for multitouch
        let abmFileType = true; // used for abm file upload
        let duplicateFileName = true; //3126-karan-duplicate asset file name
        let excludeTextValidate = true; //snehal-task3620-Add exclude domains
        let validFileName = true; //kiran-4686-added for asset link name

        if (
          this.state.inputType.length === 0 &&
          this.state.dynamicInput.length === 0
        ) {
          // no asset and no asset link is uploaded

          asseterrors["assetFiles2"] = (
            <li>
              <b>Please upload either asset link or asset file</b>
            </li>
          );
          assest1 = false;
        }
        if (this.state.campaignType == "ABM") {
          var abmarrayLength = newabmfilenames.length;
          let abmJson = [...this.state.abmJson];
          if (abmJson == 0) {
            abmFileType = false;
          } else {
            abmFileType = true;
          }
        }
        if (this.state.campaignType == "ABM") {
          let abmJson = [...this.state.abmJson];

          if (abmJson.length > 0) {
            let checkData = abmJson.filter((a) => a.success == false);
            if (checkData.length > 0) {
              abmerrors["properabmFiles"] = (
                <li>
                  <b>Please upload valid ABM file</b>
                </li>
              );
              asseterrors["assetFiles"] = "";
              this.setState({
                alertBorder: "1px solid red",
                abmerrors: abmerrors,
                asseterrors: asseterrors,
              });
              return false;
            } else {
              allabms = true;
              abmerrors["abmFiles"] = "";
              this.setState({ alertBorder: "0px solid white" });
            }
          } else {
            allabms = false;
            abmerrors["abmFiles"] = (
              <li>
                <b>Please upload ABM file</b>
              </li>
            );
            formIsValid = false;
          }
        }
        for (var i = 0; i < this.state.inputType.length; i++) {
          if (this.state.inputType[i].assetFileName == "") {
            assestinput = false;
            formIsValid = false;
            errors["assetFileName"] = (
              <li>
                <b>Please enter asset name for uploaded asset file</b>
              </li>
            );
            this.setState({ errors: errors });
            break;
          } else {
            assestinput = true;
          }
        }

        //end validation for asset file name
        /* ********************************************************************************************************************************************* */
        // start validation for asset link and asset link Name
        for (var i = 0; i < this.state.dynamicInput.length; i++) {
          if (
            this.state.dynamicInput[i].assetLink != "" &&
            this.state.dynamicInput[i].linkAssetFileName != ""
          ) {
            assestinput = true;
          } else {
            if (
              this.state.dynamicInput[i].assetLink == "" &&
              this.state.dynamicInput[i].linkAssetFileName == ""
            ) {
              assestinput = false;
              formIsValid = false;
              errors["assetLinkName"] = (
                <li>
                  <b>Please enter asset link and asset link name</b>
                </li>
              );
              this.setState({ errors: errors });
              break;
            }

            if (this.state.dynamicInput[i].assetLink == "") {
              assestinput = false;
              formIsValid = false;
              errors["assetLinkName"] = (
                <li>
                  <b>Please enter asset link for uploaded asset name</b>
                </li>
              );
              this.setState({ errors: errors });
              break;
            }
            if (this.state.dynamicInput[i].linkAssetFileName == "") {
              assestinput = false;
              formIsValid = false;
              errors["assetLinkName"] = (
                <li>
                  <b>Please enter asset link name for uploaded asset link</b>
                </li>
              );
              this.setState({ errors: errors });
              break;
            }
          }
        }
        //end validation for asset link and asset link name
        /* *********************************************************************************************************************************************** */

        // 3126 -karan - duplicate asset name not allowed - start
        let duplicateCheckFlag = false;
        let validAssetNameFlag = false; //kiran-4686-added flag
        // for asset files
        for (
          let i = 0;
          i < this.state.assetFilesSelectedDocuments.length;
          i++
        ) {
          let obj = this.state.assetFilesSelectedDocuments[i];
          if (
            obj.assetFileName !== null &&
            obj.assetFileName !== undefined &&
            obj.assetFileName !== "" &&
            obj.assetFileName !== "undefined" &&
            obj.assetFileName !== "null"
          ) {
            let changedName = obj.assetFileName;
            for (
              let j = 0;
              j < this.state.assetFilesSelectedDocuments.length;
              j++
            ) {
              if (j !== i) {
                let obj2 = this.state.assetFilesSelectedDocuments[j];
                if (obj2.suppDocName === changedName) {
                  duplicateCheckFlag = true;
                  break;
                }
                if (obj2.assetFileName === changedName) {
                  duplicateCheckFlag = true;
                  break;
                }
                if (obj2.dataFileID === changedName) {
                  duplicateCheckFlag = true;
                  break;
                }
              }
            }
          }
        }
        // for asset links
        for (let i = 0; i < this.state.dynamicInput.length; i++) {
          let obj = this.state.dynamicInput[i];
          if (
            obj.linkAssetFileName !== null &&
            obj.linkAssetFileName !== undefined &&
            obj.linkAssetFileName !== "" &&
            obj.linkAssetFileName !== "undefined" &&
            obj.linkAssetFileName !== "null"
          ) {
            let changedName = obj.linkAssetFileName;
            for (let j = 0; j < this.state.dynamicInput.length; j++) {
              if (j !== i) {
                let obj2 = this.state.dynamicInput[j];
                if (obj2.linkAssetFileName === changedName) {
                  duplicateCheckFlag = true;
                  break;
                }
              }
              //kiran-4686-added regex for validating asset link name
              //kiran-4715-changed regex -validation is only for link
              var urlRegex = new RegExp(
                // /^(?=[^\S\s]*[@#%^]+$)|(?=^\d+$)|(?=.*[@^*()!$%#<>?\\~;])(?=[a-zA-Z0-9 ])|(?=.*\.(com|co|org|net|gov|edu|mil|int|us|in|info|me|io|uk)$)|(?=.*(\/)$)|(ftp|http|https|www|smtp|ssh|telnet|dns|sip|nntp):\/\/[^ "]+$/
                /^(?=.*\.(com|co|org|net|gov|edu|mil|int|us|in|info|me|io|uk)$)|(?=.*(\/)$)|(ftp|http|https|www|smtp|ssh|telnet|dns|sip|nntp):\/\/[^ "]+$/
              );

              //Chaitanya-4762-added RegExp for validating Asset link name
              var urlRegex2 = new RegExp(
                /^[!@#$%^&*()_ +{}\[\]:;<>,.?/~`|\-=\\'"!]+$/
              );

              if (!urlRegex.test(changedName) && !urlRegex2.test(changedName)) {
              } else {
                validAssetNameFlag = true;
                break;
              }
            }
          }
        }
        //kiran-4686-added condition on validAssetNameFlag for showing error
        if (validAssetNameFlag) {
          errors["validAssetFileName"] = (
            <li>
              <b>Please enter valid asset link name</b>
            </li>
          );
          this.setState({ errors: errors });
          validFileName = false;
          formIsValid = false;
        } else {
          validFileName = true;
        }
        if (duplicateCheckFlag) {
          errors["duplicateAssetFileName"] = (
            <li>
              <b>Please enter unique asset name</b>
            </li>
          );
          this.setState({ errors: errors });
          duplicateFileName = false;
          formIsValid = false;
        } else {
          duplicateFileName = true;
        }
        // 3126 -karan - duplicate asset name not allowed - end

        /* *********************************************************************************************************************************************** */
        //start validation for lead per asset
        if (this.state.leadPerAssetChk == "Yes") {
          let tempArray = [...this.state.inputType, ...this.state.dynamicInput];
          let leadPercentageTotal = 0; //to check the total of leadPercentage (asset+ assetLink)

          for (let i = 0; i < tempArray.length; i++) {
            if (
              tempArray[i].leadPercentage === undefined ||
              tempArray[i].leadPercentage === "" ||
              tempArray[i].leadPercentage === null
            ) {
              leaderrors["leadPercentage"] = (
                <li>
                  <b>
                    Please enter lead percentage for individual uploaded asset{" "}
                  </b>
                </li>
              );
              assetvalidate = false;
              break;
            } else {
              if (
                isNaN(tempArray[i].leadPercentage) === false &&
                parseFloat(tempArray[i].leadPercentage) ===
                  parseInt(tempArray[i].leadPercentage)
              ) {
                leadPercentageTotal += parseInt(tempArray[i].leadPercentage);
                assetvalidate = true;
              } else {
                leaderrors["AssetValidate"] = (
                  <li>
                    <b>
                      Please enter only numerical (0-9) values for lead
                      percentage
                    </b>
                  </li>
                );
                assetvalidate = false;
                break;
              }
            }
          }
          if (assetvalidate === true) {
            //Sanjana-2852-validation condition (leadPercentageTotal < 100 || leadPercentageTotal > 100)  addeded
            if (leadPercentageTotal < 100 || leadPercentageTotal > 100) {
              leaderrors["assetCount"] = (
                <li>
                  <b>
                    Leads percentage per asset can't be greater or less than
                    hundred percentage
                  </b>
                </li>
              );
              assetvalidate = false;
            } else {
              assetvalidate = true;
            }
          }
        }
        //end validation for asset link and asset link name
        /* ****************************************************************************************************************************************** */
        //start validation or multitouch
        if (this.state.multiTouchChk === "Yes") {
          let commonTouchArray = [];
          let multiTouch1stCount = 0;
          let multiTouch2ndCount = 0;
          let multiTouch3rdCount = 0;
          //following the top-down approach
          for (let i = 0; i < this.state.inputType.length; i++) {
            commonTouchArray.push(this.state.inputType[i].multiTouch);
            if (this.state.inputType[i].multiTouch == "1st Touch") {
              multiTouch1stCount += 1;
            }
            if (this.state.inputType[i].multiTouch == "2nd Touch") {
              multiTouch2ndCount += 1;
            }
            if (this.state.inputType[i].multiTouch == "3rd Touch") {
              multiTouch3rdCount += 1;
            }
          }

          for (let i = 0; i < this.state.dynamicInput.length; i++) {
            commonTouchArray.push(this.state.dynamicInput[i].multiTouch);
            if (this.state.dynamicInput[i].multiTouch == "1st Touch") {
              multiTouch1stCount += 1;
            }
            if (this.state.dynamicInput[i].multiTouch == "2nd Touch") {
              multiTouch2ndCount += 1;
            }
            if (this.state.dynamicInput[i].multiTouch == "3rd Touch") {
              multiTouch3rdCount += 1;
            }
          }
          if (commonTouchArray.length == 1) {
            //false
            errors["OneAsset"] = (
              <li>
                <b>Please add more assets</b>
              </li>
            );
            multiTouchChkFlag = false;
          }

          if (commonTouchArray.length == 2) {
            if (
              commonTouchArray.includes("1st Touch") &&
              commonTouchArray.includes("2nd Touch")
            ) {
              multiTouchChkFlag = true; // true because there are only 2 assets are both the touch are present
            } else {
              //if condn for all same touch
              errors["OneAsset"] = (
                <li>
                  <b>All assets cannot be of same touch</b>
                </li>
              );
              multiTouchChkFlag = false;
              //false when all the touch are same or 3rd touch is present
              if (commonTouchArray.includes("3rd Touch")) {
                //if condition for 3rd touch
                errors["OneAsset"] = (
                  <li>
                    <b>Assets are not enough for 3rd touch</b>
                  </li>
                );
                multiTouchChkFlag = false;
              }
            }
          }
          if (commonTouchArray.length >= 3) {
            if (
              multiTouch3rdCount === commonTouchArray.length ||
              multiTouch1stCount === commonTouchArray.length ||
              multiTouch2ndCount === commonTouchArray.length
            ) {
              errors["OneAsset"] = (
                <li>
                  <b>All assets cannot be of same touch </b>
                </li>
              );
              multiTouchChkFlag = false;
            } else {
              if (
                !commonTouchArray.includes("1st Touch") ||
                !commonTouchArray.includes("2nd Touch")
              ) {
                //checking for 1st and 2nd touch
                errors["OneAsset"] = (
                  <li>
                    <b>Either of 1st touch or 2nd touch not present</b>
                  </li>
                );
                multiTouchChkFlag = false;
              }
            }
          }
        }
        /* ******************************************************************************************************************************************** */
        //final condition for validating the form flag
        if (
          assest1 === true &&
          allabms === true &&
          assestinput === true &&
          assetvalidate === true &&
          multiTouchChkFlag === true &&
          duplicateFileName === true && //3126-karan-duplicate name
          excludeTextValidate === true &&
          validFileName === true //kiran-4686-added for asset link name
        ) {
          //snehal-task3620-Add exclude domains(for excluded domain)
          formIsValid = true;
          this.setState({
            errors: "",
            leaderrors: "",
            asseterrors: "",
            abmerrors: "",
            excludeError: "",
            alertBorder: "0px",
            alertWidth: "0 px",
            alertAlign: "center",
            alertDisplay: "none",
            spanSpaceDisplay: spanDisplay,
            canProceedToStep2: true,
          });
          //snehal-task3620-Add exclude domains(exclude domain)
        } else {
          formIsValid = false;
          this.setState({
            errors: errors,
            leaderrors: leaderrors,
            asseterrors: asseterrors,
            abmerrors: abmerrors,
            excludeError: excludeError,
            alertBorder: "1px solid red",
            alertWidth: "1000px",
            alertAlign: "center",
            alertDisplay: "block",
            spanSpaceDisplay: spanDisplay,
            canProceedToStep2: false,
          });
          //snehal-task3620-Add exclude domains(for exclude doamin)
        }
        document.body.scrollTop = (0, 0);
        document.documentElement.scrollTop = (0, 0);
        return formIsValid;
      }
      // end of if (step2 == undefined || step2 == null || step2 == "")
      else {
        //Sanjana â€“ Task -2979- revised validation
        let leaderrors = {};
        let errors = {};
        let linknameerror = {};
        let backerror = {};
        let backabmerror = {};
        let excludeError = {}; //snehal-task3620-Add exclude domains
        let formIsValid = true; // main flag
        let spanDisplay = "none";
        let assetUpload = true; // used for asset files and asset link
        let assestinput = true; //used for asset file name and asset link name
        let abmBackLength = chkABMExist.length;
        let inputTypeLength = this.state.inputType.length;
        let assetvalidate = true; //used for lead per asset
        let multiTouchChkFlag = true; // used for multitouch
        let allabms = true; // used for abm file upload
        let duplicateFileName = true; //3126-karan-duplicate asset file name
        let excludeTextValidate = true; //snehal-task3620-Add exclude domains
        let validFileName = true; //kiran-4686-added for asset link name

        if (
          this.state.inputType.length === 0 &&
          this.state.backDynamicInput.length === 0
        ) {
          backerror["assetUploadError"] = (
            <li>
              <b>Please upload either asset file or asset link</b>
            </li>
          );
          assetUpload = false;
          this.setState({ backerror: backerror });
        }

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Start of Validation for campaignType === 'ABM', where ABM upload is mandatory -Sunita

        var abmJson = [...this.state.abmJson];
        if (this.state.campaignType === "ABM") {
          if (abmJson.length > 0 || abmBackLength > 0) {
            let checkData1 = abmJson.filter((a) => a.success == false);
            if (checkData1.length > 0) {
              backabmerror["properabmFiles1"] = (
                <li>
                  <b>Please upload valid ABM file</b>
                </li>
              );
              backerror["assetFiles1"] = "";
              this.setState({
                alertBorder: "1px solid red",
                backabmerror: backabmerror,
                backerror: backerror,
              });
              return false;
            } else {
              allabms = true;
              backabmerror["abmFiles1"] = "";
              this.setState({ alertBorder: "0px solid white" });
            }
          } else {
            allabms = false;
            backabmerror["abmFiles1"] = (
              <li>
                <b>Please upload ABM file</b>
              </li>
            );
            backerror["assetFiles1"] = "";
            formIsValid = false;
          }
        }

        for (var i = 0; i < inputTypeLength; i++) {
          if (
            this.state.inputType[i].suppDocName === undefined ||
            this.state.inputType[i].suppDocName === "" ||
            this.state.inputType[i].suppDocName === null
          ) {
            assestinput = false;
            formIsValid = false;
            errors["suppDocName"] = (
              <li>
                <b>Please enter asset name for uploaded asset file</b>
              </li>
            );
            this.setState({
              errors: errors,
            });
            break;
          } else {
            assestinput = true;
          }
        }

        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // start validation for asset link and asset link Name
        for (var i = 0; i < this.state.backDynamicInput.length; i++) {
          if (
            this.state.backDynamicInput[i].assetLink != "" &&
            this.state.backDynamicInput[i].linkAssetFileName != ""
          ) {
            assestinput = true;
          } else {
            if (
              this.state.backDynamicInput[i].assetLink == "" &&
              this.state.backDynamicInput[i].linkAssetFileName == ""
            ) {
              assestinput = false;
              formIsValid = false;
              linknameerror["linkname"] = (
                <li>
                  <b>Please enter asset link and asset link name</b>
                </li>
              );
              break;
            }
            if (this.state.backDynamicInput[i].assetLink == "") {
              assestinput = false;
              formIsValid = false;
              linknameerror["linkname"] = (
                <li>
                  <b>Please enter asset link for uploaded asset name</b>
                </li>
              );
              break;
            }
            if (this.state.backDynamicInput[i].linkAssetFileName == "") {
              assestinput = false;
              formIsValid = false;
              linknameerror["linkname"] = (
                <li>
                  <b>Please enter asset link name for uploaded asset link</b>
                </li>
              );
              break;
            }
          }
        }
        //end validation for asset link and asset link name
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        // 3126 -karan - duplicate asset name not allowed - start
        let duplicateCheckFlag = false;
        let validAssetNameFlag = false; //kiran-4686-added flag
        // for asset files
        for (
          let i = 0;
          i < this.state.assetFilesSelectedDocuments.length;
          i++
        ) {
          let obj = this.state.assetFilesSelectedDocuments[i];
          if (
            obj.assetFileName !== null &&
            obj.assetFileName !== undefined &&
            obj.assetFileName !== "" &&
            obj.assetFileName !== "undefined" &&
            obj.assetFileName !== "null"
          ) {
            let changedName = obj.assetFileName;
            for (
              let j = 0;
              j < this.state.assetFilesSelectedDocuments.length;
              j++
            ) {
              if (j !== i) {
                let obj2 = this.state.assetFilesSelectedDocuments[j];
                if (obj2.suppDocName === changedName) {
                  duplicateCheckFlag = true;
                  break;
                }
                if (obj2.assetFileName === changedName) {
                  duplicateCheckFlag = true;
                  break;
                }
                if (obj2.dataFileID === changedName) {
                  duplicateCheckFlag = true;
                  break;
                }
              }
            }
          }
        }
        // for asset links
        for (let i = 0; i < this.state.backDynamicInput.length; i++) {
          let obj = this.state.backDynamicInput[i];
          if (
            obj.linkAssetFileName !== null &&
            obj.linkAssetFileName !== undefined &&
            obj.linkAssetFileName !== "" &&
            obj.linkAssetFileName !== "undefined" &&
            obj.linkAssetFileName !== "null"
          ) {
            let changedName = obj.linkAssetFileName;
            for (let j = 0; j < this.state.backDynamicInput.length; j++) {
              if (j !== i) {
                let obj2 = this.state.backDynamicInput[j];
                if (obj2.linkAssetFileName === changedName) {
                  duplicateCheckFlag = true;
                  break;
                }
              }
              //kiran-4686-added regex for validating asset link name
              //kiran-4715-changed regex -validation is only for link
              var urlRegex1 = new RegExp(
                // /^(?=[^\S\s]*[@#%^]+$)|(?=^\d+$)|(?=.*[@^*()!$%#<>?\\~;])(?=[a-zA-Z0-9 ])|(?=.*\.(com|co|org|net|gov|edu|mil|int|us|in|info|me|io|uk)$)|(?=.*(\/)$)|(ftp|http|https|www|smtp|ssh|telnet|dns|sip|nntp):\/\/[^ "]+$/
                /^(?=.*\.(com|co|org|net|gov|edu|mil|int|us|in|info|me|io|uk)$)|(?=.*(\/)$)|(ftp|http|https|www|smtp|ssh|telnet|dns|sip|nntp):\/\/[^ "]+$/
              );

              //Chaitanya-4762-added RegExp for validating Asset link name
              var urlRegex3 = new RegExp(
                /^[!@#$%^&*()_ +{}\[\]:;<>,.?/~`|\-=\\'"!]+$/
              );

              if (
                !urlRegex1.test(changedName) &&
                !urlRegex3.test(changedName)
              ) {
              } else {
                validAssetNameFlag = true;
                break;
              }
            }
          }
        }
        //kiran-4686-showing error on validAssetNameFlag
        if (validAssetNameFlag) {
          errors["validAssetFileName"] = (
            <li>
              <b>Please enter valid asset link name</b>
            </li>
          );
          this.setState({ errors: errors });
          validFileName = false;
          formIsValid = false;
        } else {
          validFileName = true;
        }
        if (duplicateCheckFlag) {
          errors["duplicateAssetFileName"] = (
            <li>
              <b>Please enter unique asset name</b>
            </li>
          );
          this.setState({ errors: errors });
          duplicateFileName = false;
          formIsValid = false;
        } else {
          duplicateFileName = true;
        }
        // 3126 -karan - duplicate asset name not allowed - end

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //start validation for lead per asset
        if (this.state.leadPerAssetChk == "Yes") {
          let tempArray = [
            ...this.state.inputType,
            ...this.state.backDynamicInput,
          ];
          let leadPercentageTotal = 0; //to check the total of leadPercentage (asset+ assetLink)
          for (let i = 0; i < tempArray.length; i++) {
            if (
              tempArray[i].leadPercentage === undefined ||
              tempArray[i].leadPercentage === "" ||
              tempArray[i].leadPercentage === null
            ) {
              leaderrors["leadPercentage"] = (
                <li>
                  <b>
                    Please enter lead percentage for individual uploaded asset{" "}
                  </b>
                </li>
              );
              assetvalidate = false;
              break;
            } else {
              if (
                isNaN(tempArray[i].leadPercentage) == false &&
                parseFloat(tempArray[i].leadPercentage) ==
                  parseInt(tempArray[i].leadPercentage)
              ) {
                leadPercentageTotal += parseInt(tempArray[i].leadPercentage);
                assetvalidate = true;
              } else {
                leaderrors["AssetValidate"] = (
                  <li>
                    <b>
                      Please enter only numerical (0-9) values for lead
                      percentage
                    </b>
                  </li>
                );
                assetvalidate = false;
                break;
              }
            }
          }
          if (assetvalidate == true) {
            //Sanjana-2852-validation condition (leadPercentageTotal < 100 || leadPercentageTotal > 100)  addeded
            if (leadPercentageTotal < 100 || leadPercentageTotal > 100) {
              leaderrors["assetCount"] = (
                <li>
                  <b>
                    Leads percentage per asset can't be greater or less than
                    hundred percentage
                  </b>
                </li>
              );
              assetvalidate = false;
            } else {
              assetvalidate = true;
            }
          }
        }
        //end validation for asset link and asset link name
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //start validation or multitouch
        if (this.state.multiTouchChkBack === "Yes") {
          let commonTouchArray = [];
          let multiTouch1stCount = 0;
          let multiTouch2ndCount = 0;
          let multiTouch3rdCount = 0;
          //following the top-down approach
          for (let i = 0; i < this.state.inputType.length; i++) {
            commonTouchArray.push(this.state.inputType[i].multiTouch);
            if (this.state.inputType[i].multiTouch == "1st Touch") {
              multiTouch1stCount += 1;
            }
            if (this.state.inputType[i].multiTouch == "2nd Touch") {
              multiTouch2ndCount += 1;
            }
            if (this.state.inputType[i].multiTouch == "3rd Touch") {
              multiTouch3rdCount += 1;
            }
          }

          for (let i = 0; i < this.state.backDynamicInput.length; i++) {
            commonTouchArray.push(this.state.backDynamicInput[i].multiTouch);
            if (this.state.backDynamicInput[i].multiTouch == "1st Touch") {
              multiTouch1stCount += 1;
            }
            if (this.state.backDynamicInput[i].multiTouch == "2nd Touch") {
              multiTouch2ndCount += 1;
            }
            if (this.state.backDynamicInput[i].multiTouch == "3rd Touch") {
              multiTouch3rdCount += 1;
            }
          }
          if (commonTouchArray.length == 1) {
            backerror["OneAsset"] = (
              <li>
                <b>Please add more assets</b>
              </li>
            );
            multiTouchChkFlag = false;
          }

          if (commonTouchArray.length == 2) {
            if (
              commonTouchArray.includes("1st Touch") &&
              commonTouchArray.includes("2nd Touch")
            ) {
              multiTouchChkFlag = true; // true because there are only 2 assets are both the touch are present
            } else {
              //if condn for all same touch
              backerror["OneAsset"] = (
                <li>
                  <b>All assets cannot be of same touch</b>
                </li>
              );
              multiTouchChkFlag = false;
              //false when all the touch are same or 3rd touch is present
              if (commonTouchArray.includes("3rd Touch")) {
                //if condition for 3rd touch
                backerror["OneAsset"] = (
                  <li>
                    <b>Assets are not enough for 3rd touch</b>
                  </li>
                );
                multiTouchChkFlag = false;
              }
            }
          }

          if (commonTouchArray.length >= 3) {
            if (
              multiTouch3rdCount === commonTouchArray.length ||
              multiTouch1stCount === commonTouchArray.length ||
              multiTouch2ndCount === commonTouchArray.length
            ) {
              backerror["OneAsset"] = (
                <li>
                  <b>All assets cannot be of same touch </b>
                </li>
              );
              multiTouchChkFlag = false;
            } else {
              if (
                !commonTouchArray.includes("1st Touch") ||
                !commonTouchArray.includes("2nd Touch")
              ) {
                //checking for 1st and 2nd touch
                backerror["OneAsset"] = (
                  <li>
                    <b>Either of 1st touch or 2nd touch not present</b>
                  </li>
                );
                multiTouchChkFlag = false;
              }
            }
          }
        }
        //snehal-task3620-Add exclude domains(validations for excluded doamin)
        if (this.state.excludeText.length > 0) {
          var excludeText = this.state.excludeText;
          var excludeText_array = excludeText.split(/[\n | ,]+/);
          let excludeTextArray = [];
          for (var i = 0; i < excludeText_array.length; i++) {
            excludeTextArray.push({
              id: excludeText_array[i],
              name: excludeText_array[i],
            });
            var temp_array = excludeText_array[i];
            var pattern = new RegExp(/^[0-9a-zA-Z.@, |\n]*$/);
            var pattern1 = new RegExp(/^(|[0-9]\d*)$/);
            if (!pattern.test(temp_array) || pattern1.test(temp_array)) {
              excludeTextValidate = false;
              excludeError["excludeError1"] = (
                <li>
                  <b>Please enter valid exclude domain</b>
                </li>
              );
              this.setState({
                alertBorder: "1px solid red",
                excludeError: excludeError,
              });
            } else {
              excludeTextValidate = true;
            }
          }
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //final condition for validating the form flag
        //alert(assetUpload + "==" + allabms + "==" + assestinput + "==" + assetvalidate + "==" + multiTouchChkFlag)

        if (
          assetUpload == true &&
          allabms === true &&
          assestinput === true &&
          assetvalidate === true &&
          multiTouchChkFlag === true &&
          duplicateFileName === true && //3126-karan-duplicate name
          excludeTextValidate === true &&
          validFileName === true //kiran-4686-added validFileName
        ) {
          //snehal-task3620-Add exclude domains(for error of exclude domain)
          formIsValid = true;
          this.setState({
            backerror: "",
            backabmerror: "",
            linknameerror: "",
            errors: "",
            leaderrors: "",
            excludeError: "",
            alertBorder: "0px",
            alertWidth: "0 px",
            alertAlign: "center",
            alertDisplay: "none",
            spanSpaceDisplay: spanDisplay,
            canProceedToStep2: true,
          });
          //snehal-task3620-Add exclude domains
        } else {
          formIsValid = false;
          this.setState({
            backerror: backerror,
            backabmerror: backabmerror,
            linknameerror: linknameerror,
            leaderrors: leaderrors,
            excludeError: excludeError,
            alertBorder: "1px solid red",
            alertWidth: "1000px",
            alertAlign: "center",
            alertDisplay: "block",
            spanSpaceDisplay: spanDisplay,
            canProceedToStep2: false,
          });
          //snehal-task3620-Add exclude domains
        }
        document.body.scrollTop = (0, 0);
        document.documentElement.scrollTop = (0, 0);
        return formIsValid;
      } // end of else validation
    }
  }
  render() {
    /**
     * @author Narendra Phadke
     * @param  Description handle the login authentication
     * @return Description return All details of authentication
     */
    const { isAuthenticated, user } = this.props.auth;
    const asterisk = {
      color: "red",
    };

    /**
     * @author Narendra Phadke
     * @param  Description Fetch campID from Url
     * @return Dependencies query-string
     */
    var parsed = this.props.location.state;
    var campID, status;
    var step2 = "na";
    if (parsed !== undefined) {
      if (parsed.hasOwnProperty("campID")) {
        campID = parsed.campID;
      }
      if (parsed.hasOwnProperty("status")) {
        status = parsed.status;
      }
      if (parsed.hasOwnProperty("step2")) {
        step2 = "step2";
      }
      if (parsed.hasOwnProperty("rfpCampaign")) {
        step2 = "rfpCampaign";
      }
    }
    if (
      step2 != "undefined" ||
      step2 != null ||
      step2 != "" ||
      step2 != undefined
    ) {
      if (step2 == "rfpCampaign") {
        this.state.dynamicAssetName = this.state.inputType.map((e) =>
          Object.assign(
            {},
            e,
            this.state.dynamicAssetName.find((e1) => e1.key === e.key) || {}
          )
        );
      } else {
        //  this.state.inputType.forEach((itm, i) => {
        //   this.state.dynamicAssetName.push(Object.assign({}, itm));
        this.state.dynamicAssetName = this.state.inputType.map((e) =>
          Object.assign(
            {},
            e,
            this.state.dynamicAssetName.find((e1) => e1.key === e.key) || {}
          )
        );
        // });
      }
    }
    //Added this condition because when it is added in the below if condition it won't work so to make it work assigning step2 to 'step2'-Sonali
    if (step2 === "rfpCampaign") {
      step2 = "step2";
    }

    if (
      step2 === "undefined" ||
      step2 === null ||
      step2 === "" ||
      step2 === undefined ||
      (step2 !== "step2" && status !== "SupportingDocument")
    ) {
      return (
        <div style={{ paddingLeft: 0, paddingRight: 0 }}>
          {/* Start - Navigation */}
          <Navigation />
          {/* End - Navigation */}

          {/* Start - Main page heading */}
          <div className="main-heading">
            <label className="main-heading-title">
              Step 2 : Supporting Documents(Campaign ID:{campID})
            </label>
          </div>
          {/* End - Main page heading */}

          {/* Start - Steps */}
          <div className="underline">
            <Steps
              type="navigation"
              current={this.state.currentStep}
              //onChange={this.onHandleStepChange}
            >
              {StepTabs.map((item, index) => (
                <Steps.Step
                  title={item.title}
                  icon={item.icon}
                  status={
                    index === this.state.currentStep
                      ? "process"
                      : index > this.state.currentStep
                      ? "wait"
                      : "finish"
                  }
                />
              ))}
            </Steps>
          </div>
          {/* End - Steps */}

          <div className="row">
            <div className="col">
              {/* Start - Step 1 - Supporting Documents */}
              <Card
                className="card-addon"
                bordered={false}
                style={{ display: this.state.displayStep1 }}
              >
                {/* Start - Navigation */}
                <div className="card-navigation">
                  <a style={{ color: "#056eb8" }}>
                    <FaArrowAltCircleLeft
                      size={25}
                      style={{
                        float: "left",
                        marginRight: "5px",
                        marginBottom: "5px",
                      }}
                      onClick={this.handleBackButton}
                    />
                  </a>
                  <button
                    className="btn-save-and-next"
                    onClick={this.submitStep1}
                  >
                    Save and Next
                  </button>
                </div>
                {/* End - Navigation */}

                {/* Start - Error Block */}
                <div
                  class="col"
                  style={{
                    display: this.state.alertDisplay,
                  }}
                >
                  <div class="container-fluid">
                    <div class="card-body">
                      <div
                        class="col-lg-12"
                        style={{ border: this.state.alertBorder }}
                      >
                        {/* TODO : move Delivery Options errors to Step 2 (if any) */}
                        <div
                          id="error-list"
                          className="errorMessage"
                          style={{ fontSize: "17px" }}
                        >
                          {this.state.errors.campaignBudget
                            ? this.state.errors.campaignBudget
                            : ""}
                          {this.state.errors.assetFileName
                            ? this.state.errors.assetFileName
                            : ""}
                          {this.state.errors.assetLinkName
                            ? this.state.errors.assetLinkName
                            : ""}
                          {this.state.asseterrors.AssetName
                            ? this.state.asseterrors.AssetName
                            : ""}
                          {this.state.errors.assetlinkcount
                            ? this.state.errors.assetlinkcount
                            : ""}
                          {this.state.asseterrors.assetlinkcountLink
                            ? this.state.asseterrors.assetlinkcountLink
                            : ""}
                          {this.state.asseterrors.singleAsset
                            ? this.state.asseterrors.singleAsset
                            : ""}
                          {this.state.asseterrors.TouchError
                            ? this.state.asseterrors.TouchError
                            : ""}
                          {this.state.asseterrors.assetFiles
                            ? this.state.asseterrors.assetFiles
                            : ""}
                          {this.state.abmerrors.abmFiles
                            ? this.state.abmerrors.abmFiles
                            : ""}
                          {this.state.backerror.assetUploadError
                            ? this.state.backerror.assetUploadError
                            : ""}
                          {this.state.backerror.OneAsset
                            ? this.state.backerror.OneAsset
                            : ""}
                          {/* Sanjana behalf of raunak-Task 3032-Display error if asset link or asset is not uploaded */}
                          {this.state.abmerrors.properabmFiles
                            ? this.state.abmerrors.properabmFiles
                            : ""}
                          {this.state.asseterrors.assetLinkFiles
                            ? this.state.asseterrors.assetLinkFiles
                            : ""}
                          {this.state.backerror.assetFiles1
                            ? this.state.backerror.assetFiles1
                            : ""}
                          {this.state.backabmerror.abmFiles1
                            ? this.state.backabmerror.abmFiles1
                            : ""}
                          {/* {this.state.backerrorasset.assetFiles1?this.state.backerrorasset.assetFiles1:''} */}
                          {this.state.asseterrors.assetFiles2
                            ? this.state.asseterrors.assetFiles2
                            : ""}
                          {this.state.asseterrors.assetLinkFilename
                            ? this.state.asseterrors.assetLinkFilename
                            : ""}
                          {this.state.errors.suppDocName
                            ? this.state.errors.suppDocName
                            : ""}
                          {this.state.errors.assetFiles
                            ? this.state.errors.assetFiles
                            : ""}
                          {this.state.errors.abmFiles
                            ? this.state.errors.abmFiles
                            : ""}
                          {this.state.leaderrors.assetCount
                            ? this.state.leaderrors.assetCount
                            : ""}
                          {this.state.leaderrors.AssetValidate
                            ? this.state.leaderrors.AssetValidate
                            : ""}
                          {this.state.leaderrors.leadPercentage
                            ? this.state.leaderrors.leadPercentage
                            : ""}

                          {this.state.errors.AssetWrongFile
                            ? this.state.errors.AssetWrongFile
                            : ""}
                          {this.state.errors.suppFileNameError
                            ? this.state.errors.suppFileNameError
                            : ""}
                          {this.state.errors.suppLinkError
                            ? this.state.errors.suppLinkError
                            : ""}
                          {this.state.backabmerror.properabmFiles1
                            ? this.state.backabmerror.properabmFiles1
                            : ""}
                          {this.state.errors.OneAsset
                            ? this.state.errors.OneAsset
                            : ""}
                          {/* 3126-karan-duplicate asset file name */}
                          {this.state.errors.duplicateAssetFileName
                            ? this.state.errors.duplicateAssetFileName
                            : ""}
                          {this.state.excludeError.excludeError1
                            ? this.state.excludeError.excludeError1
                            : ""}
                          {/* kiran-4686-set error */}
                          {this.state.errors.validAssetFileName
                            ? this.state.errors.validAssetFileName
                            : ""}
                          {/* //snehal-task3620-Add exclude domains */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End - Error Block */}

                {/* Start - Upload Supporting Asset */}
                {/* Start - Heading - Upload Supporting Documents */}
                <div className="row sub-content">
                  <div className="col-sm-12 col-md-5 pl-0">
                    <div className="sub-content-heading">
                      <label className="sub-content-title">
                        Upload Supporting Asset
                      </label>
                      <label className="asterisk">*</label>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7 align-self-center mt-2 mt-md-0 px-0">
                    <div className="float-md-right">
                      <Checkbox
                        id="multitouchChk"
                        name="multitouchChk"
                        checked={
                          this.state.multiTouchChk === "Yes" ? true : false
                        }
                        value={this.state.multiTouchChk}
                        onChange={this.multiTouchHandleChange}
                      >
                        Multitouch Required
                      </Checkbox>
                      <Checkbox
                        id="leadPerAssetChk"
                        name="leadPerAssetChk"
                        checked={
                          this.state.leadPerAssetChk === "Yes" ? true : false
                        }
                        value={this.state.leadPerAssetChk}
                        onChange={this.leadPerAssethandleChange}
                      >
                        Asset wise Lead Required
                      </Checkbox>
                    </div>
                  </div>
                </div>
                {/* End - Heading - Upload Supporting Documents */}

                {/* Start - Heading - Asstes */}
                <div className="row mt-3 mb-2 row-margin-fix">
                  <div className="col pl-0">
                    <label className="sub-content-subtitle">Assets :</label>
                  </div>
                  <div className="col pr-0">
                    <label className="float-right sub-content-subtitle">
                      Total Leads : {this.state.totalLeadAllocation}
                    </label>
                  </div>
                </div>
                {/* End - Heading - Asstes */}

                {/* Start - Asset Drag and drop */}
                <div className="row row-margin-fix">
                  <div className="col px-0">
                    <div className="drag-and-drop-container">
                      <div className="d-flex flex-column justify-content-center p-5 drag-and-drop-ui ">
                        <img
                          style={{ height: 35, width: 35 }}
                          src="upload_file.png"
                        />
                        <label className="files-message">
                          Drag and drop or browse files to upload
                        </label>
                        <button className="btn-save-and-next btn-small mx-auto">
                          Choose Files
                        </button>
                      </div>
                      <input
                        className="d-flex flex-column justify-content-center p-5 drag-and-drop-files "
                        type="file"
                        multiple
                        required
                        onChange={this.handleChangeMultipleFile}
                      />
                    </div>
                    <div id="noassetList" style={{ display: "none" }}>
                      0 Asset Files Selected
                    </div>
                  </div>
                </div>
                {/* End - Asset Drag and drop */}

                {/* Start - Uploaded Assets Files Table */}
                <div className="row row-margin-fix">
                  <div
                    className="col"
                    style={{ display: this.state.displayTable }}
                  >
                    <div className="row">
                      <div className="col px-0">
                        <div
                          className="table-responsive mt-3"
                          style={{
                            overflowY: "auto",
                            maxHeight: 400,
                          }}
                        >
                          <table className="table table-bordered">
                            <thead style={{ backgroundColor: "#F5F7F8" }}>
                              <tr>
                                <th scope="col">Selected Files</th>
                                <th scope="col">Asset Name</th>
                                {this.state.leadPerAssetChk === "Yes" && (
                                  <th scope="col">Lead %</th>
                                )}
                                {this.state.leadPerAssetChk === "Yes" && (
                                  <th scope="col">Lead / Asset</th>
                                )}
                                {this.state.multiTouchChk === "Yes" && (
                                  <th scope="col">Multitouch</th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.assetFilesSelectedDocuments.length >=
                                this.state.inputType.length &&
                                this.state.assetFilesSelectedDocuments.map(
                                  (item, i) => {
                                    return (
                                      <tr>
                                        <td style={{ verticalAlign: "middle" }}>
                                          <div
                                            className="row align-items-center"
                                            style={{
                                              marginLeft: 10,
                                              marginRight: 10,
                                              marginTop: 5,
                                              marginBottom: 5,
                                              backgroundColor: item.success
                                                ? "#DAECDA"
                                                : "#FFEEF0",
                                              paddingLeft: 12,
                                              paddingTop: 12,
                                              paddingBottom: 12,
                                              paddingRight: 12,
                                              borderRadius: 2,
                                            }}
                                          >
                                            <div
                                              style={{ padding: 0, margin: 0 }}
                                              className="col-xs-2 col-md-auto"
                                            >
                                              {item.loading ? (
                                                <CircularProgress
                                                  size={26}
                                                  style={{
                                                    height: 26,
                                                    width: 26,
                                                    display: "flex",
                                                    margin: "auto",
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  style={{
                                                    height: 26,
                                                    width: 26,
                                                  }}
                                                  src={
                                                    item.success
                                                      ? "success.png"
                                                      : "failed.png"
                                                  }
                                                />
                                              )}
                                            </div>
                                            <div
                                              style={{ padding: 0, margin: 0 }}
                                              className="col overflow-hidden"
                                            >
                                              <label
                                                id="fileName"
                                                className="mb-0 ml-2 overflow-hidden align-middle"
                                                style={{
                                                  fontWeight: "normal",
                                                  fontSize: 13,
                                                  minWidth: "150px",
                                                }}
                                              >
                                                {item.dataFileID ||
                                                  item.suppDocName}
                                              </label>
                                            </div>
                                            <div
                                              style={{ padding: 0, margin: 0 }}
                                              className="col-xs-2 col-md-auto"
                                            >
                                              <a
                                                supportDocID={item.supportDocID}
                                                data-fileid={
                                                  item.dataFileID ||
                                                  item.suppDocName
                                                }
                                                style={{
                                                  float: "right",
                                                  fontFamily: "roboto",
                                                  fontSize: "12px",
                                                  fontWeight: "500",
                                                }}
                                                onClick={
                                                  this.state
                                                    .isIncompleteSuppDocStepAlreadyComplete &&
                                                  this.dynamicABMFile
                                                }
                                                className={
                                                  this.state
                                                    .isIncompleteSuppDocStepAlreadyComplete
                                                    ? "mb-0 mx-2"
                                                    : "removeAssetFile mb-0 mx-2"
                                                }
                                              >
                                                <img
                                                  style={{
                                                    height: 18,
                                                    width: 18,
                                                  }}
                                                  src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
                                                />
                                              </a>
                                            </div>
                                          </div>
                                        </td>
                                        <td style={{ verticalAlign: "middle" }}>
                                          <span
                                            key={
                                              this.state.inputType[i]
                                                .suppDocName ||
                                              item.suppDocName ||
                                              item.dataFileID
                                            }
                                          >
                                            <input
                                              value={
                                                this.state.inputType[i]
                                                  .assetFileName
                                              }
                                              defaultValue={
                                                this.state.inputType[i]
                                                  .suppDocName
                                              }
                                              onChange={(e) =>
                                                this.handleChangeAssetFileName(
                                                  e,
                                                  i,
                                                  item.suppDocName ||
                                                    item.dataFileID
                                                )
                                              }
                                              type="text"
                                              id={this.state.inputType[i].key}
                                              class="form-control"
                                              style={{ minWidth: "150px" }}
                                              placeholder="Asset Name"
                                              name="assetFileName"
                                              required
                                            />
                                          </span>
                                        </td>
                                        {this.state.leadPerAssetChk ===
                                          "Yes" && (
                                          <td
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            <span
                                              key={
                                                this.state.inputType[i]
                                                  .suppDocName
                                              }
                                            >
                                              <input
                                                value={
                                                  this.state.inputType[i]
                                                    .leadPercentage
                                                }
                                                placeholder="Lead %"
                                                onChange={(e) =>
                                                  this.handleChangeAssetFileName(
                                                    e,
                                                    i,
                                                    item.suppDocName ||
                                                      item.dataFileID
                                                  )
                                                }
                                                type="text"
                                                id={this.state.inputType[i].key}
                                                class="form-control"
                                                name="leadPercentage"
                                                required
                                              />
                                            </span>
                                          </td>
                                        )}
                                        {this.state.leadPerAssetChk ===
                                          "Yes" && (
                                          <td
                                            style={{
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            <span
                                              key={
                                                this.state.inputType[i]
                                                  .suppDocName
                                              }
                                            >
                                              <input
                                                value={
                                                  this.state.inputType[i]
                                                    .leadPerAsset
                                                }
                                                defaultValue={
                                                  this.state.inputType[i]
                                                    .leadPerAsset
                                                }
                                                type="text"
                                                id={this.state.inputType[i].key}
                                                class="form-control"
                                                name="leadPerAsset"
                                                style={{
                                                  borderRadius: "1px",
                                                  margin: "1.5px",
                                                  height: 42,
                                                  backgroundColor: "#F5F5F5",
                                                }}
                                                required
                                                disabled
                                              />
                                            </span>
                                          </td>
                                        )}
                                        {this.state.multiTouchChk === "Yes" && (
                                          <td
                                            style={{
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            <select
                                              id={this.state.inputType[i].key}
                                              value={
                                                this.state.inputType[i]
                                                  .multiTouch
                                              }
                                              name="multiTouch"
                                              onChange={(e) =>
                                                this.handleChangeAssetFileName(
                                                  e,
                                                  i,
                                                  item.suppDocName ||
                                                    item.dataFileID
                                                )
                                              }
                                              style={{
                                                borderRadius: "0.5px",
                                                width: "100%",
                                                border: "0.5px solid #d9d9d9",
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                paddingLeft: 3,
                                                paddingRight: 3,
                                                minWidth: "90px",
                                              }}
                                            >
                                              <option
                                                value="1st Touch"
                                                selected="selected"
                                              >
                                                1st Touch
                                              </option>
                                              <option value="2nd Touch">
                                                2nd Touch
                                              </option>
                                              <option value="3rd Touch">
                                                3rd Touch
                                              </option>
                                            </select>
                                          </td>
                                        )}
                                      </tr>
                                    );
                                  }
                                )}
                              <span
                                id="assetList"
                                style={{ display: "none" }}
                              ></span>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div style={{ color: "red" }}>
                      {this.state.fileerrors.FileError}
                    </div>
                    {/* Start - Message - Total Files  */}
                    <div className="row mt-3">
                      <div className="col-md-auto pl-0">
                        <label className="total-uploaded-message">
                          Total Uploaded Asset Files :{" "}
                          {this.state.assetFilesUploadedSuccessCount +
                            this.state.assetFilesUploadedFailedCount}
                        </label>
                      </div>
                      <div className="col pl-0">
                        <label style={{ fontWeight: "normal" }}>
                          (&nbsp;
                          <span style={{ color: "green" }}>
                            Successfully Uploaded :{" "}
                            {this.state.assetFilesUploadedSuccessCount}
                          </span>
                          ,&nbsp;
                          <span style={{ color: "red" }}>
                            Upload Failed :{" "}
                            {this.state.assetFilesUploadedFailedCount}
                          </span>
                          &nbsp;)
                        </label>
                      </div>
                    </div>
                    {/* End - Message - Total Files  */}
                  </div>
                </div>
                {/* End - Uploaded Assets Files Table */}

                {/* Start - Divider */}
                <hr
                  style={{
                    height: 2,
                    backgroundColor: "#f5f5f5",
                    marginLeft: 15,
                    marginRight: 15,
                    borderWidth: 0,
                  }}
                />
                {/* End - Divider */}

                {/* Start - Assets Link */}
                <div className="row row-margin-fix mt-3 mb-2">
                  <div className="d-flex">
                    <img
                      style={{
                        height: 20,
                        width: 20,
                        marginRight: 10,
                        alignSelf: "center",
                      }}
                      src="attached.png"
                    />
                    <label className="sub-content-subtitle">
                      Assets Links :&nbsp;
                    </label>
                    <a
                      style={{ alignSelf: "center" }}
                      data-toggle="tooltip"
                      onClick={(e) => this.addLink(e)}
                      title="Add one more link"
                    >
                      <img style={{ height: 20, width: 20 }} src="add.png" />
                    </a>
                  </div>
                </div>
                {/* End - Assets Link */}

                {/* Start - Uploaded Assets Links Table */}
                <div
                  className="row"
                  style={{
                    paddingLeft: 30,
                    paddingRight: 30,
                  }}
                >
                  <div className="col px-0">
                    <div
                      className="table-responsive mt-3"
                      style={{
                        overflowY: "auto",
                        maxHeight: 200,
                      }}
                    >
                      <table className="table table-bordered">
                        <thead style={{ backgroundColor: "#F5F7F8" }}>
                          <tr>
                            {this.state.leadPerAssetChk === "Yes" &&
                            this.state.multiTouchChk === "No" ? (
                              <th scope="col">
                                <div>Asset Link Name</div>
                              </th>
                            ) : this.state.multiTouchChk === "Yes" &&
                              this.state.leadPerAssetChk === "No" ? (
                              <th scope="col">
                                <div>Asset Link Name</div>
                              </th>
                            ) : this.state.multiTouchChk === "Yes" &&
                              this.state.leadPerAssetChk === "Yes" ? (
                              <th scope="col">
                                <div>Asset Link Name </div>
                              </th>
                            ) : (
                              // no one is checked
                              <th scope="col">
                                <div>Asset Link Name</div>
                              </th>
                            )}
                            {/* end of Selected Files */}

                            {this.state.leadPerAssetChk === "Yes" &&
                            this.state.multiTouchChk === "No" ? (
                              <th scope="col">
                                <div>Asset Link</div>
                              </th>
                            ) : this.state.multiTouchChk === "Yes" &&
                              this.state.leadPerAssetChk === "No" ? (
                              <th scope="col">
                                <div>Asset Link</div>
                              </th>
                            ) : this.state.multiTouchChk === "Yes" &&
                              this.state.leadPerAssetChk === "Yes" ? (
                              <th scope="col">
                                <div>Asset Link</div>{" "}
                              </th>
                            ) : (
                              //no one is checked
                              <th scope="col">
                                <div>Asset Link</div>{" "}
                              </th>
                            )}

                            {/* end of Asset Name */}

                            {this.state.leadPerAssetChk === "Yes" &&
                            this.state.multiTouchChk === "No" ? (
                              <th scope="col">
                                <div>Lead %</div>
                              </th>
                            ) : this.state.multiTouchChk === "Yes" &&
                              this.state.leadPerAssetChk === "Yes" ? (
                              <th scope="col">
                                <div>Lead %</div>
                              </th>
                            ) : (
                              ""
                            )}
                            {/* end of Lead % */}

                            {this.state.leadPerAssetChk === "Yes" &&
                            this.state.multiTouchChk === "No" ? (
                              <th scope="col">
                                <div>Lead/Asset</div>
                              </th>
                            ) : this.state.multiTouchChk === "Yes" &&
                              this.state.leadPerAssetChk === "Yes" ? (
                              <th scope="col">
                                <div>Lead/Asset</div>
                              </th>
                            ) : (
                              ""
                            )}

                            {/* end of Lead/Asset */}

                            {this.state.multiTouchChk === "Yes" &&
                            this.state.leadPerAssetChk === "No" ? (
                              <th scope="col">
                                <div>Multitouch</div>
                              </th>
                            ) : this.state.multiTouchChk === "Yes" &&
                              this.state.leadPerAssetChk === "Yes" ? (
                              <th scope="col">
                                <div>Multitouch</div>
                              </th>
                            ) : (
                              ""
                            )}
                            {/* end of Multitouch */}

                            {this.state.leadPerAssetChk === "Yes" &&
                            this.state.multiTouchChk === "No" ? (
                              <th scope="col">
                                <div>Remove</div>
                              </th>
                            ) : this.state.multiTouchChk === "Yes" &&
                              this.state.leadPerAssetChk === "No" ? (
                              <th scope="col">
                                <div>Remove</div>
                              </th>
                            ) : this.state.multiTouchChk === "Yes" &&
                              this.state.leadPerAssetChk === "Yes" ? (
                              <th scope="col">
                                <div>Remove</div>
                              </th>
                            ) : (
                              //no one is clicked
                              <th scope="col">
                                <div>Remove</div>
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.dynamicInput.map((dynamicInput, i) => {
                            if (
                              this.state.leadPerAssetChk === "Yes" &&
                              this.state.multiTouchChk === "No"
                            ) {
                              return (
                                <tr>
                                  {/* START - Column 1 - Asset Link Name */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <div
                                      key={dynamicInput.count}
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <Input
                                        id="linkAssetFileName"
                                        name="linkAssetFileName"
                                        className={{}}
                                        placeholder="Enter Asset Link Name"
                                        onChange={this.handleAssetLink.bind(
                                          this,
                                          i
                                        )}
                                        style={{
                                          //width: "520px",
                                          borderRadius: "1px",
                                          minWidth: "150px",
                                        }}
                                        defaultValue={
                                          dynamicInput.linkAssetFileName
                                        }
                                      />
                                    </div>
                                  </td>
                                  {/* END - Column 1 - Asset Link Name */}

                                  {/* START - Column 2 - Asset Link */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <div
                                      key={dynamicInput.count}
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <Input
                                        id="assetLink"
                                        name="assetLink"
                                        placeholder="Enter Asset Link"
                                        onChange={this.handleAssetLink.bind(
                                          this,
                                          i
                                        )}
                                        style={{
                                          //width: "520px",
                                          borderRadius: "1px",
                                          minWidth: "150px",
                                        }}
                                        defaultValue={dynamicInput.assetLink}
                                      />
                                    </div>
                                  </td>
                                  {/* END - Column 2 - Asset Link */}

                                  {/* START - Column 3 - Lead % */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <div key={dynamicInput.count}>
                                      <Input
                                        id="leadPercentage"
                                        name="leadPercentage"
                                        defaultValue={
                                          dynamicInput.leadPercentage
                                        }
                                        placeholder="Enter Lead %"
                                        onChange={this.handleAssetLink.bind(
                                          this,
                                          i
                                        )}
                                        style={{
                                          //width: "120px",
                                          borderRadius: "1px",
                                        }}
                                      />
                                    </div>
                                  </td>
                                  {/* END - Column 3 - Lead % */}

                                  {/* START - Column 4 - Lead/Asset */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <div key={dynamicInput.count}>
                                      <Input
                                        id="leadPerAsset"
                                        name="leadPerAsset"
                                        value={dynamicInput.leadPerAsset}
                                        disabled
                                        style={{
                                          //width: "100px",
                                          borderRadius: "1px",
                                        }}
                                      />
                                    </div>
                                  </td>
                                  {/* START - Column 4 - Lead/Asset */}

                                  {/* START - Column 5 - Remove Icon */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <div
                                      key={dynamicInput.count}
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      &nbsp;&nbsp;{" "}
                                      <a>
                                        <span
                                          class="glyphicon glyphicon-minus-sign"
                                          id={i}
                                          onClick={() => this.removeLink(i)}
                                          style={{
                                            color: "Red",
                                            fontWeight: "bold",
                                            fontSize: "22px",
                                            border: "5px solid white",
                                          }}
                                        ></span>
                                      </a>
                                    </div>
                                  </td>
                                  {/* START - Column 5 - Remove Icon */}
                                </tr>
                              );
                            } else if (
                              this.state.multiTouchChk === "Yes" &&
                              this.state.leadPerAssetChk === "No"
                            ) {
                              return (
                                <tr>
                                  {/* START - Column 1 - Asset Link File Name */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <div key={dynamicInput.count}>
                                      <Input
                                        id="linkAssetFileName"
                                        name="linkAssetFileName"
                                        className={{}}
                                        placeholder="Enter Asset Link Name"
                                        onChange={this.handleAssetLink.bind(
                                          this,
                                          i
                                        )}
                                        style={{
                                          //width: "520px",
                                          borderRadius: "1px",
                                          minWidth: "150px",
                                        }}
                                        defaultValue={
                                          dynamicInput.linkAssetFileName
                                        }
                                      />
                                    </div>
                                  </td>
                                  {/* END - Column 1 - Asset Link File Name */}

                                  {/* START - Column 2 - Asset Link */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <div key={dynamicInput.count}>
                                      <Input
                                        id="assetLink"
                                        name="assetLink"
                                        placeholder="Enter Asset Link"
                                        onChange={this.handleAssetLink.bind(
                                          this,
                                          i
                                        )}
                                        style={{
                                          //width: "520px",
                                          borderRadius: "1px",
                                          minWidth: "150px",
                                        }}
                                        defaultValue={dynamicInput.assetLink}
                                      />
                                    </div>
                                  </td>
                                  {/* END - Column 2 - Asset Link */}

                                  {/* START - Column 3 - Multitouch */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <div key={dynamicInput.count}>
                                      <select
                                        value={dynamicInput.multiTouch}
                                        style={{
                                          borderRadius: "1px",
                                          margin: "1.5px",
                                          border: "0.5px solid #d9d9d9",
                                          paddingTop: 5,
                                          paddingBottom: 5,
                                          paddingLeft: 3,
                                          paddingRight: 3,
                                          //width: "120px",
                                          minWidth: "90px",
                                          width: "100%",
                                        }}
                                        name="multiTouch"
                                        onChange={this.handleAssetLink.bind(
                                          this,
                                          i
                                        )}
                                      >
                                        <option value="1st Touch">
                                          1st Touch
                                        </option>
                                        <option value="2nd Touch">
                                          2nd Touch
                                        </option>
                                        <option value="3rd Touch">
                                          3rd Touch
                                        </option>
                                      </select>
                                    </div>
                                  </td>
                                  {/* END - Column 3 - Multitouch */}

                                  {/* START - Column 4 - Remove Icon */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <div
                                      key={dynamicInput.count}
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      &nbsp;&nbsp;{" "}
                                      <a>
                                        <span
                                          class="glyphicon glyphicon-minus-sign"
                                          id={i}
                                          onClick={() => this.removeLink(i)}
                                          style={{
                                            color: "Red",
                                            fontWeight: "bold",
                                            fontSize: "22px",
                                            border: "5px solid white",
                                          }}
                                        ></span>
                                      </a>
                                    </div>
                                  </td>
                                  {/* END - Column 4 - Remove Icon */}
                                </tr>
                              );
                            } else if (
                              this.state.multiTouchChk === "Yes" &&
                              this.state.leadPerAssetChk === "Yes"
                            ) {
                              return (
                                <tr>
                                  {/* START - Column 1 - Asset Link File Name */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <div key={dynamicInput.count}>
                                      <Input
                                        id="linkAssetFileName"
                                        name="linkAssetFileName"
                                        className={{}}
                                        placeholder="Enter Asset Link Name"
                                        onChange={this.handleAssetLink.bind(
                                          this,
                                          i
                                        )}
                                        style={{
                                          //width: "180px",
                                          borderRadius: "1px",
                                          minWidth: "150px",
                                        }}
                                        defaultValue={
                                          dynamicInput.linkAssetFileName
                                        }
                                      />
                                    </div>
                                  </td>
                                  {/* END - Column 1 - Asset Link File Name */}

                                  {/* START - Column 2 - Asset Link */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <div
                                      key={dynamicInput.count}
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <Input
                                        id="assetLink"
                                        name="assetLink"
                                        placeholder="Enter Asset Link"
                                        onChange={this.handleAssetLink.bind(
                                          this,
                                          i
                                        )}
                                        style={{
                                          //width: "520px",
                                          borderRadius: "1px",
                                          minWidth: "150px",
                                        }}
                                        defaultValue={dynamicInput.assetLink}
                                      />
                                    </div>
                                  </td>
                                  {/* END - Column 2 - Asset Link */}

                                  {/* START - Column 3 - Lead % */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <div key={dynamicInput.count}>
                                      <Input
                                        id="leadPercentage"
                                        name="leadPercentage"
                                        defaultValue={
                                          dynamicInput.leadPercentage
                                        }
                                        placeholder="Enter Lead %"
                                        onChange={this.handleAssetLink.bind(
                                          this,
                                          i
                                        )}
                                        style={{
                                          //width: "180px",
                                          borderRadius: "1px",
                                        }}
                                      />
                                    </div>
                                  </td>
                                  {/* END - Column 3 - Lead % */}

                                  {/* START - Column 4 - Lead/Asset */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <div key={dynamicInput.count}>
                                      <Input
                                        id="leadPerAsset"
                                        name="leadPerAsset"
                                        value={dynamicInput.leadPerAsset}
                                        disabled
                                        style={{
                                          //width: "180px",
                                          borderRadius: "1px",
                                        }}
                                      />
                                    </div>
                                  </td>
                                  {/* END - Column 4 - Lead/Asset */}

                                  {/* START - Column 5 - Multitouch */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <div key={dynamicInput.count}>
                                      <select
                                        value={dynamicInput.multiTouch}
                                        style={{
                                          borderRadius: "1px",
                                          margin: "1.5px",
                                          paddingTop: 5,
                                          paddingBottom: 5,
                                          paddingLeft: 3,
                                          paddingRight: 3,
                                          border: "1px solid #d9d9d9",
                                          //width: "120px",
                                          minWidth: "90px",
                                          width: "100%",
                                        }}
                                        name="multiTouch"
                                        onChange={this.handleAssetLink.bind(
                                          this,
                                          i
                                        )}
                                      >
                                        <option value="1st Touch">
                                          1st Touch
                                        </option>
                                        <option value="2nd Touch">
                                          2nd Touch
                                        </option>
                                        <option value="3rd Touch">
                                          3rd Touch
                                        </option>
                                      </select>
                                    </div>
                                  </td>
                                  {/* END - Column 5 - Multitouch */}

                                  {/* START - Column 6 - Remove Icon */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <div
                                      key={dynamicInput.count}
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <a>
                                        <span
                                          class="glyphicon glyphicon-minus-sign"
                                          id={i}
                                          onClick={() => this.removeLink(i)}
                                          style={{
                                            color: "Red",
                                            fontWeight: "bold",
                                            fontSize: "22px",
                                            border: "5px solid white",
                                          }}
                                        ></span>
                                      </a>
                                    </div>
                                  </td>
                                  {/* END - Column 6 - Remove Icon */}
                                </tr>
                              );
                            } else {
                              return (
                                <tr>
                                  {/* START - Column 1 - Asset Link File Name */}
                                  <td
                                    id="assetLink1"
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    <div
                                      key={dynamicInput.count}
                                      style={{
                                        textAlign: "center",
                                        minWidth: "150px",
                                      }}
                                    >
                                      <Input
                                        id="linkAssetFileName"
                                        name="linkAssetFileName"
                                        className={{}}
                                        placeholder="Enter Asset Link Name"
                                        onChange={this.handleAssetLink.bind(
                                          this,
                                          i
                                        )}
                                        style={{
                                          //width: "520px",
                                          borderRadius: "1px",
                                          minWidth: "150px",
                                        }}
                                        defaultValue={
                                          dynamicInput.linkAssetFileName
                                        }
                                      />
                                    </div>
                                  </td>
                                  {/* END - Column 1 - Asset Link File Name */}

                                  {/* START - Column 2 - Asset Link */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <div key={dynamicInput.count}>
                                      <Input
                                        id="assetLink"
                                        name="assetLink"
                                        placeholder="Enter Asset Link"
                                        onChange={this.handleAssetLink.bind(
                                          this,
                                          i
                                        )}
                                        style={{
                                          //width: "520px",
                                          borderRadius: "1px",
                                          minWidth: "150px",
                                        }}
                                        defaultValue={dynamicInput.assetLink}
                                      />
                                    </div>
                                  </td>
                                  {/* END - Column 2 - Asset Link */}

                                  {/* START - Column 3 - Remove Icon */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <div
                                      key={dynamicInput.count}
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <a>
                                        <span
                                          class="glyphicon glyphicon-minus-sign"
                                          id={i}
                                          onClick={() => this.removeLink(i)}
                                          style={{
                                            color: "Red",
                                            fontWeight: "bold",
                                            fontSize: "22px",
                                            border: "5px solid white",
                                          }}
                                        ></span>
                                      </a>
                                    </div>
                                  </td>
                                  {/* END - Column 3 - Remove Icon */}
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* End - Uploaded Assets Link Table */}
                {/* End - Upload Supporting Asset */}

                {/* Start - Divider */}
                <hr
                  style={{
                    height: 2,
                    backgroundColor: "#f5f5f5",
                    marginLeft: 15,
                    marginRight: 15,
                    borderWidth: 0,
                  }}
                />
                {/* End - Divider */}
                {/* //snehal-task3620-Add exclude domains(ui chages) */}
                {/* Start - ABM and Suppression */}
                {/* Start - Heading - ABM and Suppression */}
                <div className="row row-margin-fix ">
                  <label className="sub-content-title">
                    ABM and Suppression
                  </label>
                </div>
                {/* End - Heading - ABM, Suppression and Exclusion */}

                <div className="row row-margin-fix mt-3">
                  {/* Start - ABM */}
                  <div className="col-md-4 col-sm-12 mr-md-2 px-0">
                    <div className="files-box">
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="mb-0 files-box-header">
                          ABM
                          {this.state.campaignType == "ABM" && (
                            <span style={{ color: "red" }}>&nbsp;*</span>
                          )}
                          &nbsp;:&nbsp;
                          <Tooltip
                            placement="right"
                            title="The ABM file must have 1 column with header name as â€œAccount Nameâ€ OR â€œDomain Nameâ€ "
                          >
                            <Icon type="question-circle" />
                          </Tooltip>
                        </label>
                        <img
                          className="float-right mx-1"
                          style={{ height: 18, width: 18, cursor: "pointer" }}
                          src="upload.png"
                          onClick={this.handleChangeMultipleFileOfABM}
                        />
                      </div>
                      <div id="noabmList" style={{ display: "none" }}>
                        0 ABM Files Selected
                      </div>
                      <div
                        className="mt-2 mb-0"
                        id="abmMessage"
                        style={{
                          color: "green",
                          fontWeight: "normal",
                          fontSize: 14,
                        }}
                      ></div>
                      <div className="mt-2 mb-0" style={{ color: "red" }}>
                        {this.state.abmerror.abmerr1}
                      </div>
                      <div
                        className="mt-2 mb-0"
                        id={"abmError"}
                        style={{
                          color: "red",
                          fontWeight: "normal",
                          fontSize: 14,
                        }}
                      ></div>
                      <div
                        className="mt-2"
                        style={
                          this.state.abmFilesSelectedDocuments.length > 5
                            ? {
                                maxHeight: 280,
                                overflowY: "auto",
                              }
                            : {}
                        }
                      >
                        {this.state.abmFilesSelectedDocuments &&
                          this.state.abmFilesSelectedDocuments.map((item) => {
                            return (
                              <div
                                className="row align-items-center mb-2"
                                supportDocID={item.supportDocID}
                                style={{
                                  backgroundColor: item.success
                                    ? "#DAECDA"
                                    : "#FFEEF0",
                                  paddingLeft: 12,
                                  paddingTop: 12,
                                  paddingBottom: 12,
                                  paddingRight: 12,
                                  marginLeft: 0,
                                  marginRight: 0,
                                  borderRadius: 2,
                                }}
                              >
                                <div
                                  style={{ padding: 0, margin: 0 }}
                                  className="col-xs-2 col-md-auto"
                                >
                                  {item.loading ? (
                                    <CircularProgress
                                      size={26}
                                      style={{
                                        height: 26,
                                        width: 26,
                                        display: "flex",
                                        margin: "auto",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      style={{ height: 26, width: 26 }}
                                      src={
                                        item.success
                                          ? "success.png"
                                          : "failed.png"
                                      }
                                    />
                                  )}
                                </div>
                                <div
                                  style={{ padding: 0, margin: 0 }}
                                  className="col overflow-hidden align-middle"
                                >
                                  <div className="row align-middle m-0 p-0">
                                    <label
                                      className="mb-0 ml-2 overflow-hidden align-middle"
                                      style={{
                                        fontWeight: "normal",
                                        fontSize: 13,
                                      }}
                                    >
                                      {item.dataFileID || item.suppDocName}
                                    </label>
                                  </div>
                                  <div className="row align-middle m-0 p-0">
                                    <label
                                      className="mb-0 ml-2 overflow-hidden align-middle"
                                      style={{
                                        fontWeight: "normal",
                                        fontSize: 11,
                                      }}
                                    >
                                      ABM Count : {item.count}
                                    </label>
                                  </div>
                                </div>
                                <a
                                  supportDocID={item.supportDocID}
                                  data-fileid={
                                    item.dataFileID || item.suppDocName
                                  }
                                  className="col-xs-2 col-md-auto justify-content-center"
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    width: "auto",
                                  }}
                                  onClick={(e) => {
                                    if (
                                      this.state
                                        .isIncompleteSuppDocStepAlreadyComplete
                                    ) {
                                      this.dynamicABMFile(e);
                                    } else {
                                      this.removeABMFileSelectedDocuments(
                                        item.dataFileID || item.suppDocName,
                                        item.supportDocID
                                      );
                                    }
                                  }}
                                >
                                  <img
                                    className="mb-0 mx-2"
                                    style={{
                                      height: 18,
                                      width: 18,
                                    }}
                                    src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
                                  />
                                </a>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {/* End - ABM */}

                  {/* Start - Suppression */}
                  <div className="offset-md-1 offset-sm-0 col-md-4 col-sm-12 px-0 mt-md-0 mt-3">
                    <div className="files-box">
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="mb-0 files-box-header">
                          Suppression :&nbsp;
                          <Tooltip
                            placement="right"
                            title="Suppersion file can contain 1 column Email"
                          >
                            <Icon type="question-circle" />
                          </Tooltip>
                        </label>
                        <img
                          className="float-right mx-1"
                          style={{ height: 18, width: 18, cursor: "pointer" }}
                          src="upload.png"
                          onClick={this.handleChangeMultipleFileOfSuppression}
                        />
                      </div>
                      <div id="nosuppressionList" style={{ display: "none" }}>
                        0 Suppression Files Selected
                      </div>
                      <div
                        id="suppresionMessage"
                        style={{ color: "green" }}
                      ></div>
                      <div style={{ color: "red" }}>
                        {this.state.suppressionerror.suppressionerr}
                      </div>
                      <div
                        id={"suppressionError"}
                        style={{ color: "red" }}
                      ></div>
                      <div
                        className="mt-2"
                        style={
                          this.state.suppressionFilesSelectedDocuments.length >
                          5
                            ? {
                                maxHeight: 280,
                                overflowY: "auto",
                              }
                            : {}
                        }
                      >
                        {this.state.suppressionFilesSelectedDocuments &&
                          this.state.suppressionFilesSelectedDocuments.map(
                            (item) => {
                              return (
                                <div
                                  className="row align-items-center mb-2"
                                  style={{
                                    backgroundColor: item.success
                                      ? "#DAECDA"
                                      : "#FFEEF0",
                                    paddingLeft: 12,
                                    paddingTop: 12,
                                    paddingBottom: 12,
                                    paddingRight: 12,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    borderRadius: 2,
                                  }}
                                >
                                  <div
                                    style={{ padding: 0, margin: 0 }}
                                    className="col-xs-2 col-md-auto"
                                  >
                                    {item.loading ? (
                                      <CircularProgress
                                        size={26}
                                        style={{
                                          height: 26,
                                          width: 26,
                                          display: "flex",
                                          margin: "auto",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        style={{
                                          height: 26,
                                          width: 26,
                                        }}
                                        src={
                                          item.success
                                            ? "success.png"
                                            : "failed.png"
                                        }
                                      />
                                    )}
                                  </div>
                                  <div
                                    style={{ padding: 0, margin: 0 }}
                                    className="col overflow-hidden"
                                  >
                                    <label
                                      className="mb-0 ml-2 overflow-hidden align-middle"
                                      style={{
                                        fontWeight: "normal",
                                        fontSize: 13,
                                      }}
                                    >
                                      {item.dataFileID || item.suppDocName}
                                    </label>
                                  </div>
                                  <a
                                    supportDocID={item.supportDocID}
                                    data-fileid={
                                      item.dataFileID || item.suppDocName
                                    }
                                    className="col-xs-2 col-md-auto justify-content-center"
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      width: "auto",
                                    }}
                                    onClick={(e) => {
                                      if (
                                        this.state
                                          .isIncompleteSuppDocStepAlreadyComplete
                                      ) {
                                        this.dynamicABMFile(e);
                                      } else {
                                        this.removeSuppressionFileSelectedDocuments(
                                          item.dataFileID || item.suppDocName,
                                          item.supportDocID
                                        );
                                      }
                                    }}
                                  >
                                    <img
                                      className="mb-0 mx-2"
                                      style={{
                                        height: 18,
                                        width: 18,
                                      }}
                                      src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
                                    />
                                  </a>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </div>
                  {/* End - Suppression */}
                  {/* sonali-3467-added company name in exclusion file tooltip */}
                  {/* //snehal-task3620-Add exclude domains(ui chages) */}
                </div>
                <br />
                <hr
                  style={{
                    height: 2,
                    backgroundColor: "#f5f5f5",
                    marginLeft: 15,
                    marginRight: 15,
                    borderWidth: 0,
                  }}
                />
                {/* End - Divider */}

                {/* Start - Exclusion and Exclude Domain Extension */}
                {/* Start - Heading - Exclusion and Exclude Domain Extension */}
                <div className="row row-margin-fix ">
                  <label className="sub-content-title">
                    Exclusion and Exclude Domain Extension
                  </label>
                </div>
                <div className="row row-margin-fix mt-3">
                  {/* Start - Exclusion */}
                  <div className="col-md-4 col-sm-12 ml-md-2 px-0 mt-md-0 mt-3">
                    <div className="files-box">
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="mb-0 files-box-header">
                          Exclusion :&nbsp;
                          <Tooltip
                            placement="right"
                            title='Exclusion file must have one column with header name "Domain" or "Company Name"'
                          >
                            <Icon type="question-circle" />
                          </Tooltip>
                        </label>
                        <img
                          className="float-right mx-1"
                          style={{ height: 18, width: 18, cursor: "pointer" }}
                          src="upload.png"
                          onClick={this.handleChangeMultipleFileOfExclusion}
                        />
                      </div>
                      <div id="noexclusionList" style={{ display: "none" }}>
                        0 Exclusion Files Selected
                      </div>
                      <div
                        id="exclusionMessage"
                        style={{ color: "green" }}
                      ></div>
                      <div style={{ color: "red" }}>
                        {this.state.exclusionerror.exclusionerr}
                      </div>
                      <div id={"exclusionError"} style={{ color: "red" }}></div>
                      <div
                        className="mt-2"
                        style={
                          this.state.exclusionFilesSelectedDocuments.length > 5
                            ? {
                                maxHeight: 280,
                                overflowY: "auto",
                              }
                            : {}
                        }
                      >
                        {this.state.exclusionFilesSelectedDocuments &&
                          this.state.exclusionFilesSelectedDocuments.map(
                            (item) => {
                              return (
                                <div
                                  className="row align-items-center mb-2"
                                  style={{
                                    backgroundColor: item.success
                                      ? "#DAECDA"
                                      : "#FFEEF0",
                                    paddingLeft: 12,
                                    paddingTop: 12,
                                    paddingBottom: 12,
                                    paddingRight: 12,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    borderRadius: 2,
                                  }}
                                >
                                  <div
                                    style={{ padding: 0, margin: 0 }}
                                    className="col-xs-2 col-md-auto"
                                  >
                                    {item.loading ? (
                                      <CircularProgress
                                        size={26}
                                        style={{
                                          height: 26,
                                          width: 26,
                                          display: "flex",
                                          margin: "auto",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        style={{
                                          height: 26,
                                          width: 26,
                                        }}
                                        src={
                                          item.success
                                            ? "success.png"
                                            : "failed.png"
                                        }
                                      />
                                    )}
                                  </div>
                                  <div
                                    style={{ padding: 0, margin: 0 }}
                                    className="col overflow-hidden"
                                  >
                                    <label
                                      className="mb-0 ml-2 overflow-hidden align-middle"
                                      style={{
                                        fontWeight: "normal",
                                        fontSize: 13,
                                      }}
                                    >
                                      {item.dataFileID || item.suppDocName}
                                    </label>
                                  </div>
                                  <a
                                    supportDocID={item.supportDocID}
                                    data-fileid={
                                      item.dataFileID || item.suppDocName
                                    }
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      width: "auto",
                                    }}
                                    className="col-xs-2 col-md-auto justify-content-center"
                                    onClick={(e) => {
                                      if (
                                        this.state
                                          .isIncompleteSuppDocStepAlreadyComplete
                                      ) {
                                        this.dynamicABMFile(e);
                                      } else {
                                        this.removeExclusionFileSelectedDocuments(
                                          item.dataFileID,
                                          item.supportDocID
                                        );
                                      }
                                    }}
                                  >
                                    <img
                                      className="mb-0 mx-2"
                                      style={{
                                        height: 18,
                                        width: 18,
                                      }}
                                      src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
                                    />
                                  </a>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </div>
                  {/* End - Exclusion */}
                  {/* //snehal-task3620-Add exclude domains(ui chages) */}
                  <div className="row row-margin-fix mt-3">
                    {/* Start - Exclusion */}
                    <div className="offset-md-2 offset-sm-0 col-md-4 col-sm-12 px-0 mt-md-0 mt-3">
                      <div className="files-box" id="excludeTextcard">
                        <div className="d-flex justify-content-between align-items-center">
                          <label className="mb-12 files-box-header">
                            Exclude Domain Extension :&nbsp;
                            <Tooltip
                              placement="right"
                              title="Leads with domain extension as below will be rejected."
                            >
                              <Icon type="question-circle" />
                            </Tooltip>
                          </label>
                        </div>
                        <textarea
                          className=""
                          style={{
                            height: "175px",
                            resize: "none",
                            border: "1px solid #cccccc",
                          }}
                          id="excludeText"
                          name="excludeText"
                          onChange={this.excludeTextChange}
                          value={this.state.excludeText}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <hr
                  style={{
                    height: 2,
                    backgroundColor: "#f5f5f5",
                    marginLeft: 15,
                    marginRight: 15,
                    borderWidth: 0,
                  }}
                />
                {/* End - Divider */}

                {/* End - ABM, Suppression and Exclusion */}

                {/* Start - Other Documents */}
                <div className="col px-0">
                  {/* Start - Heading - Other Documents */}
                  <div className="row row-margin-fix ">
                    <label className="sub-content-title">Other Documents</label>
                  </div>
                  {/* End - Heading - Other Documents */}

                  {/* Start - Other Documents files */}
                  <div className="row row-margin-fix mt-3">
                    <div className="col-md-4 col-sm-12 mr-md-2 px-0">
                      <div className="files-box">
                        <div className="d-flex justify-content-between align-items-center">
                          <label className="mb-0 files-box-header">
                            Other :&nbsp;
                          </label>
                          <img
                            className="float-right mx-1"
                            style={{ height: 18, width: 18, cursor: "pointer" }}
                            src="upload.png"
                            onClick={this.handleChangeMultipleFileOfOther}
                          />
                        </div>
                        <div id="nootherList" style={{ display: "none" }}>
                          0 Other Files Selected
                        </div>
                        <div id="otherMessage" style={{ color: "green" }}></div>
                        <div
                          className="mt-2"
                          style={
                            this.state.otherFilesSelectedDocuments.length > 5
                              ? {
                                  maxHeight: 280,
                                  overflowY: "auto",
                                }
                              : {}
                          }
                        >
                          {this.state.otherFilesSelectedDocuments &&
                            this.state.otherFilesSelectedDocuments.map(
                              (item) => {
                                return (
                                  <div
                                    className="row align-items-center mb-2"
                                    style={{
                                      backgroundColor: item.success
                                        ? "#DAECDA"
                                        : "#FFEEF0",
                                      paddingLeft: 12,
                                      paddingTop: 12,
                                      paddingBottom: 12,
                                      paddingRight: 12,
                                      marginLeft: 0,
                                      marginRight: 0,
                                      borderRadius: 2,
                                    }}
                                  >
                                    <div
                                      style={{ padding: 0, margin: 0 }}
                                      className="col-xs-2 col-md-auto"
                                    >
                                      {item.loading ? (
                                        <CircularProgress
                                          size={26}
                                          style={{
                                            height: 26,
                                            width: 26,
                                            display: "flex",
                                            margin: "auto",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          style={{
                                            height: 26,
                                            width: 26,
                                          }}
                                          src={
                                            item.success
                                              ? "success.png"
                                              : "failed.png"
                                          }
                                        />
                                      )}
                                    </div>
                                    <div
                                      style={{ padding: 0, margin: 0 }}
                                      className="col overflow-hidden"
                                    >
                                      <label
                                        className="mb-0 ml-2 overflow-hidden align-middle"
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 13,
                                        }}
                                      >
                                        {item.dataFileID || item.suppDocName}
                                      </label>
                                    </div>
                                    <a
                                      supportDocID={item.supportDocID}
                                      data-fileid={
                                        item.dataFileID || item.suppDocName
                                      }
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        width: "auto",
                                      }}
                                      className="col-xs-2 col-md-auto justify-content-center"
                                      onClick={(e) => {
                                        if (
                                          this.state
                                            .isIncompleteSuppDocStepAlreadyComplete
                                        ) {
                                          this.dynamicABMFile(e);
                                        } else {
                                          this.removeOtherFileSelectedDocuments(
                                            item.dataFileID || item.suppDocName,
                                            item.supportDocID
                                          );
                                        }
                                      }}
                                    >
                                      <img
                                        className="mb-0 mx-2"
                                        style={{
                                          height: 18,
                                          width: 18,
                                        }}
                                        src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
                                      />
                                    </a>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End - Other Documents files */}
                </div>
                {/* End - Other Documents */}

                {/* Start - Button Save and Next */}
                <div className="row mt-5 mt-md-0">
                  <div className="col px-0">
                    <button
                      className="btn-save-and-next float-right mr-5"
                      onClick={this.submitStep1}
                    >
                      Save and Next
                    </button>
                  </div>
                </div>
                {/* End - Button Save and Next */}
                <Footer />
              </Card>
              {/* End - Step 1 - Supporting Documents */}

              {/* Start - Step 2 - DI QA & Delivery Format */}
              <Card
                className="card-addon"
                bordered={false}
                style={{ display: this.state.displayStep2 }}
              >
                {/* Start - Navigation */}
                <div className="card-navigation">
                  <a
                    style={{ color: "#056eb8" }}
                    onClick={this.handleBackButton}
                  >
                    <FaArrowAltCircleLeft
                      size={25}
                      style={{
                        float: "left",
                        marginRight: "5px",
                        marginBottom: "5px",
                      }}
                    />
                  </a>
                  <button
                    className="btn-save-and-next"
                    onClick={this.submitStep2}
                  >
                    Save and Finish
                  </button>
                </div>
                {/* End - Navigation */}

                {/* Start - Heading - Quality Assurance */}
                <div className="row sub-content align-items-baseline">
                  <div className="sub-content-heading">
                    <label className="sub-content-title">
                      Quality Assurance&nbsp;
                    </label>
                    <Tooltip
                      placement="right"
                      title="This option will divert all the leads to DI-QA for review after submission from Publisher. All the leads will be visible to Agency only after DI-QA review completion."
                    >
                      <Icon type="question-circle" />
                    </Tooltip>
                  </div>
                  <div className="ml-3">
                    <label
                      className="mb-0 mr-2 ml-lg-3"
                      style={{
                        fontSize: 15,
                        fontWeight: "normal",
                        color: "black",
                      }}
                    >
                      DI QA
                    </label>
                    <Switch
                      size="small"
                      onChange={this.switchHandlechange}
                      checked={this.state.switchValue == "ON" ? true : false}
                    />
                  </div>
                </div>
                {/* End - Heading - Quality Assurance */}

                {/* Start - Delivery Format */}
                {/* Start - Heading - Delivery Format */}
                <div className="row row-margin-fix mt-3 mb-2">
                  <label className="sub-content-title">Delivery Format</label>
                </div>
                {/* End - Heading - Delivery Format */}

                {/* Start - Delivery Format - sub details */}
                <div className="justify-content mt-2 justify-content-between flex-wrap">
                  {" "}
                  {/* Aman task-4765 (Done some layout changes) City-state validation checkbox Div*/}
                  <br />
                  <div className="checkbox-grid row-margin-fix mt-1 mb-sm-3">
                    {/*  //rutja task-4687 added country abbreviation Div*/}
                    {/* <div className="pr-0"> */}
                    <Checkbox
                      name="countryAbbreviation"
                      value={this.state.countryAbbreviation}
                      onChange={(e) => {
                        this.handleChecked(e);
                      }}
                    >
                      {/* Aman task-4765 (added Tooltip) City-state validation checkbox Div*/}
                      <span style={{ color: "#11509F" }}>
                        Country Abbreviation&nbsp;
                        <Tooltip
                          placement="right"
                          title="Select checkbox for country abbreviation in lead template."
                        >
                          <Icon type="question-circle" />
                        </Tooltip>
                      </span>
                    </Checkbox>
                    {/* </div> */}
                    {/* Aman task-4765 added City-state validation checkbox Div*/}
                    <Checkbox
                      disabled //Aman-4765- disabled this check box
                      name="cityStateValidationCheck"
                      checked={this.state.cityStateValidationCheck === "ON"}
                      defaultChecked={
                        this.state.cityStateValidationCheck === "ON"
                      } // Set initial checked state
                      onChange={this.handleCityStateValidationChecked}
                    >
                      {/* Aman task-4765 (added Tooltip) City-state validation checkbox Div*/}
                      <span style={{ color: "#11509F" }}>
                        City/State Validation&nbsp;
                        <Tooltip
                          placement="right"
                          title="Select checkbox for city-state validation for lead upload."
                        >
                          <Icon type="question-circle" />
                        </Tooltip>
                      </span>
                    </Checkbox>
                    <div className="d-flex flex-wrap">
                      {/* <div className="pr-0"> */}
                      <Checkbox
                        disabled={
                          this.state.agencyCustomizeMapping.length > 0
                            ? false
                            : true
                        }
                        name="customizeMapping"
                        value={this.state.customizeMapping}
                        onChange={(e) => {
                          this.handleChecked(e);
                          if (this.state.agencyCustomizeMapping.length > 0) {
                            this.setcustomizeMapping(e);
                          }
                        }}
                      >
                        <span style={{ color: "#11509F" }}>
                          Customize Mapping:
                        </span>
                      </Checkbox>
                      {/* </div> */}
                      {this.state.agencyCustomizeMapping.length > 0 && (
                        <div className="d-flex align-items-baseline">
                          <div className="d-flex mr-md-2 align-items-center">
                            <label
                              style={{
                                marginBottom: 0,
                                color: "#11509F",
                                fontWeight: "normal",
                              }}
                            >
                              Format:&nbsp;
                            </label>
                            <img
                              style={{ height: 18, width: 18 }}
                              src="incremental.jpg"
                              data-toggle="modal"
                              data-target="#customizeMappingAdd"
                              onClick={this.customizeMappingFormatHandleChange}
                            />
                          </div>

                          {/* Start - Modal - when clicked on plus icon */}
                          <AgencyCustomizeMappingModal
                            agencyCustomizeMapping={
                              this.state.agencyCustomizeMappingModal
                            }
                            customizeMappingCancleHandleChange={
                              this.customizeMappingCancleHandleChange
                            }
                          />
                          {/* End - Modal - when clicked on plus icon */}

                          <select
                            //Nilesh-5002- set a valaue
                            value={this.state.newCustomizeMappingFormat}
                            onChange={this.handleFormatChange}
                          >
                            {this.state.agencyCustomizeMapping.map(
                              (agencyCustomizeMapping) => {
                                return (
                                  <option
                                    value={
                                      this.state.sdEditDetail
                                        .customizeMappingFormat
                                    }
                                  >
                                    {/* Nilesh-5001- Add non english sentence when nonEnglishCheck is Yes in delivery_format_mapping_agency table*/}
                                    {agencyCustomizeMapping.format} {agencyCustomizeMapping.nonEnglishCheck == "Yes" ? "- Non English" : ""}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* //Aman-task-4765 added (change the layout as per the given UI)City-state validation checkbox */}
                  <div className="d-flex justify-content mt-2 row-margin-fix justify-content-between flex-wrap">
                    <label className="total-uploaded-message">
                      (Following Checked Fields Will Be Mandatory For Lead
                      Delivery Format)
                    </label>
                  </div>
                </div>
                {/* End - Delivery Format - sub details */}

                {/* Start - Checkbox Grid */}
                <div className="checkbox-grid row-margin-fix mt-1">
                  {/* Start - 1st row - Publisher Id, Campaign Id, Campaign Name, Allocation Id */}
                  <Checkbox
                    className="single-checkbox"
                    id="pID"
                    name="pID"
                    value={this.state.pID}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].pID
                      : "Publisher ID"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="campaignID"
                    name="campaignID"
                    value={this.state.campaignID}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].campID
                      : "Campaign ID"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    //id="campaignName"
                    name="campaignName"
                    disabled //kiran-4740-disable checkbox
                    value={this.state.campaignName}
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].campaignName
                      : "Campaign name"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    //id="reAllocationID"
                    name="reAllocationID"
                    value={this.state.reAllocationID}
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].reAllocationID
                      : "Allocation ID"}
                  </Checkbox>
                  {/* End - 1st row - Publisher Id, Campaign Id, Campaign Name, Allocation Id */}

                  {/* Start - 2nd row - Lead Interaction date, first name, last name, email */}
                  <Checkbox
                    className="single-checkbox"
                    id="leadInteractionDate"
                    name="leadInteractionDate"
                    value={this.state.leadInteractionDate}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0]
                          .leadInteractionDate
                      : "Lead interaction date"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="firstName"
                    name="firstName"
                    value={this.state.firstName}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].firstName
                      : "First name"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="lastName"
                    name="lastName"
                    value={this.state.lastName}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].lastName
                      : "Last name"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="email"
                    name="email"
                    value={this.state.email}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].email
                      : "Email"}
                  </Checkbox>
                  {/* End - 2nd row - Lead Interaction date, first name, last name, email */}

                  {/* Start - 3rd row - work phone, alternate phone no, company name, linkedIn company name */}
                  <Checkbox
                    className="single-checkbox"
                    id="workPhone"
                    name="workPhone"
                    value={this.state.workPhone}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].workPhone
                      : "Work phone"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    //id="alternatePhoneNo"
                    name="alternatePhoneNo"
                    value={this.state.alternatePhoneNo}
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0]
                          .alternatePhoneNo
                      : "Alternate phone no"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="companyName"
                    name="companyName"
                    value={this.state.companyName}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].companyName
                      : "Company name"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="linkedInCompanyName"
                    name="linkedInCompanyName"
                    value={this.state.linkedInCompanyName}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0]
                          .linkedInCompanyName
                      : "LinkedIn company name"}
                  </Checkbox>
                  {/* End - 3rd row - work phone, alternate phone no, company name, linkedIn company name */}

                  {/* Start - 4th row - industry, company employee size, company revenue, job title */}
                  <Checkbox
                    className="single-checkbox"
                    id="industry"
                    name="industry"
                    value={this.state.industry}
                    checked={this.state.industry === "Yes" ? true : false}
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].industry
                      : "Industry"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="companyEmployeeSize"
                    name="companyEmployeeSize"
                    value={this.state.companyEmployeeSize}
                    checked={
                      this.state.companyEmployeeSize === "Yes" ? true : false
                    }
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0]
                          .companyEmployeeSize
                      : "Company employee size"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="companyRevenue"
                    name="companyRevenue"
                    value={this.state.companyRevenue}
                    checked={this.state.companyRevenue === "Yes" ? true : false}
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].companyRevenue
                      : "Company revenue"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="jobTitleDelivery"
                    name="jobTitleDelivery"
                    value={this.state.jobTitleDelivery}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].jobTitle
                      : "Job title"}
                  </Checkbox>
                  {/* End - 4th row - industry, company employee size, company revenue, job title */}

                  {/* Start - 5th row - linkedIn job title, job title validation, job level, job function */}
                  <Checkbox
                    className="single-checkbox"
                    id="linkedInJobTitle"
                    name="linkedInJobTitle"
                    value={this.state.linkedInJobTitle}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0]
                          .linkedInJobTitle
                      : "LinkedIn job title"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="jobTitleValidation"
                    name="jobTitleValidation"
                    checked={
                      this.state.jobTitleValidation === "Yes" ? true : false
                    }
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    Job title validation
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="jobLevelDelivery"
                    name="jobLevelDelivery"
                    checked={
                      this.state.jobLevelDelivery === "Yes" ? true : false
                    }
                    disabled
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].jobLevel
                      : "Job level"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="jobFunction"
                    name="jobFunction"
                    checked={
                      this.state.jobFunctionDelivery === "Yes" ? true : false
                    }
                    disabled
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].jobFunction
                      : "Job function"}
                  </Checkbox>
                  {/* End - 5th row - linkedIn job title, job title validation, job level, job function */}

                  {/* Start - 6th row - Country, state, city, zipcode */}
                  <Checkbox
                    className="single-checkbox"
                    id="countryDelivery"
                    name="countryDelivery"
                    value={this.state.countryDelivery}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].country
                      : "Country"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    //id="state"
                    name="state"
                    value={this.state.state}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].state
                      : "State"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="city"
                    name="city"
                    value={this.state.city}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].city
                      : "City"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="zipCode"
                    name="zipCode"
                    value={this.state.zipCode}
                    defaultChecked
                    disabled
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].zipCode
                      : "Zip code"}
                  </Checkbox>
                  {/* End - 6th row - Country, state, city, zipcode */}

                  {/* Start - 7th row - address, street, asset name, asset id */}
                  <Checkbox
                    className="single-checkbox"
                    id="address"
                    name="address"
                    value={this.state.address}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].address
                      : "Address"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    //id="street"
                    name="street"
                    value={this.state.street}
                    // defaultChecked
                    // disabled
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].street
                      : "Street"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="assetName"
                    name="assetName"
                    value={this.state.assetName}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].assetName
                      : "Asset name"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="assetId"
                    name="assetId"
                    value={this.state.assetId}
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].supportDocID
                      : "Asset ID"}
                  </Checkbox>
                  {/* End - 7th row - address, street, asset name, asset id */}

                  {/* Start - 8th row - linkedIn, domain, ip, comments */}
                  <Checkbox
                    className="single-checkbox"
                    //id="linkedIn"
                    name="linkedIn"
                    value={this.state.linkedIn}
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].linkedIn
                      : "LinkedIn"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    //id="domain"
                    name="domain"
                    value={this.state.domain}
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].domain
                      : "Domain"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="ip"
                    name="ip"
                    value={this.state.ip}
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].ip
                      : "IP"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    //id="comments"
                    name="comments"
                    value={this.state.comments}
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].comments
                      : "Comments"}
                  </Checkbox>
                  {/* End - 8th row - linkedIn, domain, ip, comments */}

                  {/* Start - rest of the rows for Extra fields */}
                  <Checkbox
                    className="single-checkbox"
                    name="extra1"
                    value={this.state.extra1}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra1
                      : "Extra 1"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra2"
                    value={this.state.extra2}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra2
                      : "Extra 2"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra3"
                    value={this.state.extra3}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra3
                      : "Extra 3"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra4"
                    value={this.state.extra4}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra4
                      : "Extra 4"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra5"
                    value={this.state.extra5}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra5
                      : "Extra 5"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra6"
                    value={this.state.extra6}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra6
                      : "Extra 6"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra7"
                    value={this.state.extra7}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra7
                      : "Extra 7"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra8"
                    value={this.state.extra8}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra8
                      : "Extra 8"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    name="extra9"
                    value={this.state.extra9}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra9
                      : "Extra 9"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra10"
                    value={this.state.extra10}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra10
                      : "Extra 10"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra11"
                    value={this.state.extra11}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra11
                      : "Extra 11"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra12"
                    value={this.state.extra12}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra12
                      : "Extra 12"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra13"
                    value={this.state.extra13}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra13
                      : "Extra 13"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    name="extra14"
                    value={this.state.extra14}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra14
                      : "Extra 14"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    name="extra15"
                    value={this.state.extra15}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra15
                      : "Extra 15"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    name="extra16"
                    value={this.state.extra16}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra16
                      : "Extra 16"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    name="extra17"
                    value={this.state.extra17}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra17
                      : "Extra 17"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    name="extra18"
                    value={this.state.extra18}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra18
                      : "Extra 18"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra19"
                    value={this.state.extra19}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra19
                      : "Extra 19"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    name="extra20"
                    value={this.state.extra20}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {/* Priyanka-4532-Added hutk label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra20
                      : "Extra 20 (hutk)"}
                  </Checkbox>

                  {/* Aman 5085 -set the other extra filed 21 to 30 value */}

                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra21 && (  // Check if extra21 is defined and not falsy
                          this.state.agencyCustomizeMappingModal[0].extra21 !== "" || this.state.agencyCustomizeMappingModal[0].extra21 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra21"
                                  value={this.state.extra21}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra21
                                      : "Extra 21"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra22 && (
                          this.state.agencyCustomizeMappingModal[0].extra22 !== "" || this.state.agencyCustomizeMappingModal[0].extra22 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra22"
                                  value={this.state.extra22}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra22
                                      : "Extra 22"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra23 && (
                          this.state.agencyCustomizeMappingModal[0].extra23 !== "" || this.state.agencyCustomizeMappingModal[0].extra23 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra23"
                                  value={this.state.extra23}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra23
                                      : "Extra 23"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra24 && (
                          this.state.agencyCustomizeMappingModal[0].extra24 !== "" || this.state.agencyCustomizeMappingModal[0].extra24 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra24"
                                  value={this.state.extra24}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra24
                                      : "Extra 24"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra25 && (
                          this.state.agencyCustomizeMappingModal[0].extra25 !== "" || this.state.agencyCustomizeMappingModal[0].extra25 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra25"
                                  value={this.state.extra25}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra25
                                      : "Extra 25"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra26 && (
                          this.state.agencyCustomizeMappingModal[0].extra26 !== "" || this.state.agencyCustomizeMappingModal[0].extra26 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra26"
                                  value={this.state.extra26}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra26
                                      : "Extra 26"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra27 && (
                          this.state.agencyCustomizeMappingModal[0].extra27 !== "" || this.state.agencyCustomizeMappingModal[0].extra27 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra27"
                                  value={this.state.extra27}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra27
                                      : "Extra 27"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra28 && (
                          this.state.agencyCustomizeMappingModal[0].extra28 !== "" || this.state.agencyCustomizeMappingModal[0].extra28 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra28"
                                  value={this.state.extra28}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra28
                                      : "Extra 28"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra29 && (
                          this.state.agencyCustomizeMappingModal[0].extra29 !== "" || this.state.agencyCustomizeMappingModal[0].extra29 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra29"
                                  value={this.state.extra29}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra29
                                      : "Extra 29"}
                              </Checkbox>
                          ) : null
                      )
                  )}
   
                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra30 && (
                          this.state.agencyCustomizeMappingModal[0].extra30 !== "" || this.state.agencyCustomizeMappingModal[0].extra30 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra30"
                                  value={this.state.extra30}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra30
                                      : "Extra 30"}
                              </Checkbox>
                          ) : null
                      )
                  )}


                  {/* End - rest of the rows for Extra fields */}
                </div>
                {/* End - Checkbox Grid */}
                {/* End - Delivery Format */}

                {/* Start - Button Save and Finish */}
                <div className="row mt-5">
                  <div className="col px-0">
                    <button
                      className="btn-save-and-next float-right mr-5"
                      onClick={this.submitStep2}
                    >
                      Save and Finish
                    </button>
                  </div>
                </div>
                {/* End - Button Save and Finish */}
                <Footer />
              </Card>
              {/* End - Step 2 - DI QA & Delivery Format */}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ paddingLeft: 0, paddingRight: 0 }}>
          {/* Start - Navigation */}
          <Navigation />
          {/* End - Navigation */}

          {/* Start - Main page heading */}
          <div className="main-heading">
            <label className="main-heading-title">
              Step 2 : Supporting Documents(Campaign ID:{campID})
            </label>
          </div>
          {/* End - Main page heading */}

          {/* Start - Steps */}
          <div className="underline">
            <Steps
              type="navigation"
              current={this.state.currentStep}
              //onChange={this.onHandleStepChange}
            >
              {StepTabs.map((item, index) => (
                <Steps.Step
                  title={item.title}
                  icon={item.icon}
                  status={
                    index === this.state.currentStep
                      ? "process"
                      : index > this.state.currentStep
                      ? "wait"
                      : "finish"
                  }
                />
              ))}
            </Steps>
          </div>
          {/* End - Steps */}

          <div className="row">
            <div className="col">
              {/* Start - Step 1 - Supporting Documents */}
              <Card
                className="card-addon"
                bordered={false}
                style={{ display: this.state.displayStep1 }}
              >
                {/* Start - Navigation */}
                <div className="card-navigation">
                  <a style={{ color: "#056eb8" }}>
                    <FaArrowAltCircleLeft
                      size={25}
                      style={{
                        float: "left",
                        marginRight: "5px",
                        marginBottom: "5px",
                      }}
                      onClick={this.handleBackButton}
                    />
                  </a>
                  <button
                    className="btn-save-and-next"
                    onClick={this.submitStep1}
                  >
                    Save and Next
                  </button>
                </div>
                {/* End - Navigation */}

                {/* Start - Error Block */}
                <div
                  class="col"
                  style={{
                    display: this.state.alertDisplay,
                  }}
                >
                  <div class="container-fluid">
                    <div class="card-body">
                      <div
                        class="col-lg-12"
                        style={{ border: this.state.alertBorder }}
                      >
                        {/* <li style={{fontSize:'16px',color:'red'}}>Please fill all below required fields</li> */}
                        <div
                          id="error-list"
                          className="errorMessage"
                          style={{ fontSize: "17px" }}
                        >
                          {this.state.errors.budget
                            ? this.state.errors.budget
                            : ""}
                          {this.state.errors.suppDocName
                            ? this.state.errors.suppDocName
                            : ""}
                          {this.state.asseterrors.assetFiles
                            ? this.state.asseterrors.assetFiles
                            : ""}
                          {this.state.abmerrors.abmFiles
                            ? this.state.abmerrors.abmFiles
                            : ""}
                          {this.state.abmerrors.properabmFiles
                            ? this.state.abmerrors.properabmFiles
                            : ""}
                          {this.state.errors.assetCount
                            ? this.state.errors.assetCount
                            : ""}
                          {this.state.errors.AssetValidate
                            ? this.state.errors.AssetValidate
                            : ""}
                          {this.state.leaderrors.assetCount
                            ? this.state.leaderrors.assetCount
                            : ""}
                          {this.state.leaderrors.leadPercentage
                            ? this.state.leaderrors.leadPercentage
                            : ""}
                          {this.state.leaderrors.AssetValidate
                            ? this.state.leaderrors.AssetValidate
                            : ""}
                          {this.state.leadAsseterrors.assetsleadCount
                            ? this.state.leadAsseterrors.assetsleadCount
                            : ""}
                          {this.state.linkleadserrors.assetLinkerror
                            ? this.state.linkleadserrors.assetLinkerror
                            : ""}
                          {this.state.backerror.assetFiles1
                            ? this.state.backerror.assetFiles1
                            : ""}
                          {this.state.backabmerror.abmFiles1
                            ? this.state.backabmerror.abmFiles1
                            : ""}
                          {this.state.backerrorasset.assetFiles2
                            ? this.state.backerrorasset.assetFiles2
                            : ""}
                          {this.state.backerror.assetUploadError
                            ? this.state.backerror.assetUploadError
                            : ""}
                          {/* {this.state.backerrorasset.assetFiles1?this.state.backerrorasset.assetFiles1:''} */}
                          {this.state.errors.TouchErrorBack
                            ? this.state.errors.TouchErrorBack
                            : ""}
                          {this.state.backerror.OneAsset
                            ? this.state.backerror.OneAsset
                            : ""}
                          {this.state.errors.sameTouchBack
                            ? this.state.errors.sameTouchBack
                            : ""}
                          {this.state.linknameerror.linkname
                            ? this.state.linknameerror.linkname
                            : ""}
                          {this.state.errors.AssetWrongFile
                            ? this.state.errors.AssetWrongFile
                            : ""}
                          {this.state.errors.assetFiles
                            ? this.state.errors.assetFiles
                            : ""}
                          {this.state.errors.abmFiles
                            ? this.state.errors.abmFiles
                            : ""}
                          {this.state.errors.suppFileNameError
                            ? this.state.errors.suppFileNameError
                            : ""}
                          {this.state.errors.suppLinkError
                            ? this.state.errors.suppLinkError
                            : ""}
                          {this.state.backabmerror.properabmFiles1
                            ? this.state.backabmerror.properabmFiles1
                            : ""}
                          {/* 3126-karan-duplicate asset file name */}
                          {this.state.errors.duplicateAssetFileName
                            ? this.state.errors.duplicateAssetFileName
                            : ""}
                          {this.state.excludeError.excludeError1
                            ? this.state.excludeError.excludeError1
                            : ""}
                          {/* kiran-4686 set error*/}
                          {this.state.errors.validAssetFileName
                            ? this.state.errors.validAssetFileName
                            : ""}
                          {/* //snehal-task3620-Add exclude domains(ui chages) */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                  {/* </div> */}
                </div>
                {/* End - Error Block */}

                {/* Start - Upload Supporting Asset */}
                {/* Start - Heading - Upload Supporting Documents */}
                <div className="row sub-content">
                  <div className="col-sm-12 col-md-5 pl-0">
                    <div className="sub-content-heading">
                      <label className="sub-content-title">
                        Upload Supporting Asset
                      </label>
                      <label className="asterisk">*</label>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7 align-self-center mt-2 mt-md-0 px-0">
                    <div className="float-md-right">
                      <Checkbox
                        id="multitouchChk"
                        name="multitouchChk"
                        checked={
                          this.state.multiTouchChkBack === "Yes" ? true : false
                        }
                        value={this.state.multiTouchChkBack}
                        onChange={this.multiTouchHandleChangeBack}
                      >
                        Multitouch Required
                      </Checkbox>
                      <Checkbox
                        id="leadPerAssetChk"
                        name="leadPerAssetChk"
                        checked={this.state.checkedValue}
                        value={this.state.leadPerAssetChk}
                        onChange={this.leadPerAssethandleChangeBack}
                      >
                        Asset wise Lead Required
                      </Checkbox>
                    </div>
                  </div>
                </div>
                {/* End - Heading - Upload Supporting Documents */}

                {/* Start - Heading - Asstes */}
                <div className="row mt-3 mb-2 row-margin-fix">
                  <div className="col pl-0">
                    <label className="sub-content-subtitle">Assets :</label>
                  </div>
                  <div className="col pr-0">
                    <label className="float-right sub-content-subtitle">
                      Total Leads : {this.state.totalLeadAllocation}
                    </label>
                  </div>
                </div>
                {/* End - Heading - Asstes */}

                {/* Start - Asset Drag and drop */}
                <div className="row row-margin-fix">
                  <div className="col px-0">
                    <div className="drag-and-drop-container">
                      <div className="d-flex flex-column justify-content-center p-5 drag-and-drop-ui ">
                        <img
                          style={{ height: 35, width: 35 }}
                          src="upload_file.png"
                        />
                        <label className="files-message">
                          Drag and drop or browse files to upload
                        </label>
                        <button className="btn-save-and-next btn-small mx-auto">
                          Choose Files
                        </button>
                      </div>
                      <input
                        className="d-flex flex-column justify-content-center p-5 drag-and-drop-files "
                        type="file"
                        multiple
                        required
                        onChange={this.handleChangeMultipleFile}
                      />
                    </div>
                    <div id="noassetList" style={{ display: "none" }}>
                      0 Asset Files Selected
                    </div>
                  </div>
                </div>
                {/* End - Asset Drag and drop */}

                {/* Start - Uploaded Assets Files Table */}
                <div className="row row-margin-fix">
                  <div
                    className="col"
                    style={{ display: this.state.displayTable }}
                  >
                    <div className="row">
                      <div className="col px-0">
                        <div
                          className="table-responsive mt-3"
                          style={{
                            overflowY: "auto",
                            maxHeight: 400,
                          }}
                        >
                          <table className="table table-bordered">
                            <thead style={{ backgroundColor: "#F5F7F8" }}>
                              <tr>
                                <th scope="col">Selected Files</th>
                                <th scope="col">Asset Name</th>
                                {this.state.leadPerAssetChk === "Yes" && (
                                  <th scope="col">Lead %</th>
                                )}
                                {this.state.leadPerAssetChk === "Yes" && (
                                  <th scope="col">Lead / Asset</th>
                                )}
                                {this.state.multiTouchChkBack === "Yes" && (
                                  <th scope="col">Multitouch</th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.assetFilesSelectedDocuments.length >=
                                this.state.inputType.length &&
                                this.state.assetFilesSelectedDocuments.map(
                                  (item, i) => {
                                    return (
                                      <tr>
                                        <td style={{ verticalAlign: "middle" }}>
                                          <div
                                            className="row align-items-center"
                                            style={{
                                              marginLeft: 10,
                                              marginRight: 10,
                                              marginTop: 5,
                                              marginBottom: 5,
                                              backgroundColor: item.success
                                                ? "#DAECDA"
                                                : "#FFEEF0",
                                              paddingLeft: 12,
                                              paddingTop: 12,
                                              paddingBottom: 12,
                                              paddingRight: 12,
                                              borderRadius: 2,
                                            }}
                                          >
                                            <div
                                              style={{ padding: 0, margin: 0 }}
                                              className="col-xs-2 col-md-auto"
                                            >
                                              {item.loading ? (
                                                <CircularProgress
                                                  size={26}
                                                  style={{
                                                    height: 26,
                                                    width: 26,
                                                    display: "flex",
                                                    margin: "auto",
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  style={{
                                                    height: 26,
                                                    width: 26,
                                                  }}
                                                  src={
                                                    item.success
                                                      ? "success.png"
                                                      : "failed.png"
                                                  }
                                                />
                                              )}
                                            </div>
                                            <div
                                              style={{ padding: 0, margin: 0 }}
                                              className="col overflow-hidden"
                                            >
                                              <label
                                                id="fileName"
                                                className="mb-0 ml-2 overflow-hidden align-middle"
                                                style={{
                                                  fontWeight: "normal",
                                                  fontSize: 13,
                                                  minWidth: "150px",
                                                }}
                                              >
                                                {item.suppDocName ||
                                                  item.dataFileID}
                                              </label>
                                            </div>
                                            <div
                                              style={{ padding: 0, margin: 0 }}
                                              className="col-xs-2 col-md-auto"
                                            >
                                              <a
                                                id={
                                                  item.typeOfSuppDoc +
                                                  " " +
                                                  item.supportDocID
                                                }
                                                name={
                                                  item.suppDocName ||
                                                  item.dataFileID
                                                }
                                                style={{
                                                  float: "right",
                                                  fontFamily: "roboto",
                                                  fontSize: "12px",
                                                  fontWeight: "500",
                                                }}
                                                className="mb-0 mx-2"
                                                onClick={this.dynamicABMFile}
                                              >
                                                <img
                                                  style={{
                                                    height: 18,
                                                    width: 18,
                                                  }}
                                                  src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
                                                />
                                              </a>
                                            </div>
                                          </div>
                                        </td>
                                        <td style={{ verticalAlign: "middle" }}>
                                          <span
                                            key={
                                              item.suppDocName ||
                                              item.dataFileID
                                            }
                                          >
                                            <input
                                              defaultValue={
                                                item.suppDocName ||
                                                item.dataFileID
                                              }
                                              onChange={(e) =>
                                                this.handleChangeAssetFileName(
                                                  e,
                                                  i,
                                                  item.suppDocName ||
                                                    item.dataFileID
                                                )
                                              }
                                              type="text"
                                              id={item.key}
                                              class="form-control"
                                              style={{ minWidth: "150px" }}
                                              placeholder="Asset Name"
                                              name="assetFileName"
                                              required
                                            />
                                          </span>
                                        </td>
                                        {this.state.leadPerAssetChk ===
                                          "Yes" && (
                                          <td
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            <span
                                              key={
                                                item.suppDocName ||
                                                item.dataFileID
                                              }
                                            >
                                              <input
                                                value={item.leadPercentage}
                                                placeholder="Lead %"
                                                onChange={(e) =>
                                                  this.handleChangeAssetFileName(
                                                    e,
                                                    i,
                                                    item.suppDocName ||
                                                      item.dataFileID
                                                  )
                                                }
                                                type="text"
                                                id={item.key}
                                                class="form-control"
                                                name="leadPercentage"
                                                required
                                              />
                                            </span>
                                          </td>
                                        )}
                                        {this.state.leadPerAssetChk ===
                                          "Yes" && (
                                          <td
                                            style={{
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            <span
                                              key={
                                                item.suppDocName ||
                                                item.dataFileID
                                              }
                                            >
                                              <input
                                                value={item.leadPerAsset}
                                                defaultValue={item.leadPerAsset}
                                                type="text"
                                                id={item.key}
                                                class="form-control"
                                                name="leadPerAsset"
                                                style={{
                                                  borderRadius: "1px",
                                                  margin: "1.5px",
                                                  height: 42,
                                                  backgroundColor: "#F5F5F5",
                                                }}
                                                required
                                                disabled
                                              />
                                            </span>
                                          </td>
                                        )}
                                        {this.state.multiTouchChkBack ===
                                          "Yes" && (
                                          <td
                                            style={{
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            <select
                                              id={item.key}
                                              value={item.multiTouch}
                                              name="multiTouch"
                                              onChange={(e) =>
                                                this.handleChangeAssetFileName(
                                                  e,
                                                  i,
                                                  item.suppDocName ||
                                                    item.dataFileID
                                                )
                                              }
                                              style={{
                                                borderRadius: "0.5px",
                                                width: "100%",
                                                border: "0.5px solid #d9d9d9",
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                paddingLeft: 3,
                                                paddingRight: 3,
                                                minWidth: "90px",
                                              }}
                                            >
                                              <option
                                                value="1st Touch"
                                                selected="selected"
                                              >
                                                1st Touch
                                              </option>
                                              <option value="2nd Touch">
                                                2nd Touch
                                              </option>
                                              <option value="3rd Touch">
                                                3rd Touch
                                              </option>
                                            </select>
                                          </td>
                                        )}
                                      </tr>
                                    );
                                  }
                                )}
                              <span
                                id="assetList"
                                style={{ display: "none" }}
                              ></span>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div style={{ color: "red" }}>
                      {this.state.fileerrors.FileError}
                    </div>
                    {/* Start - Message - Total Files  */}
                    <div className="row mt-3">
                      <div className="col-md-auto pl-0">
                        <label className="total-uploaded-message">
                          Total Uploaded Asset Files :{" "}
                          {this.state.assetFilesUploadedSuccessCount +
                            this.state.assetFilesUploadedFailedCount}
                        </label>
                      </div>
                      <div className="col pl-0">
                        <label style={{ fontWeight: "normal" }}>
                          (&nbsp;
                          <span style={{ color: "green" }}>
                            Successfully Uploaded :{" "}
                            {this.state.assetFilesUploadedSuccessCount}
                          </span>
                          ,&nbsp;
                          <span style={{ color: "red" }}>
                            Upload Failed :{" "}
                            {this.state.assetFilesUploadedFailedCount}
                          </span>
                          &nbsp;)
                        </label>
                      </div>
                    </div>
                    {/* End - Message - Total Files  */}
                  </div>
                </div>
                {/* End - Uploaded Assets Files Table */}

                {/* Start - Divider */}
                <hr
                  style={{
                    height: 2,
                    backgroundColor: "#f5f5f5",
                    marginLeft: 15,
                    marginRight: 15,
                    borderWidth: 0,
                  }}
                />
                {/* End - Divider */}

                {/* Start - Assets Link */}
                <div className="row row-margin-fix mt-3 mb-2">
                  <div className="d-flex">
                    <img
                      style={{
                        height: 20,
                        width: 20,
                        marginRight: 10,
                        alignSelf: "center",
                      }}
                      src="attached.png"
                    />
                    <label className="sub-content-subtitle">
                      Assets Links :&nbsp;
                    </label>
                    <a
                      style={{ alignSelf: "center" }}
                      data-toggle="tooltip"
                      onClick={(e) => this.backAddLink(e)}
                      title="Add one more link"
                    >
                      <img style={{ height: 20, width: 20 }} src="add.png" />
                    </a>
                  </div>
                </div>
                {/* End - Assets Link */}

                {/* Start - Uploaded Assets Links Table */}
                <div
                  className="row"
                  style={{
                    paddingLeft: 30,
                    paddingRight: 30,
                  }}
                >
                  <div className="col px-0">
                    <div
                      className="table-responsive mt-3"
                      style={{
                        overflowY: "auto",
                        maxHeight: 200,
                      }}
                    >
                      <table className="table table-bordered">
                        <thead style={{ backgroundColor: "#F5F7F8" }}>
                          <tr>
                            {this.state.leadPerAssetChk === "Yes" &&
                            this.state.multiTouchChkBack === "No" ? (
                              <th scope="col">
                                <div>Asset Link Name</div>
                              </th>
                            ) : this.state.multiTouchChkBack === "Yes" &&
                              this.state.leadPerAssetChk === "No" ? (
                              <th scope="col">
                                <div>Asset Link Name</div>
                              </th>
                            ) : this.state.multiTouchChkBack === "Yes" &&
                              this.state.leadPerAssetChk === "Yes" ? (
                              <th scope="col">
                                <div>Asset Link Name </div>
                              </th>
                            ) : (
                              // no one is checked
                              <th scope="col">
                                <div>Asset Link Name</div>
                              </th>
                            )}
                            {/* end of Selected Files */}

                            {this.state.leadPerAssetChk === "Yes" &&
                            this.state.multiTouchChkBack === "No" ? (
                              <th scope="col">
                                <div>Asset Link</div>
                              </th>
                            ) : this.state.multiTouchChkBack === "Yes" &&
                              this.state.leadPerAssetChk === "No" ? (
                              <th scope="col">
                                <div>Asset Link</div>
                              </th>
                            ) : this.state.multiTouchChkBack === "Yes" &&
                              this.state.leadPerAssetChk === "Yes" ? (
                              <th scope="col">
                                <div>Asset Link</div>{" "}
                              </th>
                            ) : (
                              //no one is checked
                              <th scope="col">
                                <div>Asset Link</div>{" "}
                              </th>
                            )}

                            {/* end of Asset Name */}

                            {this.state.leadPerAssetChk === "Yes" &&
                            this.state.multiTouchChkBack === "No" ? (
                              <th scope="col">
                                <div>Lead %</div>
                              </th>
                            ) : this.state.multiTouchChkBack === "Yes" &&
                              this.state.leadPerAssetChk === "Yes" ? (
                              <th scope="col">
                                <div>Lead %</div>
                              </th>
                            ) : (
                              ""
                            )}
                            {/* end of Lead % */}

                            {this.state.leadPerAssetChk === "Yes" &&
                            this.state.multiTouchChkBack === "No" ? (
                              <th scope="col">
                                <div>Lead/Asset</div>
                              </th>
                            ) : this.state.multiTouchChkBack === "Yes" &&
                              this.state.leadPerAssetChk === "Yes" ? (
                              <th scope="col">
                                <div>Lead/Asset</div>
                              </th>
                            ) : (
                              ""
                            )}

                            {/* end of Lead/Asset */}

                            {this.state.multiTouchChkBack === "Yes" &&
                            this.state.leadPerAssetChk === "No" ? (
                              <th scope="col">
                                <div>Multitouch</div>
                              </th>
                            ) : this.state.multiTouchChkBack === "Yes" &&
                              this.state.leadPerAssetChk === "Yes" ? (
                              <th scope="col">
                                <div>Multitouch</div>
                              </th>
                            ) : (
                              ""
                            )}
                            {/* end of Multitouch */}

                            {/* TODO - should directly output Remove column as it is common for all */}
                            {this.state.leadPerAssetChk === "Yes" &&
                            this.state.multiTouchChkBack === "No" ? (
                              <th scope="col">
                                <div>Remove</div>
                              </th>
                            ) : this.state.multiTouchChkBack === "Yes" &&
                              this.state.leadPerAssetChk === "No" ? (
                              <th scope="col">
                                <div>Remove</div>
                              </th>
                            ) : this.state.multiTouchChkBack === "Yes" &&
                              this.state.leadPerAssetChk === "Yes" ? (
                              <th scope="col">
                                <div>Remove</div>
                              </th>
                            ) : (
                              //no one is clicked
                              <th scope="col">
                                <div>Remove</div>
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.backDynamicInput.map(
                            (backDynamicInput, i) => {
                              if (
                                this.state.leadPerAssetChk === "Yes" &&
                                this.state.multiTouchChkBack === "No"
                              ) {
                                return (
                                  <tr>
                                    {/* START - Column 1 - Asset Link Name */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div
                                        key={backDynamicInput.count}
                                        style={{
                                          textAlign: "center",
                                          minWidth: "150px",
                                        }}
                                      >
                                        <Input
                                          id="linkAssetFileName"
                                          name="linkAssetFileName"
                                          placeholder="Enter Asset Link Name"
                                          onChange={this.handleAssetLink.bind(
                                            this,
                                            i
                                          )}
                                          style={{
                                            //width: "520px",
                                            borderRadius: "1px",
                                            minWidth: "150px",
                                          }}
                                          defaultValue={
                                            backDynamicInput.linkAssetFileName
                                          }
                                        />
                                      </div>
                                    </td>
                                    {/* END - Column 1 - Asset Link Name */}

                                    {/* START - Column 2 - Asset Link */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div
                                        key={backDynamicInput.count}
                                        style={{
                                          textAlign: "center",
                                          minWidth: "150px",
                                        }}
                                      >
                                        <Input
                                          id="assetLink"
                                          name="assetLink"
                                          placeholder="Enter Asset Link"
                                          onChange={this.handleAssetLink.bind(
                                            this,
                                            i
                                          )}
                                          style={{
                                            //width: "520px",
                                            borderRadius: "1px",
                                            minWidth: "150px",
                                          }}
                                          defaultValue={
                                            backDynamicInput.assetLink
                                          }
                                        />
                                      </div>
                                    </td>
                                    {/* END - Column 2 - Asset Link */}

                                    {/* START - Column 3 - Lead % */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div key={backDynamicInput.count}>
                                        <Input
                                          id="leadPercentage"
                                          name="leadPercentage"
                                          defaultValue={
                                            backDynamicInput.leadPercentage
                                          }
                                          placeholder="Enter Lead %"
                                          onChange={this.handleAssetLink.bind(
                                            this,
                                            i
                                          )}
                                          style={{
                                            //width: "120px",
                                            borderRadius: "1px",
                                          }}
                                        />
                                      </div>
                                    </td>
                                    {/* END - Column 3 - Lead % */}

                                    {/* START - Column 4 - Lead/Asset */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div key={backDynamicInput.count}>
                                        <Input
                                          id="leadPerAsset"
                                          name="leadPerAsset"
                                          value={backDynamicInput.leadPerAsset}
                                          disabled
                                          style={{
                                            //width: "100px",
                                            borderRadius: "1px",
                                          }}
                                        />
                                      </div>
                                    </td>
                                    {/* START - Column 4 - Lead/Asset */}

                                    {/* START - Column 5 - Remove Icon */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div
                                        key={backDynamicInput.count}
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        &nbsp;&nbsp;{" "}
                                        <a>
                                          <span
                                            class="glyphicon glyphicon-minus-sign"
                                            id={i}
                                            name={backDynamicInput.supportDocID}
                                            suppDocName={
                                              backDynamicInput.linkAssetFileName
                                            }
                                            onClick={(e) =>
                                              this.backRemoveLink(e, i)
                                            }
                                            style={{
                                              color: "Red",
                                              fontWeight: "bold",
                                              fontSize: "22px",
                                              border: "5px solid white",
                                            }}
                                          ></span>
                                        </a>
                                      </div>
                                    </td>
                                    {/* START - Column 5 - Remove Icon */}
                                  </tr>
                                );
                              } else if (
                                this.state.multiTouchChkBack === "Yes" &&
                                this.state.leadPerAssetChk === "No"
                              ) {
                                return (
                                  <tr>
                                    {/* START - Column 1 - Asset Link File Name */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div key={backDynamicInput.count}>
                                        <Input
                                          id="linkAssetFileName"
                                          name="linkAssetFileName"
                                          className={{}}
                                          placeholder="Enter Asset Link Name"
                                          onChange={this.handleAssetLink.bind(
                                            this,
                                            i
                                          )}
                                          style={{
                                            //width: "520px",
                                            borderRadius: "1px",
                                            minWidth: "150px",
                                          }}
                                          defaultValue={
                                            backDynamicInput.linkAssetFileName
                                          }
                                        />
                                      </div>
                                    </td>
                                    {/* END - Column 1 - Asset Link File Name */}

                                    {/* START - Column 2 - Asset Link */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div key={backDynamicInput.count}>
                                        <Input
                                          id="assetLink"
                                          name="assetLink"
                                          placeholder="Enter Asset Link"
                                          onChange={this.handleAssetLink.bind(
                                            this,
                                            i
                                          )}
                                          style={{
                                            //width: "520px",
                                            borderRadius: "1px",
                                            minWidth: "150px",
                                          }}
                                          defaultValue={
                                            backDynamicInput.assetLink
                                          }
                                        />
                                      </div>
                                    </td>
                                    {/* END - Column 2 - Asset Link */}

                                    {/* START - Column 3 - Multitouch */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div key={backDynamicInput.count}>
                                        <select
                                          value={backDynamicInput.multiTouch}
                                          style={{
                                            borderRadius: "1px",
                                            margin: "1.5px",
                                            border: "0.5px solid #d9d9d9",
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            paddingLeft: 3,
                                            paddingRight: 3,
                                            //width: "120px",
                                            minWidth: "90px",
                                            width: "100%",
                                          }}
                                          name="multiTouch"
                                          onChange={this.handleAssetLink.bind(
                                            this,
                                            i
                                          )}
                                        >
                                          <option value="1st Touch">
                                            1st Touch
                                          </option>
                                          <option value="2nd Touch">
                                            2nd Touch
                                          </option>
                                          <option value="3rd Touch">
                                            3rd Touch
                                          </option>
                                        </select>
                                      </div>
                                    </td>
                                    {/* END - Column 3 - Multitouch */}

                                    {/* START - Column 4 - Remove Icon */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div
                                        key={backDynamicInput.count}
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        &nbsp;&nbsp;{" "}
                                        <a>
                                          <span
                                            class="glyphicon glyphicon-minus-sign"
                                            id={i}
                                            name={backDynamicInput.supportDocID}
                                            suppDocName={
                                              backDynamicInput.linkAssetFileName
                                            }
                                            onClick={(e) =>
                                              this.backRemoveLink(e, i)
                                            }
                                            style={{
                                              color: "Red",
                                              fontWeight: "bold",
                                              fontSize: "22px",
                                              border: "5px solid white",
                                            }}
                                          ></span>
                                        </a>
                                      </div>
                                    </td>
                                    {/* END - Column 4 - Remove Icon */}
                                  </tr>
                                );
                              } else if (
                                this.state.multiTouchChkBack === "Yes" &&
                                this.state.leadPerAssetChk === "Yes"
                              ) {
                                return (
                                  <tr>
                                    {/* START - Column 1 - Asset Link File Name */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div key={backDynamicInput.count}>
                                        <Input
                                          id="linkAssetFileName"
                                          name="linkAssetFileName"
                                          className={{}}
                                          placeholder="Enter Asset Link Name"
                                          onChange={this.handleAssetLink.bind(
                                            this,
                                            i
                                          )}
                                          style={{
                                            //width: "180px",
                                            borderRadius: "1px",
                                            minWidth: "150px",
                                          }}
                                          defaultValue={
                                            backDynamicInput.linkAssetFileName
                                          }
                                        />
                                      </div>
                                    </td>
                                    {/* END - Column 1 - Asset Link File Name */}

                                    {/* START - Column 2 - Asset Link */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div
                                        key={backDynamicInput.count}
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <Input
                                          id="assetLink"
                                          name="assetLink"
                                          placeholder="Enter Asset Link"
                                          onChange={this.handleAssetLink.bind(
                                            this,
                                            i
                                          )}
                                          style={{
                                            //width: "520px",
                                            borderRadius: "1px",
                                            minWidth: "150px",
                                          }}
                                          defaultValue={
                                            backDynamicInput.assetLink
                                          }
                                        />
                                      </div>
                                    </td>
                                    {/* END - Column 2 - Asset Link */}

                                    {/* START - Column 3 - Lead % */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div key={backDynamicInput.count}>
                                        <Input
                                          id="leadPercentage"
                                          name="leadPercentage"
                                          defaultValue={
                                            backDynamicInput.leadPercentage
                                          }
                                          placeholder="Enter Lead %"
                                          onChange={this.handleAssetLink.bind(
                                            this,
                                            i
                                          )}
                                          style={{
                                            //width: "180px",
                                            borderRadius: "1px",
                                          }}
                                        />
                                      </div>
                                    </td>
                                    {/* END - Column 3 - Lead % */}

                                    {/* START - Column 4 - Lead/Asset */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div key={backDynamicInput.count}>
                                        <Input
                                          id="leadPerAsset"
                                          name="leadPerAsset"
                                          value={backDynamicInput.leadPerAsset}
                                          disabled
                                          style={{
                                            //width: "180px",
                                            borderRadius: "1px",
                                          }}
                                        />
                                      </div>
                                    </td>
                                    {/* END - Column 4 - Lead/Asset */}

                                    {/* START - Column 5 - Multitouch */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div key={backDynamicInput.count}>
                                        <select
                                          value={backDynamicInput.multiTouch}
                                          style={{
                                            borderRadius: "1px",
                                            margin: "1.5px",
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            paddingLeft: 3,
                                            paddingRight: 3,
                                            border: "1px solid #d9d9d9",
                                            //width: "120px",
                                            minWidth: "90px",
                                            width: "100%",
                                          }}
                                          name="multiTouch"
                                          onChange={this.handleAssetLink.bind(
                                            this,
                                            i
                                          )}
                                        >
                                          <option value="1st Touch">
                                            1st Touch
                                          </option>
                                          <option value="2nd Touch">
                                            2nd Touch
                                          </option>
                                          <option value="3rd Touch">
                                            3rd Touch
                                          </option>
                                        </select>
                                      </div>
                                    </td>
                                    {/* END - Column 5 - Multitouch */}

                                    {/* START - Column 6 - Remove Icon */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div
                                        key={backDynamicInput.count}
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <a>
                                          <span
                                            class="glyphicon glyphicon-minus-sign"
                                            id={i}
                                            name={backDynamicInput.supportDocID}
                                            suppDocName={
                                              backDynamicInput.linkAssetFileName
                                            }
                                            onClick={(e) =>
                                              this.backRemoveLink(e, i)
                                            }
                                            style={{
                                              color: "Red",
                                              fontWeight: "bold",
                                              fontSize: "22px",
                                              border: "5px solid white",
                                            }}
                                          ></span>
                                        </a>
                                      </div>
                                    </td>
                                    {/* END - Column 6 - Remove Icon */}
                                  </tr>
                                );
                              } else {
                                return (
                                  <tr>
                                    {/* START - Column 1 - Asset Link File Name */}
                                    <td
                                      id="assetLink1"
                                      style={{ verticalAlign: "middle" }}
                                    >
                                      <div
                                        key={backDynamicInput.count}
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <Input
                                          id="linkAssetFileName"
                                          name="linkAssetFileName"
                                          className={{}}
                                          placeholder="Enter Asset Link Name"
                                          onChange={this.handleAssetLink.bind(
                                            this,
                                            i
                                          )}
                                          style={{
                                            //width: "520px",
                                            borderRadius: "1px",
                                            minWidth: "150px",
                                          }}
                                          defaultValue={
                                            backDynamicInput.linkAssetFileName
                                          }
                                        />
                                      </div>
                                    </td>
                                    {/* END - Column 1 - Asset Link File Name */}

                                    {/* START - Column 2 - Asset Link */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div key={backDynamicInput.count}>
                                        <Input
                                          id="assetLink"
                                          name="assetLink"
                                          placeholder="Enter Asset Link"
                                          onChange={this.handleAssetLink.bind(
                                            this,
                                            i
                                          )}
                                          style={{
                                            //width: "520px",
                                            borderRadius: "1px",
                                            minWidth: "150px",
                                          }}
                                          defaultValue={
                                            backDynamicInput.assetLink
                                          }
                                        />
                                      </div>
                                    </td>
                                    {/* END - Column 2 - Asset Link */}

                                    {/* START - Column 3 - Remove Icon */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div
                                        key={backDynamicInput.count}
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <a>
                                          <span
                                            class="glyphicon glyphicon-minus-sign"
                                            id={i}
                                            name={backDynamicInput.supportDocID}
                                            suppDocName={
                                              backDynamicInput.linkAssetFileName
                                            }
                                            onClick={(e) =>
                                              this.backRemoveLink(e, i)
                                            }
                                            style={{
                                              color: "Red",
                                              fontWeight: "bold",
                                              fontSize: "22px",
                                              border: "5px solid white",
                                            }}
                                          ></span>
                                        </a>
                                      </div>
                                    </td>
                                    {/* END - Column 3 - Remove Icon */}
                                  </tr>
                                );
                              }
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* End - Uploaded Assets Link Table */}
                {/* End - Upload Supporting Asset */}

                {/* Start - Divider */}
                <hr
                  style={{
                    height: 2,
                    backgroundColor: "#f5f5f5",
                    marginLeft: 15,
                    marginRight: 15,
                    borderWidth: 0,
                  }}
                />
                {/* End - Divider */}
                {/* //snehal-task3620-Add exclude domains(ui chages) */}
                {/* Start - ABM and Suppression */}
                {/* Start - Heading - ABM and Suppression */}
                <div className="row row-margin-fix ">
                  <label className="sub-content-title">
                    ABM and Suppression
                  </label>
                </div>
                {/* End - Heading - ABM, Suppression and Exclusion */}

                <div className="row row-margin-fix mt-3">
                  {/* Start - ABM */}
                  <div className="col-md-4 col-sm-12 mr-md-2 px-0">
                    <div className="files-box">
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="mb-0 files-box-header">
                          ABM
                          {this.state.campaignType == "ABM" && (
                            <span style={{ color: "red" }}>&nbsp;*</span>
                          )}
                          &nbsp;:&nbsp;
                          <Tooltip
                            placement="right"
                            title="The ABM file must have 1 column with header name as â€œAccount Nameâ€ OR â€œDomain Nameâ€ "
                          >
                            <Icon type="question-circle" />
                          </Tooltip>
                        </label>
                        <img
                          className="float-right mx-1"
                          style={{ height: 18, width: 18, cursor: "pointer" }}
                          src="upload.png"
                          onClick={this.handleChangeMultipleFileOfABM}
                        />
                      </div>
                      {this.state.sdEditDetailABM.length >= 1 ? (
                        <div id="noabmList" style={{ display: "none" }}>
                          {this.state.sdEditDetailABM.length} ABM Files Selected
                        </div>
                      ) : (
                        <div id="noabmList" style={{ display: "none" }}>
                          0 ABM Files Selected
                        </div>
                      )}
                      <div
                        className="mt-2 mb-0"
                        id="abmMessage"
                        style={{
                          color: "green",
                          fontWeight: "normal",
                          fontSize: 14,
                        }}
                      ></div>
                      <div className="mt-2 mb-0" style={{ color: "red" }}>
                        {this.state.abmerror.abmerr1}
                      </div>
                      <div
                        className="mt-2 mb-0"
                        id={"abmError"}
                        style={{
                          color: "red",
                          fontWeight: "normal",
                          fontSize: 14,
                        }}
                      ></div>
                      <div
                        className="mt-2"
                        style={
                          this.state.abmFilesSelectedDocuments.length > 5
                            ? {
                                maxHeight: 280,
                                overflowY: "auto",
                              }
                            : {}
                        }
                      >
                        {this.state.abmFilesSelectedDocuments &&
                          this.state.abmFilesSelectedDocuments.map((item) => {
                            return (
                              <div
                                className="row align-items-center mb-2"
                                supportDocID={item.supportDocID}
                                style={{
                                  backgroundColor: item.success
                                    ? "#DAECDA"
                                    : "#FFEEF0",
                                  paddingLeft: 12,
                                  paddingTop: 12,
                                  paddingBottom: 12,
                                  paddingRight: 12,
                                  marginLeft: 0,
                                  marginRight: 0,
                                  borderRadius: 2,
                                }}
                              >
                                <div
                                  style={{ padding: 0, margin: 0 }}
                                  className="col-xs-2 col-md-auto"
                                >
                                  {item.loading ? (
                                    <CircularProgress
                                      size={26}
                                      style={{
                                        height: 26,
                                        width: 26,
                                        display: "flex",
                                        margin: "auto",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      style={{
                                        height: 26,
                                        width: 26,
                                      }}
                                      src={
                                        item.success
                                          ? "success.png"
                                          : "failed.png"
                                      }
                                    />
                                  )}
                                </div>
                                <div
                                  style={{ padding: 0, margin: 0 }}
                                  className="col overflow-hidden align-middle"
                                >
                                  <div className="row align-middle m-0 p-0">
                                    <label
                                      className="mb-0 ml-2 overflow-hidden align-middle"
                                      style={{
                                        fontWeight: "normal",
                                        fontSize: 13,
                                      }}
                                    >
                                      {item.suppDocName || item.dataFileID}
                                    </label>
                                  </div>
                                  <div className="row align-middle m-0 p-0">
                                    <label
                                      className="mb-0 ml-2 overflow-hidden align-middle"
                                      style={{
                                        fontWeight: "normal",
                                        fontSize: 11,
                                      }}
                                    >
                                      ABM Count : {item.count}
                                    </label>
                                  </div>
                                </div>
                                <a
                                  id={
                                    item.typeOfSuppDoc + " " + item.supportDocID
                                  }
                                  name={item.suppDocName || item.dataFileID}
                                  className="col-xs-2 col-md-auto justify-content-center"
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    width: "auto",
                                  }}
                                  onClick={this.dynamicABMFile}
                                >
                                  <img
                                    className="mb-0 mx-2"
                                    style={{
                                      height: 18,
                                      width: 18,
                                    }}
                                    src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
                                  />
                                </a>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {/* End - ABM */}

                  {/* Start - Suppression */}
                  <div className="offset-md-1 offset-sm-0 col-md-4  col-sm-12 px-0 mt-md-0 mt-3">
                    <div className="files-box">
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="mb-0 files-box-header">
                          Suppression :&nbsp;
                          <Tooltip
                            placement="right"
                            title="Suppersion file can contain 1 column Email"
                          >
                            <Icon type="question-circle" />
                          </Tooltip>
                        </label>
                        <img
                          className="float-right mx-1"
                          style={{ height: 18, width: 18, cursor: "pointer" }}
                          src="upload.png"
                          onClick={this.handleChangeMultipleFileOfSuppression}
                        />
                      </div>
                      {this.state.sdEditDetailSuppression.length >= 1 ? (
                        <div id="nosuppressionList" style={{ display: "none" }}>
                          {" "}
                          {this.state.sdEditDetailSuppression.length}{" "}
                          Suppression Files Selected
                        </div>
                      ) : (
                        <div id="nosuppressionList" style={{ display: "none" }}>
                          0 Suppression Files Selected
                        </div>
                      )}
                      <div
                        id="suppresionMessage"
                        style={{ color: "green" }}
                      ></div>
                      <div style={{ color: "red" }}>
                        {this.state.suppressionerror.suppressionerr}
                      </div>
                      <div
                        id={"suppressionError"}
                        style={{ color: "red" }}
                      ></div>
                      <div
                        className="mt-2"
                        style={
                          this.state.suppressionFilesSelectedDocuments.length >
                          5
                            ? {
                                maxHeight: 280,
                                overflowY: "auto",
                              }
                            : {}
                        }
                      >
                        {this.state.suppressionFilesSelectedDocuments &&
                          this.state.suppressionFilesSelectedDocuments.map(
                            (item) => {
                              return (
                                <div
                                  className="row align-items-center mb-2"
                                  style={{
                                    backgroundColor: item.success
                                      ? "#DAECDA"
                                      : "#FFEEF0",
                                    paddingLeft: 12,
                                    paddingTop: 12,
                                    paddingBottom: 12,
                                    paddingRight: 12,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    borderRadius: 2,
                                  }}
                                >
                                  <div
                                    style={{ padding: 0, margin: 0 }}
                                    className="col-xs-2 col-md-auto"
                                  >
                                    {item.loading ? (
                                      <CircularProgress
                                        size={26}
                                        style={{
                                          height: 26,
                                          width: 26,
                                          display: "flex",
                                          margin: "auto",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        style={{
                                          height: 26,
                                          width: 26,
                                        }}
                                        src={
                                          item.success
                                            ? "success.png"
                                            : "failed.png"
                                        }
                                      />
                                    )}
                                  </div>
                                  <div
                                    style={{ padding: 0, margin: 0 }}
                                    className="col overflow-hidden"
                                  >
                                    <label
                                      className="mb-0 ml-2 overflow-hidden align-middle"
                                      style={{
                                        fontWeight: "normal",
                                        fontSize: 13,
                                      }}
                                    >
                                      {item.suppDocName || item.dataFileID}
                                    </label>
                                  </div>
                                  <a
                                    id={
                                      item.typeOfSuppDoc +
                                      " " +
                                      item.supportDocID
                                    }
                                    name={item.suppDocName || item.dataFileID}
                                    className="col-xs-2 col-md-auto justify-content-center"
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      width: "auto",
                                    }}
                                    onClick={this.dynamicABMFile}
                                  >
                                    <img
                                      className="mb-0 mx-2"
                                      style={{
                                        height: 18,
                                        width: 18,
                                      }}
                                      src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
                                    />
                                  </a>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </div>
                  {/* End - Suppression */}
                  {/* //snehal-task3620-Add exclude domains(ui chages) */}
                </div>
                <br />
                <hr
                  style={{
                    height: 2,
                    backgroundColor: "#f5f5f5",
                    marginLeft: 15,
                    marginRight: 15,
                    borderWidth: 0,
                  }}
                />
                {/* End - Divider */}

                {/* Start - Exclusion and Exclude Domain Extension */}
                {/* Start - Heading - Exclusion and Exclude Domain Extension */}
                <div className="row row-margin-fix ">
                  <label className="sub-content-title">
                    Exclusion and Exclude Domain Extension
                  </label>
                </div>
                <div className="row row-margin-fix mt-3">
                  {/* Start - Exclusion */}
                  <div className="col-md-4 col-sm-12 px-0 mt-md-0 mt-3">
                    <div className="files-box">
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="mb-0 files-box-header">
                          Exclusion :&nbsp;
                          <Tooltip
                            placement="right"
                            title="Exclusion file can contain 1 column Domain"
                          >
                            <Icon type="question-circle" />
                          </Tooltip>
                        </label>
                        <img
                          className="float-right mx-1"
                          style={{ height: 18, width: 18, cursor: "pointer" }}
                          src="upload.png"
                          onClick={this.handleChangeMultipleFileOfExclusion}
                        />
                      </div>
                      {this.state.sdEditDetailExclusion.length >= 1 ? (
                        <div id="noexclusionList" style={{ display: "none" }}>
                          {this.state.sdEditDetailExclusion.length} Exclusion
                          Files Selected
                        </div>
                      ) : (
                        <div id="noexclusionList" style={{ display: "none" }}>
                          0 Exclusion Files Selected
                        </div>
                      )}
                      <div
                        id="exclusionMessage"
                        style={{ color: "green" }}
                      ></div>
                      <div style={{ color: "red" }}>
                        {this.state.exclusionerror.exclusionerr}
                      </div>
                      <div id={"exclusionError"} style={{ color: "red" }}></div>
                      <div
                        className="mt-2"
                        style={
                          this.state.exclusionFilesSelectedDocuments.length > 5
                            ? {
                                maxHeight: 280,
                                overflowY: "auto",
                              }
                            : {}
                        }
                      >
                        {this.state.exclusionFilesSelectedDocuments &&
                          this.state.exclusionFilesSelectedDocuments.map(
                            (item) => {
                              return (
                                <div
                                  className="row align-items-center mb-2"
                                  style={{
                                    backgroundColor: item.success
                                      ? "#DAECDA"
                                      : "#FFEEF0",
                                    paddingLeft: 12,
                                    paddingTop: 12,
                                    paddingBottom: 12,
                                    paddingRight: 12,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    borderRadius: 2,
                                  }}
                                >
                                  <div
                                    style={{ padding: 0, margin: 0 }}
                                    className="col-xs-2 col-md-auto"
                                  >
                                    {item.loading ? (
                                      <CircularProgress
                                        size={26}
                                        style={{
                                          height: 26,
                                          width: 26,
                                          display: "flex",
                                          margin: "auto",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        style={{
                                          height: 26,
                                          width: 26,
                                        }}
                                        src={
                                          item.success
                                            ? "success.png"
                                            : "failed.png"
                                        }
                                      />
                                    )}
                                  </div>
                                  <div
                                    style={{ padding: 0, margin: 0 }}
                                    className="col overflow-hidden"
                                  >
                                    <label
                                      className="mb-0 ml-2 overflow-hidden align-middle"
                                      style={{
                                        fontWeight: "normal",
                                        fontSize: 13,
                                      }}
                                    >
                                      {item.suppDocName || item.dataFileID}
                                    </label>
                                  </div>
                                  <a
                                    id={
                                      item.typeOfSuppDoc +
                                      " " +
                                      item.supportDocID
                                    }
                                    name={item.suppDocName || item.dataFileID}
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      width: "auto",
                                    }}
                                    className="col-xs-2 col-md-auto justify-content-center"
                                    onClick={this.dynamicABMFile}
                                  >
                                    <img
                                      className="mb-0 mx-2"
                                      style={{
                                        height: 18,
                                        width: 18,
                                      }}
                                      src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
                                    />
                                  </a>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </div>
                  {/* End - Exclusion */}
                  {/* //snehal-task3620-Add exclude domains(ui chages) */}
                  <div className="row row-margin-fix mt-3">
                    {/* Start - Exclusion */}
                    <div className="offset-md-2 offset-sm-0 col-md-4 col-sm-12 px-0 mt-md-0 mt-3">
                      <div className="files-box" id="excludeTextcard">
                        <div className="d-flex justify-content-between align-items-center">
                          <label className="mb-12 files-box-header">
                            Exclude Domain Extension :&nbsp;
                            <Tooltip
                              placement="right"
                              title="Leads with domain extension as below will be rejected."
                            >
                              <Icon type="question-circle" />
                            </Tooltip>
                          </label>
                        </div>
                        <textarea
                          className=""
                          style={{
                            height: "175px",
                            resize: "none",
                            border: "1px solid #cccccc",
                          }}
                          id="excludeText"
                          name="excludeText"
                          onChange={this.excludeTextChange}
                          value={this.state.excludeText}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <hr
                  style={{
                    height: 2,
                    backgroundColor: "#f5f5f5",
                    marginLeft: 15,
                    marginRight: 15,
                    borderWidth: 0,
                  }}
                />
                {/* End - Divider */}

                {/* End - ABM, Suppression and Exclusion */}

                {/* Start - Other Documents */}
                <div className="col px-0">
                  {/* Start - Heading - Other Documents */}
                  <div className="row row-margin-fix">
                    <label className="sub-content-title">Other Documents</label>
                  </div>
                  {/* End - Heading - Other Documents */}

                  {/* Start - Other Documents files */}
                  <div className="row row-margin-fix mt-3">
                    <div className="col-md-4 col-sm-12 mr-md-2 px-0">
                      <div className="files-box">
                        <div className="d-flex justify-content-between align-items-center">
                          <label className="mb-0 files-box-header">
                            Other :&nbsp;
                          </label>
                          <img
                            className="float-right mx-1"
                            style={{ height: 18, width: 18, cursor: "pointer" }}
                            src="upload.png"
                            onClick={this.handleChangeMultipleFileOfOther}
                          />
                        </div>
                        {this.state.sdEditDetailOther.length >= 1 ? (
                          <div id="nootherList" style={{ display: "none" }}>
                            {this.state.sdEditDetailOther.length} Other Files
                            Selected
                          </div>
                        ) : (
                          <div id="nootherList" style={{ display: "none" }}>
                            0 Other Files Selected
                          </div>
                        )}
                        <div id="otherMessage" style={{ color: "green" }}></div>
                        <div
                          className="mt-2"
                          style={
                            this.state.otherFilesSelectedDocuments.length > 5
                              ? {
                                  maxHeight: 280,
                                  overflowY: "auto",
                                }
                              : {}
                          }
                        >
                          {this.state.otherFilesSelectedDocuments &&
                            this.state.otherFilesSelectedDocuments.map(
                              (item) => {
                                return (
                                  <div
                                    className="row align-items-center mb-2"
                                    style={{
                                      backgroundColor: item.success
                                        ? "#DAECDA"
                                        : "#FFEEF0",
                                      paddingLeft: 12,
                                      paddingTop: 12,
                                      paddingBottom: 12,
                                      paddingRight: 12,
                                      marginLeft: 0,
                                      marginRight: 0,
                                      borderRadius: 2,
                                    }}
                                  >
                                    <div
                                      style={{ padding: 0, margin: 0 }}
                                      className="col-xs-2 col-md-auto"
                                    >
                                      {item.loading ? (
                                        <CircularProgress
                                          size={26}
                                          style={{
                                            height: 26,
                                            width: 26,
                                            display: "flex",
                                            margin: "auto",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          style={{
                                            height: 26,
                                            width: 26,
                                          }}
                                          src={
                                            item.success
                                              ? "success.png"
                                              : "failed.png"
                                          }
                                        />
                                      )}
                                    </div>
                                    <div
                                      style={{ padding: 0, margin: 0 }}
                                      className="col overflow-hidden"
                                    >
                                      <label
                                        className="mb-0 ml-2 overflow-hidden align-middle"
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 13,
                                        }}
                                      >
                                        {item.suppDocName || item.dataFileID}
                                      </label>
                                    </div>
                                    <a
                                      id={
                                        item.typeOfSuppDoc +
                                        " " +
                                        item.supportDocID
                                      }
                                      name={item.suppDocName || item.dataFileID}
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        width: "auto",
                                      }}
                                      className="col-xs-2 col-md-auto justify-content-center"
                                      onClick={this.dynamicABMFile}
                                    >
                                      <img
                                        className="mb-0 mx-2"
                                        style={{
                                          height: 18,
                                          width: 18,
                                        }}
                                        src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
                                      />
                                    </a>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End - Other Documents files */}
                </div>
                {/* End - Other Documents */}

                {/* Start - Button Save and Next */}
                <div className="row mt-5 mt-md-0">
                  <div className="col px-0">
                    <button
                      className="btn-save-and-next float-right mr-5"
                      onClick={this.submitStep1}
                    >
                      Save and Next
                    </button>
                  </div>
                </div>
                {/* End - Button Save and Next */}
                <Footer />
              </Card>
              {/* End - Step 1 - Supporting Documents */}

              {/* Start - Step 2 - DI QA & Delivery Format */}
              <Card
                className="card-addon"
                bordered={false}
                style={{ display: this.state.displayStep2 }}
              >
                {/* Start - Navigation */}
                <div className="card-navigation">
                  <a style={{ color: "#056eb8" }}>
                    <FaArrowAltCircleLeft
                      size={25}
                      style={{
                        float: "left",
                        marginRight: "5px",
                        marginBottom: "5px",
                      }}
                      onClick={this.handleBackButton}
                    />
                  </a>
                  <button
                    className="btn-save-and-next"
                    onClick={this.submitStep2}
                  >
                    Save and Finish
                  </button>
                </div>
                {/* End - Navigation */}

                {/* Start - Heading - Quality Assurance */}
                <div className="row sub-content align-items-baseline">
                  <div className="sub-content-heading">
                    <label className="sub-content-title">
                      Quality Assurance&nbsp;
                    </label>
                    <Tooltip
                      placement="right"
                      title="This option will divert all the leads to DI-QA for review after submission from Publisher. All the leads will be visible to Agency only after DI-QA review completion."
                    >
                      <Icon type="question-circle" />
                    </Tooltip>
                  </div>
                  <div className="ml-3">
                    <label
                      className="mb-0 mr-2 ml-lg-3"
                      style={{
                        fontSize: 15,
                        fontWeight: "normal",
                        color: "black",
                      }}
                    >
                      DI QA
                    </label>
                    <Switch
                      size="small"
                      onChange={this.switchHandlechange}
                      checked={this.state.switchValue == "ON" ? true : false}
                    />
                  </div>
                </div>
                {/* End - Heading - Quality Assurance */}

                {/* Start - Delivery Format */}
                {/* Start - Heading - Delivery Format */}
                <div className="row row-margin-fix mt-3 mb-2">
                  <label className="sub-content-title">Delivery Format</label>
                </div>
                {/* End - Heading - Delivery Format */}

                {/* Start - Delivery Format - sub details */}
                <div className="justify-content mt-2 justify-content-between flex-wrap">
                  {/* Aman task-4765 (Done some layout changes) City-state validation checkbox Div*/}
                  <br />
                  <div className="checkbox-grid row-margin-fix mt-1 mb-sm-3">
                    {/* 	 //rutja task-4694 added country abbreviation Div*/}
                    {/* <div className="pr-0"> */}
                    <Checkbox
                      name="countryAbbreviation"
                      value={this.state.countryAbbreviation}
                      onChange={(e) => {
                        this.handleChecked(e);
                      }}
                    >
                      {/* Aman task-4765 (added Tooltip) City-state validation checkbox Div*/}
                      <span style={{ color: "#11509F" }}>
                        Country Abbreviation&nbsp;
                        <Tooltip
                          placement="right"
                          title="Select checkbox for country abbreviation in lead template."
                        >
                          <Icon type="question-circle" />
                        </Tooltip>
                      </span>
                    </Checkbox>
                    {/* </div> */}
                    {/* Aman task-4765 added City-state validation checkbox Div*/}
                    <Checkbox
                      disabled //Aman-4765- disabled this check box
                      name="cityStateValidationCheck"
                      checked={this.state.cityStateValidationCheck === "ON"}
                      defaultChecked={
                        this.state.cityStateValidationCheck === "ON"
                      } // Set initial checked state
                      onChange={this.handleCityStateValidationChecked}
                    >
                      {/* Aman task-4765 (added Tooltip) City-state validation checkbox Div*/}
                      <span style={{ color: "#11509F" }}>
                        City/State Validation&nbsp;
                        <Tooltip
                          placement="right"
                          title="Select checkbox for city-state validation for lead upload."
                        >
                          <Icon type="question-circle" />
                        </Tooltip>
                      </span>
                    </Checkbox>
                    <div className="d-flex flex-wrap">
                      {/* justify-content-md-end ml-1 */}
                      {/* <div className="pr-0"> */}
                      <Checkbox
                        disabled={
                          this.state.agencyCustomizeMapping.length > 0
                            ? false
                            : true
                        }
                        name="customizeMapping"
                        value={this.state.customizeMapping}
                        onChange={(e) => {
                          this.handleChecked(e);
                          if (this.state.agencyCustomizeMapping.length > 0) {
                            this.setcustomizeMapping(e);
                          }
                        }}
                      >
                        <span style={{ color: "#11509F" }}>
                          Customize Mapping:
                        </span>
                      </Checkbox>
                      {/* </div> */}
                      {this.state.agencyCustomizeMapping.length > 0 && (
                        <div className="d-flex align-items-baseline">
                          <div className="d-flex mr-md-2 align-items-center">
                            <label
                              style={{
                                marginBottom: 0,
                                color: "#11509F",
                                fontWeight: "normal",
                              }}
                            >
                              Format:&nbsp;
                            </label>
                            <img
                              style={{ height: 18, width: 18 }}
                              src="incremental.jpg"
                              data-toggle="modal"
                              data-target="#customizeMappingAdd"
                              onClick={this.customizeMappingFormatHandleChange}
                            />
                          </div>

                          {/* Start - Modal - when clicked on plus icon */}
                          <AgencyCustomizeMappingModal
                            agencyCustomizeMapping={
                              this.state.agencyCustomizeMappingModal
                            }
                            customizeMappingCancleHandleChange={
                              this.customizeMappingCancleHandleChange
                            }
                          />
                          {/* End - Modal - when clicked on plus icon */}

                          <select
                           //Nilesh-5002- set a valaue
                            value={this.state.newCustomizeMappingFormat}
                            onChange={this.handleFormatChange}
                          >
                            {this.state.agencyCustomizeMapping.map(
                              (agencyCustomizeMapping) => {
                                return (
                                  <option
                                    value={
                                      this.state.sdEditDetail
                                        .customizeMappingFormat
                                    }
                                  >
                                   {/* Nilesh-5001- Add non english sentence when nonEnglishCheck is Yes in indelivery_format_mapping_agency table */}
                                     {agencyCustomizeMapping.format} {agencyCustomizeMapping.nonEnglishCheck == "Yes" ? "- Non English" : ""}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* //Aman-task-4765 added (change the layout as per the given UI)City-state validation checkbox */}
                  <div className="d-flex justify-content mt-2 row-margin-fix justify-content-between flex-wrap">
                    <label className="total-uploaded-message">
                      (Following Checked Fields Will Be Mandatory For Lead
                      Delivery Format)
                    </label>
                  </div>
                </div>
                {/* End - Delivery Format - sub details */}

                {/* Start - Checkbox Grid */}
                <div className="checkbox-grid row-margin-fix mt-1">
                  {/* Start - 1st row - Publisher Id, Campaign Id, Campaign Name, Allocation Id */}
                  <Checkbox
                    className="single-checkbox"
                    id="pID"
                    name="pID"
                    value={this.state.pID}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].pID
                      : "Publisher ID"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="campaignID"
                    name="campaignID"
                    value={this.state.campaignID}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].campID
                      : "Campaign ID"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    //id="campaignName"
                    name="campaignName"
                    disabled //kiran-4740-disable checkbox
                    value={this.state.campaignName}
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].campaignName
                      : "Campaign name"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    //id="reAllocationID"
                    name="reAllocationID"
                    value={this.state.reAllocationID}
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].reAllocationID
                      : "Allocation ID"}
                  </Checkbox>
                  {/* End - 1st row - Publisher Id, Campaign Id, Campaign Name, Allocation Id */}

                  {/* Start - 2nd row - Lead Interaction date, first name, last name, email */}
                  <Checkbox
                    className="single-checkbox"
                    id="leadInteractionDate"
                    name="leadInteractionDate"
                    value={this.state.leadInteractionDate}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0]
                          .leadInteractionDate
                      : "Lead interaction date"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="firstName"
                    name="firstName"
                    value={this.state.firstName}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].firstName
                      : "First name"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="lastName"
                    name="lastName"
                    value={this.state.lastName}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].lastName
                      : "Last name"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="email"
                    name="email"
                    value={this.state.email}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].email
                      : "Email"}
                  </Checkbox>
                  {/* End - 2nd row - Lead Interaction date, first name, last name, email */}

                  {/* Start - 3rd row - work phone, alternate phone no, company name, linkedIn company name */}
                  <Checkbox
                    className="single-checkbox"
                    id="workPhone"
                    name="workPhone"
                    value={this.state.workPhone}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].workPhone
                      : "Work phone"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    //id="alternatePhoneNo"
                    name="alternatePhoneNo"
                    value={this.state.alternatePhoneNo}
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0]
                          .alternatePhoneNo
                      : "Alternate phone no"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="companyName"
                    name="companyName"
                    value={this.state.companyName}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].companyName
                      : "Company name"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="linkedInCompanyName"
                    name="linkedInCompanyName"
                    value={this.state.linkedInCompanyName}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0]
                          .linkedInCompanyName
                      : "LinkedIn company name"}
                  </Checkbox>
                  {/* End - 3rd row - work phone, alternate phone no, company name, linkedIn company name */}

                  {/* Start - 4th row - industry, company employee size, company revenue, job title */}
                  <Checkbox
                    className="single-checkbox"
                    id="industry"
                    name="industry"
                    value={this.state.industry}
                    checked={this.state.industry === "Yes" ? true : false}
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].industry
                      : "Industry"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="companyEmployeeSize"
                    name="companyEmployeeSize"
                    value={this.state.companyEmployeeSize}
                    checked={
                      this.state.companyEmployeeSize === "Yes" ? true : false
                    }
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0]
                          .companyEmployeeSize
                      : "Company employee size"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="companyRevenue"
                    name="companyRevenue"
                    value={this.state.companyRevenue}
                    checked={this.state.companyRevenue === "Yes" ? true : false}
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].companyRevenue
                      : "Company revenue"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="jobTitleDelivery"
                    name="jobTitleDelivery"
                    value={this.state.jobTitleDelivery}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].jobTitle
                      : "Job title"}
                  </Checkbox>
                  {/* End - 4th row - industry, company employee size, company revenue, job title */}

                  {/* Start - 5th row - linkedIn job title, job title validation, job level, job function */}
                  <Checkbox
                    className="single-checkbox"
                    id="linkedInJobTitle"
                    name="linkedInJobTitle"
                    value={this.state.linkedInJobTitle}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0]
                          .linkedInJobTitle
                      : "LinkedIn job title"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="jobTitleValidation"
                    name="jobTitleValidation"
                    checked={
                      this.state.jobTitleValidation === "Yes" ? true : false
                    }
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    Job title validation
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="jobLevelDelivery"
                    name="jobLevelDelivery"
                    hecked={
                      this.state.jobLevelDelivery === "Yes" ? true : false
                    }
                    disabled
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].jobLevel
                      : "Job level"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="jobFunction"
                    name="jobFunction"
                    checked={
                      this.state.jobFunctionDelivery === "Yes" ? true : false
                    }
                    disabled
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].jobFunction
                      : "Job function"}
                  </Checkbox>
                  {/* End - 5th row - linkedIn job title, job title validation, job level, job function */}

                  {/* Start - 6th row - Country, state, city, zipcode */}
                  <Checkbox
                    className="single-checkbox"
                    id="countryDelivery"
                    name="countryDelivery"
                    value={this.state.countryDelivery}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].country
                      : "Country"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    //id="state"
                    name="state"
                    value={this.state.state}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].state
                      : "State"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="city"
                    name="city"
                    value={this.state.city}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].city
                      : "City"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="zipCode"
                    name="zipCode"
                    value={this.state.zipCode}
                    defaultChecked
                    disabled
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].zipCode
                      : "Zip code"}
                  </Checkbox>
                  {/* End - 6th row - Country, state, city, zipcode */}

                  {/* Start - 7th row - address, street, asset name, asset id */}
                  <Checkbox
                    className="single-checkbox"
                    id="address"
                    name="address"
                    value={this.state.address}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].address
                      : "Address"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    //id="street"
                    name="street"
                    value={this.state.street}
                    // defaultChecked
                    // disabled
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].street
                      : "Street"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="assetName"
                    name="assetName"
                    value={this.state.assetName}
                    defaultChecked
                    disabled
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].assetName
                      : "Asset name"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="assetId"
                    name="assetId"
                    value={this.state.assetId}
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].supportDocID
                      : "Asset ID"}
                  </Checkbox>
                  {/* End - 7th row - address, street, asset name, asset id */}

                  {/* Start - 8th row - linkedIn, domain, ip, comments */}
                  <Checkbox
                    className="single-checkbox"
                    //id="linkedIn"
                    name="linkedIn"
                    value={this.state.linkedIn}
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].linkedIn
                      : "LinkedIn"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    //id="domain"
                    name="domain"
                    value={this.state.domain}
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].domain
                      : "Domain"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    id="ip"
                    name="ip"
                    value={this.state.ip}
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].ip
                      : "IP"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    //id="comments"
                    name="comments"
                    value={this.state.comments}
                    onChange={this.handleChecked}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].comments
                      : "Comments"}
                  </Checkbox>
                  {/* End - 8th row - linkedIn, domain, ip, comments */}

                  {/* Start - rest of the rows for Extra fields */}
                  <Checkbox
                    className="single-checkbox"
                    name="extra1"
                    value={this.state.extra1}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra1
                      : "Extra 1"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra2"
                    value={this.state.extra2}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra2
                      : "Extra 2"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra3"
                    value={this.state.extra3}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra3
                      : "Extra 3"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra4"
                    value={this.state.extra4}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra4
                      : "Extra 4"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra5"
                    value={this.state.extra5}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra5
                      : "Extra 5"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra6"
                    value={this.state.extra6}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra6
                      : "Extra 6"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra7"
                    value={this.state.extra7}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra7
                      : "Extra 7"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra8"
                    value={this.state.extra8}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra8
                      : "Extra 8"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    name="extra9"
                    value={this.state.extra9}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra9
                      : "Extra 9"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra10"
                    value={this.state.extra10}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra10
                      : "Extra 10"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra11"
                    value={this.state.extra11}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra11
                      : "Extra 11"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra12"
                    value={this.state.extra12}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra12
                      : "Extra 12"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra13"
                    value={this.state.extra13}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra13
                      : "Extra 13"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    name="extra14"
                    value={this.state.extra14}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra14
                      : "Extra 14"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    name="extra15"
                    value={this.state.extra15}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra15
                      : "Extra 15"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    name="extra16"
                    value={this.state.extra16}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra16
                      : "Extra 16"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    name="extra17"
                    value={this.state.extra17}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra17
                      : "Extra 17"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    name="extra18"
                    value={this.state.extra18}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra18
                      : "Extra 18"}
                  </Checkbox>

                  <Checkbox
                    className="single-checkbox"
                    name="extra19"
                    value={this.state.extra19}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra19
                      : "Extra 19"}
                  </Checkbox>
                  <Checkbox
                    className="single-checkbox"
                    name="extra20"
                    value={this.state.extra20}
                    onChange={this.handleChangeCheck}
                  >
                    {/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
                    {/* Priyanka-4532-Added hutk label */}
                    {this.state.customizeMapping === "Yes" &&
                    this.state.agencyCustomizeMappingModal.length === 1
                      ? this.state.agencyCustomizeMappingModal[0].extra20
                      : "Extra 20 (hutk)"}
                  </Checkbox>

                     {/* Aman 5085 -set the other extra filed 21 to 30 value */}

                     {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra21 && (
                          this.state.agencyCustomizeMappingModal[0].extra21 !== "" || this.state.agencyCustomizeMappingModal[0].extra21 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra21"
                                  value={this.state.extra21}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra21
                                      : "Extra 21"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra22 && (
                          this.state.agencyCustomizeMappingModal[0].extra22 !== "" || this.state.agencyCustomizeMappingModal[0].extra22 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra22"
                                  value={this.state.extra22}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra22
                                      : "Extra 22"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra23 && (
                          this.state.agencyCustomizeMappingModal[0].extra23 !== "" || this.state.agencyCustomizeMappingModal[0].extra23 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra23"
                                  value={this.state.extra23}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra23
                                      : "Extra 23"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra24 && (
                          this.state.agencyCustomizeMappingModal[0].extra24 !== "" || this.state.agencyCustomizeMappingModal[0].extra24 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra24"
                                  value={this.state.extra24}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra24
                                      : "Extra 24"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra25 && (
                          this.state.agencyCustomizeMappingModal[0].extra25 !== "" || this.state.agencyCustomizeMappingModal[0].extra25 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra25"
                                  value={this.state.extra25}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra25
                                      : "Extra 25"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra26 && (
                          this.state.agencyCustomizeMappingModal[0].extra26 !== "" || this.state.agencyCustomizeMappingModal[0].extra26 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra26"
                                  value={this.state.extra26}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra26
                                      : "Extra 26"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  
                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra27 && (
                          this.state.agencyCustomizeMappingModal[0].extra27 !== "" || this.state.agencyCustomizeMappingModal[0].extra27 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra27"
                                  value={this.state.extra27}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra27
                                      : "Extra 27"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra28 && (
                          this.state.agencyCustomizeMappingModal[0].extra28 !== "" || this.state.agencyCustomizeMappingModal[0].extra28 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra28"
                                  value={this.state.extra28}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra28
                                      : "Extra 28"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra29 && (
                          this.state.agencyCustomizeMappingModal[0].extra29 !== "" || this.state.agencyCustomizeMappingModal[0].extra29 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra29"
                                  value={this.state.extra29}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra29
                                      : "Extra 29"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  {this.state.agencyCustomizeMappingModal[0] && (
                      this.state.agencyCustomizeMappingModal[0].extra30 && (
                          this.state.agencyCustomizeMappingModal[0].extra30 !== "" || this.state.agencyCustomizeMappingModal[0].extra30 !== null ? (
                              <Checkbox
                                  className="single-checkbox"
                                  name="extra30"
                                  value={this.state.extra30}
                                  onChange={this.handleChangeCheck}
                              >
                                  {this.state.customizeMapping === "Yes" &&
                                  this.state.agencyCustomizeMappingModal.length === 1
                                      ? this.state.agencyCustomizeMappingModal[0].extra30
                                      : "Extra 30"}
                              </Checkbox>
                          ) : null
                      )
                  )}

                  {/* End - rest of the rows for Extra fields */}
                </div>
                {/* End - Checkbox Grid */}
                {/* End - Delivery Format */}

                {/* Start - Button Save and Finish */}
                <div className="row mt-5">
                  <div className="col px-0">
                    <button
                      className="btn-save-and-next float-right mr-5"
                      onClick={this.submitStep2}
                    >
                      Save and Finish
                    </button>
                  </div>
                </div>
                {/* End - Button Save and Finish */}
                <Footer />
              </Card>
              {/* End - Step 2 - DI QA & Delivery Format */}
            </div>
          </div>
        </div>
      );
    }
  }
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
supportingDocumentCreate.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(supportingDocumentCreate)
);
